import React, { useState, useEffect } from 'react'
import { Table } from 'antd'
import Axios from 'axios'
import { provinces, propertyTypeMap } from './constants'
import { snakeCase } from 'lodash'
import snakecaseKeys from 'snakecase-keys'
import provinceList from './province_with_amphoes.json'
import { Box, Typography } from '@material-ui/core'
import { formatNumber } from 'Utils'
import UuidEncoder from 'uuid-encoder'

function convertToRaiNganWaa(area, unit) {
  // convert all units to square waa
  switch (unit) {
    case 'rai':
      area *= 400 // 1 rai = 400 sq. waa
      break
    case 'ngan':
      area *= 100 // 1 ngan = 100 sq. waa
      break
    case 'squareMeters':
      area /= 4 // 1 sq. m = 0.25 sq. waa
      break
    default:
      // already in square waa, do nothing
      break
  }

  const rai = Math.floor(area / 400)
  const ngan = Math.floor((area % 400) / 100)
  const waa = Math.floor(area % 100)
  const result = `${rai}-${ngan}-${waa}`
  return result
}

const encoder = new UuidEncoder('base64url')

export const sortDirectionWordMap = {
  ascend: 'asc',
  descend: 'desc',
}

const dataIndexToSortName = (dataIndex) => {
  if (!dataIndex) return null
  const dataIndexString = dataIndex.join('.')
  switch (dataIndexString) {
    case 'attributes.sellPrice':
      return 'sell_price'
    default:
      break
  }
  if (dataIndex.length === 2) return snakeCase(dataIndex[1])
  return ''
}

const renameKeys = (obj, newKeys) => {
  // Create an array of key-value pairs with the new keys, or the original keys if no new keys are specified
  const keyValues = Object.keys(obj).map((key) => {
    const newKey = newKeys[key] || key
    const newValue = obj[key]

    return { [newKey]: newValue }
  })

  // Merge the key-value pairs into a single object using Object.assign()
  const renamedObj = Object.assign({}, ...keyValues)

  // Return the renamed object
  return renamedObj
}

const filtersToParams = (filters) => {
  const newKeys = {
    'attributes.province': 'property_province',
    'attributes.amphoe': 'property_amphoe',
    'attributes.propertyType': 'property_type',
    'attributes.propertyStatus': 'property_status',
  }
  return renameKeys(filters, newKeys)
}

const UserPostTable = () => {
  const [state, setState] = useState({
    data: [],
    pagination: {
      position: 'both',
      pageSize: 10,
      showSizeChanger: true,
      size: 'small',
      // eslint-disable-next-line no-use-before-define
      onShowSizeChange: handleShowSizeChange,
    },
    loading: true,
    filters: null,
  })
  const [selectedProvince, setSelectedProvince] = useState(null)

  function handleShowSizeChange(current, pageSize) {
    setState((prevState) => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
        current,
        pageSize,
      },
    }))
  }

  const fetch = (params = {}) => {
    setState((prevState) => ({ ...prevState, loading: true }))
    Axios.post(
      'https://api.marketplace.assetbeyond.com/v1/properties',
      snakecaseKeys({
        ...params,
      })
    ).then((response) => {
      const { headers } = response
      const total = Number(headers['total-count'])
      const current = Number(headers['current-page'])

      setState((prevState) => ({
        filters: response.data.filters,
        loading: false,
        data: response.data.data,
        pagination: {
          ...prevState.pagination,
          current,
          total,
        },
      }))
    })
  }

  const handleTableChange = (pagination, filters, sorter) => {
    fetch({
      page: pagination.current,
      items: pagination.pageSize,
      sortBy: dataIndexToSortName(sorter.field),
      sortDirection: sortDirectionWordMap[sorter.order] || 'desc',
      ...filtersToParams(filters),
    })

    if (filters['attributes.province']) {
      setSelectedProvince(filters['attributes.province'][0])
    }
  }

  const columns = [
    {
      title: 'จังหวัด',
      id: 'province',
      dataIndex: ['attributes', 'province'],
      filters: provinces.map((province) => ({
        text: province,
        value: province,
      })),
      filterMultiple: false,
      filterSearch: true,
    },
    {
      title: 'อำเภอ/เขต',
      id: 'amphoe',
      dataIndex: ['attributes', 'amphoe'],
      filters: selectedProvince
        ? provinceList
            .find((province) => province.name === selectedProvince)
            .amphoes.map((amphoe) => {
              return { text: amphoe, value: amphoe }
            })
        : null,
      filterSearch: true,
    },
    {
      title: 'ประเภท',
      id: 'propertyType',
      dataIndex: ['attributes', 'propertyType'],
      filters: [
        { value: 'condo', text: 'คอนโด' },
        { value: 'house', text: 'บ้าน' },
        { value: 'townhouse', text: 'ทาวน์เฮาส์' },
        { value: 'land', text: 'ที่ดิน' },
        { value: 'office', text: 'ออฟฟิศ' },
        { value: 'commercial', text: 'อาคารพาณิชย์' },
        { value: 'factory', text: 'โรงงาน โกดัง' },
        { value: 'apartment', text: 'อพาร์ทเมนต์' },
        { value: 'hotel', text: 'โรงแรม' },
        { value: 'others', text: 'อื่นๆ' },
      ],
      render: (propertyType) => propertyTypeMap[propertyType],
    },
    {
      title: 'ขาย/เช่า',
      id: 'propertyStatus',
      dataIndex: ['attributes', 'propertyStatus'],
      render: (propertyStatus) =>
        propertyStatus === 'sell'
          ? 'ขาย'
          : propertyStatus === 'rent'
          ? 'ให้เช่า'
          : null,
      filterMultiple: false,
      filters: [
        {
          value: 'sell',
          text: 'ขาย',
        },
        {
          value: 'rent',
          text: 'ให้เช่า',
        },
      ],
    },
    {
      title: 'ราคาขาย',
      id: 'sellPrice',
      sorter: true,
      align: 'right',
      dataIndex: ['attributes', 'sellPrice'],
      render: (sellPrice) => (
        <Typography
          variant="body2"
          style={{ fontWeight: 600, color: 'rgb(9, 49, 112)' }}
        >
          {formatNumber(sellPrice)}
        </Typography>
      ),
    },
    {
      title: 'ราคาเช่า',
      id: 'rentPrice',
      sorter: true,
      align: 'right',
      dataIndex: ['attributes', 'rentPrice'],
      render: (rentPrice) =>
        rentPrice ? (
          <Typography
            variant="body2"
            style={{ fontWeight: 600, color: 'rgb(9, 49, 112)' }}
          >
            {formatNumber(rentPrice)}
          </Typography>
        ) : null,
    },
    {
      title: (
        <Box>
          <Typography variant="subtitle2">เนื้อที่</Typography>
          <Typography variant="body2" color="textSecondary">
            ไร่-งาน-ตร.ว.
          </Typography>
        </Box>
      ),
      align: 'right',
      id: 'areaSize',
      dataIndex: ['attributes', 'areaSize'],
      render: (text, record) =>
        text ? convertToRaiNganWaa(text, record.attributes.areaSizeUnit) : null,
    },
    {
      title: (
        <Box>
          <Typography variant="subtitle2">พื้นที่ใช้สอย</Typography>
          <Typography variant="body2" color="textSecondary">
            ตร.ม.
          </Typography>
        </Box>
      ),
      align: 'right',
      id: 'utilitySpace',
      dataIndex: ['attributes', 'utilitySpace'],
      render: (text) => (text ? text : null),
    },
    {
      title: 'ผู้โพสต์',
      id: 'role',
      dataIndex: ['attributes', 'owner', 'data', 'attributes', 'role'],
    },
    {
      title: 'ชื่อ',
      id: 'title',
      dataIndex: ['attributes', 'title'],
      render: (text, record) => (
        <Typography
          variant="body2"
          target="_blank"
          component="a"
          href={`https://assetbeyond.com/properties/${encoder.encode(
            record.attributes.uuid
          )}`}
        >
          {text}
        </Typography>
      ),
    },
  ]

  useEffect(() => {
    fetch()
  }, [])

  return (
    <Table
      bordered
      columns={columns}
      rowKey={(record) => record.attributes.uuid}
      dataSource={state.data}
      loading={state.loading}
      pagination={state.pagination}
      onChange={handleTableChange}
    />
  )
}

export default UserPostTable
