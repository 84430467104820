import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Pagination } from 'antd';

const PropertyPagination = ({
  totalCount,
  currentPage,
  setCurrentPage,
  perPage,
  setPerPage,
  currentTab,
}) => {
  const resetPages = useCallback(() => {
    setCurrentPage(1);
    setPerPage(12);
  }, [setPerPage, setCurrentPage]);

  useEffect(() => {
    resetPages();
  }, [currentTab, resetPages]);

  return (
    <Pagination
      // eslint-disable-next-line camelcase
      locale={{ items_per_page: '/ หน้า' }}
      style={{ flexShrink: 0 }}
      size="small"
      onChange={page => setCurrentPage(page)}
      pageSizeOptions={['12', '24', '36', '48']}
      current={currentPage}
      pageSize={perPage}
      onShowSizeChange={(current, size) => setPerPage(size)}
      total={totalCount}
      showSizeChanger
    />
  );
};

PropertyPagination.defaultProps = {
  currentTab: 0,
};

PropertyPagination.propTypes = {
  totalCount: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  perPage: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  setPerPage: PropTypes.func.isRequired,
  currentTab: PropTypes.number,
};

export default PropertyPagination;
