import React, { useEffect, useCallback, Fragment } from "react";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import TrainIcon from "@material-ui/icons/Train";
import { Box } from "@material-ui/core";
import { connect as formikConnect } from "formik";
import { ExpandableCheckbox, FieldSet } from "Components/fields";
import PropTypes from "prop-types";
import TollWayCheckboxes from "./TollwayCheckboxes";

function BaseAdsDirections(props) {
  const { nearbyPlaces, formik } = props;
  const { values, setFieldValue } = formik;
  const { selectedNearbyPlaces } = values;
  const { trainStationList, tollWayList } = selectedNearbyPlaces;
  const { trainStations } = nearbyPlaces;

  const combineList = useCallback(() => {
    const anyTrainSelected = !isEmpty(trainStationList);
    const anyTollWaySelected = !isEmpty(tollWayList);
    // If something is selected
    if (anyTrainSelected || anyTollWaySelected) {
      const selectedString = [
        ...[
          anyTrainSelected && "รถไฟฟ้าใกล้เคียง (BTS/MRT)",
          ...trainStationList.map(i => `- ${i.name}`),
          anyTrainSelected && ""
        ],
        ...[
          anyTollWaySelected && "โทลล์เวย์ / ทางพิเศษใกล้เคียง",
          ...tollWayList.map(i => `- ${i.name}`),
          anyTollWaySelected && ""
        ]
      ]
        .filter(n => n !== false)
        .join("\n");
      return selectedString;
    }
  }, [trainStationList, tollWayList]);

  useEffect(() => {
    if (combineList()) {
      setFieldValue("baseAdsDirections", combineList());
    }
  }, [combineList, setFieldValue]);

  return (
    <Fragment>
      <Box mt={2}>
        {!isEmpty(trainStations) && (
          <ExpandableCheckbox
            labelIcon={<TrainIcon />}
            name="trainStationList"
            label="BTS/MRT ใกล้เคียง"
            options={trainStations}
            secondaryLabelName="distance"
          />
        )}
        {!isEmpty(nearbyPlaces) && <TollWayCheckboxes />}
        <FieldSet
          required
          placeholder="เลือกสถานที่สำคัญจากด้านบน"
          multiline
          name="baseAdsDirections"
          label="การเดินทาง"
        />
      </Box>
    </Fragment>
  );
}

const mapStateToProps = state => ({
  nearbyPlaces: state.nearbyPlaces.nearbyPlaces
});

BaseAdsDirections.propTypes = {
  nearbyPlaces: PropTypes.objectOf(PropTypes.any).isRequired,
  formik: PropTypes.objectOf(PropTypes.any).isRequired
};

export default formikConnect(connect(mapStateToProps)(BaseAdsDirections));
