/* eslint-disable camelcase */
import React, { useState } from 'react';
import { MenuItem, Menu, Button, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import Axios from 'axios';
import EditConfirmDialog from 'Components/shared/EditConfirmDialog';
import DeleteConfirmationDialog from './DeleteConfirmationDialog';
import { disabledStatus } from '../functions';
import NotationDialog from './NotationDialog';
import OwnerChangeDialog from './OwnerChangeDialog';

const useStyles = makeStyles(theme => ({
  menuItem: {
    '&:hover': {
      color: theme.palette.text.primary,
    },
  },
}));
function PropertyControlMenu({
  userRole,
  uuid,
  submissionProgress,
  listNumber,
  refreshProperties,
}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [editConfirmDialogOpen, setEditConfirmDialogOpen] = useState(false);
  const [deleteConfirmDialogOpen, setDeleteConfirmDialogOpen] = useState(false);
  const [notationDialogOpen, setNotationDialogOpen] = useState(false);
  const [ownerChangeDialogOpen, setOwnerChangeDialogOpen] =useState(false)

  const open = Boolean(anchorEl);
  const { enqueueSnackbar } = useSnackbar();
  const disabledStatusObject = disabledStatus({ userRole, submissionProgress });

  const handlePropertyDeleteClick = () => setDeleteConfirmDialogOpen(true);
  const handleEditConfirmDialogOpen = () => setEditConfirmDialogOpen(true);

  const handleNotationClick = () => setNotationDialogOpen(true);
  const handleOwnerChangeDialogClick = () => setOwnerChangeDialogOpen(true);

  const handlePropertyDelete = () => {
    Axios.delete('/v1/properties/destroy', {
      params: {
        uuid,
      },
    }).then(response => {
      if (!response.data) {
        enqueueSnackbar('ลบทรัพย์เรียบร้อยแล้ว');
        refreshProperties();
      } else {
        enqueueSnackbar('เกิดข้อผิดพลาด');
      }
    });
  };

  const menuOptions = [
    {
      id: 1,
      name: 'view',
      label: 'ดูทรัพย์',
      disabled: false,
      href: `/${userRole}/property/view?${uuid}`,
    },
    {
      id: 2,
      name: 'notation',
      label: 'จัดการบันทึก',
      handleClick: handleNotationClick,
    },
    {
      id: 3,  
      label: 'เปลี่ยนผู้ดูแลทรัพย์',
      disabled: userRole === 'sales',
      handleClick: handleOwnerChangeDialogClick,
    },
    {
      id: 4,
      label: 'กรอกฟอร์มต่อ',
      disabled: submissionProgress !== 'draft',
      href: `/${userRole}/work-submit-form?${uuid}`,
    },
    {
      id: 5,
      label: 'คัดลอกมาสร้างใหม่',
      disabled: submissionProgress === 'draft' || userRole !== 'sales',
      href: `/${userRole}/work-submit-form/copy?${uuid}`,
    },
    {
      id: 6,
      name: 'approve',
      label: 'ตรวจสอบทรัพย์',
      disabled: disabledStatusObject.approve,
      href: `/${userRole}/property/approve?${uuid}`,
    },
    {
      id: 7,
      name: 'edit',
      label: 'แก้ไขทรัพย์',
      disabled: disabledStatusObject.edit,
      href: `/${userRole}/work-submit-form/edit?${uuid}`,
    },
    {
      id: 8,
      name: 'confirm_edit',
      label: 'ยืนยันการแก้ไข',
      disabled: disabledStatusObject.editConfirmation,
      handleClick: handleEditConfirmDialogOpen,
    },
    {
      id: 9,
      name: 'delete',
      label: 'ลบ',
      disabled: disabledStatusObject.delete,
      handleClick: handlePropertyDeleteClick,
    },
  ];

  const handleMenuClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleNotationDialogClose = () => {
    setNotationDialogOpen(false);
    handleMenuClose();
  };

  const handleOwnerChangeDialogClose = () => {
    setOwnerChangeDialogOpen(false);
    handleMenuClose();
  };

  return (
    <>
      {notationDialogOpen && (
        <NotationDialog
          userRole={userRole}
          uuid={uuid}
          open={notationDialogOpen}
          listNumber={listNumber}
          handleClose={handleNotationDialogClose}
        />
      )}
       {ownerChangeDialogOpen && (
        <OwnerChangeDialog        
          uuid={uuid}
          open={ownerChangeDialogOpen}
          listNumber={listNumber}
          handleClose={handleOwnerChangeDialogClose}
        />
      )}

      <DeleteConfirmationDialog
        open={deleteConfirmDialogOpen}
        listNumber={listNumber}
        handlePropertyDelete={handlePropertyDelete}
        setDeleteConfirmDialogOpen={setDeleteConfirmDialogOpen}
      />
      <EditConfirmDialog
        handleMenuClose={handleMenuClose}
        editConfirmDialogOpen={editConfirmDialogOpen}
        setEditConfirmDialogOpen={setEditConfirmDialogOpen}
        userRole={userRole}
        listNumber={listNumber}
        uuid={uuid}
      />
      <Button
        onClick={handleMenuClick}
        variant="text"
        color="secondary"
        disableElevation
      >
        เมนูทรัพย์
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        getContentAnchorEl={null}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {menuOptions.map(
          option =>
            !(
              userRole === 'sales' &&
              (option.name === 'approve' || option.name === 'delete')
            ) && !option.disabled &&(
              <MenuItem
                className={classes.menuItem}
                component={option.href ? 'button' : 'li'}
              
                button
                key={option.id}
                onClick={option.handleClick || null}
                href={option.href || null}
              >
                {option.label}
              </MenuItem>
            )
        )}
      </Menu>
    </>
  );
}

PropertyControlMenu.propTypes = {
  userRole: PropTypes.string.isRequired,
  uuid: PropTypes.string.isRequired,
  submissionProgress: PropTypes.string.isRequired,
  listNumber: PropTypes.string.isRequired,
  refreshProperties: PropTypes.func.isRequired,
};

export default PropertyControlMenu;
