import Axios from 'axios';
import { pick, pickBy } from 'lodash';
import { fieldNameBySection, submittingProgress } from 'Constants';
import snakecaseKeys from 'snakecase-keys';

function validValue(value) {
  return value && value !== '' && value.length !== 0;
}

const propertyExist = async ({ listNumber }) => {
  return Axios.get('/v1/properties/show', {
    params: snakecaseKeys({ listNumber }),
  }).then(response => response.data.data !== null);
};

async function submitProperty({ values, activeStep, userRole, editMode }) {
  const {
    firstSection,
    secondSection,
    thirdSection,
    fourthSection,
  } = fieldNameBySection;

  const firstStep = pick(values, firstSection);
  const secondStep = pick(values, secondSection);
  const thirdStep = pick(values, thirdSection);
  const fourthStep = pick(values, [...fourthSection, 'selectedNearbyPlaces']);
  const fifthStep = pick(values, 'videoLink');

  let params = {};

  switch (activeStep) {
    case 0:
      params = firstStep;
      break;
    case 1:
      params = secondStep;
      break;
    case 2:
      params = thirdStep;
      break;
    case 3:
      params = fourthStep;
      break;
    case 4:
      params = fifthStep;
      break;
    default:
      break;
  }

  params = activeStep === 4 ? params : pickBy(params, validValue);
  let submittingParams;

  if (editMode) {
    submittingParams = {
      ...params,
      listNumber: values.listNumber,
      submissionProgress: submittingProgress.editMode[userRole],
    };
  } else if (activeStep === 4) {
    submittingParams = {
      ...params,
      listNumber: values.listNumber,
      currentFormStep: 5,
      submissionProgress: submittingProgress.submitMode[userRole],
    };
  } else {
    submittingParams = {
      ...params,
      listNumber: values.listNumber,
      currentFormStep: activeStep + 1,
    };
  }

  // params = mapValues(params, item => JSON.stringify(item));

  let response;
  const doesPropertyExist = await propertyExist({
    listNumber: values.listNumber,
  });

  if (!doesPropertyExist) {
    response = await Axios.post(
      '/v1/properties/create',
      snakecaseKeys(submittingParams)
    );
  } else {
    response = await Axios.patch(
      '/v1/properties/update',
      snakecaseKeys(submittingParams)
    );
  }

  return response;
}

export default submitProperty;
