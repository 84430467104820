import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  paper: {
    minWidth: '320px',
    maxWidth: '480px',
    overflowX: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(4),
  },
  mainText: {
    textAlign: 'center',
  },
  subText: {
    display: 'block',
    textAlign: 'center',
  },
  textField: {
    marginTop: theme.spacing(1.25),
    marginBottom: theme.spacing(1.25),
  },
  subBody: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1.5),
    marginLeft: theme.spacing(1),
  },
  button: {
    marginLeft: theme.spacing(2),
  },
}));
export default useStyles;
