import React from 'react';
import { Box, Typography } from '@material-ui/core';
import EmptyImage from 'Assets/empty.svg';

function NoData() {
  return (
    <Box
      minWidth={320}
      maxWidth={{ xs: '100%', sm: 350, md: 350, lg: 350, xl: 350 }}
      pt={4}
      pb={4}
    >
      <img
        height="auto"
        width="100%"
        style={{
          display: 'block',
        }}
        src={EmptyImage}
        alt="No data found"
      />
      <Box mt={3} />
      <Typography align="center" color="textSecondary" variant="subtitle2">
        ไม่พบทรัพย์ที่ค้นหา
      </Typography>
    </Box>
  );
}

export default NoData;
