import React from 'react';
import {
  Dialog,
  DialogContent,
  Typography,
  DialogActions,
  Button,
  Drawer,
  Card,
  CardHeader,
  IconButton,
  CardContent,
  useMediaQuery,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useTheme } from '@material-ui/core/styles';

const content = (
  <React.Fragment>
    <Typography gutterBottom color="primary" variant="subtitle1">
      ทำไมจึงต้องกำหนดความปลอดภัยของรหัสผ่าน
    </Typography>
    <Typography variant="body2">
      เพื่อปกป้องบัญชีของคุณจากการถูกผู้ไม่หวังดีเข้าใช้งาน
      เนื่องจากรหัสผ่านในระดับต่ำเป็นรหัสที่คาดเดาได้ง่ายมาก เช่น 12345678,
      password, qwertyuiop
    </Typography>
    <br />
    <Typography gutterBottom color="primary" variant="subtitle1">
      ระดับความปลอดภัยใช้อะไรในการวัด
    </Typography>
    <Typography variant="body2">
      ระดับความปลอดภัยถูกแบ่งโดยความยากง่ายในการคาดเดา โดยมีระดับตั้งแต่ 0 จนถึง
      4 หมายถึงคาดเดาได้ง่ายมาก ไปจนถึงคาดเดาได้ยากมากตามลำดับ
    </Typography>
    <br />
    <Typography gutterBottom color="primary" variant="subtitle1">
      ควรเพิ่มความซับซ้อนของรหัสผ่านอย่างไร
    </Typography>
    <Typography variant="body2">
      ใช้ตัวเลขผสมกับตัวอักษร และ/หรือ ตัวอักษรเล็กใหญ่สลับกัน
      รวมถึงการเพิ่มสัญลักษณ์พิเศษจะช่วยให้รหัสผ่านของคุณมีความปลอดภัยสูง
    </Typography>
  </React.Fragment>
);

const PasswordStrengthInfoDialog = props => {
  const { open, handleClose } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'sm'));

  return isMobile ? (
    <Drawer anchor="bottom" open={open} onClose={handleClose}>
      <Card>
        <CardHeader
          title={
            <Typography color="textSecondary" variant="h6">
              ความปลอดภัยของรหัสผ่าน
            </Typography>
          }
          action={
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          }
        />
        <CardContent>{content}</CardContent>
      </Card>
    </Drawer>
  ) : (
    <Dialog open={open} onClose={handleClose}>
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" variant="outlined">
          ปิด
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PasswordStrengthInfoDialog;
