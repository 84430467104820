import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import queryString from "query-string";
import { Helmet } from "react-helmet-async";
import { Box, makeStyles } from "@material-ui/core";
import {
  Error,
  CheckCircle,
  DraftsOutlined,
  AccessTimeOutlined,
  Drafts,
  ErrorOutline,
  CheckCircleOutline,
  WatchLater,
  Cancel,
  CancelOutlined,
} from "@material-ui/icons";
import clsx from "clsx";
import PropertyByTabs from "../PropertyByTabs";
import PropertyCardList from "../PropertyList/PropertyCardList";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up("xl")]: {
      transition: "all 0.3s",
      marginLeft: theme.spacing(25),
      marginRight: theme.spacing(25),
    },
  },
  rootDrawerOpen: {
    [theme.breakpoints.up("xl")]: {
      transition: "all 0.3s",
      marginLeft: theme.spacing(10),
      marginRight: theme.spacing(10),
    },
  },
}));

const getTabList = (role) => {
  switch (role) {
    case "sales":
      return [
        {
          id: 1,
          label: "ฉบับร่าง",
          icon: <Drafts />,
          iconOutlined: <DraftsOutlined />,
          submissionProgress: ["draft"],
        },
        {
          id: 2,
          label: "รอการอนุมัติ",
          icon: <WatchLater />,
          iconOutlined: <AccessTimeOutlined />,
          submissionProgress: [
            "sales_submitted",
            "sales_edited",
            "admin_approved",
            "admin_editing",
            "admin_edited",
            "manager_editing",
            "manager_rejected",
          ],
        },
        {
          id: 3,
          label: "ต้องแก้ไข",
          icon: <Error />,
          iconOutlined: <ErrorOutline />,
          submissionProgress: ["admin_rejected", "sales_editing"],
        },
        {
          id: 4,
          label: "สมบูรณ์ (ทั้งหมด)",
          icon: <CheckCircle />,
          iconOutlined: <CheckCircleOutline />,
          submissionProgress: ["completed"],
        },
        {
          id: 5,
          label: "Active",
          icon: <CheckCircle />,
          iconOutlined: <CheckCircleOutline />,
          submissionProgress: ["completed"],
          propertyState: ["active"],
        },
        {
          id: 6,
          label: "Expired",
          icon: <CheckCircle />,
          iconOutlined: <CheckCircleOutline />,
          submissionProgress: ["completed"],
          propertyState: ["expired"],
        },
        {
          id: 7,
          label: "Exclusive",
          icon: <CheckCircle />,
          iconOutlined: <CheckCircleOutline />,
          submissionProgress: ["completed"],
          contractType: ["exClientNotSellable"],
        },
        {
          id: 8,
          label: "Open",
          icon: <CheckCircle />,
          iconOutlined: <CheckCircleOutline />,
          submissionProgress: ["completed"],
          contractType: ["openNoCommission"],
        },
      ];

    case "manager":
    case "admin":
      return [
        {
          id: 1,
          label: "รอสร้างโฆษณา",
          icon: <Error />,
          iconOutlined: <ErrorOutline />,
          submissionProgress: ["completed"],
          hasAds: false,
          propertyState: [
            "active",
            "passive",
            "passive_sold",
            "passive_ads",
            "expired",
            "pledged",
          ],
        },
        {
          id: 2,
          label: "Active",
          icon: <CheckCircle />,
          iconOutlined: <CheckCircleOutline />,
          submissionProgress: ["completed"],
          hasAds: true,
          propertyState: ["active"],
        },
        {
          id: 3,
          label: "Passive Ads",
          icon: <CheckCircle />,
          iconOutlined: <CheckCircleOutline />,
          submissionProgress: ["completed"],
          hasAds: true,
          propertyState: ["passive_ads"],
        },
        {
          id: 4,
          label: "Passive / Expired",
          icon: <CheckCircle />,
          iconOutlined: <CheckCircleOutline />,
          submissionProgress: ["completed"],
          hasAds: true,
          propertyState: ["passive", "passive_sold", "expired"],
        },
        {
          id: 5,
          label: "Suspended",
          icon: <Cancel />,
          iconOutlined: <CancelOutlined />,
          submissionProgress: ["completed"],
          hasAds: true,
          propertyState: ["suspended", "sold"],
        },
      ];
    default:
      return {};
  }
};

function MyProperties({ user, location, history, drawerOpen }) {
  const classes = useStyles();
  const values = queryString.parse(location.search);
  const userRole = user.role;
  const tabList = getTabList(userRole);

  const tabNumberFromUrl =
    Number(values.tab) > tabList.length - 1 ? 0 : Number(values.tab);

  return (
    <Box className={clsx(drawerOpen ? classes.rootDrawerOpen : classes.root)}>
      <Helmet>
        <title>ทรัพย์ของฉัน - AssetBeyond</title>
      </Helmet>
      <Box width="100%" borderRadius={4}>
        <PropertyByTabs
          tabList={tabList}
          tabNumberFromUrl={tabNumberFromUrl}
          onTabChange={(val) =>
            history.push({
              pathname: `/${userRole}/${
                userRole === "sales" ? "my-properties" : "complete-properties"
              }`,
              search: `?tab=${val}`,
            })
          }
        >
          {(currentTab) => (
            <PropertyCardList currentTab={currentTab} userRole={userRole} />
          )}
        </PropertyByTabs>
      </Box>
    </Box>
  );
}

const mapStateToProps = (state) => ({
  user: state.user.user,
  drawerOpen: state.drawer.drawerOpen,
});

MyProperties.propTypes = {
  user: PropTypes.objectOf(PropTypes.string).isRequired,
  location: PropTypes.shape({
    hash: PropTypes.string,
    key: PropTypes.string,
    pathname: PropTypes.string,
    search: PropTypes.string,
    state: PropTypes.string,
  }).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  drawerOpen: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(MyProperties);
