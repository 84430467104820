import { amber, green, lightGreen } from '@material-ui/core/colors';

export const normalize = value => ((value - 0) * 100) / (5 - 0);

export const scoreMap = {
  '0': '',
  '1': 'ต่ำมาก',
  '2': 'ต่ำ',
  '3': 'ปานกลาง',
  '4': 'สูง',
  '5': 'สูงมาก',
};

export const colorMap = {
  '1': '#f44336',
  '2': '#f44336',
  '3': amber[800],
  '4': green[700],
  '5': lightGreen[700],
};
