import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {
  Typography,
  useTheme,
  useMediaQuery,
  Divider,
  Box,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  menuLabel: {
    color: 'rgba(255,255,255)',
  },
  listItem: {
    '&:hover': {
      color: '#fff',
      backgroundColor: 'rgba(255,255,255,0.1)',
    },
  },
  listItemSelected: {
    backgroundColor: [[theme.palette.secondary.main], '!important'],
  },
}));

function DashboardSidebarItems({
  mainListItemsArray,
  secondaryMenuItems,
  location,
  handleDrawerClose,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'));
  return (
    <React.Fragment>
      {mainListItemsArray.map(item => (
        <ListItem
          onClick={() => (isMobile ? handleDrawerClose() : null)}
          key={item.id}
          className={classes.listItem}
          classes={{
            selected: classes.listItemSelected,
          }}
          selected={location.pathname.includes(item.pathname)}
          button
          disableRipple
          component={React.forwardRef((props, ref) => (
            <RouterLink innerRef={ref} to={item.pathname} {...props} />
          ))}
        >
          <ListItemIcon>
            {React.cloneElement(item.icon, {
              style: {
                color: 'rgba(255,255,255,0.8)',
              },
            })}
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography className={classes.menuLabel}>
                {item.menuLabel}
              </Typography>
            }
          />
        </ListItem>
      ))}
      {secondaryMenuItems && (
        <>
          <Box my={2}>
            <Divider
              variant="middle"
              style={{ background: 'rgb(255 255 255 / 10%)' }}
            />
          </Box>
          {secondaryMenuItems.map(item => (
            <ListItem
              onClick={() => (isMobile ? handleDrawerClose() : null)}
              key={item.id}
              className={classes.listItem}
              classes={{
                selected: classes.listItemSelected,
              }}
              selected={location.pathname.includes(item.pathname)}
              button
              disableRipple
              component={React.forwardRef((props, ref) => (
                <RouterLink innerRef={ref} to={item.pathname} {...props} />
              ))}
            >
              <ListItemIcon>
                {React.cloneElement(item.icon, {
                  style: {
                    color: 'rgba(255,255,255,0.8)',
                  },
                })}
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography className={classes.menuLabel}>
                    {item.menuLabel}
                  </Typography>
                }
              />
            </ListItem>
          ))}
        </>
      )}
    </React.Fragment>
  );
}

DashboardSidebarItems.propTypes = {
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  mainListItemsArray: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      pathname: PropTypes.string,
      menuLabel: PropTypes.string,
      link: PropTypes.element,
      icon: PropTypes.element,
    })
  ).isRequired,
  handleDrawerClose: PropTypes.func.isRequired,
};

export default DashboardSidebarItems;
