import { SET_USER } from '../constants/action-types';

const initialState = {
  user: {
    avatar: '',
    provider: '',
    name: '',
    email: '',
    uuid: '',
    role: '',
    contactInfo: {
      name: '',
      phone_number: '',
      line_id: '',
      email: '',
    },
  },
};

export default (state = initialState, action) => {
  const { user } = action;
  switch (action.type) {
    case SET_USER:
      return { ...state, user };
    default:
      return { ...state };
  }
};
