import React from 'react';
import { Container, Box, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import PropertyTabs from './PropertyTabs';

const useStyles = makeStyles(theme => ({
  container: {
    padding: 0,
  },
  childContainer: {
    [theme.breakpoints.only('xs')]: {
      margin: 0,
      maxWidth: '100%',
    },
  },
  tabsWrapper: {
    [theme.breakpoints.only('xs')]: {
      padding: 0.5,
    },
  },
}));

function PropertyByTabs({ tabList, children, tabNumberFromUrl, onTabChange }) {
  const classes = useStyles();

  return (
    <Box className={classes.tabsWrapper}>
      <PropertyTabs
        onTabChange={onTabChange}
        tabNumberFromUrl={tabNumberFromUrl}
        tabList={tabList}
      >
        {currentTab => (
          <Container maxWidth="xl" className={classes.container}>
            <Box className={classes.childContainer}>{children(currentTab)}</Box>
          </Container>
        )}
      </PropertyTabs>
    </Box>
  );
}

PropertyByTabs.propTypes = {
  tabList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
    })
  ).isRequired,
  children: PropTypes.func.isRequired,
};

export default PropertyByTabs;
