import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { PostAdd } from '@material-ui/icons';

const CreateAdvertisementButton = ({ uuid, userRole }) => {
  return (
    <>
      <Button
        color="secondary"
        variant="text"
        startIcon={<PostAdd />}
        href={`/${userRole}/ads-form?uuid=${uuid}&editMode=false`}
      >
        สร้างโฆษณา
      </Button>     
    </>
  );
};

CreateAdvertisementButton.propTypes = {
  uuid: PropTypes.string.isRequired,
  userRole: PropTypes.oneOf(['admin', 'manager', 'sales']).isRequired,
};

export default CreateAdvertisementButton;
