import Axios from 'axios';
import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';

export const setApplicationJSONHeader = () => {
  Axios.defaults.headers.common['Content-Type'] = 'application/json';
  Axios.defaults.data = {};
};

export const setAuthTokenHeaders = () => {
  const headerList = ['access-token', 'expiry', 'uid', 'client', 'token-type'];
  if (localStorage.getItem('access-token')) {
    headerList.forEach(header => {
      Axios.defaults.headers.common[header] = localStorage.getItem(header);
    });
  } else {
    headerList.forEach(header => {
      delete Axios.defaults.headers.common[header];
    });
  }
};

export const Logout = ({ initLogout, setInitLogout }) => {
  const [redirect, setRedirect] = useState(false);

  const headerList = ['access-token', 'expiry', 'uid', 'client', 'token-type'];

  if (initLogout) {
    Axios.delete('/v1/auth/sign_out', {
      params: {
        'access-token': localStorage.getItem('access-token'),
        uid: localStorage.getItem('uid'),
        client: localStorage.getItem('client'),
      },
    }).then(() => {
      headerList.forEach(header => localStorage.removeItem(header));
      setInitLogout(false);
      setRedirect(true);
    });
  }
  return redirect && <Redirect to="/login" />;
};
