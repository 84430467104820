import { propertyAcronyms } from 'Components/control/shared/PropertyDashboard/constants';

export const filterMap = {
  'propertyStates.name': {
    title: null,
    value: null,
  },
  propertyType: {
    title: 'ประเภททรัพย์',
    paramsName: 'propertyGroup',
    value: propertyAcronyms,
  },
  contractType: {
    title: 'ประเภทสัญญา',
    paramsName: 'contractType',
    value: {
      exClientNotSellable: 'Ex ขายเองไม่ได้',
      openNoCommission: 'Open',
      exSellable: 'Ex ขายเองได้',
    },
  },
  'users.name': {
    title: 'เจ้าหน้าที่',
    paramsName: 'user',
    value: null,
  },
  propertyProvince: {
    title: 'จังหวัด',
    paramsName: 'propertyProvince',
    value: null,
  },
};

export const filterValueMap = {
  ...propertyAcronyms,
  exClientNotSellable: 'Ex ขายเองไม่ได้',
  openNoCommission: 'Open',
  exSellable: 'Ex ขายเองได้',
};

export const contractTypeFilterMap = {
  'Ex ขายเองไม่ได้': 'exClientNotSellable',
  Open: 'openNoCommission',
  'Ex ขายเองได้': 'exSellable',
};
