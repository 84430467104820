import React, { Fragment, useState, useRef } from 'react';
import Axios from 'axios';
import { connect } from 'formik';
import { FieldSet } from 'Components/fields';
import SearchFieldResult from './SearchFieldResult';

function AddressSearchField(props) {
  const { value, formik } = props;
  const { setFieldValue } = formik;
  const anchorRef = useRef(null);
  const [searchInput, setSearchInput] = useState('');
  const [filteredAddress, setFilteredAddress] = useState([]);
  const [loading, setLoading] = useState(false);

  function fetchAddress(e) {
    setLoading(true);
    if (e.target.value !== '')
      Axios.get('/v1/address/search', {
        params: {
          keyword: e.target.value,
        },
      }).then(response => {
        setFilteredAddress(response.data.result);
        setLoading(false);
      });
    else {
      setFilteredAddress([]);
      setLoading(false);
    }
  }

  function handleClose(event) {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setFilteredAddress([]);
    setSearchInput('');
  }

  function handleSearchInput(e) {
    setFieldValue('sellerTambon', e.target.value);
    setSearchInput(e.target.value);
    fetchAddress(e);
  }

  const handleSelect = (e, address) => {
    const { tambon, amphoe, province } = address;
    setFieldValue('sellerTambon', tambon);
    setFieldValue('sellerAmphoe', amphoe);
    setFieldValue('sellerProvince', province);
    setFieldValue('sellerPostCode', address.post_code);
    handleClose(e);
  };

  return (
    <Fragment>
      <FieldSet
        name="sellerTambon"
        label="ตำบล / แขวง"
        value={value}
        inputRef={anchorRef}
        InputProps={{
          onChange: handleSearchInput,
        }}
      />
      <SearchFieldResult
        loading={loading}
        searchInput={searchInput}
        anchorEl={anchorRef.current}
        tambon={value}
        handleSelect={handleSelect}
        handleClose={handleClose}
        filteredAddress={filteredAddress}
      />
    </Fragment>
  );
}

export default connect(AddressSearchField);
