import React from 'react';
import PropTypes from 'prop-types';
import { CardContent, makeStyles, Box } from '@material-ui/core';
import ContentHeader from './ContentHeader';
import ContentAddress from './ContentAddress';
import ContentOwnerInfo from './ContentOwnerInfo';
import ContentPrice from './ContentPrice';

const useStyles = makeStyles(theme => ({
  cardContent: {
    flexGrow: 1,
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(1),
  },
}));

const PropertyCardContent = ({ property }) => {
  const {
    nickname,
    submissionProgress,
    thSubmissionProgress,
    submissionProgressType,
    listNumber,
    propertyTambon,
    propertyAmphoe,
    propertyProvince,
    dateSubmitted,
    owner,
    sellPrice,
    rentPrice,
    uuid,
    baseAdsTitle
  } = property;

  const classes = useStyles();

  return (
    <CardContent className={classes.cardContent}>
      {owner.data && (
        <ContentOwnerInfo
          dateSubmitted={dateSubmitted}
          owner={owner.data.attributes}
        />
      )}
      <ContentHeader
        baseAdsTitle={baseAdsTitle}
        submissionProgress={submissionProgress}
        uuid={uuid}
        listNumber={listNumber}
        nickname={nickname}
        thSubmissionProgress={thSubmissionProgress}
        submissionProgressType={submissionProgressType}
      />
      {submissionProgress !== 'draft' && (
        <>
          <ContentAddress
            propertyTambon={propertyTambon}
            propertyAmphoe={propertyAmphoe}
            propertyProvince={propertyProvince}
          />
          <Box mt={2}>
            <ContentPrice sellPrice={sellPrice} rentPrice={rentPrice} />
          </Box>
        </>
      )}
    </CardContent>
  );
};

PropertyCardContent.propTypes = {
  property: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default PropertyCardContent;
