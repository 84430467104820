import React from 'react'
import { Map, GoogleApiWrapper } from 'google-maps-react'
import { connect } from 'react-redux'
import { connect as formikConnect } from 'formik'
import PropTypes from 'prop-types'
import { setLoading, setNearbyPlaces } from 'JS/actions'
// import { trainStationNameMap } from 'Constants';
import { getDistance } from 'Utils'

const getResultName = (request, result) => {
  return result.name
}

function GoogleMapsAPI(props) {
  const {
    google,
    setLoading,
    setIsFetched,
    setFetchingData,
    setShouldFetchData,
    setNearbyPlaces,
    radius,
    formik,
  } = props

  const { values } = formik
  const latLong = values.latLong

  const lat = Number(latLong.split(',')[0])
  const long = Number(latLong.split(',')[1])

  function createRequest(location, keyword, options = { byType: false }) {
    return !options.byType
      ? {
          location,
          radius: String(radius),
          keyword,
        }
      : {
          location,
          radius: String(radius),
          type: [keyword],
        }
  }

  function fetchNearby(mapProps, map) {
    setFetchingData(true)
    setIsFetched(false)
    const derivedGoogle = mapProps.google
    const place = new derivedGoogle.maps.LatLng(lat, long)

    const requestList = [
      { name: 'hospitals', request: createRequest(place, 'โรงพยาบาล') },
      {
        name: 'departmentStores',
        request: createRequest(place, 'shopping_mall', {
          byType: true,
        }),
      },
      {
        name: 'supermarkets',
        request: createRequest(place, 'supermarket', { byType: true }),
      },
      { name: 'schools', request: createRequest(place, 'โรงเรียน') },
      {
        name: 'universities',
        request: createRequest(place, 'มหาวิทยาลัย'),
      },
      {
        name: 'trainStations',
        request: createRequest(place, 'subway_station', {
          byType: true,
        }),
      },
      {
        name: 'governmentBuildings',
        request: createRequest(place, 'สถานที่ราชการ'),
      },
    ]

    const service = new derivedGoogle.maps.places.PlacesService(map)

    const filteredRequestList = requestList.filter((request) =>
      props.requestList.includes(request.name)
    )

    let tempNearbyPlaces = {}

    filteredRequestList.forEach((request) => {
      setLoading(true)
      service.nearbySearch(request.request, (results, status) => {
        if (status === derivedGoogle.maps.places.PlacesServiceStatus.OK) {
          tempNearbyPlaces = {
            ...tempNearbyPlaces,
            [request.name]: results.map((result) => ({
              id: result.place_id,
              name: getResultName(request, result),
              distance: getDistance(
                [lat, long],
                [result.geometry.location.lat(), result.geometry.location.lng()]
              ),
              latLong: [
                result.geometry.location.lat(),
                result.geometry.location.lng(),
              ],
            })),
          }
        }
        if (
          filteredRequestList.indexOf(request) ===
          filteredRequestList.length - 1
        ) {
          setLoading(false)
          setNearbyPlaces(tempNearbyPlaces)
          setFetchingData(false)
          setIsFetched(true)
          setShouldFetchData(false)
        }
      })
    })
  }

  return (
    <Map
      style={{ display: 'none' }}
      visible={false}
      onReady={fetchNearby}
      google={google}
    />
  )
}

GoogleMapsAPI.propTypes = {
  google: PropTypes.objectOf(PropTypes.object).isRequired,
  setLoading: PropTypes.func.isRequired,
  setIsFetched: PropTypes.func.isRequired,
  setFetchingData: PropTypes.func.isRequired,
  setShouldFetchData: PropTypes.func.isRequired,
  radius: PropTypes.number.isRequired,
  requestList: PropTypes.arrayOf(
    PropTypes.oneOf([
      'departmentStores',
      'hospitals',
      'schools',
      'universities',
      'governmentBuildings',
      'supermarkets',
      'trainStations',
    ])
  ).isRequired,
  setNearbyPlaces: PropTypes.func.isRequired,
  formik: PropTypes.objectOf(PropTypes.any).isRequired,
}

const mapDispatchToProps = {
  setLoading,
  setNearbyPlaces,
}

export default connect(
  null,
  mapDispatchToProps
)(
  formikConnect(
    GoogleApiWrapper(() => ({
      LoadingContainer: () => <div />,
      apiKey: 'AIzaSyBJVywKBrS-jHMrQgCclianaA1E0AAZHyE',
      language: 'th',
      region: 'TH',
    }))(GoogleMapsAPI)
  )
)
