import React from 'react';
import PropTypes from 'prop-types';
import SimpleDialog from 'Components/shared/SimpleDialog';
import { Typography } from '@material-ui/core';
import Axios from 'axios';
import { useSnackbar } from 'notistack';

const AdsDeleteDialog = ({
  open,
  handleClose,
  deletingTitle,
  deletingId,
  fetchAds,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const handleAdsDelete = adsId => () => {
    Axios.delete(`/v1/advertisements?id=${adsId}`)
      .then(() => {
        fetchAds();
        handleClose();
        enqueueSnackbar('ลบโฆษณาเรียบร้อยแล้ว');
      })
      .catch(() => {
        enqueueSnackbar('เกิดข้อผิดพลาดในการลบ กรุณาลองใหม่อีกครั้ง');
        handleClose();
      });
  };
  return (
    <SimpleDialog
      open={open}
      handleClose={handleClose}
      dialogTitle="ยืนยันการลบโฆษณา"
      message={
        <>
          <Typography
            display="block"
            component="span"
            variant="body2"
            gutterBottom
          >
            คุณต้องการลบโฆษณาชื่อ
          </Typography>
          <Typography component="span" color="textPrimary">
            {deletingTitle}
          </Typography>
        </>
      }
      primaryBtnText="ลบ"
      secondaryBtnText="ยกเลิก"
      handlePrimaryClick={handleAdsDelete(deletingId)}
      handleSecondaryClick={handleClose}
    />
  );
};

AdsDeleteDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  deletingTitle: PropTypes.string.isRequired,
  deletingId: PropTypes.string.isRequired,
  fetchAds: PropTypes.func.isRequired,
};

export default AdsDeleteDialog;
