import React from 'react';
import { AppBar, Toolbar, Typography, Box } from '@material-ui/core';
import HideOnScroll from 'Components/shared/HideOnScroll';

const GlobalAppBar = () => {
  return (
    <HideOnScroll>
      <AppBar color="inherit" elevation={1}>
        <Toolbar>
          <Typography component="h1" variant="h6" color="inherit" noWrap>
            ASSET BEYOND
          </Typography>
          <Box flexGrow={1} />
        </Toolbar>
      </AppBar>
    </HideOnScroll>
  );
};

export default GlobalAppBar;
