import PropTypes from "prop-types";

/* eslint-disable camelcase */
export const statusMap = status => {
  switch (Number(status)) {
    case 1:
      return "ขาย";
    case 2:
      return "ให้เช่า";
    case 3:
      return "ขายดาวน์";
    case 4:
      return "ขายและให้เช่า";
    case 5:
      return "เซ้ง";
    default:
      return "";
  }
};

export const fieldNameBySection = {
  firstSection: [
    "listNumber",
    "nickname",
    "contractType",
    "commissionPercentage",
    "programme",
    "referFrom",
    "referPercentage",
    "dateReceived",
    "dateExpired",
    "dateSubmitted",
    "sellerName",
    "sellerInitialAddress",
    "sellerSoi",
    "sellerRoad",
    "sellerTambon",
    "sellerAmphoe",
    "sellerProvince",
    "sellerPostCode",
    "sellerPhone",
    "sellerLineId"
  ],
  secondSection: [
    "deedNumber",
    "deedParcelNumber",
    "deedDealingFileNumber",
    "deedTambon",
    "deedAmphoe",
    "deedProvince",
    "latLong",
    "propertySoi",
    "propertyRoad",
    "propertyTambon",
    "propertyAmphoe",
    "propertyProvince",
    "region",
    "zone"
  ],
  thirdSection: [
    "propertyType",
    "propertyStatus",
    "sellPrice",
    "rentPrice",
    "houseNumber",
    "projectName",
    "areaSizeRai",
    "areaSizeNgan",
    "areaSizeWaa",
    "utilitySpace",
    "floorCount",
    "bedroomCount",
    "bathroomCount",
    "otherRoomCount",
    "interiorCondition",
    "exteriorCondition",
    "additionalEquipment",
    "features",
    "headingDirection",
    "landscapeType",
    "landPricePerWaa",
    "landEstimatedPricePerWaa",
    "landFrontWidth",
    "landOfficialZoneType",
    "landRoadCondition",
    "landRoadLength",
    "landConditions",
    "condoMaxFloorCount",
    "condoPricePerSqm",
    "condoLivingFloor",
    "condoBuildingNumber",
    "warehouseIsInsidePurpleZone",
    "warehouseHasOperationPermit",
    "warehouseMaximumWeightCapacity",
    "warehouseMaximumBeamWeight",
    "warehouseTrailerAccessible"
  ],
  fourthSection: [
    "baseAdsTitle",
    "baseAdsDetails",
    "baseAdsNearbyPlaces",
    "baseAdsDirections"
  ],
  fifthSection: [
    "deeds",
    "propertyImages",
    "agentAssignmentFiles",
    "ownerIdCards",
    "ownerHouseDocuments",
    "propertyHouseDocuments",
    "authorizedPartyIdCards",
    "authorizedPartyHouseDocuments",
    "sellContractDocuments",
    "buildingPermits",
    "operationPermits",
    "videoLink"
  ]
};

export const sectionNameMap = {
  firstSection: "ใบปะหน้า",
  secondSection: "แบบสำรวจทรัพย์สิน (ที่ตั้ง)",
  thirdSection: "แบบสำรวจทรัพย์สิน (รายละเอียด)",
  fourthSection: "การโฆษณา",
  fifthSection: "ไฟล์เอกสารและรูปภาพ",
  0: "ใบปะหน้า",
  1: "แบบสำรวจทรัพย์สิน (ที่ตั้ง)",
  2: "แบบสำรวจทรัพย์สิน (รายละเอียด)",
  3: "การโฆษณา",
  4: "ไฟล์เอกสารและรูปภาพ"
};

export const t = {
  listNumber: "List เลขที่",
  nickname: "ชื่อทรัพย์โดยย่อ",
  propertyStatus: "ประเภทประกาศ",
  propertyType: "ประเภททรัพย์",
  areaSizeRai: "พื้นที่ (ไร่)",
  areaSizeNgan: "พื้นที่ (งาน)",
  areaSizeWaa: "พื้นที่ (ตารางวา)",
  utilitySpace: "พื้นที่ใช้สอย (ตารางเมตร)",
  sellPrice: "ราคาขาย",
  rentPrice: "ราคาเช่า",
  latLong: "พิกัด GPS",
  projectName: "ชื่อโครงการ",
  houseNumber: "บ้านเลขที่",
  region: "ภาค",
  zone: "โซนพื้นที่",
  floorCount: "จำนวนชั้น",
  bedroomCount: "จำนวนห้องนอน",
  bathroomCount: "จำนวนห้องน้ำ",
  otherRoomCount: "จำนวนห้องอื่นๆ",
  exteriorCondition: "สภาพภายนอก",
  interiorCondition: "สภาพภายใน",
  additionalEquipment: "อุปกรณ์ที่ติดมาด้วย",
  features: "จุดเด่น",
  headingDirection: "ทิศทาง",
  landscapeType: "วิว",
  condoMaxFloorCount: "จำนวนชั้นทั้งหมด (คอนโด)",
  condoPricePerSqm: "ราคาต่อตารางเมตร (คอนโด)",
  condoLivingFloor: "อยู่ชั้นที่ (คอนโด)",
  condoBuildingNumber: "ลำดับอาคาร (คอนโด)",
  landPricePerWaa: "ราคาต่อตารางวา (ที่ดิน)",
  landEstimatedPricePerWaa: "ราคาประเมินต่อตารางวา (ที่ดิน)",
  landFrontWidth: "หน้ากว้าง (ที่ดิน)",
  landOfficialZoneType: "ผังสี (ที่ดิน)",
  landRoadCondition: "สภาพถนน (ที่ดิน)",
  landRoadLength: "ความยาวถนน (ที่ดิน)",
  landConditions: "สภาพที่ดิน",
  warehouseIsInsidePurpleZone: "อยู่ในพื้นที่สีม่วง (โกดัง)",
  warehouseHasOperationPermit: "มีใบอนุญาตประกอบกิจการโรงงาน (โกดัง)",
  warehouseMaximumWeightCapacity: "น้ำหนักที่รับได้ (โกดัง)",
  warehouseMaximumBeamWeight: "น้ำหนักคานที่รับได้ (โกดัง)",
  warehouseTrailerAccessible: "รถพ่วงเข้าออกได้ (โกดัง)",
  nearbyPlaces: "",
  currentFormStep: "",
  sellerInitialAddress: "บ้านเลขที่ (ผู้ขาย)",
  sellerName: "ชื่อ (ผู้ขาย)",
  sellerSoi: "ซอย (ผู้ขาย)",
  sellerRoad: "ถนน (ผู้ขาย)",
  sellerTambon: "ตำบล/แขวง (ผู้ขาย)",
  sellerAmphoe: "อำเภอ/เขต (ผู้ขาย)",
  sellerProvince: "จังหวัด (ผู้ขาย)",
  sellerPostCode: "รหัสไปรษณีย์ (ผู้ขาย)",
  sellerPhone: "เบอร์โทรศัพท์ (ผู้ขาย)",
  sellerLineId: "ไลน์ไอดี (ผู้ขาย)",
  contractType: "ประเภทสัญญา",
  programme: "Programme",
  commissionPercentage: "ค่าคอมฯ",
  referPercentage: "ค่า refer",
  referFrom: "Refer จาก",
  dateExpired: "วันที่หมดอายุ",
  dateReceived: "วันที่ได้รับ",
  dateSubmitted: "วันที่ส่งงาน",
  deedNumber: "เลขที่โฉนด",
  deedParcelNumber: "เลขที่ดิน",
  deedDealingFileNumber: "หน้าสำรวจ",
  deedTambon: "ตำบล/แขวง (โฉนด)",
  deedAmphoe: "อำเภอ/เขต (โฉนด)",
  deedProvince: "จังหวัด (โฉนด)",
  baseAdsTitle: "หัวข้อโฆษณา",
  baseAdsDetails: "รายละเอียดโฆษณา",
  baseAdsNearbyPlaces: "สถานที่ใกล้เคียง",
  baseAdsDirections: "การเดินทาง",
  propertyInitialAddress: "เลขที่ (ทรัพย์)",
  propertySoi: "ซอย (ทรัพย์)",
  propertyRoad: "ถนน (ทรัพย์)",
  propertyTambon: "ตำบล/แขวง (ทรัพย์)",
  propertyAmphoe: "อำเภอ/เขต (ทรัพย์)",
  propertyProvince: "จังหวัด (ทรัพย์)",
  propertyPostCode: "รหัสไปรษณีย์ (ทรัพย์)",
  propertyImages: "ภาพทรัพย์",
  deeds: "สำเนาโฉนด",
  agentAssignmentFiles: "สัญญาแต่งตั้งนายหน้า",
  ownerIdCards: "สำเนาบัตรประชาชนเจ้าของกรรมสิทธิ์",
  ownerHouseDocuments: "สำเนาทะเบียนบ้านเจ้าของกรรมสิทธิ์",
  propertyHouseDocuments: "สำเนาทะเบียนบ้านหลังที่ขาย",
  authorizedPartyIdCards: "สำเนาบัตรประชาชนผู้รับมอบอำนาจ",
  authorizedPartyHouseDocuments: "สำเนาทะเบียนบ้านของผู้รับมอบอำนาจ",
  sellContractDocuments: "หนังสือสัญญาขาย (ทด.13)",
  buildingPermits: "ใบอนุญาตก่อสร้าง",
  operationPermits: "ใบอนุญาต รง.4",
  videoLink: "ลิงค์ YouTube"
};

export const propertyTypeMap = {
  0: "ไม่มี",
  1: "คอนโด",
  2: "บ้านเดี่ยว",
  3: "บ้านชั้นเดียว",
  4: "บ้านแฝด",
  5: "ทาวน์เฮ้าส์",
  6: "ทาวน์โฮม",
  7: "โฮมออฟฟิศ",
  8: "อาคารพาณิชย์",
  9: "สำนักงาน",
  10: "ที่ดินเปล่า",
  11: "ที่ดินพร้อมสิ่งปลูกสร้าง",
  12: "โกดัง/โรงงาน",
  13: "อพาร์ทเม้นท์",
  14: "รีสอร์ท",
  15: "ฟาร์ม"
};

export const propertyConditionMap = {
  "1": "ดี",
  "2": "ปานกลาง",
  "3": "เก่า",
  "4": "รกร้าง",
  "5": "ต้องซ่อม",
  "": ""
};

export const trainStationNameMap = {
  มหาวิทยาลัยเกษตรศาสตร์: "BTS",
  เสนานิคม: "BTS",
  รัชโยธิน: "BTS",
  ห้าแยกลาดพร้าว: "BTS",
  หมอชิต: "BTS",
  สะพานควาย: "BTS",
  อารีย์: "BTS",
  สนามเป้า: "BTS",
  อนุสาวรีย์ชัยสมรภูมิ: "BTS",
  พญาไท: "BTS",
  ราชเทวี: "BTS",
  สยาม: "BTS",
  ชิดลม: "BTS",
  เพลินจิต: "BTS",
  นานา: "BTS",
  อโศก: "BTS",
  พร้อมพงษ์: "BTS",
  ทองหล่อ: "BTS",
  เอกมัย: "BTS",
  พระโขนง: "BTS",
  อ่อนนุช: "BTS",
  บางจาก: "BTS",
  ปุณณวิถี: "BTS",
  อุดมสุข: "BTS",
  บางนา: "BTS",
  แบริ่ง: "BTS",
  สำโรง: "BTS",
  ปู่เจ้า: "BTS",
  ช้างเอราวัณ: "BTS",
  โรงเรียนนายเรือ: "BTS",
  ปากน้ำ: "BTS",
  ศรีนครินทร์: "BTS",
  แพรกษา: "BTS",
  สายลวด: "BTS",
  เคหะฯ: "BTS",
  สนามกีฬาแห่งชาติ: "BTS",
  ราชดำริ: "BTS",
  ศาลาแดง: "BTS",
  ช่องนนทรี: "BTS",
  สุรศักดิ์: "BTS",
  สะพานตากสิน: "BTS",
  กรุงธนบุรี: "BTS",
  วงเวียนใหญ่: "BTS",
  โพธิ์นิมิตร: "BTS",
  ตลาดพลู: "BTS",
  วุฒากาศ: "BTS",
  บางหว้า: "BTS / MRT",
  บางซื่อ: "MRT",
  กำแพงเพชร: "MRT",
  สวนจตุจักร: "MRT",
  จตุจักร: "MRT",
  พหลโยธิน: "MRT",
  ลาดพร้าว: "MRT",
  รัชดาภิเษก: "MRT",
  สุทธิสาร: "MRT",
  ห้วยขวาง: "MRT",
  ศูนย์วัฒนธรรมแห่งประเทศไทย: "MRT",
  พระราม9: "MRT",
  "พระราม 9": "MRT",
  เพชรบุรี: "MRT",
  สุขุมวิท: "MRT",
  ศูนย์การประชุมแห่งชาติสิริกิติ์: "MRT",
  คลองเตย: "MRT",
  ลุมพินี: "MRT",
  สีลม: "MRT",
  สามย่าน: "MRT",
  หัวลำโพง: "MRT",
  วัดมังกร: "MRT",
  สามยอด: "MRT",
  สนามไชย: "MRT",
  อิสรภาพ: "MRT",
  ท่าพระ: "MRT",
  บางไผ่: "MRT",
  "เพชรเกษม 48": "MRT",
  ภาษีเจริญ: "MRT",
  บางแค: "MRT",
  หลักสอง: "MRT",
  บางอ้อ: "MRT",
  บางพลัด: "MRT",
  สิรินธร: "MRT",
  บางยี่ขัน: "MRT",
  บางขุนนนท์: "MRT",
  ไฟฉาย: "MRT",
  "จรัญฯ 13": "MRT",
  คลองบางไผ่: "MRT สายสีม่วง",
  ตลาดบางใหญ่: "MRT สายสีม่วง",
  สามแยกบางใหญ่: "MRT สายสีม่วง",
  บางพลู: "MRT สายสีม่วง",
  บางรักใหญ่: "MRT สายสีม่วง",
  บางรักน้อยท่าอิฐ: "MRT สายสีม่วง",
  ไทรม้า: "MRT สายสีม่วง",
  สะพานพระนั่งเกล้า: "MRT สายสีม่วง",
  แยกนนทบุรี1: "MRT สายสีม่วง",
  "แยกนนทบุรี 1": "MRT สายสีม่วง",
  บางกระสอ: "MRT สายสีม่วง",
  ศูนย์ราชการนนทบุรี: "MRT สายสีม่วง",
  กระทรวงสาธารณสุข: "MRT สายสีม่วง",
  แยกติวานนท์: "MRT สายสีม่วง",
  วงศ์สว่าง: "MRT สายสีม่วง",
  บางซ่อน: "MRT สายสีม่วง",
  เตาปูน: "MRT สายสีม่วง",
  สุวรรณภูมิ: "Airport Link",
  ลาดกระบัง: "Airport Link",
  บ้านทับช้าง: "Airport Link",
  หัวหมาก: "Airport Link",
  รามคำแหง: "Airport Link",
  มักกะสัน: "Airport Link",
  ราชปรารภ: "Airport Link"
};

export const getSubmissionProgressForControl = (tab, role) => {
  const mapping = {
    admin: {
      0: [
        "sales_submitted",
        "sales_edited",
        "manager_rejected",
        "admin_editing"
      ],
      1: ["sales_editing", "admin_edited", "admin_rejected", "admin_approved"],
      2: ["manager_editing", "completed"]
    },
    manager: {
      0: ["admin_edited", "admin_approved", "manager_editing"],
      1: [
        "sales_submitted",
        "sales_editing",
        "sales_edited",
        "admin_rejected",
        "admin_editing",
        "manager_rejected"
      ],
      2: ["completed"]
    }
  };
  return mapping[role][tab];
};

export const getSubmissionProgressByTabAndRole = (tab, role) => {
  const mapping = {
    sales: {
      0: ["draft"],
      1: [
        "sales_submitted",
        "sales_edited",
        "admin_approved",
        "admin_editing",
        "admin_edited",
        "manager_editing",
        "manager_rejected"
      ],
      2: ["admin_rejected", "sales_editing"],
      3: ["completed"]
    },
    admin: {
      0: ["completed"],
      1: ["completed"],
      2: ["completed"]
    },
    manager: {
      0: ["completed"],
      1: ["completed"],
      2: ["completed"]
    }
  };
  return mapping[role][tab];
};

export const mapTabNumberToSubmissionProgress = {
  sales: {
    0: ["draft"],
    1: ["sales_submitted", "sales_edited"],
    2: ["admin_rejected", "sales_editing"],
    3: ["completed"]
  },
  admin: {
    0: ["draft"],
    1: ["admin_approved", "admin_edited"],
    2: ["manager_rejected", "admin_editing"],
    3: ["completed"]
  },
  manager: {
    0: ["draft"],
    1: ["completed"]
  }
};

export const submittingProgress = {
  submitMode: {
    sales: "sales_submitted",
    admin: "admin_approved",
    manager: "completed"
  },
  editMode: {
    sales: "sales_editing",
    admin: "admin_editing",
    manager: "manager_editing"
  }
};

export const propertyProgressMap = {
  sales: {
    draft: "ฉบับร่าง",
    sales_editing: "กำลังแก้ไข",
    sales_edited: "รอการอนุมัติ (แก้ไข)",
    sales_submitted: "รอการอนุมัติ",
    admin_approved: "รอการอนุมัติ",
    admin_rejected: "ต้องแก้ไข",
    manager_rejected: "รอการอนุมัติ",
    completed: "สมบูรณ์"
  },
  admin: {
    draft: "ฉบับร่าง",
    admin_edited: "รอการอนุมัติ (แก้ไข)",
    admin_editing: "กำลังแก้ไข",
    sales_submitted: "N/A",
    admin_approved: "รอการอนุมัติ",
    admin_rejected: "N/A",
    manager_rejected: "ต้องแก้ไข",
    completed: "สมบูรณ์"
  },
  manager: {
    draft: "ฉบับร่าง",
    sales_submitted: "N/A",
    admin_approved: "N/A",
    admin_rejected: "N/A",
    manager_rejected: "N/A",
    completed: "สมบูรณ์"
  }
};

export const tabList = {
  sales: ["ฉบับร่าง", "ส่งแล้ว", "ต้องแก้ไข", "อนุมัติแล้ว"],
  admin: ["ฉบับร่าง", "ส่งแล้ว", "ต้องแก้ไข", "อนุมัติแล้ว"],
  manager: ["ฉบับร่าง", "สมบูรณ์"]
};

export const editAllowedSubmissionProgress = {
  sales: ["admin_rejected", "sales_editing", "completed"],
  admin: [
    "manager_rejected",
    "admin_editing",
    "completed",
    "sales_submitted",
    "sales_edited"
  ],
  manager: ["completed", "admin_edited", "admin_approved", "manager_editing"]
};

export const userPropsShape = PropTypes.shape({
  avatar: PropTypes.string,
  provider: PropTypes.string,
  name: PropTypes.string,
  email: PropTypes.string,
  role: PropTypes.oneOf(["admin", "manager", "sales"]),
  uuid: PropTypes.string,
  contactInfo: PropTypes.object
});
