import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Box,
  MenuItem,
  makeStyles,
  ListItemText,
  Checkbox,
  ListItemIcon,
} from '@material-ui/core';
import { pickBy } from 'lodash';
import { ArrowDropDown } from '@material-ui/icons';
import { filterMap, contractTypeFilterMap } from './constants';
import MenuButton from './MenuButton';

const useStyles = makeStyles(theme => ({
  nested: {
    height: theme.spacing(5),
  },
}));

const PropertyFilter = ({
  filters,
  setActiveFilters,
  currentTab,
  filteredItems,
  setFilteredItems,
}) => {
  const classes = useStyles();
  const [selectedEntries, setSelectedEntries] = useState({
    propertyGroup: [],
    contractType: [],
    propertyProvince: [],
    user: [],
  });

  useEffect(() => {
    setSelectedEntries({
      propertyGroup: [],
      contractType: [],
      propertyProvince: [],
      user: [],
    });
  }, [currentTab]);

  const handleChildClick = (key, filterName) => () => {
    if (selectedEntries[filterName].includes(key)) {
      // Remove selected key from the state
      setSelectedEntries({
        ...selectedEntries,
        [filterName]: selectedEntries[filterName].filter(item => item !== key),
      });
    } else {
      setSelectedEntries({
        ...selectedEntries,
        [filterName]: [...selectedEntries[filterName], key],
      });
    }
  };

  const handleFilterClick = () => {
    // Selecting only non-empty filter values
    const filtered = pickBy(selectedEntries, v => v.length > 0);

    if (filtered.contractType) {
      filtered.contractType = filtered.contractType.map(
        item => contractTypeFilterMap[item]
      );
    }
    // Ensure Unique filter values
    Object.entries(filtered).forEach(([key, value]) => {
      filtered[key] = Array.from(new Set(value));
    });

    setFilteredItems(filtered);
    setActiveFilters(Object.values(filtered).flat());
  };

  const handleResetFilterClick = filterName => () => {
    setFilteredItems({
      ...filteredItems,
      [filterName]: null,
    });
    setSelectedEntries({
      ...selectedEntries,
      [filterName]: [],
    });
    const newSelectedEntries = { ...selectedEntries };
    delete newSelectedEntries[filterName];

    setActiveFilters(
      Object.values(newSelectedEntries)
        .map(val => val)
        .flat()
    );
  };

  return (
    <Box display="flex" flexWrap="wrap">
      {Object.entries(filters).map(
        ([filterName, filterValues]) =>
          filterMap[filterName].title && (
            <Box
              key={filterName}
              display="flex"
              flexWrap="wrap"
              flexDirection="row"
            >
              <Box mr={1}>
                <MenuButton
                  buttonProps={{
                    variant: 'text',
                    color: 'primary',
                    endIcon: <ArrowDropDown />,
                  }}
                  buttonText={filterMap[filterName].title}
                >
                  {/* Ensure unique values for selection */}
                  {Array.from(
                    new Set(
                      filterValues.map(filterValue =>
                        filterMap[filterName].value
                          ? filterMap[filterName].value[filterValue]
                          : filterValue
                      )
                    )
                  ).map(filterValue => (
                    <MenuItem
                      className={classes.nested}
                      disableRipple
                      dense
                      key={filterValue}
                      onClick={handleChildClick(
                        filterValue,
                        filterMap[filterName].paramsName
                      )}
                    >
                      <ListItemIcon style={{ minWidth: 32 }}>
                        <Checkbox
                          size="small"
                          edge="start"
                          checked={selectedEntries[
                            filterMap[filterName].paramsName
                          ].includes(filterValue)}
                          disableRipple
                        />
                      </ListItemIcon>
                      <ListItemText
                        primaryTypographyProps={{
                          variant: 'subtitle2',
                          color: 'textPrimary',
                          style: {
                            fontWeight: 400,
                          },
                        }}
                        primary={filterValue}
                      />
                    </MenuItem>
                  ))}

                  <Box
                    px={0.5}
                    bgcolor="hsla(228, 20%, 98%, 1)"
                    py={1}
                    display="flex"
                    justifyContent="space-between"
                  >
                    <Button
                      size="small"
                      onClick={handleResetFilterClick(
                        filterMap[filterName].paramsName
                      )}
                      color="secondary"
                    >
                      รีเซ็ต
                    </Button>
                    <Button
                      size="small"
                      variant="contained"
                      onClick={handleFilterClick}
                      color="secondary"
                    >
                      ตกลง
                    </Button>
                  </Box>
                </MenuButton>
              </Box>
            </Box>
          )
      )}
    </Box>
  );
};

PropertyFilter.propTypes = {
  filters: PropTypes.objectOf(PropTypes.array).isRequired,
  setActiveFilters: PropTypes.func.isRequired,
  currentTab: PropTypes.number.isRequired,
  filteredItems: PropTypes.objectOf(PropTypes.array).isRequired,
  setFilteredItems: PropTypes.func.isRequired,
};

export default PropertyFilter;
