import React from 'react';
import { debounce } from 'lodash';
import { TextField as MuiTextField } from '@material-ui/core';
import CommentBox from 'Components/shared/CommentBox';
import PropTypes from 'prop-types';

class TextFieldBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
    };
    this.syncChange = debounce(this.syncChange, 150);
  }

  componentDidMount() {
    this.timer = setTimeout(() => {
      this.setState({
        // eslint-disable-next-line react/destructuring-assignment
        value: this.props.value,
      });
    }, 1000);
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  syncChange = e => {
    const { onChange } = this.props;
    onChange(e);
  };

  handleChange = e => {
    if (e.persist) {
      e.persist();
    }

    this.setState({ value: e.target.value }, this.syncChange(e));
  };

  render() {
    const { value } = this.state;
    const { onBlur, name } = this.props;

    return (
      <>
        <MuiTextField
          {...this.props}
          id={name}
          name={name}
          onChange={this.handleChange}
          onBlur={e => {
            onBlur(e);
          }}
          value={value}
        />
        <CommentBox name={name} />
      </>
    );
  }
}

TextFieldBase.propTypes = {
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default TextFieldBase;
