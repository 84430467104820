import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, makeStyles } from '@material-ui/core';
import { propertyTypeMap } from 'Constants';

const useStyles = makeStyles(theme => ({
  small: {
    color: theme.palette.shades.grey.light,
    letterSpacing: 2,
    fontWeight: 500,
    verticalAlign: 'middle',
  },
}));

const PropertyShowSubtitle = ({ property }) => {
  const classes = useStyles();
  const propertyType = propertyTypeMap[property.propertyType];
  const tambon = property.propertyTambon;
  const province = property.propertyProvince;

  const TextWrapper = text => (
    <Box mr={0.5}>
      <Typography className={classes.small} variant="body2">
        {text}
      </Typography>
    </Box>
  );
  return (
    <Box pl={{ xs: 1, sm: 3 }} display="flex" alignItems="baseline">
      <>
        {TextWrapper(propertyType)}
        {TextWrapper('・')}
        {TextWrapper(tambon)}
        {TextWrapper('・')}
        {TextWrapper(province)}
      </>
    </Box>
  );
};

PropertyShowSubtitle.propTypes = {
  property: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default PropertyShowSubtitle;
