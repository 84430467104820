import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, makeStyles } from '@material-ui/core';
import { Input } from 'antd';
import Axios from 'axios';
import snakecaseKeys from 'snakecase-keys';

const useStyles = makeStyles(theme => ({
  box: {
    padding: '3.4px 5px',
    '&:hover': {
      cursor: 'pointer',
      border: '1px solid #d9d9d9',
      borderRadius: '4px',
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),
      paddingTop: theme.spacing(0.3),
      paddingBottom: theme.spacing(0.3),
    },
  },
}));

const EditableFeaturedTagChip = ({ featuredTag, uuid }) => {
  const classes = useStyles();
  const [editClicked, setEditClicked] = useState(false);
  const [inputValue, setInputValue] = useState(featuredTag);

  const handleSubmit = e => {
    Axios.patch(
      `/v1/properties/update`,
      snakecaseKeys({
        uuid,
        featuredTag: e.target.value,
      })
    ).then(response => {
      setInputValue(response.data.data.attributes.featuredTag);
      setEditClicked(false);
    });
  };

  return (
    <Box width="150px">
      {!editClicked ? (
        <Box
          onClick={() => setEditClicked(!editClicked)}
          className={classes.box}
        >
          <Typography variant="body2">{inputValue || 'ไม่มี'}</Typography>
        </Box>
      ) : (
        <Input
          onPressEnter={handleSubmit}
          onChange={e => setInputValue(e.target.value)}
          value={inputValue}
          autoFocus
          onBlur={() => setEditClicked(false)}
          placeholder="เหมาะสำหรับลงทุน"
        />
      )}
    </Box>
  );
};

EditableFeaturedTagChip.propTypes = {
  featuredTag: PropTypes.string.isRequired,
  uuid: PropTypes.string.isRequired,
};

export default EditableFeaturedTagChip;
