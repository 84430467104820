import React, { PureComponent } from 'react';
import { connect } from 'formik';
import { isEmpty, isEqual, flatten } from 'lodash';
import PropTypes from 'prop-types';
import { FieldSet } from 'Components/fields';
import NearbyPlacesCheckboxes from './NearbyPlacesCheckboxes';

class BaseAdsNearbyPlaces extends PureComponent {
  componentDidUpdate(prevProps) {
    const { formik } = this.props;
    const { values, setFieldValue } = formik;
    const { selectedNearbyPlaces } = values;

    if (
      !isEqual(
        selectedNearbyPlaces,
        prevProps.formik.values.selectedNearbyPlaces
      )
    ) {
      setFieldValue('baseAdsNearbyPlaces', this.combineList());
    }
  }

  combinePlaces = places => {
    return places.map(place => `- ${place.name}\n`).join('');
  };

  getListHeading = listName => {
    switch (listName) {
      case 'departmentStoreList':
      case 'supermarketList':
        return 'ห้างสรรพสินค้า / ซูเปอร์มาร์เก็ต\n';
      case 'hospitalList':
        return 'โรงพยาบาล\n';
      case 'schoolList':
        return 'โรงเรียน\n';
      case 'universityList':
        return 'มหาวิทยาลัย\n';
      case 'governmentBuildingList':
        return 'สถานที่ราชการ\n';
      default:
        return '';
    }
  };

  notSelected = () => {
    const { formik } = this.props;
    const { values } = formik;
    const { selectedNearbyPlaces } = values;

    return flatten(Object.values(selectedNearbyPlaces)).length === 0;
  };

  combineList = () => {
    const { formik } = this.props;
    const { values } = formik;
    const { selectedNearbyPlaces } = values;

    const orderedSelectedNearbyPlaces = {
      departmentStoreList: selectedNearbyPlaces.departmentStoreList,
      supermarketList: selectedNearbyPlaces.supermarketList,
      universityList: selectedNearbyPlaces.universityList,
      hospitalList: selectedNearbyPlaces.hospitalList,
      governmentBuildingList: selectedNearbyPlaces.governmentBuildingList,
      schoolList: selectedNearbyPlaces.schoolList,
    };

    if (this.notSelected()) return '';
    const result = ['สถานที่ใกล้เคียง\n'];
    Object.entries(orderedSelectedNearbyPlaces).forEach(
      ([placeType, places]) => {
        if (!isEmpty(places)) {
          if (['departmentStoreList', 'supermarketList'].includes(placeType)) {
            if (!result.includes('ห้างสรรพสินค้า / ซูเปอร์มาร์เก็ต\n')) {
              result.push('\n');
              result.push(this.getListHeading(placeType));
            }
            result.push(this.combinePlaces(places));
          } else {
            result.push('\n');
            result.push(this.getListHeading(placeType));
            result.push(this.combinePlaces(places));
          }
        }
      }
    );
    return result.join('');
  };

  render() {
    return (
      <>
        <NearbyPlacesCheckboxes />
        <FieldSet
          required
          placeholder="เลือกสถานที่สำคัญจากด้านบน"
          multiline
          name="baseAdsNearbyPlaces"
          label="สถานที่สำคัญใกล้เคียง"
        />
      </>
    );
  }
}

BaseAdsNearbyPlaces.propTypes = {
  formik: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default connect(BaseAdsNearbyPlaces);
