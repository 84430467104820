import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Button,
  ListItemIcon,
  Divider,
  makeStyles,
} from '@material-ui/core';
import { KeyboardArrowRight, Phone } from '@material-ui/icons';
import { formatPhoneNumber } from 'Utils';
import clsx from 'clsx';
import LineIcon from './LineIcon';
import FloatingContactBar from './FloatingContactBar';

const useStyles = makeStyles(theme => ({
  noAvatar: {
    background: theme.palette.primary.main,
  },
  buttonTop: {
    marginBottom: theme.spacing(1),
  },
  phoneAvatar: {
    backgroundColor: theme.palette.secondary.light,
    width: 30,
    height: 30,
  },
}));

const isTop = el => {
  return el.getBoundingClientRect().top <= window.innerHeight;
};

const PropertyShowContact = ({ contactInfo, avatar, loading }) => {
  const [contactInfoVisible, setContactInfoVisible] = useState(false);
  const wrapperRef = useRef(null);
  const classes = useStyles();

  useEffect(() => {
    const trackScrolling = () => {
      const wrappedElement = wrapperRef.current;
      if (isTop(wrappedElement)) {
        setContactInfoVisible(true);
      } else {
        setContactInfoVisible(false);
      }
    };
    document.addEventListener('scroll', trackScrolling);
    return () => document.removeEventListener('scroll', trackScrolling);
  }, [contactInfoVisible, wrapperRef]);

  const handleLineClick = () =>
    window.open(`http://line.me/ti/p/~${contactInfo.lineId}`);

  const handleTelClick = () => window.open(`tel:${contactInfo.phoneNumber}`);

  const CustomListItem = ({ icon, primaryText, secondaryText }) => {
    return (
      <ListItem dense disableGutters>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText
          primary={primaryText}
          primaryTypographyProps={{
            variant: 'body2',
            style: {
              fontWeight: 500,
            },
          }}
          secondary={secondaryText}
          secondaryTypographyProps={{
            variant: 'body2',
            component: 'p',
          }}
        />
      </ListItem>
    );
  };
  return (
    !loading && (
      <div ref={wrapperRef}>
        <FloatingContactBar
          avatar={avatar}
          name={contactInfo.name}
          phone={contactInfo.phoneNumber}
          lineId={contactInfo.lineId}
          contactInfoVisible={contactInfoVisible}
        />
        <Box p={3}>
          <Typography variant="body2" color="textSecondary">
            สอบถาม / นัดชมสถานที่
          </Typography>
          <ListItem disableGutters>
            <ListItemAvatar>
              <Avatar
                className={clsx(!avatar && classes.noAvatar)}
                src={avatar || null}
              >
                {!avatar ? contactInfo.name[0] : null}
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={`คุณ${contactInfo.name}`}
              secondary="ผู้ดูแลทรัพย์"
              primaryTypographyProps={{
                style: {
                  fontWeight: 500,
                },
              }}
              secondaryTypographyProps={{
                variant: 'body2',
                component: 'p',
              }}
            />
          </ListItem>
          <Divider />
          <Box mt={2}>
            <CustomListItem
              secondaryText="โทรศัพท์"
              icon={
                <Avatar className={classes.phoneAvatar} variant="rounded">
                  <Phone fontSize="small" color="inherit" />
                </Avatar>
              }
              primaryText={formatPhoneNumber(contactInfo.phoneNumber)}
            />
          </Box>
          {CustomListItem({
            icon: <LineIcon />,
            primaryText: contactInfo.lineId,
            secondaryText: 'ไลน์ไอดี',
          })}
        </Box>
        <Box px={3} pb={3}>
          <Button
            className={classes.buttonTop}
            onClick={handleTelClick}
            endIcon={<KeyboardArrowRight fontSize="small" />}
            fullWidth
            color="secondary"
            size="large"
            variant="outlined"
          >
            สอบถาม (โทร)
          </Button>
          <Button
            className={classes.button}
            onClick={handleLineClick}
            endIcon={<KeyboardArrowRight fontSize="small" />}
            fullWidth
            color="secondary"
            size="large"
            variant="contained"
          >
            สอบถาม (ไลน์)
          </Button>
        </Box>
      </div>
    )
  );
};

PropertyShowContact.defaultProps = {
  avatar: '',
};

PropertyShowContact.propTypes = {
  contactInfo: PropTypes.objectOf(PropTypes.any).isRequired,
  avatar: PropTypes.string,
  loading: PropTypes.bool.isRequired,
};

export default PropertyShowContact;
