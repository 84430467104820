/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useEffect } from 'react'
import { Table } from 'antd'
import snakecaseKeys from 'snakecase-keys'
import Axios from 'axios'
import { Typography } from '@material-ui/core'
import { formatNumber } from 'Utils'

const landTypeMap = {
  empty: 'ที่ดินเปล่า',
  field: 'ไร่ สวน',
  farm: 'ฟาร์ม',
  with_buildings: 'ที่ดินพร้อมสิ่งปลูกสร้าง',
}

const documentTypeMap = {
  ns4: 'น.ส.4',
  nsk3: 'น.ส.3ก.',
  pbt5: 'ภ.บ.ท.5',
}

const UserLandSellTable = () => {
  const [state, setState] = useState({
    data: [],
    pagination: {
      position: 'both',
      pageSize: 10,
      showSizeChanger: true,
      size: 'small',
      // eslint-disable-next-line no-use-before-define
      onShowSizeChange: handleShowSizeChange,
    },
    loading: true,
    filters: null,
  })

  function handleShowSizeChange(current, pageSize) {
    setState((prevState) => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
        current,
        pageSize,
      },
    }))
  }

  const fetch = (params = {}) => {
    setState((prevState) => ({ ...prevState, loading: true }))
    Axios.get('/v2/user_responses', {
      params: snakecaseKeys({
        ...params,
        responseType: 'land_sell',
      }),
    }).then((response) => {
      const { headers } = response
      const total = Number(headers['total-count'])
      const current = Number(headers['current-page'])

      setState((prevState) => ({
        loading: false,
        data: response.data.data,
        pagination: {
          ...prevState.pagination,
          current,
          total,
        },
      }))
    })
  }

  const columns = [
    {
      title: 'วันที่ส่งข้อมูล',
      dataIndex: ['attributes', 'createdAt'],
      render: (createdAt) =>
        new Date(createdAt).toLocaleString('th-th', {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
        }),
    },
    {
      title: 'ประเภททรัพย์',
      id: 'landType',
      dataIndex: ['attributes', 'landType'],
      render: (data, record) => (
        <Typography variant="body2">
          {landTypeMap[record.attributes.landType]}
        </Typography>
      ),
    },
    {
      title: 'ราคาทรัพย์',
      id: 'sellingPrice',
      dataIndex: ['attributes', 'sellingPrice'],
      align: 'right',
      render: (sellingPrice) => (
        <Typography variant="body2">{`${formatNumber(
          sellingPrice
        )} บาท`}</Typography>
      ),
    },
    {
      title: 'จังหวัดที่ตั้ง',
      id: 'sellingProvince',
      dataIndex: ['attributes', 'sellingProvince'],
    },
    {
      title: 'อำเภอ',
      id: 'sellingAmphoe',
      dataIndex: ['attributes', 'sellingAmphoe'],
    },
    {
      title: 'ตำบล',
      id: 'tambon',
      dataIndex: ['attributes', 'tambon'],
    },
    // {
    //   title: 'ชื่อ',
    //   id: 'sellerName',
    //   dataIndex: ['attributes', 'sellerName'],
    // },
    // {
    //   title: 'อีเมล',
    //   id: 'sellerEmail',
    //   dataIndex: ['attributes', 'sellerEmail'],
    // },
    // {
    //   title: 'ไอดีไลน์',
    //   id: 'sellerLine',
    //   dataIndex: ['attributes', 'sellerLine'],
    // },
    // {
    //   title: 'เบอร์โทร',
    //   id: 'sellerPhone',
    //   dataIndex: ['attributes', 'sellerPhone'],
    // },
    {
      title: 'ขนาดพื้นที่',
      id: 'landSizeRai',
      dataIndex: ['attributes', 'landSizeRai'],
      render: (landSizeRai, record) => {
        const parts = []

        if (landSizeRai > 0) {
          parts.push(`${landSizeRai} ไร่`)
        }

        if (record.attributes.landSizeNgan > 0) {
          parts.push(`${record.attributes.landSizeNgan} งาน`)
        }

        if (record.attributes.landSizeWaa > 0) {
          parts.push(`${record.attributes.landSizeWaa} ตร.ว.`)
        }

        const sizeString = parts.join(' ')

        return (
          <>
            <Typography variant="body2">{sizeString}</Typography>
          </>
        )
      },
    },
    {
      title: 'เอกสาร',
      id: 'documentType',
      dataIndex: ['attributes', 'documentType'],
      render: (val, record) => (
        <span>
          {documentTypeMap[val]} <br />
          {record.attributes.documentImagesUrls.map((url, index) => (
            <a key={index} href={url} target="_blank" rel="noopener noreferrer">
              {`เอกสารที่ ${index + 1}`}
            </a>
          ))}
        </span>
      ),
    },
    {
      title: 'ภาพที่ดิน',
      dataIndex: ['attributes', 'landImagesUrls'],
      render: (images) => (
        <div>
          {images.map((image, index) => (
            <a href={image} target="_blank" rel="noopener noreferrer">
              <img
                key={index}
                src={image}
                alt={`Image ${index}`}
                style={{ maxWidth: '150px', maxHeight: '100px', padding: 4 }}
              />
            </a>
          ))}
        </div>
      ),
    },
  ]

  const handleTableChange = (pagination) => {
    fetch({
      page: pagination.current,
      items: pagination.pageSize,
    })
  }

  useEffect(() => {
    fetch({
      page: 1,
      items: 10,
    })
  }, [])

  const renderRowClassName = (record) => {
    if (record.attributes.responseType === 'land_sell') {
      return 'land-sell-row'
    }
    return ''
  }

  return (
    <Table
      bordered
      columns={columns}
      rowKey={(record) => record.attributes.uuid}
      expandable={{
        expandedRowRender: (record) => (
          <p style={{ margin: 0 }}>
            ชื่อ {record.attributes.sellerName}
            <br />
            {record.attributes.sellerEmail}
            <br />
            Line: {record.attributes.sellerLine}
            <br />
            โทร: {record.attributes.sellerPhone}
          </p>
        ),
      }}
      dataSource={state.data}
      pagination={state.pagination}
      loading={state.loading}
      onChange={handleTableChange}
      rowClassName={renderRowClassName}
    />
  )
}

export default UserLandSellTable
