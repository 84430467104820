import React, {  useState } from "react";
import SimpleDialog from "Components/shared/SimpleDialog";
import { FormControlLabel, Radio, RadioGroup,  } from "@material-ui/core";
import Axios from "axios";
import snakecaseKeys from "snakecase-keys";
import { useSnackbar } from "notistack";

const OwnerChangeDialog = ({
  open,
  handleClose,
  uuid, 
  listNumber,
}) => {
  const [userId, setUserId] = useState('');
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (e) => {
    setUserId(e.target.value);
  };

  const handleSave = () => {
    Axios.patch(
      "/v1/properties/update",
      snakecaseKeys({
        uuid,
        userId,
      })
    ).then(() => {
      enqueueSnackbar("เปลี่ยนผู้ดูแลทรัพย์เรียบร้อยแล้ว");
      handleClose();
      window.location.reload();
    });
  };

  return (
    <SimpleDialog
      open={open}
      dialogTitle={`เปลี่ยนผู้ดูแลทรัพย์ ${listNumber}`}
      message={
        <>
          <RadioGroup value={userId} onChange={handleChange}>
            <FormControlLabel value="4" control={<Radio />} label="เทพกรณ์" />
            <FormControlLabel value="13" control={<Radio />} label="วัชรพงษ์" />
            <FormControlLabel value="8" control={<Radio />} label="จริยา" />
            <FormControlLabel value="7" control={<Radio />} label="สาธิต" />
            <FormControlLabel value="14" control={<Radio />} label="ณิ" />
          </RadioGroup>
        </>
      }
      primaryBtnText="บันทึก"
      secondaryBtnText="ปิด"
      handleSecondaryClick={handleClose}
      handlePrimaryClick={handleSave}
    />
  );
};

export default OwnerChangeDialog;
