import React from 'react';
import CommentBox from 'Components/shared/CommentBox';
import { connect } from 'formik';
import { useTheme } from '@material-ui/core/styles';
import { DatePicker } from '@material-ui/pickers';
import PropTypes from 'prop-types';

const DatePickerFieldSet = connect(({ name, label, formik, ...rest }) => {
  const theme = useTheme();
  const { setFieldValue, setFieldTouched, values } = formik;

  return (
    <>
      <DatePicker
        name={name}
        label={label}
        format="dd MMMM yyyy"
        inputVariant="outlined"
        fullWidth
        onChange={val => {
          setFieldValue(name, val);
        }}
        onBlur={() => setFieldTouched(name, true)}
        value={values[name]}
        style={{ marginBottom: theme.spacing(2.5) }}
        {...rest}
      />
      <CommentBox name={name} />
    </>
  );
});

DatePickerFieldSet.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default DatePickerFieldSet;
