import React from 'react';
import PropTypes from 'prop-types';
import {
  useTheme,
  useMediaQuery,
  useScrollTrigger,
  Slide,
} from '@material-ui/core';

function HideOnScroll({ children }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'));

  const trigger = useScrollTrigger();

  return isMobile ? (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  ) : (
    children
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
};

export default HideOnScroll;
