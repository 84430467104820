import React from 'react'
import PropTypes from 'prop-types'
import { Card, Box, makeStyles, CardActions } from '@material-ui/core'
import PropertyCardMenu from './PropertyCardMenu'
import PropertyCardImage from './PropertyCardImage'
import PropertyCardContent from './PropertyCardContent'

const useStyles = makeStyles((theme) => ({
  card: {
    margin: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      flexBasis: '96%',
    },
    border: '1px solid rgba(0,0,0,0.08)',
    display: 'flex',
    flexDirection: 'column',
  },
  actions: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
  },
}))

const PropertyCard = ({
  property,
  userRole,
  refreshProperties,
  controlMode,
  currentTab,
}) => {
  const classes = useStyles()
  const { firstImage } = property

  return (
    <Card className={classes.card} elevation={1}>
      <Box display="flex" flexDirection="column">
        <PropertyCardImage
          propertyState={property.propertyState.data.attributes.name}
          currentFormStep={property.currentFormStep}
          image={firstImage}
        />
        <PropertyCardContent property={property} />
        <Box
          position="relative"
          display="flex"
          alignItems="flex-start"
          flexDirection="column"
          justifyContent="space-between"
          p={1}
        >
          <CardActions className={classes.actions}>
            <PropertyCardMenu
              currentTab={currentTab}
              controlMode={controlMode}
              userRole={userRole}
              uuid={property.uuid}
              submissionProgress={property.submissionProgress}
              listNumber={property.listNumber}
              refreshProperties={refreshProperties}
            />
          </CardActions>
        </Box>
      </Box>
    </Card>
  )
}

PropertyCard.defaultProps = {
  controlMode: false,
}

PropertyCard.propTypes = {
  property: PropTypes.objectOf(PropTypes.any).isRequired,
  userRole: PropTypes.string.isRequired,
  refreshProperties: PropTypes.func.isRequired,
  controlMode: PropTypes.bool,
  currentTab: PropTypes.number.isRequired,
}

export default PropertyCard
