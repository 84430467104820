import React from 'react';
import { InsertDriveFile, Home, Image } from '@material-ui/icons';
import {
  PersonCardIcon,
  FileMultipleIcon,
  DeedIcon,
  ContractIcon,
  ConstructionIcon,
  FactoryIcon,
} from 'Components/customIcons';

const uploadFieldList = showingList => [
  {
    name: 'agentAssignmentFiles',
    label: 'สัญญาแต่งตั้งนายหน้า *',
    imageIcon: false,
    icon: <InsertDriveFile />,
  },
  {
    name: 'ownerIdCards',
    label: 'สำเนาบัตรประชาชนเจ้าของกรรมสิทธิ์ *',
    imageIcon: false,
    icon: <PersonCardIcon />,
    multiple: true,
    unitText: 'คนที่',
  },
  {
    name: 'ownerHouseDocuments',
    label: 'สำเนาทะเบียนบ้านเจ้าของกรรมสิทธิ์',
    imageIcon: false,
    icon: <FileMultipleIcon />,
    disabled: !showingList.ownerHouseDocuments,
    multiple: true,
    unitText: 'คนที่',
  },
  {
    name: 'propertyHouseDocuments',
    label: 'สำเนาทะเบียนบ้านหลังที่ขาย',
    disabled: !showingList.propertyHouseDocuments,
    imageIcon: false,
    icon: <Home />,
  },
  {
    name: 'authorizedPartyIdCards',
    label: 'สำเนาบัตรประชาชนผู้รับมอบอำนาจ',
    disabled: !showingList.authorizedPartyIdCards,
    imageIcon: false,
    icon: <PersonCardIcon />,
  },
  {
    name: 'authorizedPartyHouseDocuments',
    label: 'สำเนาทะเบียนบ้านของผู้รับมอบอำนาจ',
    disabled: !showingList.authorizedPartyHouseDocuments,
    imageIcon: false,
    icon: <FileMultipleIcon />,
  },
  {
    name: 'deeds',
    label: 'สำเนาโฉนด *',
    multiple: true,
    unitText: 'โฉนดเล่มที่',
    imageIcon: true,
    icon: DeedIcon,
  },
  {
    name: 'sellContractDocuments',
    label: 'หนังสือสัญญาขาย (ทด.13)',
    disabled: !showingList.sellContractDocuments,
    imageIcon: true,
    icon: ContractIcon,
  },
  {
    name: 'buildingPermits',
    label: 'ใบอนุญาตก่อสร้าง',
    disabled: !showingList.buildingPermits,
    imageIcon: true,
    icon: ConstructionIcon,
  },
  {
    name: 'operationPermits',
    label: 'ใบอนุญาต รง.4',
    disabled: !showingList.operationPermits,
    imageIcon: true,
    icon: FactoryIcon,
  },
  {
    name: 'propertyImages',
    label: 'ภาพทรัพย์',
    secondaryLabel: '*ภาพแรกจะเป็นภาพหลักที่ใช้แสดงผล',
    imageIcon: false,
    icon: <Image />,
  },
];

export default uploadFieldList;
