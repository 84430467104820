import React, { useEffect } from 'react';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import SectionDividerWithIcon from 'Components/shared/SectionDividerWithIcon';
import { FastFieldSet, SelectFieldSet, FieldSet } from 'Components/fields';
import { FileDocumentIcon } from 'Components/customIcons';
import { regionOptions, zoneOptions } from '../shared';
import LatLongField from './LatLongField';

function SecondSection() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <div>
      <SectionDividerWithIcon icon={<FileDocumentIcon />}>
        รายละเอียดทรัพย์ตามโฉนด
      </SectionDividerWithIcon>
      <FastFieldSet required name="deedNumber" label="เลขที่โฉนด" />
      <FastFieldSet required name="deedParcelNumber" label="เลขที่ดิน" />
      <FastFieldSet required name="deedDealingFileNumber" label="หน้าสำรวจ" />
      <FastFieldSet required name="deedTambon" label="ตำบล / แขวง" />
      <FastFieldSet required name="deedAmphoe" label="อำเภอ / เขต" />
      <FastFieldSet required name="deedProvince" label="จังหวัด" />
      <SectionDividerWithIcon icon={<LocationOnIcon />}>
        ที่ตั้งทรัพย์ปัจจุบัน
      </SectionDividerWithIcon>
      <LatLongField />
      <FieldSet name="propertySoi" label="ซอย" />
      <FieldSet name="propertyRoad" label="ถนน" />
      <FieldSet required name="propertyTambon" label="ตำบล / แขวง" />
      <FieldSet required name="propertyAmphoe" label="อำเภอ / เขต" />
      <FieldSet required name="propertyProvince" label="จังหวัด" />
      <SelectFieldSet
        options={regionOptions}
        required
        name="region"
        label="ภาค"
      />
      <SelectFieldSet
        options={zoneOptions}
        required
        name="zone"
        label="พื้นที่"
      />
    </div>
  );
}

export default SecondSection;
