import { Checkbox } from 'antd';
import React, { useState } from 'react';
import 'antd/dist/antd.css';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { userPropsShape } from 'Constants';
import { tableFieldNames } from '../constants';

const TableFieldSelector = ({ setEnabledFields, enabledFields, user }) => {
  const [indeterminate, setIndeterminate] = useState(true);
  const [checkAll, setCheckAll] = useState(false);
  const usingFields = tableFieldNames[user.role];

  const handleChange = checkedValues => {
    setEnabledFields(checkedValues);
    setIndeterminate(
      !!enabledFields.length && enabledFields.length < usingFields.length
    );
    setCheckAll(enabledFields.length === usingFields.length);
  };

  const handleCheckAll = e => {
    setEnabledFields(
      e.target.checked
        ? usingFields.map(fieldName => fieldName.value)
        : ['listNumber']
    );
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };
  return (
    <Box
      bgcolor="white"
      boxShadow={0}
      p={3}
      display="flex"
      justifyContent="flex-end"
      flexDirection="column"
    >
      <Box>
        <Checkbox
          indeterminate={indeterminate}
          onChange={handleCheckAll}
          checked={checkAll}
        >
          เลือกทั้งหมด
        </Checkbox>
      </Box>
      <Box mt={3}>
        <Checkbox.Group
          options={usingFields}
          defaultValue={['listNumber']}
          value={enabledFields}
          onChange={handleChange}
        />
      </Box>
    </Box>
  );
};

TableFieldSelector.propTypes = {
  user: userPropsShape.isRequired,
  setEnabledFields: PropTypes.func.isRequired,
  enabledFields: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const mapStateToProps = state => ({
  user: state.user.user,
});

export default connect(mapStateToProps)(TableFieldSelector);
