import React, { useState, useEffect } from 'react';
import { Box, Typography, Zoom, makeStyles } from '@material-ui/core';
import { getIn, connect } from 'formik';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
  rejectText: {
    borderRadius: theme.shape.borderRadius * 10,
    padding: theme.spacing(0.5),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    background: red[100],
    color: 'hsl(0, 73%, 20%)',
  },
}));

function CommentBox({ formik, name, noMargin }) {
  const classes = useStyles();
  const theme = useTheme();
  const { status } = formik;
  const [open, setOpen] = useState(false);
  const [mounted, setMounted] = useState(false);
  const rejectedMessage = getIn(status, name);

  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (status) {
      if (status[name]) {
        setOpen(true);
      } else {
        setOpen(false);
      }
    }
  }, [status, name]);

  return status && !!rejectedMessage ? (
    <Zoom timeout={transitionDuration} in={mounted && open} unmountOnExit>
      <Box mb={2.5} mt={noMargin ? 1 : -1} ml={1}>
        <Typography
          className={classes.rejectText}
          variant="caption"
          color="textSecondary"
        >
          {rejectedMessage}
        </Typography>
      </Box>
    </Zoom>
  ) : (
    ''
  );
}

CommentBox.defaultProps = {
  noMargin: false,
};

CommentBox.propTypes = {
  noMargin: PropTypes.bool,
};

export default connect(CommentBox);
