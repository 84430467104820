import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { formatNumber } from 'Utils';

const showPrice = (sellPrice, rentPrice) => {
  if (sellPrice) return sellPrice;
  if (rentPrice) return rentPrice;
  return '';
};

const ContentPrice = ({ sellPrice, rentPrice }) => {
  return (
    (!!sellPrice || !!rentPrice) && (
      <Typography
        variant="subtitle2"
        style={{ fontWeight: 500 }}
        color="textSecondary"
      >
        {formatNumber(showPrice(sellPrice, rentPrice))}
      </Typography>
    )
  );
};

ContentPrice.defaultProps = {
  sellPrice: null,
  rentPrice: null,
};

ContentPrice.propTypes = {
  sellPrice: PropTypes.number,
  rentPrice: PropTypes.number,
};

export default ContentPrice;
