import React, { useRef, useState } from 'react';
import { connect, Field } from 'formik';
import { useTheme } from '@material-ui/core/styles';
import { useMediaQuery, MenuItem } from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import { FixedSizeList as List } from 'react-window';
import CommentBox from 'Components/shared/CommentBox';
import PropTypes from 'prop-types';

const SelectFieldSet = connect(props => {
  const { name, label, options, formik, value, ...rest } = props;
  const { setFieldValue } = formik;
  const anchorElRef = useRef(null);
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'));

  const handleMenuItemClick = val => () => {
    setFieldValue(name, val);
    setOpen(false);
  };

  function Row({ index, style }) {
    const itemValue = options[index].value;
    const itemLabel = options[index].label;

    return (
      <MenuItem
        disableRipple
        padding={0}
        key={index}
        style={style}
        value={itemValue}
        onClick={handleMenuItemClick(itemValue)}
      >
        {itemLabel}
      </MenuItem>
    );
  }

  const getMenuHeight = (rowCount, rowHeight) => {
    const height = rowCount * rowHeight;
    if (height > 300) return 300;
    return height;
  };

  return (
    <>
      <Field
        name={name}
        label={label}
        component={TextField}
        select
        variant="outlined"
        fullWidth
        style={{ marginBottom: theme.spacing(2.5) }}
        inputRef={anchorElRef}
        InputLabelProps={isMobile ? { shrink: true } : null}
        SelectProps={{
          native: isMobile,
          open,
          onOpen: () => setOpen(true),
          onClose: () => setOpen(false),
          renderValue: !isMobile
            ? selected =>
                selected
                  ? options.find(option => option.value === selected).label
                  : ''
            : null,
        }}
        {...rest}
      >
        {isMobile ? (
          options.map(option => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))
        ) : (
          <List
            itemSize={48}
            itemCount={options.length}
            width={anchorElRef.offsetWidth}
            height={getMenuHeight(48, options.length)}
          >
            {Row}
          </List>
        )}
      </Field>
      <CommentBox name={name} />
    </>
  );
});

SelectFieldSet.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      label: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    })
  ).isRequired,
};

export default SelectFieldSet;
