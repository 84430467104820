import React from 'react';
import { makeStyles, SvgIcon, darken } from '@material-ui/core';
import { green, blue, orange, red, grey } from '@material-ui/core/colors';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import { mdiProgressWrench } from '@mdi/js';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { CheckCircleOutline, Drafts } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  icon: {
    display: 'flex',
    fontSize: '1rem',
    marginRight: theme.spacing(0.5),
  },
  neutralIcon: {
    color: grey[800],
  },
  completeIcon: {
    color: darken(green[900], 0.3),
  },
  pendingIcon: {
    color: darken(orange[900], 0.3),
  },
  workingIcon: {
    color: darken(blue[900], 0.3),
  },
  alertIcon: {
    color: darken(red[900], 0.3),
  },
}));

function StatusIcon({ statusType, ...rest }) {
  const classes = useStyles();

  const renderIcon = status => {
    switch (status) {
      case 'neutral':
        return (
          <Drafts
            {...rest}
            className={clsx(classes.neutralIcon, classes.icon)}
          ></Drafts>
        );

      case 'complete':
        return (
          <CheckCircleOutline
            {...rest}
            className={clsx(classes.completeIcon, classes.icon)}
          />
        );
      case 'working':
        return (
          <SvgIcon
            {...rest}
            className={clsx(classes.icon, classes.workingIcon)}
          >
            <path d={mdiProgressWrench} />
          </SvgIcon>
        );
      case 'pending':
        return (
          <AccessTimeIcon
            {...rest}
            className={clsx(classes.icon, classes.pendingIcon)}
          />
        );
      case 'alert':
        return (
          <ErrorIcon
            {...rest}
            color="error"
            className={clsx(classes.icon, classes.alertIcon)}
          />
        );
      default:
        return '';
    }
  };

  return <>{renderIcon(statusType)}</>;
}

StatusIcon.propTypes = {
  statusType: PropTypes.string.isRequired,
};

export default StatusIcon;
