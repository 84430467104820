import React, { useState } from 'react';
import { Button, Menu, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  menuRoot: {
    paddingBottom: 0,
  },
});
const MenuButton = ({ buttonText, buttonProps, children }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = e => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button onClick={handleClick} {...buttonProps}>
        {buttonText}
      </Button>
      <Menu
        classes={{ list: classes.menuRoot }}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={open}
        onClose={handleClose}
      >
        {children}
      </Menu>
    </>
  );
};

export default MenuButton;
