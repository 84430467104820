import React from "react";
import PropTypes from "prop-types";
import {
  Typography,
  Box,
  GridList,
  GridListTile,
  GridListTileBar
} from "@material-ui/core";
import { useTheme, makeStyles, lighten } from "@material-ui/core/styles";
import { red } from "@material-ui/core/colors";
import clsx from "clsx";
import RejectWrapper from "../RejectWrapper";
import CommentChip from "../CommentChip";
import moment from "moment";

const useStyles = makeStyles(theme => ({
  heading: {
    marginBottom: theme.spacing(2)
  },
  tile: {
    borderRadius: theme.shape.borderRadius,
    cursor: "pointer",
    border: "3px solid #ffffff00",
    transition: theme.transitions.create("border-color", {
      duration: theme.transitions.duration.shortest
    }),
    "&:hover": {
      border: "3px solid rgb(2, 24, 44)"
    }
  },
  tileAppear: {
    background: "rgba(0, 0, 0, 0.5)"
  },
  tileDisappear: {
    height: "100%",
    background: "rgba(0, 0, 0, 0)"
  },
  tileIcon: {
    color: "white",
    fontWeight: 500,
    borderRadius: theme.shape.borderRadius,
    position: "absolute",
    top: 0,
    right: 0,
    background: red[700],
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  rejectText: {
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(0.5),
    background: lighten(theme.palette.secondary.light, 0.55)
  },
  unitText: {
    position: "absolute",
    borderRadius: theme.shape.borderRadius,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    top: 0,
    left: 0,
    color: "white",
    background: theme.palette.primary.main
  }
}));

const showRejectButtonStatus = {
  admin: ["sales_submitted", "sales_edited"],
  manager: ["admin_approved", "admin_edited"]
};

const shouldShowUnitCount = fieldName => {
  const regEx = RegExp(/ownerIdCards|ownerHouseDocuments|deeds/);
  return regEx.test(fieldName);
};

function DetailImage(props) {
  const {
    heading,
    imageObject,
    name,
    user,
    uuid,
    isRejected,
    rejectMessage,
    fetchProperty,
    submissionProgress,
    approveMode
  } = props;

  const classes = useStyles();
  const shouldShowReject =
    ["admin", "manager"].includes(user.role) &&
    approveMode &&
    showRejectButtonStatus[user.role].includes(submissionProgress);

  const theme = useTheme();

  return (
    <Box
      mt={3.5}
      bgcolor="hsla(228, 20%, 97%, 1)"
      borderRadius={8}
      p={2}
      pl={2}
    >
      {shouldShowReject ? (
        <RejectWrapper
          boxProps={{
            alignItems: "flex-start",
            display: "flex",
            justifyContent: "space-between"
          }}
          uuid={uuid}
          id={name}
          isRejected={isRejected}
          rejectMessage={rejectMessage}
          fetchProperty={fetchProperty}
          textElement={
            <Typography
              className={classes.heading}
              component="p"
              align="left"
              variant="caption"
              color="textSecondary"
            >
              {heading}
            </Typography>
          }
        />
      ) : (
        <Typography
          className={classes.heading}
          component="p"
          align="left"
          variant="caption"
          color="textSecondary"
        >
          {heading}
        </Typography>
      )}
      {rejectMessage && (
        <Box mb={2}>
          <CommentChip
            rejectMessage={rejectMessage}
            fetchProperty={fetchProperty}
            uuid={uuid}
            id={name}
          />
        </Box>
      )}
      {Object.values(imageObject).flat().length > 0 ? (
        <GridList spacing={4} cellHeight={150} cols={2}>
          {Object.keys(imageObject).map(key =>
            imageObject[key].map(image => (
              <GridListTile
                key={image.id}
                onClick={() =>
                  window.open(
                    image.url.replace("localhost:3000", "localhost:3001")
                  )
                }
                classes={{ tile: classes.tile }}
              >
                <img src={image.thumb} alt={image.name} />

                <GridListTileBar
                  subtitle={
                    <Box
                      p={0.5}
                      width="100%"
                      bgcolor="hsl(0,0%,0%,0.4)"
                      position="absolute"
                      bottom={0}
                      right={0}
                    >
                      <Typography align="right" variant="body2">
                        {moment(image.createdAt * 1000).fromNow()}
                      </Typography>
                      <Typography align="right" variant="body2">
                        {moment(image.createdAt * 1000).format(
                          "Do MMM YYYY, HH:mm:ss"
                        )}
                      </Typography>
                    </Box>
                  }
                  titlePosition="top"
                  className={clsx(classes.tileDisappear)}
                  actionPosition="right"
                  actionIcon={
                    image.type === "application/pdf" && (
                      <Typography
                        color="inherit"
                        variant="body2"
                        className={classes.tileIcon}
                      >
                        PDF
                      </Typography>
                    )
                  }
                  title={
                    shouldShowUnitCount(name) && (
                      <Typography
                        variant="body2"
                        className={classes.unitText}
                        color="primary"
                        display="inline"
                        align="right"
                      >
                        {`${name === "deeds" ? "ใบที่" : "คนที่"} ${Number(
                          key
                        ) + 1}`}
                      </Typography>
                    )
                  }
                />
              </GridListTile>
            ))
          )}
        </GridList>
      ) : (
        <Typography
          style={{ marginTop: theme.spacing(2) }}
          align="left"
          variant="body2"
          color="textSecondary"
        >
          ไม่ได้อัพโหลด
        </Typography>
      )}
    </Box>
  );
}

DetailImage.defaultProps = {
  rejectMessage: ""
};

DetailImage.propTypes = {
  uuid: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  isRejected: PropTypes.bool.isRequired,
  rejectMessage: PropTypes.string,
  user: PropTypes.objectOf(PropTypes.string).isRequired,
  fetchProperty: PropTypes.func.isRequired,
  heading: PropTypes.string.isRequired,
  imageObject: PropTypes.objectOf(PropTypes.array).isRequired,
  submissionProgress: PropTypes.string.isRequired,
  approveMode: PropTypes.bool.isRequired
};

export default DetailImage;
