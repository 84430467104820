import React from 'react';
import { Typography, Box, Popover } from '@material-ui/core';
import { getPropertyDetailsContent } from 'Utils';
import PropTypes from 'prop-types';
import { lightGreen, red } from '@material-ui/core/colors';

function EditHistoryBox({
  open,
  anchorEl,
  handleClose,
  id,
  isEdited,
  editedFields,
  fieldData,
}) {
  const editedFieldData = isEdited ? editedFields[id] : '';
  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Box p={2}>
        <Box mt={1} mb={3}>
          <Typography color="textPrimary" variant="subtitle2">
            ประวัติการแก้ไข
          </Typography>
        </Box>

        <Box
          display="flex"
          p={1}
          style={{ backgroundColor: red[50] }}
          borderRadius={4}
        >
          <Typography
            variant="body2"
            style={{
              whiteSpace: 'pre',
              color: 'hsl(354, 100%, 18%)',
            }}
          >
            {getPropertyDetailsContent(id, editedFieldData)}
          </Typography>
        </Box>
        <Box mt={1} mb={1} />
        <Box p={1} style={{ backgroundColor: lightGreen[50] }} borderRadius={4}>
          <Typography
            style={{
              whiteSpace: 'pre',
              color: 'hsl(88, 51%, 15%)',
            }}
            variant="body2"
          >
            {fieldData}
          </Typography>
        </Box>
      </Box>
    </Popover>
  );
}

EditHistoryBox.defaultProps = {
  anchorEl: null,
};

EditHistoryBox.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  isEdited: PropTypes.bool.isRequired,
  editedFields: PropTypes.objectOf(PropTypes.any).isRequired,
  fieldData: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  anchorEl: PropTypes.instanceOf(Element),
};

export default EditHistoryBox;
