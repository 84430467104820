import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Menu, Button, MenuItem, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  menuItem: {
    '&:hover': {
      color: theme.palette.text.primary,
    },
  },
}));
const AdvertisementControlMenu = ({ uuid, userRole }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();
  const menuOpen = Boolean(anchorEl);

  const menuOptions = [
    {
      id: 1,
      label: 'รายการโฆษณา',
      href: `/${userRole}/advertisements?uuid=${uuid}`,
    },
    {
      id: 2,
      label: 'สร้างโฆษณาใหม่',
      href: `/${userRole}/ads-form?uuid=${uuid}&editMode=false`,
    },
    {
      id: 3,
      label: 'สถิติโฆษณา',
      href: `/${userRole}/property/stats?${uuid}`,
    },
  ];

  function handleMenuClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleMenuClose() {
    setAnchorEl(null);
  }

  return (
    <>
      <Button
        onClick={handleMenuClick}
        variant="text"
        color="secondary"
        disableElevation
      >
        เมนูโฆษณา
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={menuOpen}
        getContentAnchorEl={null}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {menuOptions.map(option => (
          <MenuItem
            className={classes.menuItem}
            component="button"
            disabled={option.disabled}
            button
            key={option.id}
            href={option.href}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

AdvertisementControlMenu.propTypes = {
  uuid: PropTypes.string.isRequired,
  userRole: PropTypes.oneOf(['admin', 'manager', 'sales']).isRequired,
};

export default AdvertisementControlMenu;
