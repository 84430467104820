/* eslint camelcase: 0 */
import * as Yup from "yup";
import provinceList from "../../forms/addressSelectorComponents/province.json";

const textRequiredMessage = "จำเป็นต้องกรอก";
const selectRequiredMessage = "จำเป็นต้องเลือก";
const numberTypeMessage = "กรอกเป็นตัวเลขเท่านั้น";

const firstSectionSchema = Yup.object().shape({
  listNumber: Yup.string().required(textRequiredMessage),
  nickname: Yup.string()
    .required(textRequiredMessage)
    .max(56, "สูงสุด 56 ตัวอักษร"),
  contractType: Yup.mixed()
    .oneOf(
      ["exClientNotSellable", "openNoCommission", "exSellable"],
      "ไม่มีในตัวเลือก"
    )
    .required(selectRequiredMessage),
  commissionPercentage: Yup.number()
    .typeError(numberTypeMessage)
    .min(1, "จำนวนขั้นต่ำ 1")
    .max(100, "จำนวนสูงสุด 100"),
  programme: Yup.mixed()
    .oneOf(["p1", "p2", "callIn", "refer"], "ไม่มีในตัวเลือก")
    .required(selectRequiredMessage),
  referPercentage: Yup.number()
    .typeError(numberTypeMessage)
    .min(1, "จำนวนขั้นต่ำ 1")
    .max(100, "จำนวนสูงสุด 100"),
  sellerName: Yup.string().required(textRequiredMessage),
  sellerPhone: Yup.string()
    .matches(/^[0-9]{10}$/, "กรุณากรอกเบอร์โทรที่ถูกต้อง (ตัวเลขเท่านั้น)")
    .required(textRequiredMessage),
  sellerPostCode: Yup.string().length(5, "กรุณากรอกรหัสไปรษณีย์ที่ถูกต้อง")
});

const secondSectionSchema = Yup.object().shape({
  deedNumber: Yup.string().required(textRequiredMessage),
  deedParcelNumber: Yup.string().required(textRequiredMessage),
  deedTambon: Yup.string().required(textRequiredMessage),
  deedDealingFileNumber: Yup.string().required(textRequiredMessage),
  deedAmphoe: Yup.string().required(textRequiredMessage),
  deedProvince: Yup.string().required(textRequiredMessage),
  latLong: Yup.string()
    .matches(
      /\d+\.\d+,\d+\.\d+/,
      "กรุณากรอกในรูปแบบ 13.00,100.00 โดยไม่มีเว้นวรรค"
    )
    .required(textRequiredMessage),
  propertyTambon: Yup.string().required(textRequiredMessage),
  propertyAmphoe: Yup.string().required(textRequiredMessage),
  propertyProvince: Yup.string()
    .oneOf(
      provinceList.map(province => province.name),
      val => {
        return `กรุณากรอกจังหวัดตามรายชื่อนี้เท่านั้น ${JSON.stringify(
          val.values
        )}`;
      }
    )
    .required(textRequiredMessage),
  region: Yup.string().required(textRequiredMessage),
  zone: Yup.string().required(textRequiredMessage)
});

const thirdSectionSchema = Yup.object().shape({
  sellPrice: Yup.number()
    .typeError("กรอกเป็นตัวเลขเท่านั้น")
    .integer("กรอกเป็นตัวเลขเท่านั้น (ไม่มีทศนิยม)")
    .min(0, "กรุณากรอกค่าให้ถูกต้อง (จำนวนต่ำสุดคือ 0)")
    .when("propertyStatus", {
      is: val => [1, 3, 4, 5].includes(val),
      then: Yup.number()
        .required(textRequiredMessage)
        .typeError("กรอกเป็นตัวเลขเท่านั้น")
        .integer("กรอกเป็นตัวเลขเท่านั้น (ไม่มีทศนิยม)")
        .min(0, "กรุณากรอกค่าให้ถูกต้อง (จำนวนต่ำสุดคือ 0)")
    }),
  rentPrice: Yup.number()
    .typeError("กรอกเป็นตัวเลขเท่านั้น")
    .integer("กรอกเป็นตัวเลขเท่านั้น (ไม่มีทศนิยม)")
    .min(0, "กรุณากรอกค่าให้ถูกต้อง (จำนวนต่ำสุดคือ 0)")
    .when("propertyStatus", {
      is: val => [2, 4].includes(val),
      then: Yup.number()
        .required(textRequiredMessage)
        .typeError("กรอกเป็นตัวเลขเท่านั้น")
        .integer("กรอกเป็นตัวเลขเท่านั้น (ไม่มีทศนิยม)")
        .min(0, "กรุณากรอกค่าให้ถูกต้อง (จำนวนต่ำสุดคือ 0)")
    }),
  houseNumber: Yup.string()
    .nullable()
    .when("propertyType", {
      is: val => ![10, 11].includes(Number(val)),
      then: Yup.string().required(textRequiredMessage)
    }),
  areaSizeRai: Yup.number()
    .typeError("กรอกเป็นตัวเลขเท่านั้น")
    .integer("กรุณากรอกเป็นตัวเลขที่ไม่มีทศนิยม")
    .min(0, "กรุณากรอกค่าให้ถูกต้อง (จำนวนต่ำสุดคือ 0)"),
  areaSizeNgan: Yup.number()
    .typeError("กรอกเป็นตัวเลขเท่านั้น")
    .integer("กรุณากรอกเป็นตัวเลขที่ไม่มีทศนิยม")
    .min(0, "จำนวนต่ำสุดคือ 0")
    .max(3, "กรอกได้สูงสุด 3 งาน (4 งานขึ้นไปให้แปลงเป็นไร่)"),
  areaSizeWaa: Yup.number()
    .required(textRequiredMessage)
    .typeError("กรอกเป็นตัวเลขเท่านั้น")
    .min(0, "จำนวนต่ำสุดคือ 0")
    .max(99.99, "กรอกได้สูงสุด 99 ตารางวา (100 ขึ้นไปให้แปลงเป็นงาน)"),
  utilitySpace: Yup.number().when("propertyType", {
    is: val => ![10, 11, 14, 15].includes(val),
    then: Yup.number()
      .min(1, "กรุณากรอกค่าให้ถูกต้อง (จำนวนต่ำสุดคือ 1)")
      .required(textRequiredMessage)
      .typeError("กรอกเป็นตัวเลขเท่านั้น")
  }),
  propertyType: Yup.number().required(selectRequiredMessage),
  propertyStatus: Yup.number().required(selectRequiredMessage),
  bedroomCount: Yup.number().when("propertyType", {
    is: val => ![10, 11].includes(val),
    then: Yup.number().required(selectRequiredMessage)
  }),
  bathroomCount: Yup.number().when("propertyType", {
    is: val => ![10, 11].includes(val),
    then: Yup.number().required(selectRequiredMessage)
  }),
  landPricePerWaa: Yup.number().typeError("กรอกเป็นตัวเลขเท่านั้น"),
  landEstimatedPricePerWaa: Yup.number().typeError("กรอกเป็นตัวเลขเท่านั้น"),
  landFrontWidth: Yup.string().matches(
    /^\d+(\.\d+)?x\d+(\.\d+)?$/,
    "กรุณากรอกในรูปแบบ 99x99 (มีทศนิยมได้)"
  ),
  landRoadLength: Yup.number().typeError(numberTypeMessage),
  warehouseMaximumWeightCapacity: Yup.number().typeError(numberTypeMessage),
  warehouseMaximumBeamWeight: Yup.number().typeError(numberTypeMessage)
});

const fourthSectionSchema = Yup.object().shape({
  baseAdsTitle: Yup.string().required(textRequiredMessage),
  baseAdsDetails: Yup.string()
    .required(textRequiredMessage)
    .min(79, "กรอกขั้นต่ำ 79 ตัวอักษร"),
  baseAdsDirections: Yup.string()
    .required(textRequiredMessage)
    .min(79, "กรอกขั้นต่ำ 79 ตัวอักษร"),
  baseAdsNearbyPlaces: Yup.string()
    .required(textRequiredMessage)
    .min(79, "กรอกขั้นต่ำ 79 ตัวอักษร")
});

const fifthSectionSchema = Yup.object().shape({
  propertyImagesAttached: Yup.string().oneOf(
    ["true"],
    "กรุณาอัพโหลดอย่างน้อย 5 ภาพ"
  ),
  agentAssignmentFilesAttached: Yup.string().oneOf(
    ["true"],
    "กรุณาอัพโหลดอย่างน้อย 1 ภาพ"
  ),
  ownerIdCardsAttached: Yup.string().oneOf(
    ["true"],
    "กรุณาอัพโหลดอย่างน้อย 1 ภาพ"
  ),
  deedsAttached: Yup.string().oneOf(["true"], "กรุณาอัพโหลดอย่างน้อย 1 ภาพ"),
  videoLink: Yup.string().matches(
    /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\\-]+\?v=|embed\/|v\/)?)([\w\\-]+)(\S+)?$/,
    {
      excludeEmptyString: true,
      message: "กรุณาใส่ลิงค์ Youtube ที่ถูกต้อง"
    }
  )
});

const WorkSubmitFormSchema = [
  firstSectionSchema,
  secondSectionSchema,
  thirdSectionSchema,
  fourthSectionSchema,
  fifthSectionSchema
];

export default WorkSubmitFormSchema;
