import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Container,
  Box,
  Typography,
  makeStyles,
  TextField,
  Button
} from "@material-ui/core";
import Axios from "axios";
import LinkContainer from "./LinkContainer";
import snakecaseKeys from "snakecase-keys";
import { useSnackbar } from "notistack";

const useStyles = makeStyles(theme => ({
  container: {
    [theme.breakpoints.only("xs")]: {
      padding: 0
    }
  }
}));

const websiteList = [
  "livinginsider", 
  "thaihometown",
  "pantipmarket",
  "nineasset",
  "teedin108",
  "teedin2",
  "property2share",
  "thaihomelist",
  "facebookmarketplace",
  "facebookpage",
  "bangkokassets",
  "ddproperty",
  "ddteedin",

];

const PropertyStats = ({ uuid, mode }) => {
  const [advertisements, setAdvertisements] = useState([]);
  const [listNumber, setListNumber] = useState("");
  const [shouldUpdate, setShouldUpdate] = useState(true);
  const [noteList, setNoteList] = useState({});
  const { enqueueSnackbar } = useSnackbar();

  const classes = useStyles();

  console.log(noteList);

  useEffect(() => {
    if (shouldUpdate) {
      Axios.get("/v1/properties/show", {
        params: {
          uuid,
          fields: ["advertisements", "listNumber"]
        }
      }).then(response => {
        const property = response.data.data.attributes;
        setListNumber(property.listNumber);
        setAdvertisements(property.advertisements.data);

        setNoteList(
          property.advertisements.data
            .map(item => ({
              id: item.id,
              note: item.attributes.additionalNote || ""
            }))
            .reduce((acc, cur) => ({ ...acc, [cur.id]: cur.note }), {})
        );
        setShouldUpdate(false);
      });
    }
  }, [uuid, shouldUpdate]);

  const handleNoteSubmit = (adsId, value) => () => {
    Axios.patch("/v1/advertisements/update", {
      uuid,
      id: adsId,
      advertisements: snakecaseKeys({ additionalNote: value })
    }).then(() => {
      setShouldUpdate(true);
      enqueueSnackbar("อัพเดทโน้ตเรียบร้อยแล้ว");
    });
  };

  return (
    <Container className={classes.container} maxWidth="sm">
      <Box p={2} bgcolor="white">
        <Typography align="center">{listNumber}</Typography>
        {advertisements.map(ads => (
          <Box key={ads.id} p={2} mt={2}>
            <Typography variant="h6">{ads.attributes.title}</Typography>
            {websiteList.map(web => {
              const postedLinks = ads.attributes.postedLinks.data;
              return (
                <LinkContainer
                  mode={mode}
                  setShouldUpdate={setShouldUpdate}
                  adsId={ads.id}
                  key={web}
                  websiteName={web}
                  postedLinks={postedLinks}
                />
              );
            })}
            <Box mx={-1} mt={3}>
              <Box mb={2} bgcolor="white">
                <TextField
                  placeholder="พิมพ์ที่นี่เพื่อบันทึกข้อความเพิ่มเติม หากต้องการลบให้ล้างค่าแล้วกดบันทึก"
                  fullWidth
                  multiline
                  value={noteList[ads.id]}
                  onChange={e =>
                    setNoteList({ ...noteList, [ads.id]: e.target.value })
                  }
                  variant="outlined"
                  name="additionalNote"
                  label="บันทึกเพิ่มเติม"
                  rows={5}
                />
              </Box>
              <Box display="flex" justifyContent="flex-end">
                <Button
                  onClick={handleNoteSubmit(ads.id, noteList[ads.id])}
                  color="primary"
                  variant="contained"
                >
                  บันทึก
                </Button>
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
    </Container>
  );
};

PropertyStats.defaultProps = {
  uuid: null
};

PropertyStats.propTypes = {
  uuid: PropTypes.string
};

export default PropertyStats;
