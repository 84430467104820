import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import Title from './Title';
import Actions from './Actions';
import Content from './Content';

const useStyles = makeStyles(theme => ({
  paper: {
    [theme.breakpoints.up('sm')]: {
      maxHeight: 500,
    },
  },
  button: {
    marginLeft: theme.spacing(2),
  },
}));
const ApprovalConfirmationDialog = ({
  open,
  handleClose,
  listNumber,
  rejectedFieldsCount,
  rejectedFields,
  uuid,
  userRole,
}) => {
  const [editRedirect, setEditRedirect] = useState('false');
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Dialog
      classes={{
        paper: classes.paper,
      }}
      scroll="paper"
      maxWidth="xs"
      open={open}
      onClose={handleClose}
      fullScreen={fullScreen}
    >
      <Title
        listNumber={listNumber}
        rejectedFieldsCount={rejectedFieldsCount}
      />
      <Content
        editRedirect={editRedirect}
        setEditRedirect={setEditRedirect}
        userRole={userRole}
        rejectedFields={rejectedFields}
        rejectedFieldsCount={rejectedFieldsCount}
      />
      <Actions
        editRedirect={editRedirect}
        uuid={uuid}
        rejectedFieldsCount={rejectedFieldsCount}
        handleClose={handleClose}
        userRole={userRole}
      />
    </Dialog>
  );
};

ApprovalConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  listNumber: PropTypes.string.isRequired,
  rejectedFieldsCount: PropTypes.number.isRequired,
  rejectedFields: PropTypes.objectOf(PropTypes.any).isRequired,
  uuid: PropTypes.string.isRequired,
  userRole: PropTypes.string.isRequired,
};

export default ApprovalConfirmationDialog;
