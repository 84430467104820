import React from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';
import {} from '@material-ui/icons';
import PropTypes from 'prop-types';
import { composeAddress } from 'Utils';
import PropertyShowMaps from '../PropertyShowMaps';

const useStyles = makeStyles(theme => ({
  captionText: {
    marginTop: theme.spacing(0.5),
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
    },
    marginRight: theme.spacing(1),
  },
  mapsWrapper: {
    [theme.breakpoints.only('xs')]: {
      marginLeft: theme.spacing(-2),
      marginRight: theme.spacing(-2),
    },
  },
  header: {
    fontWeight: 500,
  },
}));

const PropertyShowLocation = ({
  soi,
  road,
  amphoe,
  tambon,
  province,
  latLong,
  nearbyPlaces,
  title,
}) => {
  const latitude = Number(latLong.split(',')[0]);
  const longitude = Number(latLong.split(',')[1]);
  const classes = useStyles();

  return (
    <Box>
      <Box mt={4} pl={{ xs: 2, sm: 4 }} mb={2}>
        <Typography
          className={classes.header}
          variant="body1"
          color="textPrimary"
        >
          ที่ตั้งทรัพย์
        </Typography>
      </Box>
      <Box pl={{ xs: 2, sm: 4 }} display="flex">
        <Typography style={{ color: 'rgb(72,72,72)' }} variant="body1">
          {composeAddress({
            soi,
            road,
            amphoe,
            tambon,
            province,
          })}
        </Typography>
      </Box>
      <Box mt={4} pl={{ xs: 2, sm: 4 }} mb={2}>
        <Typography
          className={classes.header}
          variant="body1"
          color="textPrimary"
        >
          พิกัด GPS ที่ตั้งทรัพย์
        </Typography>
      </Box>
      <Box pl={{ xs: 2, sm: 4 }} display="flex">
        <Typography
          component="a"
          className={classes.mapsLink}
          href={`https://www.google.com/maps/search/?api=1&query=${latLong}`}
          target="_blank"
          variant="body1"
        >
          {latLong}
        </Typography>
      </Box>
      <Box className={classes.mapsWrapper} mt={4}>
        <PropertyShowMaps
          title={title}
          lat={latitude}
          lng={longitude}
          nearbyPlaces={nearbyPlaces}
        />
      </Box>
    </Box>
  );
};

PropertyShowLocation.defaultProps = {
  soi: '',
  road: '',
};

PropertyShowLocation.propTypes = {
  soi: PropTypes.string,
  road: PropTypes.string,
  amphoe: PropTypes.string.isRequired,
  tambon: PropTypes.string.isRequired,
  province: PropTypes.string.isRequired,
  latLong: PropTypes.string.isRequired,
  nearbyPlaces: PropTypes.objectOf(PropTypes.array.isRequired).isRequired,
  title: PropTypes.string.isRequired,
};

export default PropertyShowLocation;
