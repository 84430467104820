import React from 'react';
import { connect } from 'react-redux';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  normal: {
    zIndex: 9999,
    height: 3,
    width: '100%',
    position: 'fixed',
    top: 0,
  },
  appear: {
    opacity: 1,
  },
  disappear: {
    opacity: 0,
  },
}));

function LinearLoading(props) {
  const classes = useStyles();
  const { loading } = props;

  return (
    <LinearProgress
      classes={{
        root: clsx(
          classes.normal,
          loading ? classes.appear : classes.disappear
        ),
      }}
      color="primary"
      variant="indeterminate"
    />
  );
}

LinearLoading.propTypes = {
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  loading: state.loading.loading,
});

export default connect(mapStateToProps)(LinearLoading);
