import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Chip } from '@material-ui/core';
import { makeStyles, lighten } from '@material-ui/core/styles';
import RejectWrapper from '../RejectWrapper';
import EditHistoryBox from '../EditHistoryBox';
import CommentChip from '../CommentChip';

const useStyles = makeStyles(theme => ({
  bodyBox: {
    '&:hover button': {
      opacity: 1,
      height: 'auto',
    },
  },
  rejectButton: {
    opacity: 0,
    transition: theme.transitions.create(['opacity'], {
      duration: theme.transitions.duration.shortest,
    }),
  },
  rejectText: {
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(0.5),
    background: lighten(theme.palette.secondary.light, 0.55),
  },
  divider: {
    marginBottom: theme.spacing(1),
  },
  isEditedChip: {
    fontWeight: 500,
    marginRight: theme.spacing(1),
    background: theme.palette.secondary.dark,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: 'white',
    },
    '&:active': {
      backgroundColor: theme.palette.secondary.main,
      color: 'white',
    },
    '&:focus': {
      backgroundColor: theme.palette.secondary.main,
      color: 'white',
    },
  },
}));

const showRejectButtonStatus = {
  admin: ['sales_submitted', 'sales_edited'],
  manager: ['admin_approved', 'admin_edited'],
};

function DetailText({
  heading,
  body,
  user,
  id,
  uuid,
  isRejected,
  rejectMessage,
  fetchProperty,
  submissionProgress,
  approveMode,
  editedFields,
}) {
  const isEdited = Object.keys(editedFields).includes(id);
  const classes = useStyles();
  const shouldShowReject =
    ['admin', 'manager'].includes(user.role) &&
    approveMode &&
    showRejectButtonStatus[user.role].includes(submissionProgress);

  const [editHistoryAnchorEl, setEditHistoryAnchorEl] = useState(null);

  const handleEditHistoryClose = () => {
    setEditHistoryAnchorEl(null);
  };

  const handleEditHistoryOpen = e => {
    setEditHistoryAnchorEl(e.currentTarget);
  };

  return (
    <>
      <Box
        mt={3.5}
        bgcolor="hsla(228, 20%, 97%, 1)"
        borderRadius={8}
        p={1}
        pl={2}
      >
        <Typography color="textSecondary" variant="caption">
          {heading}
        </Typography>
        {shouldShowReject && heading !== 'List เลขที่' ? (
          <>
            <RejectWrapper
              fieldData={body}
              isEdited={isEdited}
              editedFields={editedFields}
              isRejected={isRejected}
              rejectMessage={rejectMessage}
              fetchProperty={fetchProperty}
              uuid={uuid}
              id={id}
              boxProps={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-end',
              }}
              textElement={
                <Typography
                  color={body === 'ไม่ได้กรอก' ? 'textSecondary' : 'primary'}
                  style={{ whiteSpace: 'pre-line' }}
                >
                  {body}
                </Typography>
              }
            />
            <EditHistoryBox
              anchorEl={editHistoryAnchorEl}
              isEdited={isEdited}
              fieldData={body}
              id={id}
              editedFields={editedFields}
              open={Boolean(editHistoryAnchorEl)}
              handleClose={handleEditHistoryClose}
            />
          </>
        ) : (
          <Typography
            color={body === 'ไม่ได้กรอก' ? 'textSecondary' : 'textPrimary'}
            style={{ whiteSpace: 'pre-line' }}
          >
            {body}
          </Typography>
        )}
      </Box>
      <Box mt={1} pl={1}>
        {isEdited && approveMode && (
          <>
            <Chip
              className={classes.isEditedChip}
              clickable
              size="small"
              label="มีการแก้ไข"
              onClick={handleEditHistoryOpen}
            />
          </>
        )}
        {rejectMessage && (
          <CommentChip
            rejectMessage={rejectMessage}
            fetchProperty={fetchProperty}
            uuid={uuid}
            id={id}
          />
        )}
      </Box>
    </>
  );
}

DetailText.defaultProps = {
  isRejected: false,
  rejectMessage: '',
};

DetailText.propTypes = {
  fetchProperty: PropTypes.func.isRequired,
  isRejected: PropTypes.bool,
  rejectMessage: PropTypes.string,
  uuid: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  user: PropTypes.objectOf(PropTypes.string).isRequired,
  heading: PropTypes.string.isRequired,
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  submissionProgress: PropTypes.string.isRequired,
  approveMode: PropTypes.bool.isRequired,
  editedFields: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default DetailText;
