/* eslint-disable camelcase */
export const initialShowList = {
  authorizedPartyIdCards: false,
  authorizedPartyHouseDocuments: false,
  ownerHouseDocuments: false,
  propertyHouseDocuments: false,
  sellContractDocuments: false,
  buildingPermits: false,
  operationPermits: false,
};

export const initialImageList = [
  {
    name: 'propertyImages',
    thName: 'ภาพทรัพย์',
    images: {},
    order: [],
  },
  {
    name: 'agentAssignmentFiles',
    thName: 'สัญญาแต่งตั้งนายหน้า',
    images: {},
    order: [],
  },
  {
    name: 'ownerIdCards',
    thName: 'สำเนาบัตรประชาชนเจ้าของกรรมสิทธิ์',
    images: {},
    order: [],
  },
  {
    name: 'ownerHouseDocuments',
    thName: 'สำเนาทะเบียนบ้านเจ้าของกรรมสิทธิ์',
    images: {},
    order: [],
  },
  {
    name: 'propertyHouseDocuments',
    thName: 'สำเนาทะเบียนบ้านหลังที่ขาย',
    images: {},
    order: [],
  },
  {
    name: 'authorizedPartyIdCards',
    thName: 'สำเนาบัตรประชาชนผู้รับมอบอำนาจ',
    images: {},
    order: [],
  },
  {
    name: 'authorizedPartyHouseDocuments',
    thName: 'สำเนาทะเบียนบ้านของผู้รับมอบอำนาจ',
    images: {},
    order: [],
  },
  {
    name: 'sellContractDocuments',
    thName: 'หนังสือสัญญาขาย (ทด.13)',
    images: {},
    order: [],
  },
  {
    name: 'buildingPermits',
    thName: 'ใบอนุญาตก่อสร้าง',
    images: {},
    order: [],
  },
  {
    name: 'operationPermits',
    thName: 'ใบอนุญาต รง.4',
    images: {},
    order: [],
  },
  {
    name: 'deeds',
    thName: 'สำเนาโฉนด',
    images: {},
    order: [],
  },
];

export const initialFieldCountList = {
  deeds: 1,
  ownerHouseDocuments: 1,
  ownerIdCards: 1,
  agentAssignmentFiles: 1,
  propertyImages: 1,
  authorizedPartyIdCards: 1,
  authorizedPartyHouseDocuments: 1,
  propertyHouseDocuments: 1,
  sellContractDocuments: 1,
  buildingPermits: 1,
  operationPermits: 1,
};
