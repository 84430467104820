export const contractTypeOptions = [
  {
    label: 'สัญญา EX ขายเองไม่ได้',
    value: 'exClientNotSellable',
  },
  {
    label: 'สัญญา Open ลูกค้าขายเองได้ ไม่จ่ายค่าคอม',
    value: 'openNoCommission',
  },
  { label: 'สัญญา EX ลูกค้าขายเองได้ จ่ายค่าคอม', value: 'exSellable' },
];

export const programmeOptions = [
  {
    label: 'P1 (งานจากพนักงาน)',
    value: 'p1',
  },
  { label: 'P2 (รับค่าลิสต์)', value: 'p2' },
  {
    label: 'Call in (งานจากบริษัท)',
    value: 'callIn',
  },
  {
    label: 'Refer',
    value: 'refer',
  },
];

export const zoneOptions = [
  {
    value: 'A',
    label:
      'A: พระนคร ป้อมปราบศัตรูพ่าย สัมพันธวงศ์ ปทุมวัน บางรัก สาทร ยานนาวา บางคอแหลม วัฒนา คลองเตย (CBD)',
  },
  {
    value: 'B',
    label: 'B: ดุสิต ราชเทวี พญาไท ดินแดง ห้วยขวาง บางซื่อ จตุจักร',
  },
  {
    value: 'C',
    label: 'C: ลาดพร้าว วังทองหลาง บางกะปิ บึงกุ่ม คันนายาว',
  },
  {
    value: 'D',
    label: 'D: สวนหลวง พระโขนง บางนา ประเวศ',
  },
  {
    value: 'E',
    label: 'E: สะพานสูง มีนบุรี ลาดกระบัง คลองสามวา หนอกจอก',
  },
  {
    value: 'F',
    label: 'F: บางเขน หลักสี่ สายไหม ดอนเมือง',
  },
  {
    value: 'G',
    label: 'G: บางพลัด บางกอกน้อย บางกอกใหญ่ คลองสาน ธนบุรี ราษฏร์บูรณะ',
  },
  {
    value: 'H',
    label:
      'H: ตลิ่งชัน ทวีวัฒนา บางแค ภาษีเจริญ  จอมทอง ทุ่งครุ บางขุนเทียน บางบอน หนองแขม',
  },
  {
    value: 'I',
    label: 'I: นนทบุรี',
  },
  {
    value: 'J',
    label: 'J: ปทุมธานี',
  },
  {
    value: 'K',
    label: 'K: สมุทรปราการ',
  },
  {
    value: 'L',
    label: 'L: ต่างจังหวัด',
  },
];

export const regionOptions = [
  {
    value: 'กลาง',
    label: 'ภาคกลาง',
  },
  {
    value: 'เหนือ',
    label: 'ภาคเหนือ',
  },
  {
    value: 'ตะวันออก',
    label: 'ภาคตะวันออก',
  },
  {
    value: 'ตะวันตก',
    label: 'ภาคตะวันตก',
  },
  {
    value: 'ตะวันออกเฉียงเหนือ',
    label: 'ภาคตะวันออกเฉียงเหนือ',
  },
  {
    value: 'ใต้',
    label: 'ภาคใต้',
  },
];

export const propertyTypeOptions = [
  {
    value: 0,
    label: 'คลิกเลือก',
  },
  {
    value: 1,
    label: 'คอนโดมิเนียม',
  },
  {
    value: 2,
    label: 'บ้านเดี่ยว',
  },
  {
    value: 3,
    label: 'บ้านชั้นเดียว',
  },
  {
    value: 4,
    label: 'บ้านแฝด',
  },
  {
    value: 5,
    label: 'ทาวน์เฮ้าส์',
  },
  {
    value: 6,
    label: 'ทาวน์โฮม',
  },
  {
    value: 7,
    label: 'โฮมออฟฟิศ',
  },
  {
    value: 8,
    label: 'อาคารพาณิชย์/ตึกแถว',
  },
  {
    value: 9,
    label: 'สำนักงาน/ออฟฟิศ',
  },
  {
    value: 10,
    label: 'ที่ดินเปล่า',
  },
  {
    value: 11,
    label: 'ที่ดินพร้อมสิ่งปลูกสร้าง',
  },
  {
    value: 12,
    label: 'โกดัง/โรงงาน',
  },
  {
    value: 13,
    label: 'อพาร์ทเม้นท์/ห้องเช่า',
  },
  {
    value: 14,
    label: 'รีสอร์ท/โรงแรม',
  },
  {
    value: 15,
    label: 'ฟาร์ม/สวนเกษตร',
  },
];

export const propertyStatusOptions = [
  {
    value: 1,
    label: 'ขาย',
  },
  {
    value: 2,
    label: 'ให้เช่า',
  },
  {
    value: 3,
    label: 'ขายดาวน์',
  },
  {
    value: 4,
    label: 'ขายและให้เช่า',
  },
  {
    value: 5,
    label: 'เซ้ง',
  },
];

export const propertyConditionOptions = [
  'ดี',
  'ปานกลาง',
  'เก่า',
  'รกร้าง',
  'ต้องซ่อม',
].map(elem => ({ value: elem, label: elem }));

export const featureOptions = [
  'หลังมุม',
  'หลังริม',
  'ที่ถมแล้ว',
  'ถนนกว้าง',
  'อยู่ต้นซอย',
  'ติดถนนหลัก',
  'วิวแม่น้ำ',
  'วิวสระ',
  'วิวสวน',
  'วิวเมือง',
  'อยู่ในพื้นที่สีม่วง',
  'ติดสวน',
  'ติดทะเลสาบ',
].map(element => ({ value: element, label: element }));

export const directionOptions = [
  'เหนือ',
  'ใต้',
  'ตะวันออก',
  'ตะวันตก',
  'ตะวันออกเฉียงเหนือ',
  'ตะวันออกเฉียงใต้',
  'ตะวันตกเฉียงเหนือ',
  'ตะวันตกเฉียงใต้',
].map(elem => ({ value: elem, label: elem }));

export const landscapeOptions = [
  'วิวแม่น้ำ',
  'วิวสระ',
  'วิวสวน',
  'วิวเมือง',
  'มีตึกบัง',
  'อื่นๆ',
].map(elem => ({ value: elem, label: elem }));

export const roadConditionOptions = [
  'ถนนคอนกรีต',
  'ถนนลาดยาง',
  'ถนนลูกรัง',
  'ถนนดิน',
].map(elem => ({ value: elem, label: elem }));

export const landConditionOptions = [
  'ถมแล้ว',
  'ติดถนนสองด้าน (แปลงมุม)',
  'อยู่ติดแม่น้ำ/คลองชลประทาน',
  'ต่ำกว่าถนนน้อยกว่า 0.5 เมตร',
  'ต่ำกว่าถนนมากกว่า 0.5 เมตร',
  'เป็นบ่อปลาหรือสระน้ำ',
  'ใกล้แนวเสาไฟฟ้าแรงสูง',
].map(el => ({ value: el, label: el }));

export const warehousePurpleZoneOptions = [
  {
    value: 'true',
    label: 'อยู่ในพื้นที่สีม่วง',
  },
  {
    value: 'false',
    label: 'ไม่อยู่ในพื้นที่สีม่วง',
  },
];

export const warehouseHasOperationPermitOptions = [
  {
    value: 'true',
    label: 'มีใบอนุญาตประกอบกิจการโรงงาน',
  },
  {
    value: 'false',
    label: 'ไม่มีใบอนุญาตประกอบกิจการโรงงาน',
  },
];

export const warehouseTrailerAccessibleOptions = [
  {
    value: 'true',
    label: 'รถพ่วงเข้าออกได้',
  },
  {
    value: 'false',
    label: 'รถพ่วงเข้าออกไม่ได้',
  },
];

const options = [];
for (let i = 0; i <= 100; i += 1) {
  options.push(i);
}
export const numberOptions = options.map(option => ({
  value: option,
  label: option,
}));
