import React, { useState, useEffect } from 'react'
import { Table } from 'antd'
import snakecaseKeys from 'snakecase-keys'
import Axios from 'axios'
import { Typography } from '@material-ui/core'
import { formatNumber } from 'Utils'
import './style.css'
const propertyTypes = [
  {
    label: 'บ้าน',
    name: 'house',
  },
  {
    label: 'ทาวน์เฮาส์',
    name: 'townhouse',
  },

  {
    label: 'คอนโด',
    name: 'condo',
  },
  {
    label: 'โกดัง, โรงงาน',
    name: 'factory',
  },
  {
    label: 'อพาร์ทเมนต์',
    name: 'apartment',
  },
  {
    label: 'ที่ดิน',
    name: 'land',
  },
  {
    label: 'อาคารพาณิชย์',
    name: 'commercial',
  },
  {
    label: 'สำนักงาน',
    name: 'office',
  },
  {
    label: 'อื่นๆ',
    name: 'others',
  },
]

const UserResponseTable = () => {
  const [state, setState] = useState({
    data: [],
    pagination: {
      position: 'both',
      pageSize: 10,
      showSizeChanger: true,
      size: 'small',
      // eslint-disable-next-line no-use-before-define
      onShowSizeChange: handleShowSizeChange,
    },
    loading: true,
    filters: null,
  })

  function handleShowSizeChange(current, pageSize) {
    setState((prevState) => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
        current,
        pageSize,
      },
    }))
  }

  const fetch = (params = {}) => {
    setState((prevState) => ({ ...prevState, loading: true }))
    Axios.get('/v2/user_responses', {
      params: snakecaseKeys({
        ...params,
        responseType: 'others',
      }),
    }).then((response) => {
      const { headers } = response
      const total = Number(headers['total-count'])
      const current = Number(headers['current-page'])

      setState((prevState) => ({
        loading: false,
        data: response.data.data.filter(
          (item) => item.attributes.responseType === 'others'
        ),
        pagination: {
          ...prevState.pagination,
          current,
          total,
        },
      }))
    })
  }

  const columns = [
    {
      title: 'วันที่ส่งข้อมูล',
      dataIndex: ['attributes', 'createdAt'],
      render: (createdAt) =>
        new Date(createdAt).toLocaleString('th-th', {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
        }),
    },
    {
      title: 'ประเภททรัพย์',
      id: 'sellingPropertyType',
      dataIndex: ['attributes', 'sellingPropertyType'],
      render: (sellingPropertyType, record) => (
        <Typography variant="body2">
          {
            propertyTypes?.find(
              (propertyType) => propertyType.name === sellingPropertyType
            )?.label
          }
        </Typography>
      ),
    },
    {
      title: 'ราคาทรัพย์',
      id: 'sellingPrice',
      dataIndex: ['attributes', 'sellingPrice'],
      align: 'right',
      render: (sellingPrice) => (
        <Typography variant="body2">{`${formatNumber(
          sellingPrice
        )} บาท`}</Typography>
      ),
    },
    {
      title: 'จังหวัดที่ตั้ง',
      id: 'sellingProvince',
      dataIndex: ['attributes', 'sellingProvince'],
    },
    {
      title: 'อำเภอ',
      id: 'sellingAmphoe',
      dataIndex: ['attributes', 'sellingAmphoe'],
    },
    {
      title: 'ชื่อ',
      id: 'sellerName',
      dataIndex: ['attributes', 'sellerName'],
    },
    {
      title: 'อีเมล',
      id: 'sellerEmail',
      dataIndex: ['attributes', 'sellerEmail'],
    },
    {
      title: 'ไอดีไลน์',
      id: 'sellerLine',
      dataIndex: ['attributes', 'sellerLine'],
    },
    {
      title: 'เบอร์โทร',
      id: 'sellerPhone',
      dataIndex: ['attributes', 'sellerPhone'],
    },
    {
      title: 'เวลาที่สะดวก',
      id: 'contactTime',
      dataIndex: ['attributes', 'contactTime'],
      render: (contactTime) => (
        <>
          {contactTime?.map((time) => (
            <Typography key={time} variant="body2">
              {time}
            </Typography>
          ))}
        </>
      ),
    },
  ]

  const handleTableChange = (pagination) => {
    fetch({
      page: pagination.current,
      items: pagination.pageSize,
    })
  }

  useEffect(() => {
    fetch({
      page: 1,
      items: 10,
    })
  }, [])

  return (
    <Table
      bordered
      columns={columns}
      rowKey={(record) => record.attributes.uuid}
      dataSource={state.data}
      pagination={state.pagination}
      loading={state.loading}
      onChange={handleTableChange}
    />
  )
}

export default UserResponseTable
