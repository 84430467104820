/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SimpleDialog from 'Components/shared/SimpleDialog';
import { useSnackbar } from 'notistack';
import { useLocation, useHistory } from 'react-router-dom';
import Axios from 'axios';
import { Typography, Box } from '@material-ui/core';
import { t } from 'Constants';
import snakecaseKeys from 'snakecase-keys';
import { getRejectedFields } from 'Components/control/shared/PropertyDetails/functions/propertyDetailsFunctions';

const editConfirmRedirectPath = ({
  userRole,
  editConfirmSubmissionProgress,
}) => {
  switch (userRole) {
    case 'admin':
      return '/admin/property-control?tab=1';
    case 'manager':
      return editConfirmSubmissionProgress === 'manager_rejected'
        ? '/manager/property-control?tab=1'
        : '/manager/complete-properties?tab=0';
    case 'sales':
      return '/sales/my-properties?tab=1';
    default:
      return `${userRole}`;
  }
};

const EditConfirmDialog = ({
  editConfirmDialogOpen,
  setEditConfirmDialogOpen,
  userRole,
  listNumber,
  uuid,
  handleMenuClose,
}) => {
  const location = useLocation();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [hasRejectedFields, setHasRejectedFields] = useState(false);
  const [uneditedFields, setUneditedFields] = useState([]);

  useEffect(() => {
    if (uuid && editConfirmDialogOpen) {
      Axios.get('/v1/properties/show', {
        params: {
          uuid,
          fields: ['submissionProgress', 'rejectedFields', 'uneditedFields'],
        },
      }).then(response => {
        const property = response.data.data.attributes;
        const { submissionProgress, rejectedFields } = property;
        setUneditedFields(property.uneditedFields);
        setHasRejectedFields(
          Object.keys(
            getRejectedFields({
              userRole,
              approveMode: true,
              submissionProgress,
              rejectedFields,
            })
          ).length > 0
        );
      });
    }
  }, [editConfirmDialogOpen, uuid, userRole]);

  const editConfirmSubmissionProgress = () => {
    if (!hasRejectedFields) {
      if (['sales', 'admin'].includes(userRole)) return `${userRole}_edited`;
      if (userRole === 'manager') return 'completed';
    }
    if (hasRejectedFields) {
      if (['manager', 'admin'].includes(userRole))
        return `${userRole}_rejected`;
    }
    return '';
  };

  const handleEditConfirmClick = () => {
    Axios.patch(
      '/v1/properties/update',
      snakecaseKeys({
        listNumber,
        submissionProgress: editConfirmSubmissionProgress(),
      })
    ).then(response => {
      if (response.data.hasError) {
        setEditConfirmDialogOpen(false);
        if (handleMenuClose) handleMenuClose();
        enqueueSnackbar('เกิดข้อผิดพลาด');
      } else {
        enqueueSnackbar('ยืนยันการแก้ไขเรียบร้อยแล้ว');
        setEditConfirmDialogOpen(false);
        if (handleMenuClose) handleMenuClose();

        // Handle Admin editing sales property case
        const redirectPath = editConfirmRedirectPath({
          userRole,
          editConfirmSubmissionProgress: editConfirmSubmissionProgress(),
        });
        if (redirectPath.includes(location.pathname)) {
          window.location.replace(redirectPath);
        } else {
          history.push(redirectPath);
        }
      }
    });
  };
  return (
    <SimpleDialog
      dialogTitle="ยืนยันการแก้ไขทรัพย์"
      message={
        <>
          <Typography>
            {uneditedFields.length > 0
              ? 'คุณยังมีรายการที่ยังไม่ได้แก้ไข กรุณาแก้ไขให้ครบถ้วนดังนี้'
              : 'กรุณาคลิกยืนยันหากคุณได้แก้ไขทรัพย์นี้เรียบร้อยแล้ว'}
          </Typography>
          {uneditedFields.map(field => (
            <Box
              key={field}
              borderRadius={4}
              my={1.5}
              p={1}
              bgcolor="background.default"
            >
              <Typography color="textPrimary" variant="body2">
                {t[field]}
              </Typography>
            </Box>
          ))}
        </>
      }
      primaryBtnProps={{ disabled: uneditedFields.length > 0 }}
      open={editConfirmDialogOpen}
      primaryBtnText="ยืนยัน"
      handlePrimaryClick={handleEditConfirmClick}
      handleSecondaryClick={() => setEditConfirmDialogOpen(false)}
      handleClose={() => setEditConfirmDialogOpen(false)}
      secondaryBtnText="ยกเลิก"
    />
  );
};

EditConfirmDialog.defaultProps = {
  handleMenuClose: null,
};

EditConfirmDialog.propTypes = {
  editConfirmDialogOpen: PropTypes.bool.isRequired,
  setEditConfirmDialogOpen: PropTypes.func.isRequired,
  userRole: PropTypes.string.isRequired,
  listNumber: PropTypes.string.isRequired,
  uuid: PropTypes.string.isRequired,
  handleMenuClose: PropTypes.func,
};

export default EditConfirmDialog;
