/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { Hidden } from '@material-ui/core';
import DesktopImages from './DesktopImages';
import MobileImageSlider from './MobileImageSlider';

const PropertyShowImages = ({ uuid }) => {
  return (
    <>
      <Hidden xsDown>
        <DesktopImages uuid={uuid} />
      </Hidden>
      <Hidden smUp>
        <MobileImageSlider uuid={uuid} />
      </Hidden>
    </>
  );
};

PropertyShowImages.propTypes = {
  uuid: PropTypes.string.isRequired,
};

export default PropertyShowImages;
