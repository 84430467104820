import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { InputAdornment, Grid, IconButton, Tooltip } from '@material-ui/core';
import LocalHotelIcon from '@material-ui/icons/LocalHotel';
import CategoryIcon from '@material-ui/icons/Category';
import RefreshIcon from '@material-ui/icons/Refresh';
import LandscapeIcon from '@material-ui/icons/Landscape';
import SectionDividerWithIcon from 'Components/shared/SectionDividerWithIcon';
import {
  SelectFieldSet,
  FastFieldSet,
  CheckboxGroupFieldSet,
  FieldSet,
  RadioGroupFieldSet,
} from 'Components/fields';
import {
  formatNumber,
  calculatePricePerSqm,
  calculatePricePerWaa,
} from 'Utils';
import {
  AreaSizeIcon,
  BuildingIcon,
  WareHoseIcon,
  HomeCityIcon,
  FloorPlanIcon,
  ShowerIcon,
} from 'Components/customIcons';
import {
  propertyTypeOptions,
  propertyStatusOptions,
  numberOptions,
  propertyConditionOptions,
  landConditionOptions,
  warehousePurpleZoneOptions,
  warehouseHasOperationPermitOptions,
  warehouseTrailerAccessibleOptions,
  featureOptions,
  directionOptions,
  landscapeOptions,
  roadConditionOptions,
} from '../shared';

const shouldDisplayProjectName = propertyType =>
  [1, 2, 3, 4, 5, 6].includes(Number(propertyType));

const shouldDisplayHouseNumber = propertyType =>
  ![10, 11].includes(propertyType);

function ThirdSection({ values, setFieldValue }) {
  const sellType = [1, 3, 4, 5].includes(Number(values.propertyStatus));
  const rentType = [2, 4].includes(Number(values.propertyStatus));
  const landType = [10, 11].includes(Number(values.propertyType));
  const condoType = values.propertyType === 1;
  const warehouseType = values.propertyType === 12;

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <div>
      <SectionDividerWithIcon icon={<HomeCityIcon />}>
        รายละเอียดทรัพย์
      </SectionDividerWithIcon>
      <SelectFieldSet
        required
        name="propertyType"
        label="ประเภททรัพย์"
        options={propertyTypeOptions}
      />
      <SelectFieldSet
        required
        name="propertyStatus"
        label="ประเภทประกาศ"
        options={propertyStatusOptions}
      />
      {sellType && (
        <FastFieldSet
          type="text"
          required
          name="sellPrice"
          label="ราคาขาย"
          helperText={formatNumber(String(values.sellPrice))}
        />
      )}
      {rentType && (
        <FastFieldSet
          type="text"
          required
          name="rentPrice"
          label="ราคาเช่า (ต่อเดือน)"
          helperText={formatNumber(values.rentPrice)}
        />
      )}
      {shouldDisplayHouseNumber(values.propertyType) && (
        <FastFieldSet
          placeholder="99/888"
          required
          type="text"
          name="houseNumber"
          label="บ้านเลขที่"
        />
      )}
      {shouldDisplayProjectName(values.propertyType) && (
        <FastFieldSet
          type="text"
          name="projectName"
          label="ชื่อโครงการ (ถ้ามี)"
        />
      )}
      <SectionDividerWithIcon
        icon={<AreaSizeIcon />}
        secondaryText="กรอกตามจริง ไม่มีผลต่อการแสดงโฆษณา"
      >
        เนื้อที่และพื้นที่ใช้สอย
      </SectionDividerWithIcon>
      <FastFieldSet
        type="text"
        name="areaSizeRai"
        label="เนื้อที่ (ไร่)"
        InputProps={{
          endAdornment: <InputAdornment position="end">ไร่</InputAdornment>,
        }}
      />
      <FastFieldSet
        type="text"
        name="areaSizeNgan"
        label="เนื้อที่ (งาน)"
        InputProps={{
          endAdornment: <InputAdornment position="end">งาน</InputAdornment>,
        }}
      />
      <FastFieldSet
        required
        type="text"
        name="areaSizeWaa"
        label="เนื้อที่ (ตารางวา)"
        InputProps={{
          endAdornment: <InputAdornment position="end">ตร.ว.</InputAdornment>,
        }}
      />
      {!landType && (
        <>
          <FastFieldSet
            required
            type="text"
            name="utilitySpace"
            label="พื้นที่ใช้สอย (ตารางเมตร)"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">ตร.ม.</InputAdornment>
              ),
            }}
          />
          <SectionDividerWithIcon icon={<FloorPlanIcon />}>
            จำนวนชั้นและห้อง
          </SectionDividerWithIcon>
          <Grid spacing={2} container>
            <Grid item xs={12} lg={6}>
              <SelectFieldSet
                required
                name="bedroomCount"
                label="จำนวนห้องนอน"
                options={numberOptions}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocalHotelIcon color="primary" />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <SelectFieldSet
                required
                name="bathroomCount"
                label="จำนวนห้องน้ำ"
                options={numberOptions}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <ShowerIcon color="primary" />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <SelectFieldSet
                name="floorCount"
                label="จำนวนชั้น"
                options={numberOptions}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <SelectFieldSet
                name="otherRoomCount"
                label="จำนวนห้องอื่นๆ"
                options={numberOptions}
              />
            </Grid>
          </Grid>
          <SectionDividerWithIcon icon={<CategoryIcon />}>
            สภาพทรัพย์ (อื่นๆ)
          </SectionDividerWithIcon>
          <SelectFieldSet
            name="exteriorCondition"
            label="สภาพทรัพย์ (ภายนอก)"
            options={propertyConditionOptions}
          />
          <SelectFieldSet
            name="interiorCondition"
            label="สภาพทรัพย์ (ภายใน)"
            options={propertyConditionOptions}
          />
          <CheckboxGroupFieldSet
            label="อุปกรณ์ที่ให้มาด้วย"
            name="additionalEquipment"
            options={['พร้อมเฟอร์นิเจอร์', 'พร้อมเครื่องใช้ไฟฟ้า'].map(el => ({
              value: el,
              label: el,
            }))}
          />
          <CheckboxGroupFieldSet
            dense
            label="จุดเด่น"
            name="features"
            options={featureOptions}
          />
          <SelectFieldSet
            name="headingDirection"
            label="ทิศทาง"
            options={directionOptions}
          />
          <SelectFieldSet
            name="landscapeType"
            label="วิว"
            options={landscapeOptions}
          />
        </>
      )}
      {landType && (
        <>
          <SectionDividerWithIcon icon={<LandscapeIcon />}>
            รายละเอียดของที่ดิน
          </SectionDividerWithIcon>
          <FieldSet
            type="text"
            name="landPricePerWaa"
            label="ราคาต่อตารางวา"
            helperText={formatNumber(values.landPricePerWaa)}
            placeholder="คลิกปุ่มด้านขวาเพื่อคำนวณ"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title="คำนวณราคาต่อตร.ว.">
                    <IconButton
                      onClick={() => {
                        setFieldValue(
                          'landPricePerWaa',
                          calculatePricePerWaa(
                            {
                              rai: values.areaSizeRai || 0,
                              ngan: values.areaSizeNgan || 0,
                              waa: values.areaSizeWaa || 0,
                            },
                            values.sellPrice
                          )
                        );
                      }}
                    >
                      <RefreshIcon />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
          <FastFieldSet
            name="landEstimatedPricePerWaa"
            type="text"
            label="ราคาประเมินต่อตารางวา"
            helperText={formatNumber(values.landEstimatedPricePerWaa)}
          />
          <FastFieldSet name="landFrontWidth" label="หน้ากว้าง" type="text" />
          <FastFieldSet name="landOfficialZoneType" label="ผังสี" type="text" />
          <SelectFieldSet
            name="landRoadCondition"
            label="ประเภทถนนทางเข้า"
            options={roadConditionOptions}
          />
          <FastFieldSet
            name="landRoadLength"
            label="ความกว้างของถนนหน้าที่ดิน"
            type="text"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">เมตร</InputAdornment>
              ),
            }}
          />
          <CheckboxGroupFieldSet
            label="ลักษณะของที่ดิน"
            name="landConditions"
            options={landConditionOptions}
          />
        </>
      )}
      {condoType && (
        <>
          <SectionDividerWithIcon icon={<BuildingIcon />}>
            รายละเอียดของคอนโด
          </SectionDividerWithIcon>
          <FieldSet
            type="text"
            name="condoPricePerSqm"
            label="ราคาต่อตารางเมตร"
            placeholder="คลิกปุ่มด้านขวาเพื่อคำนวณ"
            helperText={formatNumber(values.condoPricePerSqm)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title="คำนวณราคาต่อตร.ม.">
                    <IconButton
                      onClick={() => {
                        setFieldValue(
                          'condoPricePerSqm',
                          calculatePricePerSqm(
                            values.utilitySpace,
                            values.sellPrice
                          )
                        );
                      }}
                    >
                      <RefreshIcon />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
          <Grid spacing={2} container>
            <Grid item xs={12} lg={6}>
              <SelectFieldSet
                name="condoMaxFloorCount"
                label="จำนวนชั้นทั้งหมดของโครงการ"
                options={numberOptions}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <SelectFieldSet
                name="condoLivingFloor"
                label="ห้องอยู่ชั้นที่"
                options={numberOptions}
              />
            </Grid>
          </Grid>
          <FieldSet name="condoBuildingNumber" label="ลำดับของอาคาร" />
        </>
      )}
      {warehouseType && (
        <>
          <SectionDividerWithIcon imgIcon={WareHoseIcon}>
            รายละเอียดของโกดัง/โรงงาน
          </SectionDividerWithIcon>
          <RadioGroupFieldSet
            name="warehouseIsInsidePurpleZone"
            label="ตั้งอยู่ในเขตพื้นที่อุตสาหกรรม (พื้นที่สีม่วง)"
            options={warehousePurpleZoneOptions}
          />
          <RadioGroupFieldSet
            name="warehouseHasOperationPermit"
            label="มีใบอนุญาตประกอบกิจการโรงงาน (รง.4)"
            options={warehouseHasOperationPermitOptions}
          />
          <FastFieldSet
            name="warehouseMaximumWeightCapacity"
            label="น้ำหนักที่รับได้ (พื้น)"
            InputProps={{
              endAdornment: <InputAdornment position="end">ตัน</InputAdornment>,
            }}
          />
          <FastFieldSet
            name="warehouseMaximumBeamWeight"
            label="น้ำหนักที่รับได้ (คาน)"
            InputProps={{
              endAdornment: <InputAdornment position="end">ตัน</InputAdornment>,
            }}
          />
          <RadioGroupFieldSet
            name="warehouseTrailerAccessible"
            label="รถเทรลเลอร์/รถพ่วงเข้าออกได้"
            options={warehouseTrailerAccessibleOptions}
          />
        </>
      )}
    </div>
  );
}

ThirdSection.defaultProps = {
  setFieldValue: null,
  values: {},
};

ThirdSection.propTypes = {
  values: PropTypes.objectOf(PropTypes.any),
  setFieldValue: PropTypes.func,
};

export default ThirdSection;
