import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Typography, Box } from '@material-ui/core';
import { Select } from 'antd';
import Axios from 'axios';
import { green, red } from '@material-ui/core/colors';

const { Option } = Select;
const useStyles = makeStyles(theme => ({
  box: {
    padding: '3.4px 5px',
    '&:hover': {
      cursor: 'pointer',
      border: '1px solid #d9d9d9',
      borderRadius: '4px',
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),
      paddingTop: theme.spacing(0.3),
      paddingBottom: theme.spacing(0.3),
    },
  },
}));
const EditableFeaturedChip = ({ featured, uuid }) => {
  const classes = useStyles();
  const [editClicked, setEditClicked] = useState(false);
  const [selectedValue, setSelectedValue] = useState(featured);

  const handleChange = value => {
    Axios.patch(`/v1/properties/update`, {
      uuid,
      featured: value,
    }).then(response => {
      setSelectedValue(response.data.data.attributes.featured);
      setEditClicked(false);
    });
  };

  return (
    <Box width="80px">
      {!editClicked ? (
        <Box
          onClick={() => setEditClicked(!editClicked)}
          className={classes.box}
        >
          <Typography
            style={{
              color: selectedValue ? green[800] : red[700],
              fontWeight: 600,
            }}
            variant="body2"
          >
            {selectedValue ? 'Yes' : 'No'}
          </Typography>
        </Box>
      ) : (
        <Select
          open={editClicked}
          autoFocus
          onBlur={() => setEditClicked(false)}
          style={{ width: 80 }}
          onChange={handleChange}
          value={selectedValue}
          optionLabelProp="label"
        >
          {[
            {
              label: 'Yes',
              value: true,
            },
            {
              label: 'No',
              value: false,
            },
          ].map(option => (
            <Option
              key={option.value}
              value={option.value}
              label={option.label}
            >
              {option.label}
            </Option>
          ))}
        </Select>
      )}
    </Box>
  );
};

EditableFeaturedChip.propTypes = {
  featured: PropTypes.bool.isRequired,
  uuid: PropTypes.string.isRequired,
};

export default EditableFeaturedChip;
