/* eslint-disable camelcase */
import { formatNumber } from 'Utils';
import { propertyTypeMap } from 'Constants';

const statusMap = {
  '1': 'ขาย',
  '2': 'ให้เช่า',
  '3': 'ขายดาวน์',
  '4': 'ขาย / ให้เช่า',
  '5': 'เซ้ง',
};

function isBangkok(province) {
  const bkkNames = ['กรุงเทพมหานคร', 'กรุงเทพ', 'กทม'];
  return bkkNames.includes(province);
}

function getSoi(soi) {
  if (soi === '') return '';
  return `ซอย${soi}`;
}

function getRoad(road) {
  if (road === '') return '';
  return `ถนน${road}`;
}

function getTambon(tambon, province) {
  return isBangkok(province) ? `แขวง${tambon}` : `ตำบล${tambon}`;
}

function getAmphoe(amphoe, province) {
  return isBangkok(province) ? `เขต${amphoe}` : `อำเภอ${amphoe}`;
}

function processTitleProps(props) {
  const {
    propertyStatus,
    propertyType,
    projectName,
    soi,
    road,
    tambon,
    amphoe,
    province,
  } = props;

  const output = {
    propertyStatus: statusMap[propertyStatus],
    propertyType: propertyTypeMap[propertyType],
    projectName,
    soi: getSoi(soi),
    road: getRoad(road),
    tambon: getTambon(tambon, province),
    amphoe: getAmphoe(amphoe, province),
    province,
  };

  return output;
}

export const composeTitle = values => {
  const processedTitleObjects = processTitleProps({
    propertyStatus: values.propertyStatus,
    propertyType: values.propertyType,
    projectName: values.projectName,
    soi: values.propertySoi,
    road: values.propertyRoad,
    tambon: values.propertyTambon,
    amphoe: values.propertyAmphoe,
    province: values.propertyProvince,
  });

  const {
    propertyStatus,
    propertyType,
    projectName,
    soi,
    road,
    tambon,
    amphoe,
    province,
  } = processedTitleObjects;

  const output = [
    propertyStatus,
    propertyType,
    projectName,
    soi,
    road,
    tambon,
    amphoe,
    province,
  ]
    .filter(n => n)
    .join(' ');
  return output;
};

const convertToWaa = (rai = 0, ngan = 0, waa = 0) => {
  if (rai === 0 && ngan === 0) return 0;
  const raiResult = rai * 400;
  const nganResult = ngan * 100;
  return raiResult + nganResult + waa;
};

const removeEmpty = val => {
  return val && val !== 0 && !val.includes('n/a');
};

const getLine = line => {
  return line.length > 2;
};

export const composeDetails = values => {
  const {
    propertyStatus,
    propertyType,
    projectName,
    propertySoi,
    propertyRoad,
    areaSizeRai,
    areaSizeNgan,
    areaSizeWaa,
    utilitySpace,
    floorCount,
    bedroomCount,
    bathroomCount,
    condoPricePerSqm,
    landFrontWidth,
    landPricePerWaa,
  } = values;

  const processedValues = {
    propertyStatus: statusMap[propertyStatus],
    propertyType: propertyTypeMap[propertyType],
    projectName,
    soi: getSoi(propertySoi),
    road: getRoad(propertyRoad),
    rai: `${areaSizeRai || 'n/a'} ไร่`,
    ngan: `${areaSizeNgan || 'n/a'} งาน`,
    waa: `${areaSizeWaa || 'n/a'} ตร.ว.`,
    calculatedWaa: `${formatNumber(
      convertToWaa(
        Number(areaSizeRai),
        Number(areaSizeNgan),
        Number(areaSizeWaa)
      )
    ) || 'n/a'} ตร.ว.`,
    utilitySpace: `พื้นที่ใช้สอย: ${utilitySpace || 'n/a'} ตร.ม.`,
    floorCount: `${floorCount || 'n/a'} ชั้น`,
    bedroomCount: `${bedroomCount || 'n/a'} ห้องนอน`,
    bathroomCount: `${bathroomCount || 'n/a'} ห้องน้ำ`,
    condoPricePerSqm: `ราคาต่อตารางเมตร: ${formatNumber(condoPricePerSqm) ||
      'n/a'} บาท`,
    landFrontWidth: `หน้ากว้าง: ${landFrontWidth || 'n/a'} เมตร`,
    landPricePerWaa: `ราคาต่อตารางวา: ${formatNumber(landPricePerWaa) ||
      'n/a'} บาท`,
  };

  // const {
  //   propertyStatus,
  //   propertyType,
  //   projectName,
  //   soi,
  //   road,
  //   rai,
  //   ngan,
  //   waa,
  //   calculatedWaa,
  //   utilitySpace,
  //   floorCount,
  //   bedroomCount,
  //   bathroomCount,
  //   condoPricePerSqm,
  //   landFrontWidth,
  //   landPricePerWaa,
  // } = processedValues;

  const firstLine = `${[
    processedValues.propertyStatus,
    processedValues.propertyType,
    processedValues.projectName,
    processedValues.soi,
    processedValues.road,
  ]
    .filter(removeEmpty)
    .join(' ')}\n`;

  // If there is only waa then return nothing
  const raiNganWaa =
    processedValues.calculatedWaa === processedValues.waa
      ? ['n/a'].filter(removeEmpty)
      : `(${[processedValues.rai, processedValues.ngan, processedValues.waa]
          .filter(removeEmpty)
          .join(' ')})`;

  const secondLine = `${['เนื้อที่:', processedValues.calculatedWaa, raiNganWaa]
    .filter(removeEmpty)
    .join(' ')}\n`;

  const thirdLine = `${[processedValues.utilitySpace]
    .filter(removeEmpty)
    .join(' ')}\n`;

  const fourthLine = `${[
    processedValues.floorCount,
    processedValues.bedroomCount,
    processedValues.bathroomCount,
  ]
    .filter(removeEmpty)
    .join(' ')}\n`;

  let fifthLine = '';
  if (processedValues.propertyType === 1)
    fifthLine = [processedValues.condoPricePerSqm].filter(removeEmpty).join('');
  if (processedValues.propertyType === 10 || processedValues.propertyType === 1)
    fifthLine = [processedValues.landFrontWidth].filter(removeEmpty).join('');

  const sixthLine = `\n${[processedValues.landPricePerWaa]
    .filter(removeEmpty)
    .join(' ')}`;

  return [firstLine, secondLine, thirdLine, fourthLine, fifthLine, sixthLine]
    .filter(getLine)
    .join('')
    .trim();
};

// const firstLine = [
//     statusMap[status],
//     typeWords,
//     soiWords,
//     roadWords,
//     waaOnly ? sizeWaaWords : sizeRaiNganWaa,
//     sizeSQMWords,
//   ]
//     .filter(n => n)
//     .join(' ');
//   const secondLine = [floorCountWords, bedroomCountWords, bathroomCountWords]
//     .filter(n => n)
//     .join(' ');
//   const thirdLine = getThirdLine(
//     propertyType,
//     landPricePerWaa,
//     landFrontWidth,
//     condoPricePerSQM
//   );

//   return `${firstLine}\n${secondLine}\n${thirdLine}`;
