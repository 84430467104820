import React from 'react';
import PropTypes from 'prop-types';
import SimpleDialog from 'Components/shared/SimpleDialog';
import { Typography } from '@material-ui/core';

const DeleteConfirmationDialog = ({
  open,
  listNumber,
  setDeleteConfirmDialogOpen,
  handlePropertyDelete,
}) => {
  return (
    <SimpleDialog
      open={open}
      dialogTitle="ยืนยันการลบทรัพย์ "
      message={
        <>
          <Typography component="span" gutterBottom>
            {`คุณต้องการลบทรัพย์เลขที่ ${listNumber} หรือไม่`}
          </Typography>
          <Typography component="span" display="block">
            ข้อมูลทุกอย่างที่เกี่ยวข้องจะถูกลบ รวมไปถึงรูปภาพใดๆที่ได้อัพโหลด
          </Typography>
        </>
      }
      primaryBtnText="ลบ"
      secondaryBtnText="ยกเลิก"
      handleSecondaryClick={() => setDeleteConfirmDialogOpen(false)}
      handlePrimaryClick={handlePropertyDelete}
    />
  );
};

DeleteConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  listNumber: PropTypes.string.isRequired,
  setDeleteConfirmDialogOpen: PropTypes.func.isRequired,
  handlePropertyDelete: PropTypes.func.isRequired,
};

export default DeleteConfirmationDialog;
