/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Axios from 'axios';
import { Container, makeStyles, Box, Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import { setUser } from 'JS/actions';
import { Helmet } from 'react-helmet-async';
import clsx from 'clsx';
import snakecaseKeys from 'snakecase-keys';
import PropertyShowTitle from './PropertyShowTitle';
import GlobalAppBar from '../GlobalAppBar';
import PropertyDetailChipList from './PropertyDetailChipList';
import PropertyShowPrice from './PropertyShowPrice';
import PropertyShowSubtitle from './PropertyShowSubtitle';
import PropertyShowImages from './PropertyShowImages/PropertyShowImages';
import PropertyShowNearbyPlaces from './PropertyShowNearbyPlaces';
import PropertyShowDetails from './PropertyShowDetails';
import PropertyShowLocation from './PropertyShowLocation';
import PropertyShowDirections from './PropertyShowDirections/PropertyShowDirections';
import PropertyShowContact from './PropertyShowContact';
import './PropertyShow.css';

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: 0,
    borderRadius: theme.shape.borderRadius,
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
  gridItem: {
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(2),
    },
    background: '#fff',
  },
  contactInfo: {
    borderRadius: 8,
    background: 'white',
    [theme.breakpoints.down('xs')]: {
      marginTop: 0,
      boxShadow: 'none',
    },
    [theme.breakpoints.only('sm')]: {
      marginTop: theme.spacing(0),
      margin: theme.spacing(1),
      boxShadow: 'none',
    },
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(5),
      position: 'sticky',
      top: 80,
      padding: 0,
      marginLeft: theme.spacing(2),
      fallbacks: [
        { position: '-webkit-sticky' },
        { position: '-moz-sticky' },
        { position: '-ms-sticky' },
        { position: '-o-sticky' },
      ],
    },
    boxShadow: 'rgba(0, 0, 0, 0.12) 0px 6px 16px',
  },
  containerTop: {
    borderRadius: '4px 4px 0 0',
  },
  containerBottom: {
    borderRadius: '0 0 4px 4px',
    boxShadow: theme.shadows[1],
  },
  details: {
    borderTop: `2px solid ${theme.palette.background.default}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(8),
  },
}));

function PropertyShow({ location, user }) {
  const classes = useStyles();
  const url = location.pathname;
  const propertyUUID = url.match(
    /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/g
  )[0];

  const [loading, setLoading] = useState(true);
  const [property, setProperty] = useState({});
  const [contactInfo, setContactInfo] = useState({});
  const [avatar, setAvatar] = useState('');

  useEffect(() => {
    if (
      localStorage.getItem('access-token') &&
      localStorage.getItem('uid') &&
      localStorage.getItem('client')
    ) {
      Axios.get('/v1/auth/validate_token', {
        params: {
          'access-token': localStorage.getItem('access-token'),
          uid: localStorage.getItem('uid'),
          client: localStorage.getItem('client'),
        },
      }).then(response => {
        const { data } = response.data;
        setUser({
          provider: data.provider,
          name: data.name,
          email: data.email,
          role: data.role,
          uuid: data.uuid,
        });
      });
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    Axios.get(`/v1/properties/show`, {
      params: snakecaseKeys({
        uuid: propertyUUID,
        propertyImagesOnly: true,
        fields: [
          'baseAdsTitle',
          'baseAdsDetails',
          'baseAdsNearbyPlaces',
          'baseAdsDirections',
          'sellPrice',
          'rentPrice',
          'latLong',
          'propertySoi',
          'propertyRoad',
          'propertyTambon',
          'propertyAmphoe',
          'propertyProvince',
          'selectedNearbyPlaces',
          'owner',
          'areaSizeRai',
          'areaSizeNgan',
          'areaSizeWaa',
          'utilitySpace',
          'propertyType',
          'bedroomCount',
          'bathroomCount',
          'condoLivingFloor',
          'condoMaxFloorCount',
          'landFrontWidth',
          'landRoadLength',
          'firstImage',
        ],
      }),
    }).then(response => {
      const responseProperty = response.data.data.attributes;
      setProperty(response.data.data.attributes);
      // setImage(response.data.image);
      setContactInfo(
        responseProperty.owner.data.attributes.contactInfo.data.attributes
      );
      setAvatar(responseProperty.owner.data.attributes.avatarUrl);
      setLoading(false);
    });
  }, [propertyUUID]);

  return (
    <Box bgcolor="white">
      {!loading && (
        <Helmet>
          <title>{property.baseAdsTitle}</title>
          <meta property="og:title" content={property.baseAdsTitle} />
          <meta property="og:image" content={property.firstImage} />
          <meta property="og:description" content={property.baseAdsDetails} />
          <meta
            property="og:url"
            content={`https://client.assetbeyond.com/property/${propertyUUID}`}
          />
        </Helmet>
      )}
      <GlobalAppBar user={user} />
      <PropertyShowImages uuid={propertyUUID} />

      <Container className={clsx(classes.container)} maxWidth="lg">
        <Grid justify="center" container spacing={0}>
          <Grid item xs={12} md={8}>
            <Box className={classes.gridItem}>
              <PropertyShowTitle
                // loading={loading}
                title={property.baseAdsTitle}
              />
              <PropertyShowSubtitle
                // loading={loading}

                property={property}
              />
              <Box mt={4}>
                <PropertyShowPrice
                  // loading={loading}
                  sellPrice={property.sellPrice}
                  rentPrice={property.rentPrice}
                />
              </Box>
              {!loading && (
                <>
                  <Box my={8}>
                    <PropertyDetailChipList property={property} />
                  </Box>
                  <Box
                    borderTop="1px solid rgba(0,0,0,0.08)"
                    borderBottom="1px solid rgba(0,0,0,0.08)"
                  >
                    <PropertyShowDetails
                      baseAdsDetails={property.baseAdsDetails}
                      baseAdsNearbyPlaces={property.baseAdsNearbyPlaces}
                      baseAdsDirections={property.baseAdsDirections}
                    />
                  </Box>
                  <PropertyShowLocation
                    title={property.baseAdsTitle}
                    latLong={property.latLong}
                    nearbyPlaces={property.selectedNearbyPlaces}
                    soi={property.propertySoi}
                    road={property.propertyRoad}
                    tambon={property.propertyTambon}
                    amphoe={property.propertyAmphoe}
                    province={property.propertyProvince}
                  />
                  <Box my={2} mb={2} borderBottom="1px solid rgba(0,0,0,0.04)">
                    <PropertyShowDirections
                      baseAdsDirections={property.baseAdsDirections}
                    />
                  </Box>
                  <PropertyShowNearbyPlaces
                    nearbyPlaces={property.selectedNearbyPlaces}
                  />
                </>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box className={classes.contactInfo}>
              {!loading && (
                <PropertyShowContact
                  loading={loading}
                  avatar={avatar}
                  contactInfo={contactInfo}
                />
              )}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

PropertyShow.propTypes = {
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  user: PropTypes.objectOf(PropTypes.any).isRequired,
};

const mapStateToProps = state => ({
  user: state.user.user,
});

const mapDispatchToProps = {
  setUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(PropertyShow);
