import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TextField, IconButton, Box } from '@material-ui/core';
import Axios from 'axios';
import { CheckCircle } from '@material-ui/icons';
import snakecaseKeys from 'snakecase-keys';
import { useSnackbar } from 'notistack';

const LinkEditor = ({
  websiteName,
  adsId,
  initialValue,
  setShouldUpdate,
  setEditMode,
}) => {
  const [value, setValue] = useState(initialValue);
  const { enqueueSnackbar } = useSnackbar();
  const updateLink = () => {
    Axios.patch(
      '/v1/posted_links',
      snakecaseKeys({
        adsId,
        websiteName,
        link: value,
      })
    )
      .then(() => {
        enqueueSnackbar('อัพเดทลิงค์เรียบร้อย');
        setShouldUpdate(true);
        setEditMode(false);
      })
      .catch(() => {
        enqueueSnackbar('เกิดข้อผิดพลาด กรุณาลองใหม่');
        setEditMode(false);
      });
  };

  return (
    <>
      <TextField
        autoFocus
        InputProps={{ style: { fontSize: '0.875rem' } }}
        onChange={e => setValue(e.target.value)}
        value={value}
        fullWidth
        name={`${websiteName}${adsId}`}
        size="small"
      />
      <Box ml={1}>
        <IconButton onClick={updateLink} title="บันทึก" size="small">
          <CheckCircle fontSize="small" />
        </IconButton>
      </Box>
    </>
  );
};

LinkEditor.propTypes = {
  websiteName: PropTypes.string.isRequired,
  adsId: PropTypes.string.isRequired,
  initialValue: PropTypes.string.isRequired,
  setShouldUpdate: PropTypes.func.isRequired,
  setEditMode: PropTypes.func.isRequired,
};

export default LinkEditor;
