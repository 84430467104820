import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  makeStyles,
  Box,
  Button,
  Collapse,
} from '@material-ui/core';
import clsx from 'clsx';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  pre: {
    color: 'rgb(72,72,72)',
    lineHeight: 1.7,
    whiteSpace: 'pre-wrap',
    fontWeight: 400,
    marginBottom: theme.spacing(2),
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  header: {
    fontWeight: 500,
  },
  wrapper: {
    padding: theme.spacing(2),
    [theme.breakpoints.only('xs')]: {
      padding: theme.spacing(2),
    },
  },
  expand: {
    height: '100%',
  },
  button: {
    marginLeft: theme.spacing(-1),
  },
}));
const PropertyShowDetails = ({ baseAdsDetails, baseAdsNearbyPlaces }) => {
  const [expand, setExpand] = useState(false);
  const classes = useStyles();

  return (
    <Box position="relative" p={{ xs: 0, sm: 1.5 }} className={classes.root}>
      <Box
        borderRadius={8}
        className={clsx(
          classes.wrapper,
          expand ? classes.expand : classes.notExpand
        )}
      >
        <Box mb={2}>
          <Typography
            className={classes.header}
            variant="body1"
            color="textPrimary"
          >
            รายละเอียด
          </Typography>
        </Box>
        <Typography variant="body1" className={classes.pre}>
          {baseAdsDetails}
        </Typography>

        <Collapse timeout={600} in={expand}>
          <Box mb={2}>
            <Typography variant="body1" className={classes.pre}>
              {baseAdsNearbyPlaces}
            </Typography>
          </Box>
        </Collapse>
        <Button
          disableTouchRipple
          onClick={() => setExpand(!expand)}
          color="secondary"
          endIcon={expand ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          className={classes.button}
        >
          {expand ? 'ย่อรายละเอียด' : 'อ่านรายละเอียดเพิ่มเติม'}
        </Button>
      </Box>
    </Box>
  );
};

PropertyShowDetails.propTypes = {
  baseAdsDetails: PropTypes.string.isRequired,
  baseAdsNearbyPlaces: PropTypes.string.isRequired,
};

export default PropertyShowDetails;
