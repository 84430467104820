import React from 'react';
import { Field, getIn } from 'formik';
import { useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import TextFieldBase from './TextFieldBase';

const fieldToTextField = ({
  field,
  form,
  variant = 'outlined',
  disabled,
  ...props
}) => {
  const { name } = field;
  const { touched, errors, isSubmitting } = form;

  const fieldError = getIn(errors, name);
  const showFieldError = getIn(touched, name) && !!fieldError;

  return {
    ...props,
    ...field,
    variant,
    error: showFieldError,
    helperText: showFieldError ? fieldError : props.helperText,
    disabled: disabled !== undefined ? disabled : isSubmitting,
  };
};

const FastFieldSet = ({ name, validate, ...restProps }) => {
  const theme = useTheme();
  return (
    <Field
      validate={validate}
      name={name}
      render={props => (
        <TextFieldBase
          {...fieldToTextField({ ...props, ...restProps })}
          theme={theme}
          fullWidth
          style={{ marginBottom: theme.spacing(2.5) }}
        />
      )}
    />
  );
};

FastFieldSet.defaultProps = {
  validate: null,
};

FastFieldSet.propTypes = {
  name: PropTypes.string.isRequired,
  validate: PropTypes.func,
};

export default FastFieldSet;
