import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box, Typography, Link, IconButton, SvgIcon } from "@material-ui/core";
import { green, red } from "@material-ui/core/colors";
import { pickBy, truncate } from "lodash";
import { CancelOutlined, Refresh } from "@material-ui/icons";
import { mdiCircleEditOutline } from "@mdi/js";
import { formatDateFromDateString } from "Utils";
import moment from "moment";
import { useSnackbar } from "notistack";

// import bangkokassetsImage from 'Assets/postedWebs/bangkokassets.png';
// import thaihometownImage from 'Assets/postedWebs/thaihometown.png';
// import dotpropertyImage from 'Assets/postedWebs/dotproperty.png';
// import pantipmarketImage from 'Assets/postedWebs/pantipmarket.png';
// import terrabkkImage from 'Assets/postedWebs/terrabkk.png';
// import bankumkaImage from 'Assets/postedWebs/bankumka.png';
// import teedin108Image from 'Assets/postedWebs/teedin108.png';
// import prakardImage from 'Assets/postedWebs/prakard.png';
// import thaihomeonlineImage from 'Assets/postedWebs/thaihomeonline.png';
// import ddpropertyImage from 'Assets/postedWebs/ddproperty.png';
// import livingjoinImage from 'Assets/postedWebs/livingjoin.png';
// import nineassetImage from 'Assets/postedWebs/nineasset.png';
// import ddteedinImage from 'Assets/postedWebs/ddteedin.png';
// import property2shareImage from 'Assets/postedWebs/property2share.png';
// import bkklandImage from 'Assets/postedWebs/bkkland.png';
// import thaihomelistImage from 'Assets/postedWebs/thaihomelist.png';
// import teedin2Image from 'Assets/postedWebs/teedin2.png';
import LinkEditor from "./LinkEditor";
import Axios from "axios";
import snakecaseKeys from "snakecase-keys";

// const imageMap = {
//   thaihometown: thaihometownImage,
//   dotproperty: dotpropertyImage,
//   bangkokassets: bangkokassetsImage,
//   pantipmarket: pantipmarketImage,
//   terrabkk: terrabkkImage,
//   bankumka: bankumkaImage,
//   teedin108: teedin108Image,
//   prakard: prakardImage,
//   thaihomeonline: thaihomeonlineImage,
//   ddproperty: ddpropertyImage,
//   livingjoin: livingjoinImage,
//   nineasset: nineassetImage,
//   ddteedin: ddteedinImage,
//   landthailand: '',
//   property2share: property2shareImage,
//   bkkland: bkklandImage,
//   thaihomelist: thaihomelistImage,
//   teedin2: teedin2Image,
// };

const websiteMap = {
  thaihometown: "ThaiHomeTown",
  dotproperty: "DotProperty",
  bangkokassets: "BangkokAssets",
  pantipmarket: "PantipMarket",
  terrabkk: "TerraBkk",
  bankumka: "BanKumka",
  teedin108: "Teedin108",
  prakard: "Prakard.com",
  prakardproperty: "PrakardProperty",
  thaihomeonline: "ThaiHomeOnline",
  ddproperty: "DDProperty",
  livingjoin: "LivingJoin",
  nineasset: "9Asset",
  ddteedin: "DDTeedin",
  landthailand: "LandThailand",
  property2share: "Property2Share",
  bkkland: "BkkLand",
  thaihomelist: "ThaiHomeList",
  teedin2: "Teedin2",
  klungbaan: "KlungBaan",
  property108: "Property108",
  livinginsider: "LivingInsider",
  kaidee: "Kaidee",
  facebookmarketplace: "Facebook Marketplace",
  facebookpage: "Facebook Page"
};

const LinkContainer = ({
  websiteName,
  postedLinks,
  adsId,
  setShouldUpdate,
  mode
}) => {
  const [editMode, setEditMode] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const postedLink =
    postedLinks.length > 0 &&
    postedLinks.find(item => item.attributes.websiteName === websiteName);

  const linkExist = !!postedLink;
  // const expiryDate = linkExist ? postedLink.attributes.expiryDate * 1000 : 0;
  // const views = linkExist ? postedLink.attributes.views : 0;
  const updatedAt = (() => {
    if (linkExist && postedLink.attributes.lastUpdatedDate !== 0) {
      return postedLink.attributes.lastUpdatedDate * 1000;
    }
    return 0;
  })();
  const createdAt = (() => {
    if (linkExist && postedLink.attributes.createdAt) {
      return postedLink.attributes.createdAt;
    }
    return 0;
  })();

  const handleManualUpdate = async params => {
    await Axios.patch(
      "https://api.assetbeyond.com/v1/posted_links",
      pickBy(
        snakecaseKeys({
          ...params
        })
      )
    );
    enqueueSnackbar("อัพเดทเรียบร้อยแล้ว กำลังรีเฟรช...");
    window.location.reload();
  };

  return (
    <Box
      height="100%"
      key={websiteName}
      borderRadius={8}
      p={2}
      mx={-1}
      my={2}
      bgcolor={linkExist ? green[50] : red[50]}
    >
      <Box mb={2}>
        <Box>
          <Box mb={0.5} display="flex" justifyContent="space-between">
            <Typography style={{ fontWeight: 500 }}>
              {websiteMap[websiteName]}
            </Typography>
            <Typography
              title={formatDateFromDateString(createdAt)}
              variant="subtitle2"
              color="textSecondary"
            >
              {createdAt !== 0 &&
                "โพสต์เมื่อ " + moment(new Date(createdAt)).fromNow()}
            </Typography>
            {/* {linkExist && (
              <Box>
                {!!expiryDate && (
                  <Typography
                    style={{ marginRight: 8 }}
                    variant="caption"
                    color="textSecondary"
                  >
                    {moment(expiryDate).isAfter(moment())
                      ? "หมดอายุในอีก"
                      : "หมดอายุมาแล้ว"}
                    {moment(expiryDate).isBefore(moment()) &&
                    moment() < moment(expiryDate).add(22, "hours")
                      ? "1 วัน"
                      : moment(expiryDate).fromNow(true)}
                  </Typography>
                )}
                {!!expiryDate && !!views && (
                  <Typography color="textSecondary" variant="caption">
                    |
                  </Typography>
                )}
                {!!views && (
                  <Typography
                    style={{ marginLeft: 8 }}
                    variant="caption"
                    color="textSecondary"
                  >
                    {views !== 0 && `${views} views`}
                  </Typography>
                )}
              </Box>
            )} */}
          </Box>
          <Box display={"flex"} alignItems="center" ml={-0.5}>
            {!editMode && linkExist && (
              <Box mr={0.5}>
                <IconButton
                  color="secondary"
                  onClick={() =>
                    handleManualUpdate({
                      adsId: adsId,
                      websiteName,
                      lastUpdatedDate: Math.round(new Date().getTime() / 1000)
                    })
                  }
                  title="อัพเดทแล้ว"
                  size="small"
                >
                  <Refresh fontSize="small"></Refresh>
                </IconButton>
              </Box>
            )}
            {linkExist && (
              <Box>
                <Typography
                  title={formatDateFromDateString(updatedAt)}
                  variant="subtitle2"
                  color="textSecondary"
                  component={"span"}
                >
                  {updatedAt !== 0 &&
                    "อัพเดทเมื่อ " + moment(updatedAt).fromNow()}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Box>

      <Box height="25px" display="flex" justifyContent="space-between">
        {editMode ? (
          <LinkEditor
            setShouldUpdate={setShouldUpdate}
            editMode={editMode}
            setEditMode={setEditMode}
            websiteName={websiteName}
            adsId={adsId}
            initialValue={linkExist ? postedLink.attributes.link : ""}
          />
        ) : (
          <Link
            style={{ maxWidth: "100%", overflow: "hidden" }}
            target="_blank"
            href={linkExist ? postedLink.attributes.link : "#"}
            variant="body2"
            color="textSecondary"
          >
            {linkExist
              ? truncate(postedLink.attributes.link, { length: 50 })
              : "ยังไม่ได้โพสต์"}
          </Link>
        )}

        <Box>
          {editMode ? (
            <Box display="flex">
              <IconButton
                color="secondary"
                onClick={() => setEditMode(!editMode)}
                title="ยกเลิก"
                size="small"
              >
                <CancelOutlined fontSize="small" />
              </IconButton>
            </Box>
          ) : (
            <IconButton
              color="secondary"
              onClick={() => setEditMode(!editMode)}
              title="แก้ไข"
              size="small"
            >
              <SvgIcon fontSize="small">
                <path d={mdiCircleEditOutline} />
              </SvgIcon>
            </IconButton>
          )}
        </Box>
      </Box>
    </Box>
  );
};

LinkContainer.propTypes = {
  websiteName: PropTypes.string.isRequired,
  postedLinks: PropTypes.arrayOf(PropTypes.any).isRequired,
  adsId: PropTypes.string.isRequired,
  setShouldUpdate: PropTypes.func.isRequired
};

export default LinkContainer;
