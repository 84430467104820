import React from 'react';
import { makeStyles, Typography, Box } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  title: {
    fontWeight: '500',
    lineHeight: '1.5',
    wordBreak: 'break-word',
  },
});

const PropertyShowTitle = props => {
  const { title } = props;
  const classes = useStyles();
  return (
    <Box p={{ xs: 1, sm: 3 }} mx="auto">
      <Typography className={classes.title} variant="h4">
        {title}
      </Typography>
    </Box>
  );
};

PropertyShowTitle.defaultProps = {
  title: '',
};

PropertyShowTitle.propTypes = {
  title: PropTypes.string,
};

export default PropertyShowTitle;
