import React from 'react';
import GoogleMapReact from 'google-map-react';
import { Home } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import Marker from './Marker';

const PropertyShowMaps = ({ lat, lng, title }) => {
  const center = [lat, lng];
  const zoom = 17;

  return (
    <Box mx={{ xs: 0, sm: 4 }}>
      <div style={{ height: '350px', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: 'AIzaSyBJVywKBrS-jHMrQgCclianaA1E0AAZHyE',
            language: 'th',
            region: 'th',
          }}
          defaultCenter={center}
          defaultZoom={zoom}
        >
          <Marker
            main
            lat={lat}
            lng={lng}
            icon={<Home fontSize="small" />}
            name={title}
          />
        </GoogleMapReact>
      </div>
    </Box>
  );
};

PropertyShowMaps.propTypes = {
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
};

export default PropertyShowMaps;
