// rootReducer.js
import { combineReducers } from 'redux';
import userReducer from './userReducer';
import loadingReducer from './loadingReducer';
import nearbyPlacesReducer from './nearbyPlacesReducer';
import drawerReducer from './drawerReducer';

const rootReducer = combineReducers({
  user: userReducer,
  nearbyPlaces: nearbyPlacesReducer,
  loading: loadingReducer,
  drawer: drawerReducer,
});

export default rootReducer;
