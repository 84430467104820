import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  appBarSpacer: {
    [theme.breakpoints.up('xs')]: theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    overflowX: 'auto',
  },
  container: {
    marginBottom: theme.spacing(8),
    padding: theme.spacing(4),
    [theme.breakpoints.only('xs')]: {
      padding: theme.spacing(1),
    },
  },
}));

function DashboardContentWrapper(props) {
  const { children } = props;
  const classes = useStyles();
  return (
    <main className={classes.content}>
      <div className={classes.appBarSpacer} />
      <Container maxWidth="xl" className={classes.container}>
        {children}
      </Container>
    </main>
  );
}

DashboardContentWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DashboardContentWrapper;
