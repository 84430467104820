/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import { Box, Typography, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Axios from 'axios';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { Field } from 'formik';
import CommentBox from 'Components/shared/CommentBox';
import { compressImageFiles } from 'Utils';
import { snakeCase } from 'lodash';
import snakecaseKeys from 'snakecase-keys';
import ImagePreview from './ImagePreview';
import ImageSkeletons from './ImageSkeletons';

const nameMap = {
  propertyImages: 'propertyImagesAttached',
  agentAssignmentFiles: 'agentAssignmentFilesAttached',
  ownerIdCards: 'ownerIdCardsAttached',
  deeds: 'deedsAttached',
};

const useStyles = makeStyles(theme => ({
  fieldset: {
    cursor: 'pointer',
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    border: '1px dashed rgba(0, 0, 0, 0.3)',
    borderRadius: theme.shape.borderRadius,
  },
  errorFieldset: {
    border: `1px dashed ${theme.palette.error.main}`,
  },
  label: {
    display: 'flex',
    justifyContent: 'center',
  },
  removeIcon: {
    color: theme.palette.error.main,
  },
  circularProgress: {
    marginRight: theme.spacing(1),
  },
  dropInstructions: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export default function FileUploadField(props) {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const dropzoneRef = useRef(null);
  const {
    name,
    hasMultipleFields,
    currentField,
    values,
    unitText,
    setFieldValue,
    fieldError,
    imageList,
    setImageList,
  } = props;

  const [fileNumberError, setFileNumberError] = useState(false);
  const [fileTypeError, setFileTypeError] = useState(false);

  const [fileCount, setFileCount] = useState(0);
  const [compressing, setCompressing] = useState(false);
  const [uploading, setUploading] = useState(false);

  const imageCount = imageList.images[String(currentField - 1)]
    ? imageList.images[String(currentField - 1)].length
    : 0;

  useEffect(() => {
    if (currentField === 1) {
      if (name === 'propertyImages' && imageCount < 5) {
        setFieldValue(nameMap[name], 'false');
        return;
      }
      if (imageCount < 1) setFieldValue(nameMap[name], 'false');
      else setFieldValue(nameMap[name], 'true');
    }
  }, [currentField, imageCount, name, setFieldValue]);

  function uploadToServer(files) {
    setUploading(true);

    function reductiveUploadChain(uploadingFiles) {
      return uploadingFiles.reduce((chain, currentFile) => {
        return chain.then(() => {
          const formData = new FormData();
          formData.append('list_number', values.listNumber);
          formData.append('attachment_name', snakeCase(name));
          formData.append('field_number', currentField);
          formData.append(`${snakeCase(name)}[]`, currentFile);
          return Axios.post('/v1/properties/upload_images', formData);
        });
      }, Promise.resolve());
    }

    reductiveUploadChain(files).then(() => {
      Axios.get(`/v1/properties/show`, {
        params: snakecaseKeys({
          listNumber: values.listNumber,
          fields: ['images'],
        }),
      }).then(response => {
        setImageList(response.data.data.attributes.images);
        enqueueSnackbar('อัพโหลดภาพเรียบร้อยแล้ว');
        setUploading(false);
      });
    });
  }

  function isImage(file) {
    return ['image/jpg', 'image/jpeg'].includes(file.type);
  }

  function isPDF(file) {
    return file.type === 'application/pdf';
  }

  async function handleDrop(acceptedFiles) {
    if (acceptedFiles.length === 0 || !values.listNumber) return;
    if (acceptedFiles.length > 20) {
      setFileNumberError(true);
      return;
    }
    const imageFiles = acceptedFiles.filter(isImage);
    const pdfFiles = acceptedFiles.filter(isPDF);
    setFileNumberError(false);
    setFileCount(acceptedFiles.length);
    if (imageFiles.length > 0) {
      setCompressing(true);
      const compressedImageFiles = await compressImageFiles(imageFiles);
      setCompressing(false);

      uploadToServer([...compressedImageFiles, ...pdfFiles]);
    } else {
      uploadToServer(pdfFiles);
    }
  }

  const handleReject = () => {
    setFileTypeError(true);
  };

  const handleAccept = () => {
    setFileTypeError(false);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
    onDropRejected: handleReject,
    onDropAccepted: handleAccept,
    accept:
      name === 'propertyImages'
        ? ['image/jpg', 'image/jpeg']
        : ['image/jpg', 'image/jpeg', 'application/pdf'],
  });

  const getMessage = () => {
    if (isDragActive) return 'ปล่อยเพื่ออัพโหลด';
    if (fileNumberError) return 'กรุณาอัพโหลดครั้งละไม่เกิน 20 ภาพ';
    if (fileTypeError)
      return name === 'propertyImages'
        ? 'ไฟล์ที่ไม่รองรับ รองรับไฟล์ jpg เท่านั้น'
        : 'ไฟล์ที่ไม่รองรับ รองรับไฟล์ jpg และ pdf เท่านั้น';

    if (compressing || uploading)
      return (
        <>
          <CircularProgress
            className={classes.circularProgress}
            variant="indeterminate"
            size={20}
          />
          {compressing ? 'กำลังบีบอัดรูปภาพ...' : 'กำลังอัพโหลด...'}
        </>
      );

    return name === 'propertyImages'
      ? 'ลากไฟล์มาที่นี่ หรือคลิกเพื่ออัพโหลด (jpg)'
      : 'ลากไฟล์มาที่นี่ หรือคลิกเพื่ออัพโหลด (jpg, pdf)';
  };

  return (
    <>
      <Box display="flex" justifyContent="center" flexDirection="column">
        <div
          ref={dropzoneRef}
          data-fieldname={`${name}_field_${currentField}`}
        />
        <fieldset
          {...getRootProps({
            className: clsx(
              'dropzone',
              classes.fieldset,
              (fileNumberError || fileTypeError) && classes.errorFieldset
            ),
          })}
        >
          {hasMultipleFields && (
            <Typography
              style={{ width: 'auto' }}
              component="legend"
            >{`${unitText} ${currentField}`}</Typography>
          )}
          <input id={`${name}_field_${currentField}`} {...getInputProps()} />

          <Typography
            align="center"
            className={classes.dropInstructions}
            color={fileNumberError || fileTypeError ? 'error' : 'textSecondary'}
            variant="body2"
            component="span"
          >
            {getMessage()}
          </Typography>
        </fieldset>

        {((fieldError && currentField === 1) || imageCount === 0) && (
          <Typography
            style={{ marginLeft: 8, marginTop: 8 }}
            color="error"
            variant="body2"
          >
            {fieldError}
          </Typography>
        )}

        {currentField === 1 && <CommentBox noMargin name={name} />}
        <Box mt={2}>
          <ImagePreview
            imageCount={imageCount}
            setImageList={setImageList}
            currentField={currentField}
            listNumber={values.listNumber}
            imageList={imageList}
            hasMultipleFields={hasMultipleFields}
            name={name}
            uploading={uploading}
            compressing={compressing}
            fileCount={fileCount}
            skeletons={<ImageSkeletons fileCount={fileCount} />}
          />
        </Box>
      </Box>
      {nameMap[name] ? <Field type="hidden" name={nameMap[name]} /> : null}
    </>
  );
}

FileUploadField.defaultProps = {
  hasMultipleFields: false,
  unitText: null,
  fieldError: null,
};

FileUploadField.propTypes = {
  name: PropTypes.string.isRequired,
  hasMultipleFields: PropTypes.bool,
  currentField: PropTypes.number.isRequired,
  values: PropTypes.PropTypes.objectOf(PropTypes.any).isRequired,
  unitText: PropTypes.string,
  setFieldValue: PropTypes.func.isRequired,
  fieldError: PropTypes.string,
  imageList: PropTypes.shape({
    name: PropTypes.string,
    thName: PropTypes.string,
    images: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.object)),
    order: PropTypes.arrayOf(PropTypes.array),
  }).isRequired,
  setImageList: PropTypes.func.isRequired,
};
