/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import PropTypes from 'prop-types';
import { Box, Avatar, makeStyles } from '@material-ui/core';
import AvatarImage from 'Assets/avatar.svg';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import ExtendedButton from 'Components/shared/ExtendedButton';
import Axios from 'axios';
import { useSnackbar } from 'notistack';
import { pick } from 'lodash';

const useStyles = makeStyles(theme => ({
  avatar: {
    position: 'relative',
    margin: '0 auto',
    width: 130,
    height: 130,
    marginBottom: theme.spacing(2),
  },
  uploadIcon: {
    position: 'absolute',
    right: '35%',
    top: '70%',
  },
  input: {
    display: 'none',
  },
}));

function ProfileAvatar({ avatarUrl, setUser }) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const handleUpload = e => {
    const formData = new FormData();
    formData.append('user[avatar]', e.target.files[0]);
    Axios.post('/v1/users/update', formData)
      .then(response => {
        setUser(
          pick(response.data.data.attributes, [
            'name',
            'role',
            'email',
            'provider',
            'avatarUrl',
          ])
        );
        enqueueSnackbar('เปลี่ยนภาพโปรไฟล์เรียบร้อยแล้ว');
      })
      .catch(error => {
        enqueueSnackbar(JSON.stringify(error));
      });
  };

  return (
    <Box position="relative">
      <Avatar className={classes.avatar} src={avatarUrl || AvatarImage} />
      <input
        onChange={handleUpload}
        accept="image/*"
        className={classes.input}
        id="avatar-image"
        type="file"
      />
      <label htmlFor="avatar-image">
        <ExtendedButton
          component="span"
          size="small"
          className={classes.uploadIcon}
          color="secondary"
          shape="circular"
          variant="contained"
          iconIsolated
          icon={<PhotoCameraIcon fontSize="small" />}
        />
      </label>
    </Box>
  );
}

ProfileAvatar.defaultProps = {
  avatarUrl: null,
};

ProfileAvatar.propTypes = {
  avatarUrl: PropTypes.string,
  setUser: PropTypes.func.isRequired,
};

export default ProfileAvatar;
