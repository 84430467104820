import React, { Fragment, useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import {
  Paper,
  Box,
  Button,
  Typography,
  Stepper,
  Step,
  StepButton,
  StepLabel,
  Container,
  Badge,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import { connect } from 'react-redux';
import { duplicateForm, rehydrateForm, submitProperty } from 'Services';
import SimpleDialog from 'Components/shared/SimpleDialog';
import { Redirect, useLocation } from 'react-router-dom';
import { intersection } from 'lodash';
import { fieldNameBySection, editAllowedSubmissionProgress } from 'Constants';
import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';
import { getRejectedFields } from 'Components/control/shared/PropertyDetails/functions/propertyDetailsFunctions';
import FirstSection from './FirstSection';
import SecondSection from './SecondSection';
import ThirdSection from './ThirdSection';
import FourthSection from './FourthSection';
import FifthSection from './FifthSection';
import {
  defaultInitialValues,
  warehouseValues,
  condoValues,
} from './shared/initialValues';
import WorkSubmitFormSchema from './shared';
import FormikDebug from './debug/FormikDebug';
import FormButtons from './FormButtons';

const useStyles = makeStyles(theme => ({
  container: {
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(4),
      paddingTop: 0,
    },
    padding: 0,
  },
  paper: {
    padding: theme.spacing(2),
  },
  textField: {
    marginBottom: theme.spacing(2.5),
  },
  button: {
    marginRight: theme.spacing(1),
  },

  padding: {
    paddingRight: theme.spacing(1),
  },
  stepButton: {
    transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
  },
  badge: {
    paddingRight: theme.spacing(1),
  },
}));

const steps = [
  {
    id: 0,
    label: 'ใบปะหน้า',
    name: 'firstSection',
  },
  {
    id: 1,
    label: 'แบบสำรวจทรัพย์สิน (ที่ตั้ง)',
    name: 'secondSection',
  },
  {
    id: 2,
    label: 'แบบสำรวจทรัพย์สิน (รายละเอียด)',
    name: 'thirdSection',
  },
  {
    id: 3,
    label: 'การโฆษณา',
    name: 'fourthSection',
  },
  {
    id: 4,
    label: 'ไฟล์เอกสารและรูปภาพ',
    name: 'fifthSection',
  },
];

function getStepContent(step) {
  switch (step) {
    case 0:
      return <FirstSection />;
    case 1:
      return <SecondSection />;
    case 2:
      return <ThirdSection />;
    case 3:
      return <FourthSection />;
    case 4:
      return <FifthSection />;
    default:
      return <div></div>;
  }
}

function MainWorkSubmitForm({ uuid, user }) {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [initialValues, setInitialValues] = useState(defaultInitialValues);
  const [activeStep, setActiveStep] = useState(0);
  const [editMode, setEditMode] = useState(false);
  const [duplicateMode, setDuplicateMode] = useState(false);
  const [editModeNotAllowed, setEditModeNotAllowed] = useState(false);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [myPropertiesRedirect, setMyPropertiesRedirect] = useState(false);
  const [submissionProgress, setSubmissionProgress] = useState('');
  const [rejectedFieldNames, setRejectedFieldNames] = useState([]);
  const [firstTime, setFirstTime] = useState(false);
  const [property, setProperty] = useState(null);
  const userRole = user.role;

  const location = useLocation();

  useEffect(() => {
    const duplicatePath = `/${userRole}/work-submit-form/copy`;
    if (location.pathname === duplicatePath && property && uuid) {
      setDuplicateMode(true);
    } else {
      setDuplicateMode(false);
    }
  }, [location.pathname, uuid, property, userRole]);

  // Checking if the actual submission progress allows edit
  useEffect(() => {
    const editPath = `/${userRole}/work-submit-form/edit`;
    if (location.pathname === editPath) {
      setEditMode(true);
      if (property && uuid) {
        if (
          editAllowedSubmissionProgress[userRole].includes(
            property.submissionProgress
          )
        ) {
          setEditModeNotAllowed(false);
        } else {
          setEditModeNotAllowed(true);
        }
      }
    }
  }, [location, uuid, userRole, property]);

  useEffect(() => {
    if (uuid) {
      if (duplicateMode) {
        duplicateForm({
          uuid,
          setProperty,
          setInitialValues,
        });
        setFirstTime(true);
      } else {
        rehydrateForm({
          uuid,
          setProperty,
          setInitialValues,
          setActiveStep,
          setSubmissionProgress,
        });
        setFirstTime(false);
      }
    } else {
      setFirstTime(true);
      setInitialValues(defaultInitialValues);
    }
  }, [uuid, duplicateMode]);

  // Displaying rejected fields
  useEffect(() => {
    if (uuid && property && editMode && userRole !== 'manager') {
      setRejectedFieldNames(
        Object.keys(
          getRejectedFields({
            userRole,
            approveMode: false,
            submissionProgress: property.submissionProgress,
            rejectedFields: property.rejectedFields,
          })
        )
      );
    }
  }, [uuid, editMode, userRole, property]);

  const successDialogMessage = () => {
    switch (userRole) {
      case 'sales':
      case 'admin':
        return 'สร้างทรัพย์เรียบร้อยแล้ว กรุณารอการ Approve ในขั้นตอนต่อไป';
      case 'manager':
        return 'สร้างทรัพย์เรียบร้อยแล้ว คลิกเพื่อไปหน้ารวม';
      default:
        return '';
    }
  };

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  function handleSubmit(values, { setSubmitting, setTouched, setErrors }) {
    submitProperty({
      values,
      activeStep,
      userRole,
      editMode,
    }).then(response => {
      if (!response.data.hasError) {
        setSubmitting(false);
        if (!editMode) {
          if (activeStep === steps.length - 1) {
            setSuccessDialogOpen(true);
          } else {
            handleNext();
          }
        }
        setFirstTime(false);
        setErrors({});
        setTouched({});
        setProperty(response.data.data.attributes);
        setSubmissionProgress(response.data.data.attributes.submissionProgress);
        if (activeStep !== steps.length - 1) {
          enqueueSnackbar('บันทึกข้อมูลเรียบร้อย');
        }
      } else {
        setSubmitting(false);
        setTouched({});
        setErrors({});
        enqueueSnackbar('เกิดข้อผิดพลาดขณะส่งข้อมูลไปยังเซิฟเวอร์');
      }
    });
  }

  const headerContent = () => {
    return editMode ? 'แก้ไขฟอร์มส่งงาน' : 'แบบฟอร์มส่งงานออนไลน์';
  };

  const handleStep = step => () => {
    setActiveStep(step);
  };

  return (
    <Fragment>
      <Helmet>
        <title>ฟอร์มส่งงานออนไลน์ - AssetBeyond</title>
      </Helmet>
      <SimpleDialog
        dialogTitle="กรอกรายละเอียดทรัพย์สมบูรณ์แล้ว"
        message={successDialogMessage()}
        open={successDialogOpen}
        handlePrimaryClick={() => {
          setMyPropertiesRedirect(true);
        }}
      />
      {myPropertiesRedirect && (
        <Redirect to={`/${userRole}/my-properties?tab=1`} />
      )}

      {editMode && editModeNotAllowed ? (
        <SimpleDialog
          dialogTitle="เกิดข้อผิดพลาดใน URL"
          message="คุณไม่ได้รับอนุญาตให้แก้ไขแบบฟอร์มนี้ คลิกตกลงเพื่อกลับไปยังหน้าหลัก"
          open={editMode && editModeNotAllowed}
          handlePrimaryClick={() => {
            window.location.replace(`/${userRole}/my-properties`);
          }}
        />
      ) : (
        <Container maxWidth="sm" className={classes.container}>
          <Paper elevation={1} className={classes.paper}>
            <Box mb={2} mt={2}>
              <Typography
                style={{ textAlign: 'center' }}
                gutterBottom
                variant="h5"
              >
                {headerContent()}
              </Typography>
            </Box>
            <Stepper nonLinear activeStep={activeStep} orientation="vertical">
              {steps.map(step => (
                <Step key={step.id}>
                  {editMode ? (
                    <StepButton
                      className={classes.stepButton}
                      onClick={handleStep(step.id)}
                    >
                      <StepLabel>
                        <Badge
                          variant="dot"
                          className={classes.badge}
                          badgeContent={
                            intersection(
                              fieldNameBySection[step.name],
                              rejectedFieldNames
                            ).length
                          }
                          color="error"
                        >
                          {step.label}
                        </Badge>
                      </StepLabel>
                    </StepButton>
                  ) : (
                    <StepLabel>{step.label}</StepLabel>
                  )}
                </Step>
              ))}
            </Stepper>
            {process.env.NODE_ENV === 'development' && (
              <>
                <Button
                  variant="contained"
                  onClick={() => setInitialValues(warehouseValues)}
                >
                  FILL WAREHOUSE
                </Button>
                <Button
                  variant="contained"
                  onClick={() => setInitialValues(condoValues)}
                >
                  FILL CONDO
                </Button>
              </>
            )}
            <Formik
              enableReinitialize
              initialValues={initialValues}
              initialStatus={{}}
              validateOnBlur
              validateOnChange={false}
              validationSchema={WorkSubmitFormSchema[activeStep]}
              onSubmit={handleSubmit}
              render={({ isSubmitting, values, setFieldValue, setStatus }) => {
                return (
                  <Form noValidate autoComplete="off">
                    {React.cloneElement(getStepContent(activeStep), {
                      isSubmitting,
                      values,
                      setFieldValue,
                      uuid,
                      setStatus,
                      editMode,
                      firstTime,
                      property,
                    })}
                    <FormButtons
                      uuid={uuid}
                      submissionProgress={submissionProgress}
                      listNumber={values.listNumber}
                      userRole={userRole}
                      isSubmitting={isSubmitting}
                      editMode={editMode}
                      activeStep={activeStep}
                      steps={steps}
                      setActiveStep={setActiveStep}
                    />
                    {process.env.NODE_ENV === 'development' && <FormikDebug />}
                  </Form>
                );
              }}
            />
          </Paper>
        </Container>
      )}
    </Fragment>
  );
}

MainWorkSubmitForm.defaultProps = {
  uuid: '',
};

MainWorkSubmitForm.propTypes = {
  uuid: PropTypes.string,
  user: PropTypes.objectOf(PropTypes.any).isRequired,
};

const mapStateToProps = state => ({
  user: state.user.user,
});

export default connect(mapStateToProps)(MainWorkSubmitForm);
