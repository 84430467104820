import React from 'react';
import WorkSubmitForm from 'Components/WorkSubmitForm';
import MyProfile from 'Components/control/shared/MyProfile';
import MyProperties from 'Components/control/shared/MyProperties';
import PropertyDetails from 'Components/control/shared/PropertyDetails';
import AssignmentIcon from '@material-ui/icons/Assignment';
import BusinessIcon from '@material-ui/icons/Business';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import DashboardTemplate from 'Components/control/shared/DashboardTemplate/DashboardTemplate';
import PropTypes from 'prop-types';
import PropertyStats from 'Components/control/shared/PropertyStats';
import { Dashboard } from '@material-ui/icons';
import PropertyDashboard from 'Components/control/shared/PropertyDashboard';

const linkMap = [
  {
    pathname: '/sales/property-dashboard',
    component: <PropertyDashboard />,
  },
  {
    pathname: '/sales/work-submit-form/edit',
    component: <WorkSubmitForm />,
  },
  {
    pathname: '/sales/work-submit-form/copy',
    component: <WorkSubmitForm />,
  },
  {
    pathname: '/sales/work-submit-form',
    component: <WorkSubmitForm />,
  },
  {
    pathname: '/sales/my-profile',
    component: <MyProfile />,
  },
  {
    pathname: '/sales/my-properties',
    component: <MyProperties />,
  },
  {
    pathname: '/sales/property/view',
    component: <PropertyDetails />,
  },
  {
    pathname: '/sales/property/stats',
    component: <PropertyStats />,
  },
];

const salesListItems = [
  {
    id: 1,
    pathname: '/sales/my-profile',
    menuLabel: 'ข้อมูลส่วนตัว',
    icon: <AccountCircleIcon />,
  },
  {
    id: 2,
    pathname: '/sales/my-properties',
    menuLabel: 'ทรัพย์ของฉัน',
    icon: <BusinessIcon />,
  },
  {
    id: 3,
    pathname: '/sales/work-submit-form',
    menuLabel: 'ฟอร์มส่งงาน',
    icon: <AssignmentIcon />,
  },
  {
    id: 4,
    pathname: '/sales/property-dashboard',
    menuLabel: 'รีพอร์ต',
    icon: <Dashboard />,
  },
];
function SalesDashboard({ location, ...rest }) {
  return (
    <DashboardTemplate
      location={location}
      menuItems={salesListItems}
      userRole="sales"
      linkMap={linkMap}
      {...rest}
    />
  );
}

SalesDashboard.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    hash: PropTypes.string,
    key: PropTypes.string,
  }).isRequired,
};

export default SalesDashboard;
