import React from 'react';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import { ExpandableCheckbox } from 'Components/fields';

const mainNames = [
  'ทางพิเศษฉลองรัช สายรามอินทรา–อาจณรงค์',
  'ทางพิเศษฉลองรัช สายรามอินทรา–วงแหวนรอบนอกกรุงเทพมหานคร',
  'ทางยกระดับอุตราภิมุข',
  'ทางพิเศษเฉลิมมหานคร สายดินแดง–ท่าเรือ',
  'ทางพิเศษเฉลิมมหานคร สายบางนา–ท่าเรือ',
  'ทางพิเศษเฉลิมมหานคร  สายดาวคะนอง–ท่าเรือ',
  'ทางพิเศษศรีรัช ทางด่วนช่วง ดินแดง พระราม 9 พญาไท',
  'ทางพิเศษศรีรัช ทางด่วนช่วง ดินแดง ประชาชื่น แจ้งวัฒนะ',
  'ทางพิเศษศรีรัช ทางด่วนช่วง มักกะสัน พระราม 9 ศรีนครินทร์',
  'ทางพิเศษบูรพาวิถี (ทางด่วนบางนาตราด)',
  'ทางพิเศษอุดรรัถยา (ทางด่วนสายบางปะอิน–ปากเกร็ด)',
  'ทางพิเศษสายศรีรัช-วงแหวนรอบนอกกรุงเทพมหานคร',
  'มอเตอร์เวย์ กรุงเทพ ชลบุรี สายใหม่',
  'ถนนวงแหวนกาญจนาภิเษก',
  'ทางพิเศษกาญจนาภิเษก (บางพลี-สุขสวัสดิ์)',
  'ถนนวงแหวนกาญจนาภิเษกด้านตะวันตก',
];

const subNames = [
  'ทางด่วนรามอินทรา-อาจณรงค์ / เลียบด่วนรามอินทรา',
  'ทางด่วนรามอินทรา สุขาภิบาล วัชรพล นิมิตใหม่',
  'ดอนเมืองโทลล์เวย์ ทางยกระดับดินแดง–ดอนเมือง อนุสรณ์สถาน−รังสิต',
  'ด่านดินแดง, ด่านเพชรบุรี, ด่านสุขุมวิท, ด่านพระรามสี่ 1, ด่านเลียบแม่น้ำ และด่านท่าเรือ 1',
  'ด่านท่าเรือ 2, ด่านอาจณรงค์, ด่านสุขุมวิท 62 และด่านบางนา',
  'ด่านพระรามที่ 2 ด่านสาธุประดิษฐ์ ด่านพระราม 3 ด่านสุขสวัสดิ์ และด่านดาวคะนอง',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  'ถนนวงแหวนรอบนอกด้านตะวันออก บางปะอิน–บางพลี',
  'ถนนวงแหวนรอบนอกด้านใต้',
  'บางปะอิน–บางบัวทอง–บางขุนเทียน',
];

const tollWayOptions = mainNames.map((name, index) => ({
  name,
  subName: subNames[index],
}));

function TollWayCheckboxes() {
  return (
    <ExpandableCheckbox
      labelIcon={<DirectionsCarIcon />}
      name="tollWayList"
      options={tollWayOptions}
      secondaryLabelName="subName"
      label="โทลเวย์ / ทางพิเศษใกล้เคียง (ไม่ได้ดึงจาก Google Maps)"
    />
  );
}

export default TollWayCheckboxes;
