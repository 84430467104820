import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import './styles.css';
import { Box, Typography, makeStyles } from '@material-ui/core';
import Axios from 'axios';

const useStyles = makeStyles(theme => ({
  imageCount: {
    background: 'hsl(36, 6%, 16%, 0.8)',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(0.3),
    paddingBottom: theme.spacing(0.3),
    borderRadius: theme.shape.borderRadius,
    color: '#fff',
  },
}));

const ImageLightbox = ({ open, setOpen, imageIndex, setImageIndex, uuid }) => {
  const classes = useStyles();
  const [imageList, setImageList] = useState([]);

  useEffect(() => {
    Axios.get('/v1/properties/images', {
      params: {
        uuid,
        size: [1024, 1024],
        count: 100,
      },
    }).then(response => {
      setImageList(response.data);
    });
  }, [uuid]);

  return (
    open && (
      <Lightbox
        imageTitle={
          <Box position="absolute" top={16} left="50%">
            <Typography className={classes.imageCount}>
              {imageIndex + 1} / {imageList.length}
            </Typography>
          </Box>
        }
        reactModalStyle={{
          content: {
            zIndex: 1300,
          },
          overlay: {
            zIndex: 1300,
          },
        }}
        enableZoom={false}
        mainSrc={imageList[imageIndex]}
        nextSrc={imageList[(imageIndex + 1) % imageList.length]}
        prevSrc={
          imageList[(imageIndex + imageList.length - 1) % imageList.length]
        }
        onCloseRequest={() => setOpen(false)}
        onMovePrevRequest={() =>
          setImageIndex((imageIndex + imageList.length - 1) % imageList.length)
        }
        onMoveNextRequest={() =>
          setImageIndex((imageIndex + 1) % imageList.length)
        }
      />
    )
  );
};

ImageLightbox.propTypes = {
  setOpen: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  imageIndex: PropTypes.number.isRequired,
  setImageIndex: PropTypes.func.isRequired,
};

export default ImageLightbox;
