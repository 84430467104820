import React, { useState, useEffect } from "react";
import { Button, Box } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { useTheme } from "@material-ui/core/styles";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import PropTypes from "prop-types";
import CheckboxList from "./CheckboxList";

function ExpandableCheckbox(props) {
  const theme = useTheme();
  const { label, name, secondaryLabelName, labelIcon, options } = props;
  const [customOptions, setCustomOptions] = useState([]);
  const [expandCount, setExpandCount] = useState(10);

  useEffect(() => {
    setCustomOptions(options.slice(0, 5));
  }, [options]);

  function handleExpand() {
    setCustomOptions(options.slice(0, expandCount));
    if (expandCount < options.length + 5) setExpandCount(expandCount + 5);
  }

  function handleCollapse() {
    setCustomOptions(options.slice(0, 5));
    setExpandCount(10);
  }

  return (
    <CheckboxList
      labelIcon={labelIcon}
      options={customOptions}
      name={name}
      label={label}
      secondaryLabelName={secondaryLabelName}
    >
      <Box pt={1} display="flex" justifyContent="flex-end">
        <Button
          disabled={expandCount <= 10}
          color="primary"
          onClick={handleCollapse}
        >
          <KeyboardArrowUpIcon style={{ marginRight: theme.spacing(1) }} />
          ย่อ
        </Button>
        <Button
          disabled={expandCount >= options.length + 5}
          color="primary"
          onClick={handleExpand}
        >
          <KeyboardArrowDownIcon style={{ marginRight: theme.spacing(1) }} />
          ดูเพิ่ม
        </Button>
      </Box>
    </CheckboxList>
  );
}

ExpandableCheckbox.defaultProps = {
  secondaryLabelName: null,
  labelIcon: null
};

ExpandableCheckbox.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  secondaryLabelName: PropTypes.string,
  labelIcon: PropTypes.element,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string
    })
  ).isRequired
};

export default ExpandableCheckbox;
