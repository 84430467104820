import { snakeCase, uniqBy } from 'lodash'
import { filterValueMap } from './constants'
import { propertyAcronyms } from '../constants'

const renameKeys = (obj, newKeys) => {
  // Create an array of key-value pairs with the new keys, or the original keys if no new keys are specified
  const keyValues = Object.keys(obj).map((key) => {
    const newKey = newKeys[key] || key
    const newValue = obj[key]

    return { [newKey]: newValue }
  })

  // Merge the key-value pairs into a single object using Object.assign()
  const renamedObj = Object.assign({}, ...keyValues)

  // Return the renamed object
  return renamedObj
}

export const filtersToParams = (filters) => {
  const newKeys = {
    'attributes.propertyState.data.attributes.name': 'state',
    'attributes.owner.data.attributes.contactInfo.data.attributes.name': 'user',
    'attributes.propertyType': 'property_group',
    'attributes.propertyAmphoe': 'property_amphoe',
    'attributes.propertyProvince': 'property_province',
    'attributes.contractType': 'contract_type',
    'attributes.dateExpired': 'remainingDays',
  }
  return renameKeys(filters, newKeys)
}

export const dataIndexToSortName = (dataIndex) => {
  if (!dataIndex) return 'list_number'
  const dataIndexString = dataIndex.join('.')
  switch (dataIndexString) {
    case 'attributes.propertyState.data.attributes.name':
      return 'property_states.name'
    case 'attributes.owner.data.attributes.name':
      return 'users.name'
    case 'attributes.nickname':
      return 'properties.nickname'
    case 'attributes.dateExpired':
      return 'date_expired'
    default:
      break
  }
  if (dataIndex.length === 2) return snakeCase(dataIndex[1])
  return ''
}

export const filterData = (data) => (formatValue, formatText) => {
  const result = data.map((item) => {
    const text = formatText(item)
    const value = formatValue(item)
    return { text, value }
  })

  // Remove duplicates from the array of objects by converting them to JSON strings
  const uniqueSet = new Set(result.map((e) => JSON.stringify(e)))

  // Convert the Set back to an array of objects by parsing the JSON strings
  const uniqueArray = Array.from(uniqueSet).map((e) => JSON.parse(e))

  return uniqueArray
}

export const convertToFilterArray = (filterObject, filterName) => {
  // If the filter object or name are not provided, return an empty array
  if (!filterObject || !filterName) {
    return []
  }

  // Create an array of objects with a text and value property for each filter option
  const filterOptions = filterObject[filterName].map((value) => {
    const text = filterValueMap[filterName][value] || value
    const valueAcronym = propertyAcronyms[value] || value

    return {
      text,
      value: valueAcronym,
    }
  })

  // Remove duplicate options from the array based on the text property
  const uniqueFilterOptions = uniqBy(filterOptions, 'text')

  // Return the final array of options
  return uniqueFilterOptions
}
