export const disabledStatusForApproveButton = (
  userRole,
  submissionProgress
) => {
  if (userRole === 'sales') return true;
  if (userRole === 'manager') {
    return !['admin_approved', 'admin_edited'].includes(submissionProgress);
  }
  if (userRole === 'admin')
    return !['sales_submitted', 'sales_edited'].includes(submissionProgress);
  return false;
};

export const disabledStatusForEditButton = (userRole, submissionProgress) =>
  !(
    (userRole === 'sales' &&
      ['admin_rejected', 'sales_editing', 'completed'].includes(
        submissionProgress
      )) ||
    (userRole === 'admin' &&
      ['manager_rejected', 'admin_editing'].includes(submissionProgress)) ||
    (userRole === 'manager' &&
      ['completed', 'manager_editing'].includes(submissionProgress))
  );

export const disabledStatusForEditConfirmationButton = (
  userRole,
  submissionProgress
) =>
  !(
    (userRole === 'sales' && submissionProgress === 'sales_editing') ||
    (userRole === 'admin' && submissionProgress === 'admin_editing') ||
    (userRole === 'manager' && submissionProgress === 'manager_editing')
  );

export const disabledStatusForDeleteButton = (role, submissionProgress) =>
  !(role === 'manager' && submissionProgress === 'completed');

export const disabledStatus = ({ userRole, submissionProgress }) => {
  return {
    approve: disabledStatusForApproveButton(userRole, submissionProgress),
    edit: disabledStatusForEditButton(userRole, submissionProgress),
    editConfirmation: disabledStatusForEditConfirmationButton(
      userRole,
      submissionProgress
    ),
    delete: disabledStatusForDeleteButton(userRole, submissionProgress),
  };
};
