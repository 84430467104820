import Axios from 'axios';
import { zoneOptions } from 'Components/WorkSubmitForm/shared';
import regionData from '../json/region.json';

const API_KEY = '46598cae9e2bf94956bc7b666b1643e8';
const GeocodeReverseEndPoint = 'https://api.longdo.com/map/services/address';

export async function handleAddressFetch(latLong) {
  if (latLong === '') {
    return false;
  }
  const lat = latLong.split(',')[0];
  const lon = latLong.split(',')[1];

  const response = await Axios.get(GeocodeReverseEndPoint, {
    params: {
      lat,
      lon,
      key: API_KEY,
    },
    transformRequest: [
      /* eslint no-param-reassign: ["error", { "props": false }] */
      (data, headers) => {
        delete headers.common.expiry;
        delete headers.common['access-token'];
        delete headers.common.client;
        delete headers.common['token-type'];
        delete headers.common.uid;
        return data;
      },
    ],
  });
  return response;
}

function setZoneValue(province, amphoe, setFieldValue) {
  let result = [];
  if (['กรุงเทพมหานคร', 'กรุงเทพ'].includes(province)) {
    result = zoneOptions.find(elem => elem.label.includes(amphoe));
    setFieldValue('zone', result.value);
  } else {
    result = zoneOptions.find(elem => elem.label.includes(province));
    if (result) {
      setFieldValue('zone', result.value);
    } else {
      setFieldValue('zone', 'L');
    }
  }
}

function removeAmphoeStartWords(amphoe) {
  return amphoe.replace(/เขต|อำเภอ|อ\./g, '');
}

function removeProvinceStartWords(province) {
  return province.replace(/จ\.|จังหวัด/g, '');
}

function removeTambonStartWords(tambon) {
  return tambon.replace(/แขวง|ตำบล|ต\./g, '');
}

export function setAddressState(longdoAddressData, setFieldValue) {
  const { province, district, subdistrict, road } = longdoAddressData;

  const processedProvince = removeProvinceStartWords(province);
  const processedAmphoe = removeAmphoeStartWords(district);
  const processedTambon = removeTambonStartWords(subdistrict);
  const processedRegion = regionData.find(
    elem => elem.province === processedProvince
  ).region;

  setFieldValue('propertyProvince', processedProvince);
  setFieldValue('propertyAmphoe', processedAmphoe);
  setFieldValue('propertyTambon', processedTambon);
  setFieldValue('region', processedRegion);
  setZoneValue(processedProvince, processedAmphoe, setFieldValue);
  if (road) {
    setFieldValue('propertyRoad', road.replace(/ถนน|ถ\./, ''));
  } else {
    setFieldValue('propertyRoad', '');
  }
}
