import React from 'react';
import PropTypes from 'prop-types';
import PropertyDetails from 'Components/control/shared/PropertyDetails';
import MyProperties from 'Components/control/shared/MyProperties';
import WorkSubmitForm from 'Components/WorkSubmitForm';
import PropertyControl from 'Components/control/shared/PropertyControl';
import BusinessIcon from '@material-ui/icons/Business';
import BuildIcon from '@material-ui/icons/Build';
import MyProfile from 'Components/control/shared/MyProfile';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AdvertisementForm from 'Components/control/shared/AdvertisementForm';
import DashboardTemplate from 'Components/control/shared/DashboardTemplate/DashboardTemplate';
import { Dashboard } from '@material-ui/icons';
import PropertyStats from 'Components/control/shared/PropertyStats';
import PropertyDashboard from 'Components/control/shared/PropertyDashboard';
import AdvertisementDetails from 'Components/control/shared/AdvertisementDetails';

const linkMap = [
  {
    pathname: '/admin/ads-form',
    component: <AdvertisementForm />,
  },

  {
    pathname: '/admin/my-profile',
    component: <MyProfile />,
  },
  {
    pathname: '/admin/work-submit-form/edit',
    component: <WorkSubmitForm />,
  },
  {
    pathname: '/admin/work-submit-form',
    component: <WorkSubmitForm />,
  },
  {
    pathname: '/admin/property-control',
    component: <PropertyControl />,
  },
  {
    pathname: '/admin/property/view',
    component: <PropertyDetails />,
  },
  {
    pathname: '/admin/property/approve',
    component: <PropertyDetails />,
  },
  {
    pathname: '/admin/complete-properties',
    component: <MyProperties />,
  },
  {
    pathname: '/admin/property/stats',
    component: <PropertyStats />,
  },
  {
    pathname: '/admin/property-dashboard',
    component: <PropertyDashboard />,
  },
  {
    pathname: '/admin/advertisements',
    component: <AdvertisementDetails />,
  },
];

const adminListItems = [
  {
    id: 1,
    pathname: '/admin/my-profile',
    menuLabel: 'ข้อมูลส่วนตัว',
    icon: <AccountCircleIcon />,
  },
  {
    id: 2,
    pathname: '/admin/property-control',
    menuLabel: 'จัดการทรัพย์',
    icon: <BuildIcon />,
  },
  {
    id: 3,
    pathname: '/admin/property-dashboard',
    menuLabel: 'รีพอร์ต',
    icon: <Dashboard />,
  },
  {
    id: 4,
    pathname: '/admin/complete-properties',
    menuLabel: 'ทรัพย์ที่สมบูรณ์',
    icon: <BusinessIcon />,
  },
];

function AdminDashboard({ location, ...rest }) {
  return (
    <DashboardTemplate
      location={location}
      menuItems={adminListItems}
      userRole="admin"
      linkMap={linkMap}
      {...rest}
    />
  );
}

AdminDashboard.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    hash: PropTypes.string,
    key: PropTypes.string,
  }).isRequired,
};

export default AdminDashboard;
