import React, { useState, Fragment, useEffect } from 'react';
import { Typography, Paper, Container, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Axios from 'axios';
import SectionDividerWithText from 'Components/shared/SectionDividerWithText';
import { connect } from 'react-redux';
import { setUser } from 'JS/actions';
import GoogleLoginWrapper from '../shared/GoogleLogin/GoogleLoginWrapper';
import ManualLogin from './ManualLogin';
import RedirectPath from '../shared/RedirectPath';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
  },
  sectionHeader: {
    textAlign: 'center',
    marginBottom: theme.spacing(5),
  },
  container: {
    [theme.breakpoints.only('xs')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
}));

function Login({ setUser }) {
  const [redirect, setRedirect] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    if (
      localStorage.getItem('access-token') ||
      localStorage.getItem('uid') ||
      localStorage.getItem('client')
    ) {
      Axios.get('/v1/auth/validate_token', {
        params: {
          'access-token': localStorage.getItem('access-token'),
          uid: localStorage.getItem('uid'),
          client: localStorage.getItem('client'),
        },
      })
        .then(response => {
          const { data } = response.data;
          setUser({
            avatar: data.avatar_url,
            name: data.name,
            email: data.email,
            role: data.role,
            uuid: data.uuid,
          });
          window.location.replace(`/${data.role}`);
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, [setUser]);

  return (
    <Fragment>
      {redirect ? (
        <RedirectPath />
      ) : (
        <Container className={classes.container} maxWidth="xs">
          <Box mt={8}>
            <Paper elevation={6} className={classes.paper}>
              <Typography
                className={classes.sectionHeader}
                color="primary"
                variant="h5"
              >
                เข้าสู่ระบบ
              </Typography>
              <GoogleLoginWrapper setRedirect={setRedirect} />
              <SectionDividerWithText text="หรือ" />
              <ManualLogin setRedirect={setRedirect} />
            </Paper>
          </Box>
        </Container>
      )}
    </Fragment>
  );
}

const mapDispatchToProps = {
  setUser,
};

export default connect(null, mapDispatchToProps)(Login);
