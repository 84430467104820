import React from 'react';
import { Input } from 'antd';
import { Box } from '@material-ui/core';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';

const { Search } = Input;

const PropertySearchBox = ({ propertyFetch }) => {
  const delayedFetch = debounce(value => {
    propertyFetch({
      search: value.trim() || null,
    });
  }, 500);
  return (
    <Box pt={4} display="flex" justifyContent="flex-start">
      <Search
        size="large"
        placeholder="ค้นหาทรัพย์จาก List, ชื่อ"
        onChange={e => delayedFetch(e.target.value)}
        onSearch={value => propertyFetch({ search: value.trim() || null })}
      />
    </Box>
  );
};

PropertySearchBox.propTypes = {
  propertyFetch: PropTypes.func.isRequired,
};

export default PropertySearchBox;
