import React, { useState } from 'react';
import { Link, Typography, Button } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import Axios from 'axios';
import { useSnackbar } from 'notistack';
import { connect } from 'react-redux';
import { setUser } from 'JS/actions';
import { setAuthTokenHeaders } from 'Services';
import { Formik, Field, Form } from 'formik';
import { TextField } from 'formik-material-ui';
import LoadingSpinner from 'Components/shared/LoadingSpinner';
import useStyles from './ManualLogin_Style';

function ManualLogin(props) {
  const { setRedirect, setUser } = props;
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  function saveHeadersToLocalStorage(headers) {
    const headerList = [
      'access-token',
      'client',
      'uid',
      'expiry',
      'token-type',
    ];
    headerList.forEach(header => {
      localStorage.setItem(header, headers[header]);
    });
  }

  function saveUserIDtoLocalStorage(response) {
    localStorage.setItem('uuid', response.data.data.uuid);
  }

  function handleSubmit(values, { setSubmitting }) {
    setLoading(true);
    Axios({
      method: 'post',
      url: '/v1/auth/sign_in',
      params: {
        email: values.email,
        password: values.password,
      },
    })
      .then(response => {
        const { data } = response.data;
        setUser({
          name: data.name,
          email: data.email,
          role: data.role,
          uuid: data.uuid,
        });
        saveHeadersToLocalStorage(response.headers);
        saveUserIDtoLocalStorage(response);
        setAuthTokenHeaders();
      })
      .then(() => {
        setSubmitting(false);
        setLoading(false);
        enqueueSnackbar('เข้าสู่ระบบเรียบร้อย');
        setRedirect(true);
      })
      .catch(() => {
        setLoading(false);
        setSubmitting(false);
        enqueueSnackbar('ข้อมูลล็อกอินไม่ถูกต้อง');
      });
  }

  return (
    <>
      <Formik
        initialValues={{
          email: '',
          password: '',
        }}
        onSubmit={handleSubmit}
        render={({ isSubmitting }) => (
          <Form noValidate>
            <Field
              component={TextField}
              type="email"
              name="email"
              label="Email"
              variant="outlined"
              fullWidth
              autoFocus
              style={{ marginBottom: theme.spacing(2.5) }}
            />
            <Field
              type="password"
              component={TextField}
              name="password"
              label="Password"
              variant="outlined"
              fullWidth
              autoFocus
              style={{ marginBottom: theme.spacing(2.5) }}
            />

            <div className={classes.linkWrapper}>
              <div>
                <Link
                  href="/resetpassword"
                  variant="subtitle2"
                  className={classes.link}
                >
                  ลืมรหัสผ่าน
                </Link>
                <Typography
                  variant="caption"
                  component="span"
                  className={classes.spacer}
                >
                  |
                </Typography>
                <Link
                  href="/register"
                  variant="subtitle2"
                  className={classes.link}
                >
                  สมัครสมาชิก
                </Link>
              </div>
            </div>
            <Button
              id="submit"
              disabled={isSubmitting}
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              เข้าสู่ระบบ
            </Button>
          </Form>
        )}
      />
      {loading && <LoadingSpinner />}
    </>
  );
}
const mapDispatchToProps = { setUser };

export default connect(
  null,
  mapDispatchToProps
)(ManualLogin);
