import React from 'react';
import { connect } from 'react-redux';
import { InputAdornment, Tooltip, IconButton } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { useSnackbar } from 'notistack';
import { connect as formikConnect } from 'formik';
import { FieldSet } from 'Components/fields';
import { setLoading } from 'JS/actions';
import { handleAddressFetch, setAddressState } from 'Services';

function LatLongField(props) {
  const { loading, formik, setLoading } = props;
  const { setFieldValue, values } = formik;
  const { enqueueSnackbar } = useSnackbar();
  const value = values.latLong;

  function validateLatLong() {
    return !!value.match(/\d+\.\d+,\d+\.\d+/g);
  }

  function fetchAddress() {
    if (validateLatLong()) {
      setLoading(true);
      handleAddressFetch(value).then(response => {
        if (response.data) {
          setAddressState(response.data, setFieldValue);
          enqueueSnackbar('ดึงข้อมูลเรียบร้อยแล้ว');
          setLoading(false);
        } else {
          enqueueSnackbar('ไม่พบข้อมูลที่อยู่จาก GPS');
          setLoading(false);
        }
      });
    } else {
      enqueueSnackbar('กรุณากรอกละติจูด ลองจิจูดให้ถูกต้อง');
    }
  }

  return (
    <FieldSet
      required
      label="พิกัดแปลง ตามเว็บกรมที่ดิน"
      name="latLong"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Tooltip title="ดึงข้อมูล">
              <div>
                <IconButton disabled={loading} onClick={fetchAddress}>
                  <RefreshIcon />
                </IconButton>
              </div>
            </Tooltip>
            <Tooltip title="ไปที่ dolwms.dol.go.th">
              <IconButton
                onClick={() => {
                  window.open('http://dolwms.dol.go.th/tvwebp/', '_blank');
                }}
              >
                <OpenInNewIcon />
              </IconButton>
            </Tooltip>
          </InputAdornment>
        ),
      }}
    />
  );
}

const mapDispatchToProps = { setLoading };

const mapStateToProps = state => ({
  loading: state.loading.loading,
});

export default formikConnect(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(LatLongField)
);
