import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import CommentBox from 'Components/shared/CommentBox';

const FieldSet = ({ name, label, variant, disableCommentBox, ...rest }) => {
  const theme = useTheme();
  return (
    <>
      <Field
        id={name}
        name={name}
        label={label}
        component={TextField}
        variant={variant || 'outlined'}
        fullWidth
        style={{ marginBottom: theme.spacing(2.5) }}
        {...rest}
      />
      {!disableCommentBox && <CommentBox name={name} />}
    </>
  );
};

export default FieldSet;
