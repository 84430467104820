import React, { useState } from 'react';
import GoogleLogin from 'react-google-login';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { setUser } from 'JS/actions';
import { setAuthTokenHeaders } from 'Services';
import LoadingSpinner from 'Components/shared/LoadingSpinner';
import Axios from 'axios';

const useStyles = makeStyles(theme => ({
  googleLogin: {
    fontWeight: '600 !important',
    marginBottom: theme.spacing(2),
    width: '100%',
    '& span': {
      fontWeight: '600 !important',
      fontSize: '0.9375rem',
    },
  },
}));

const saveGoogleHeaders = response => {
  const headerList = ['access-token', 'expiry', 'uid', 'client', 'token-type'];
  headerList.forEach(header => {
    localStorage.setItem(header, response.headers[header]);
  });
};

const responseGoogle = (response, setRedirect, setUser, setLoading) => {
  if (response.error) return;
  setLoading(true);
  const { tokenId, accessToken } = response;
  // const requestOptions = {
  //   method: 'POST',
  //   headers: {
  //     Authorization: `Bearer ${accessToken}`,
  //     'Content-Type': 'application/json',
  //     access_token: `${accessToken}`,
  //   },
  //   body: JSON.stringify(tokenId),
  // };
  Axios.post(
    '/v1/auth/request/google',
    { idToken: tokenId },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
        access_token: `${accessToken}`,
      },
    }
  ).then(loginResponse => {
    setUser(loginResponse.data.user);
    saveGoogleHeaders(loginResponse);
    setAuthTokenHeaders();
    setRedirect(true);
  });
  // fetch('/v1/auth/request/google', requestOptions)
  //   .then(resp => {
  //     resp.json().then(data => setUser(data.user));
  //     saveGoogleHeaders(resp);
  //   })
  //   .then(() => {
  //     setAuthTokenHeaders();
  //     setLoading(false);
  //     setRedirect(true);
  //   });
};

const GoogleLoginWrapper = props => {
  const { setRedirect, setUser } = props;
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  return (
    <>
      <GoogleLogin
        className={classes.googleLogin}
        onSuccess={response => {
          responseGoogle(response, setRedirect, setUser, setLoading);
        }}
        onFailure={response => {
          responseGoogle(response, setRedirect, setLoading);
        }}
        buttonText="Continue with Google"
        clientId="649860993836-rjttdfl23evqhbmduiao0ch1ahkm81lt.apps.googleusercontent.com"
      />
      {loading && <LoadingSpinner />}
    </>
  );
};

GoogleLoginWrapper.propTypes = {
  setRedirect: PropTypes.func.isRequired,
  setUser: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  setUser,
};

export default connect(null, mapDispatchToProps)(GoogleLoginWrapper);
