const cardListFields = [
  'listNumber',
  'nickname',
  'propertyAmphoe',
  'propertyTambon',
  'propertyProvince',
  'sellPrice',
  'rentPrice',
  'dateSubmitted',
  'dateExpired',
  'dateReceived',
  'advertisements',
  'saleStatus',
  'thSaleStatus',
  'owner',
  'submissionProgress',
  'submissionProgressType',
  'thSubmissionProgress',
  'firstImage',
  'uuid',
  'currentFormStep',
  'propertyState',
  'baseAdsTitle'
];

export default cardListFields;
