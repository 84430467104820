import React from 'react';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  dividerWrapper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    width: '100%',
    alignContent: 'center',
    justifyContent: 'center',
  },
  dividerLine: {
    borderTop: '1px solid #0000002b',
    marginTop: '12px',
    flex: 1,
  },
  orText: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
    textAlign: 'center',
  },
}));

const SectionDividerWithText = props => {
  const classes = useStyles();
  const { text } = props;
  return (
    <div className={classes.dividerWrapper}>
      <span className={classes.dividerLine} />
      <Typography className={classes.orText}>{text}</Typography>
      <span className={classes.dividerLine} />
    </div>
  );
};

SectionDividerWithText.propTypes = {
  text: PropTypes.string.isRequired,
};

export default SectionDividerWithText;
