import React, { useState, useEffect } from 'react';
import { Modal, CircularProgress, useMediaQuery } from '@material-ui/core';

import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  modal: {
    [theme.breakpoints.up('xs')]: {
      overflow: 'hidden',
      position: 'fixed',
      width: 40,
      height: 40,
      marginTop: '-20px',
      marginLeft: '-20px',
      top: '50%',
      left: '50%',
    },
    [theme.breakpoints.up('md')]: {
      overflow: 'hidden',
      position: 'fixed',
      width: 60,
      height: 60,
      marginTop: '-30px',
      marginLeft: '-30px',
      top: '50%',
      left: '50%',
    },
  },
}));

function LoadingSpinner() {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'sm'));
  const [shouldDisplay, setShouldDisplay] = useState(false);

  useEffect(() => {
    const loadTimer = setTimeout(() => setShouldDisplay(true), 0);
    return () => {
      clearTimeout(loadTimer);
    };
  }, []);

  return (
    shouldDisplay && (
      <Modal
        style={{ top: '50%', left: '50%', background: 'transparent' }}
        className={classes.modal}
        open
      >
        <CircularProgress
          style={{ outline: 'none' }}
          disableShrink
          size={isMobile ? 40 : 60}
        />
      </Modal>
    )
  );
}

export default LoadingSpinner;
