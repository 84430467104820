/* eslint-disable camelcase */
import React, { useState, Fragment, useEffect } from 'react';
import { Box, Paper, Typography, Button } from '@material-ui/core';
import Axios from 'axios';
import { useSnackbar } from 'notistack';
import { Form, Formik } from 'formik';
import { FieldSet } from 'Components/fields';
import * as Yup from 'yup';
import { Helmet } from 'react-helmet-async';
import snakecaseKeys from 'snakecase-keys';
import { pick } from 'lodash';
import useStyles from './MyProfile_Style';
import ProfileAvatar from './ProfileAvatar';

const roleMap = {
  sales: 'SALESPERSON',
  admin: 'ADMIN',
  manager: 'MANAGER',
  sysadmin: 'SYSTEM ADMIN',
  user: 'USER',
};

const validationSchema = Yup.object().shape({
  contactName: Yup.string().required('จำเป็นต้องกรอก'),
  contactPhoneNumber: Yup.string()
    .matches(/\d+/, 'กรุณากรอกเฉพาะตัวเลข')
    .test('len', 'กรุณากรอกเบอร์โทรที่ถูกต้อง (ตัวเลขเท่านั้น)', val =>
      val ? val.length === 10 : true
    )
    .required('จำเป็นต้องกรอก'),
  contactLineId: Yup.string().required('จำเป็นต้องกรอก'),
});

function MyProfile() {
  const [user, setUser] = useState({
    provider: '',
    uuid: '',
    avatarUrl: '',
    role: '',
    email: '',
    name: '',
  });
  const [contactInfo, setContactInfo] = useState({
    name: '',
    phoneNumber: '',
    lineId: '',
  });
  const [initialValues, setInitialValues] = useState({
    email: '',
    name: '',
    contactName: '',
    contactPhoneNumber: '',
    contactLineId: '',
  });
  const { enqueueSnackbar } = useSnackbar();
  const [editMode, setEditMode] = useState(false);

  // Setting values to textFields
  useEffect(() => {
    Axios.get('/v1/users/show').then(response => {
      const responseUser = response.data.data.attributes;
      const responseContactInfo = responseUser.contactInfo.data
        ? responseUser.contactInfo.data.attributes
        : null;
      const { name, email } = responseUser;
      setUser(
        pick(responseUser, ['name', 'role', 'email', 'provider', 'avatarUrl'])
      );
      setContactInfo(responseContactInfo);
      setInitialValues({
        name,
        email,
        contactName: responseContactInfo ? responseContactInfo.name : '',
        contactLineId: responseContactInfo ? responseContactInfo.lineId : '',
        contactPhoneNumber: responseContactInfo
          ? responseContactInfo.phoneNumber
          : '',
      });
    });
  }, []);

  function handleSubmit(values, { setSubmitting }) {
    if (user.name !== values.name) {
      Axios.post('/v1/users/update', {
        user: {
          name: values.name,
        },
      })
        .then(response => {
          setUser(
            pick(response.data.data.attributes, [
              'name',
              'role',
              'email',
              'provider',
              'avatarUrl',
            ])
          );
          setEditMode(false);
          enqueueSnackbar('แก้ไขข้อมูลแล้ว');
          setSubmitting(false);
        })
        .catch(error => {
          enqueueSnackbar(JSON.stringify(error));
          setSubmitting(false);
        });
    }
    if (
      !contactInfo ||
      contactInfo.phoneNumber !== values.contactPhoneNumber ||
      contactInfo.lineId !== values.contactLineId ||
      contactInfo.name !== values.contactName
    ) {
      Axios.post('/v1/contact_infos/update', {
        contactInfo: snakecaseKeys({
          name: values.contactName,
          phoneNumber: values.contactPhoneNumber,
          lineId: values.contactLineId,
        }),
      })
        .then(response => {
          setContactInfo(response.data.data.attributes);
          setEditMode(false);
          enqueueSnackbar('แก้ไขข้อมูลแล้ว');
          setSubmitting(false);
        })
        .catch(error => {
          enqueueSnackbar(JSON.stringify(error));
          setSubmitting(false);
        });
    }
  }

  function handleEditClick() {
    setEditMode(true);
  }

  function handleCancelEdit() {
    setEditMode(false);
  }

  const classes = useStyles();
  return (
    <>
      <Helmet>
        <title>ข้อมูลส่วนตัว - AssetBeyond</title>
      </Helmet>
      <Box
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
      >
        <Paper className={classes.paper} elevation={1}>
          <Box>
            <ProfileAvatar setUser={setUser} avatarUrl={user.avatarUrl} />
            <Typography
              className={classes.mainText}
              gutterBottom
              color="textSecondary"
              variant="h5"
            >
              {user.name}
            </Typography>
            <Typography
              className={classes.subText}
              color="textSecondary"
              variant="caption"
            >
              {roleMap[user.role]}
            </Typography>
          </Box>
          <Formik
            validationSchema={validationSchema}
            initialValues={initialValues}
            enableReinitialize
            validateOnChange={false}
            validateOnBlur
            onSubmit={handleSubmit}
            render={({ isSubmitting }) => (
              <Form autoComplete="off">
                <Box mt={3}>
                  <FieldSet
                    name="name"
                    disableCommentBox
                    disabled={!editMode || user.provider !== 'email'}
                    helperText={
                      user.provider !== 'email'
                        ? 'แก้ไขได้เฉพาะผู้ที่สมัครผ่าน Email'
                        : ''
                    }
                    variant="filled"
                    className={classes.textField}
                    label="ชื่อ - นามสกุล"
                  />
                  <FieldSet
                    disableCommentBox
                    disabled
                    className={classes.textField}
                    variant="filled"
                    name="email"
                    label="E-mail"
                  />
                  <Typography
                    color="textSecondary"
                    variant="subtitle1"
                    className={classes.subBody}
                  >
                    ข้อมูลการติดต่อสำหรับลงโฆษณา
                  </Typography>
                  <FieldSet
                    disableCommentBox
                    disabled={!editMode}
                    placeholder="ยังไม่มีชื่อเล่น"
                    className={classes.textField}
                    variant="filled"
                    name="contactName"
                    label="ชื่อ"
                  />
                  <FieldSet
                    disableCommentBox
                    disabled={!editMode}
                    placeholder="ยังไม่มีเบอร์โทร"
                    className={classes.textField}
                    variant="filled"
                    name="contactPhoneNumber"
                    label="เบอร์โทร"
                  />
                  <FieldSet
                    disableCommentBox
                    disabled={!editMode}
                    placeholder="ยังไม่มีไอดีไลน์"
                    className={classes.textField}
                    variant="filled"
                    name="contactLineId"
                    label="ไอดีไลน์"
                  />
                </Box>
                <Box mt={2} display="flex" justifyContent="flex-end">
                  {editMode && (
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={handleCancelEdit}
                    >
                      ยกเลิก
                    </Button>
                  )}
                  {!editMode && (
                    <Fragment>
                      <Button
                        onClick={handleEditClick}
                        disabled={editMode}
                        color="primary"
                        variant="outlined"
                      >
                        แก้ไขข้อมูล
                      </Button>
                    </Fragment>
                  )}
                  <Button
                    type="submit"
                    disabled={!editMode || isSubmitting}
                    className={classes.button}
                    color="primary"
                    variant="contained"
                  >
                    บันทึก
                  </Button>
                </Box>
              </Form>
            )}
          ></Formik>
        </Paper>
      </Box>
    </>
  );
}

export default MyProfile;
