/* eslint-disable camelcase */
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import PropTypes from 'prop-types';
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { connect } from 'formik';
import { isEqual } from 'lodash';

const styles = theme => ({
  formControl: {
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2.5),
    borderRadius: theme.shape.borderRadius,
    boxSizing: 'border-box',
  },
  formLabel: {
    marginLeft: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  labelIcon: {
    marginRight: theme.spacing(1),
  },
});

class CheckboxList extends Component {
  shouldComponentUpdate(nextProps) {
    const { formik, options, name } = this.props;
    const { values } = formik;
    const { selectedNearbyPlaces } = values;
    return (
      !isEqual(
        nextProps.formik.values.selectedNearbyPlaces[name],
        selectedNearbyPlaces[name]
      ) || !isEqual(nextProps.options, options)
    );
  }

  handleListClick = ({ placeName, latLong, distance }) => () => {
    const { formik, name } = this.props;
    const { values, setFieldValue } = formik;

    const setPlaces = val => setFieldValue('selectedNearbyPlaces', val);
    const selectingField = values.selectedNearbyPlaces[name];

    if (selectingField.find(place => place.name === placeName)) {
      setPlaces({
        ...values.selectedNearbyPlaces,
        [name]: selectingField.filter(val => val.name !== placeName),
      });
    } else {
      setPlaces({
        ...values.selectedNearbyPlaces,
        [name]: [...selectingField, { name: placeName, latLong, distance }],
      });
    }
  };

  render() {
    const {
      secondaryLabelName,
      options,
      label,
      labelIcon,
      children,
      formik,
      classes,
      name,
    } = this.props;

    const { values } = formik;

    return (
      <FormControl
        fullWidth
        component="fieldset"
        className={classes.formControl}
      >
        <FormLabel component="legend" className={classes.formLabel}>
          {labelIcon &&
            React.cloneElement(labelIcon, {
              color: 'primary',
              className: classes.labelIcon,
            })}
          {label}
        </FormLabel>
        <FormGroup>
          <List>
            {options.map(option => (
              <ListItem
                disableRipple
                disableGutters
                dense
                button
                onClick={this.handleListClick({
                  placeName: option.name,
                  latLong: option.latLong,
                  distance: option.distance,
                })}
                key={option.id ? option.id : option.name}
              >
                <ListItemIcon>
                  <Checkbox
                    color="secondary"
                    size="small"
                    disableRipple
                    edge="start"
                    checked={Boolean(
                      values.selectedNearbyPlaces[name].find(
                        place => place.name === option.name
                      )
                    )}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={option.name}
                  secondary={
                    secondaryLabelName ? option[secondaryLabelName] : null
                  }
                />
              </ListItem>
            ))}
          </List>
        </FormGroup>
        {children}
      </FormControl>
    );
  }
}

CheckboxList.defaultProps = {
  labelIcon: null,
  secondaryLabelName: '',
};

CheckboxList.propTypes = {
  formik: PropTypes.objectOf(PropTypes.any).isRequired,
  labelIcon: PropTypes.element,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string,
    })
  ).isRequired,
  secondaryLabelName: PropTypes.string,
  children: PropTypes.element.isRequired,
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default connect(withStyles(styles)(CheckboxList));
