import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles(theme => ({
  imageSkeleton: {
    [theme.breakpoints.only('xs')]: {
      width: '100%',
    },
    width: 300,
  },
  root: {
    [theme.breakpoints.only('xs')]: {
      flexBasis: '91%',
    },
  },
}));

const PropertyCardListLoader = () => {
  const classes = useStyles();
  return [0, 1, 2, 4, 5, 6].map(item => (
    <Box
      className={classes.root}
      key={item}
      ml={2}
      display="flex"
      flexDirection="column"
    >
      <Skeleton height={150} className={classes.imageSkeleton} />
      <Box p={2}>
        <Skeleton width="15em" />
        <Skeleton height="10px" width="30%" />
        <Skeleton height="10px" width="70%" />
        <Skeleton height="15px" width="50%" />
      </Box>
    </Box>
  ));
};

export default PropertyCardListLoader;
