import { makeStyles } from '@material-ui/core/styles';
import { amber, green, lightGreen } from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
  helperText: {
    textAlign: 'right',
  },
  strengthIndicator: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  passwordStrengthWrapper: {
    margin: theme.spacing(1),
  },
  textField: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  linearColorPrimary1: { backgroundColor: '#ffcdd2' },
  linearBarColorPrimary1: { backgroundColor: theme.palette.error.main },
  linearColorPrimary2: { backgroundColor: '#ffcdd2' },
  linearBarColorPrimary2: { backgroundColor: theme.palette.error.main },
  linearColorPrimary3: { backgroundColor: amber[100] },
  linearBarColorPrimary3: { backgroundColor: amber[800] },
  linearColorPrimary4: { backgroundColor: lightGreen[100] },
  linearBarColorPrimary4: { backgroundColor: lightGreen[500] },
  linearColorPrimary5: { backgroundColor: green[100] },
  linearBarColorPrimary5: { backgroundColor: green[500] },
}));

export default useStyles;
