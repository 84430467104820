import React from 'react';
import PropTypes from 'prop-types';
import { Link, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  link: {
    '&:hover': {
      color: theme.palette.text.primary,
      textDecoration: 'none',
      backgroundImage:
        'linear-gradient(transparent 75%, hsl(209, 100%, 80%) 0px)',
    },
    textDecoration: 'none',
  },
}));

const PropertyLink = ({ children, uuid }) => {
  const classes = useStyles();
  return (
    <Link className={classes.link} href={`property/view?${uuid}`}>
      {children}
    </Link>
  );
};

PropertyLink.propTypes = {
  children: PropTypes.string.isRequired,
  uuid: PropTypes.string.isRequired,
};

export default PropertyLink;
