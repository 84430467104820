import React from 'react';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import PersonIcon from '@material-ui/icons/Person';
import UpdateIcon from '@material-ui/icons/Update';
import { green } from '@material-ui/core/colors';
import { capitalize } from 'lodash';
import { formatDateFromDateString } from 'Utils';
import PropTypes from 'prop-types';

function BasicInfoList({ completed, userName, userRole, createdAt }) {
  const list = [
    {
      id: 1,
      primaryText: 'ทรัพย์ที่ Approve แล้ว',
      icon: <CheckCircleOutlineIcon />,
      color: green[500],
      tooltipText: 'Approve แล้ว',
      disabled: !completed,
    },
    {
      id: 2,
      primaryText: userName,
      secondaryText: userRole,
      icon: <PersonIcon />,
      tooltipText: 'ผู้สร้างทรัพย์',
      disabled: false,
    },
    {
      id: 3,
      primaryText: formatDateFromDateString(createdAt),
      icon: <UpdateIcon />,
      tooltipText: 'สร้างเมื่อ',
      disabled: false,
    },
  ];

  return (
    <List dense disablePadding>
      {list.map(listItem => {
        return (
          !listItem.disabled && (
            <ListItem key={listItem.id} disableGutters>
              <ListItemIcon style={{ padding: 0 }}>
                <Tooltip placement="bottom" title={listItem.tooltipText}>
                  {React.cloneElement(listItem.icon, {
                    style: { color: listItem.color || null },
                  })}
                </Tooltip>
              </ListItemIcon>

              <ListItemText
                primary={listItem.primaryText}
                secondary={capitalize(listItem.secondaryText)}
              />
            </ListItem>
          )
        );
      })}
    </List>
  );
}

BasicInfoList.propTypes = {
  completed: PropTypes.bool.isRequired,
  userName: PropTypes.string.isRequired,
  userRole: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
};

export default BasicInfoList;
