import React from 'react';
import PropTypes from 'prop-types';
import { DialogTitle, Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    background: 'rgb(246,246,249)',
  },
}));

const Title = ({ listNumber, rejectedFieldsCount }) => {
  const classes = useStyles();
  return (
    <DialogTitle className={classes.root} disableTypography>
      <Typography variant="h6">
        {`ยืนยันการตรวจสอบทรัพย์เลขที่ ${listNumber}`}
      </Typography>
      <Typography color="textSecondary" variant="subtitle2">
        {rejectedFieldsCount > 0
          ? `มี ${rejectedFieldsCount} หัวข้อที่ต้องการการแก้ไข`
          : 'ไม่มีหัวข้อที่ต้องการการแก้ไข'}
      </Typography>
    </DialogTitle>
  );
};

Title.propTypes = {
  listNumber: PropTypes.string.isRequired,
  rejectedFieldsCount: PropTypes.number.isRequired,
};

export default Title;
