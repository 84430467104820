import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  heading: {
    textAlign: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const HeadingText = ({ children }) => {
  const classes = useStyles();
  return (
    <Typography
      color="primary"
      className={classes.heading}
      gutterBottom
      variant="h5"
    >
      {children}
    </Typography>
  );
};

HeadingText.propTypes = {
  children: PropTypes.string.isRequired,
};

export default HeadingText;
