import React from 'react';
import { InputAdornment, Tooltip, IconButton } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import { connect } from 'formik';
import PropTypes from 'prop-types';
import { composeTitle } from 'Services';
import { FieldSet } from 'Components/fields';

function BaseAdsTitle({ formik }) {
  const { values, setFieldValue } = formik;

  function handleTitleCompose() {
    setFieldValue('baseAdsTitle', composeTitle(values));
  }

  return (
    <FieldSet
      required
      multiline
      name="baseAdsTitle"
      label="หัวข้อโฆษณา"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Tooltip title="ดึงข้อมูล">
              <IconButton onClick={handleTitleCompose}>
                <RefreshIcon />
              </IconButton>
            </Tooltip>
          </InputAdornment>
        ),
      }}
    />
  );
}

BaseAdsTitle.propTypes = {
  formik: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default connect(BaseAdsTitle);
