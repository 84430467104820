import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
} from '@material-ui/core';

const SelfEditSelection = ({
  editRedirect,
  setEditRedirect,
  userRole,
  rejectedFieldsCount,
}) => {
  const getNoEditLabel = ({ hasRejectedFields, role }) => {
    switch (role) {
      case 'admin':
        if (hasRejectedFields) return 'ส่งไปให้ Sales แก้ไข';
        if (!hasRejectedFields) return 'ส่งไปให้ Manager approve';
        break;
      case 'manager':
        if (hasRejectedFields) return 'ส่งไปให้ Admin แก้ไข';
        if (!hasRejectedFields) return 'Approve ทรัพย์ (เสร็จสมบูรณ์)';
        break;
      default:
        return '';
    }
    return '';
  };
  return (
    <FormControl component="fieldset">
      <RadioGroup
        name="editRedirect"
        value={editRedirect}
        onChange={e => setEditRedirect(e.target.value)}
      >
        <FormControlLabel
          value="true"
          control={<Radio />}
          label="แก้ไขทรัพย์เองบางส่วน"
        />

        <FormControlLabel
          value="false"
          control={<Radio />}
          label={getNoEditLabel({
            role: userRole,
            hasRejectedFields: rejectedFieldsCount > 0,
          })}
        />
      </RadioGroup>
    </FormControl>
  );
};

SelfEditSelection.propTypes = {
  editRedirect: PropTypes.oneOf(['true', 'false']).isRequired,
  setEditRedirect: PropTypes.func.isRequired,
  userRole: PropTypes.string.isRequired,
  rejectedFieldsCount: PropTypes.number.isRequired,
};

export default SelfEditSelection;
