import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Popover, Box, Button, Typography } from '@material-ui/core';
import Axios from 'axios';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';
import { t } from 'Constants';
import snakecaseKeys from 'snakecase-keys';

const useStyles = makeStyles(theme => ({
  button: {
    marginLeft: theme.spacing(0.5),
  },
  header: {
    marginBottom: theme.spacing(3),
  },
}));

function RejectBox(props) {
  const {
    id,
    open,
    anchorEl,
    handleClose,
    uuid,
    fetchProperty,
    rejectMessage,
    menuAnchorEl,
    handleMenuClose,
  } = props;
  const [value, setValue] = useState(rejectMessage);
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  useEffect(() => {
    setValue(rejectMessage);
  }, [rejectMessage]);

  function handleReject() {
    Axios.patch(
      '/v1/properties/update',
      snakecaseKeys({
        uuid,
        rejectingField: JSON.stringify({ [id]: value }),
      })
    ).then(() => {
      handleClose();
      if (menuAnchorEl) handleMenuClose();
      fetchProperty();
      enqueueSnackbar('บันทึกเรียบร้อย');
    });
  }

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Box p={2}>
        <Typography className={classes.header} variant="subtitle2">
          {t[id]}
        </Typography>
        <ValidatorForm noValidate onSubmit={handleReject}>
          <TextValidator
            placeholder="ระบุเหตุผลที่ reject"
            onChange={e => setValue(e.target.value)}
            value={value}
            fullWidth
            variant="standard"
            label="เหตุผลในการ Reject"
            multiline
            name={id}
          />
          <Box mt={5} display="flex" justifyContent="flex-end">
            <Button onClick={handleClose} color="primary">
              ยกเลิก
            </Button>
            <Button className={classes.button} type="submit" color="primary">
              บันทึก
            </Button>
          </Box>
        </ValidatorForm>
      </Box>
    </Popover>
  );
}

RejectBox.defaultProps = {
  anchorEl: null,
  menuAnchorEl: null,
  rejectMessage: '',
  handleMenuClose: null,
};

RejectBox.propTypes = {
  rejectMessage: PropTypes.string,
  fetchProperty: PropTypes.func.isRequired,
  uuid: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  anchorEl: PropTypes.instanceOf(Element),
  handleClose: PropTypes.func.isRequired,
  menuAnchorEl: PropTypes.instanceOf(Element),
  handleMenuClose: PropTypes.func,
};

export default RejectBox;
