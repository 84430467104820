import React from 'react';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import PropTypes from 'prop-types';
import { connect, FieldArray } from 'formik';
import { useTheme } from '@material-ui/core/styles';
import CommentBox from 'Components/shared/CommentBox';

function FormikCheckboxGroup(props) {
  const theme = useTheme();
  const { options, name, label, dense, formik } = props;
  const { values } = formik;
  const fieldValues = values[name];

  function handleCheck(e, option, arrayHelpers) {
    if (e.target.checked) {
      arrayHelpers.push(option.value);
    } else {
      const removingItemIndex = fieldValues.indexOf(option.value);
      arrayHelpers.remove(removingItemIndex);
    }
  }

  return (
    <>
      <FieldArray
        name={name}
        render={arrayHelpers => (
          <FormControl
            fullWidth
            component="fieldset"
            style={{
              padding: theme.spacing(2),
              marginBottom: theme.spacing(2.5),
              border: '1px solid rgba(0, 0, 0, 0.23)',
              borderRadius: theme.shape.borderRadius,
              boxSizing: 'border-box',
            }}
          >
            <FormLabel component="legend">{label}</FormLabel>
            <FormGroup row={dense}>
              {options.map(option => (
                <FormControlLabel
                  key={option.id ? option.id : option.value}
                  control={
                    <Checkbox
                      name={option.value}
                      checked={fieldValues.includes(option.value)}
                      onChange={e => handleCheck(e, option, arrayHelpers)}
                      value={option.value}
                    />
                  }
                  label={option.label}
                />
              ))}
            </FormGroup>
            <input type="hidden" name={name} value={values} />
          </FormControl>
        )}
      />
      <CommentBox name={name} />
    </>
  );
}

FormikCheckboxGroup.defaultProps = {
  dense: false,
};

FormikCheckboxGroup.propTypes = {
  dense: PropTypes.bool,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default connect(FormikCheckboxGroup);
