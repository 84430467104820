import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import {
  Paper,
  Box,
  Typography,
  Container,
  makeStyles,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { pick } from 'lodash';
import { getPropertyDetailsContent } from 'Utils';
import { setLoading } from 'JS/actions';
import { t, fieldNameBySection, sectionNameMap } from 'Constants';
import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';
import ApprovalActions from './ApprovalActions';
import DetailImage from './DetailImage';
import DetailText from './DetailText';
import BasicInfoList from './BasicInfoList';
import {
  getRejectedFields,
  editedFieldsIsFor,
} from './functions/propertyDetailsFunctions';

const useStyles = makeStyles(theme => ({
  sectionWrapper: {
    padding: theme.spacing(3),

    marginBottom: theme.spacing(2),
  },
  container: {
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(5),
      paddingTop: 0,
    },
    padding: 0,
  },
}));

const shouldShowApprovalDialog = {
  admin: ['sales_submitted', 'sales_edited'],
  manager: ['admin_approved', 'admin_edited'],
};

function PropertyDetails({ uuid, user, setLoading, location }) {
  const classes = useStyles();
  const userRole = user.role;
  const [approveMode, setApproveMode] = useState(
    location.pathname === `/${userRole}/property/approve`
  );
  const [property, setProperty] = useState(null);
  const [images, setImages] = useState([]);
  const [rejectedFields, setRejectedFields] = useState({});
  const [editedFields, setEditedFields] = useState({});
  const [submissionProgress, setSubmissionProgress] = useState('');

  async function manualFetchProperty() {
    const response = await Axios.get('/v1/properties/show', {
      params: { uuid },
    });

    const { attributes } = response.data.data;
    setProperty(attributes);
    setImages(attributes.images);
    setRejectedFields(
      getRejectedFields({
        userRole,
        submissionProgress: attributes.submissionProgress,
        approveMode,
        rejectedFields: attributes.rejectedFields,
      })
    );
    if (
      attributes.editedFields &&
      editedFieldsIsFor({
        userRole,
        editedSubmissionProgress:
          attributes.editedFields.lastSubmissionProgress,
        isComplete: attributes.isComplete,
      })
    ) {
      setEditedFields(attributes.editedFields.data);
    }
  }

  useEffect(() => {
    if (location.pathname === `/${userRole}/property/approve`) {
      setApproveMode(true);
    }
    if (location.pathname === `/${userRole}/property/view`) {
      setApproveMode(false);
    }
    const fetchProperty = async () => {
      setLoading(true);
      const response = await Axios.get('/v1/properties/show', {
        params: { uuid },
      });

      const { attributes } = response.data.data;
      setProperty(attributes);
      setImages(attributes.images);
      setRejectedFields(
        getRejectedFields({
          userRole,
          submissionProgress: attributes.submissionProgress,
          approveMode,
          rejectedFields: attributes.rejectedFields,
        })
      );
      setSubmissionProgress(attributes.submissionProgress);
      if (
        attributes.editedFields &&
        editedFieldsIsFor({
          userRole,
          editedSubmissionProgress:
            attributes.editedFields.lastSubmissionProgress,
          isComplete: attributes.isComplete,
        })
      ) {
        setEditedFields(attributes.editedFields.data);
      }
      setLoading(false);
    };

    fetchProperty();
  }, [setLoading, uuid, userRole, approveMode, location.pathname]);

  function checkReject(fieldName) {
    return (
      Object.keys(rejectedFields).length > 0 &&
      Object.keys(rejectedFields).includes(fieldName)
    );
  }

  // eslint-disable-next-line no-shadow
  function getRejectMessage({ fieldName, approveMode, rejectedFields }) {
    return checkReject(fieldName) && approveMode
      ? rejectedFields[fieldName]
      : '';
  }

  const notationExist = () => !!property[`${userRole}Notation`];

  return (
    <>
      <Helmet>
        <title>รายละเอียดทรัพย์ - AssetBeyond</title>
      </Helmet>
      {property && (
        <Container maxWidth="sm" className={classes.container}>
          <Box boxShadow={1} mb={2} p={3} borderRadius={4} bgcolor="white">
            <BasicInfoList
              approveMode={approveMode}
              uuid={uuid}
              createdAt={property.createdAt}
              userName={property.owner.data.attributes.name || ''}
              userRole={property.owner.data.attributes.role || ''}
              completed={property.isComplete}
            />
          </Box>
          {notationExist() && (
            <Box boxShadow={1} mb={2} p={3} borderRadius={4} bgcolor="white">
              <Box
                bgcolor="hsla(228, 20%, 97%, 1)"
                borderRadius={8}
                p={1}
                pl={2}
              >
                <Typography color="textSecondary" variant="caption">
                  บันทึกส่วนตัว
                </Typography>
                <Typography
                  style={{ wordBreak: 'break-word' }}
                  color="textPrimary"
                >
                  {property[`${userRole}Notation`]}
                </Typography>
              </Box>
            </Box>
          )}

          {Object.keys(fieldNameBySection).map((section, index) => (
            <Paper key={section} className={classes.sectionWrapper}>
              <Box mt={2} mb={2}>
                <Typography variant="h6" color="primary" align="right">
                  ส่วนที่ {index + 1}
                </Typography>
                <Typography color="textSecondary" align="right" variant="body2">
                  {sectionNameMap[section]}
                </Typography>
              </Box>
              {Object.keys(pick(property, fieldNameBySection[section]))
                .filter(
                  key =>
                    !['currentFormStep', 'submissionProgress'].includes(key)
                )
                .map(fieldName => (
                  <DetailText
                    editedFields={editedFields}
                    fetchProperty={manualFetchProperty}
                    isRejected={checkReject(fieldName)}
                    rejectMessage={getRejectMessage({
                      fieldName,
                      approveMode,
                      rejectedFields,
                    })}
                    submissionProgress={submissionProgress}
                    uuid={uuid}
                    user={user}
                    id={fieldName}
                    key={fieldName}
                    heading={t[fieldName]}
                    approveMode={approveMode}
                    body={getPropertyDetailsContent(
                      fieldName,
                      property[fieldName]
                    )}
                  />
                ))}
              {images.length > 0 && index === 4 && (
                <Box mt={8} bgcolor="#fff">
                  {images.map(category => (
                    <DetailImage
                      fetchProperty={manualFetchProperty}
                      isRejected={checkReject(category.name)}
                      rejectMessage={getRejectMessage({
                        fieldName: category.name,
                        approveMode,
                        rejectedFields,
                      })}
                      uuid={uuid}
                      user={user}
                      name={category.name}
                      key={category.name}
                      heading={category.thName}
                      imageObject={category.images}
                      approveMode={approveMode}
                      submissionProgress={submissionProgress}
                    />
                  ))}
                </Box>
              )}
            </Paper>
          ))}
          <>
            {userRole !== 'sales' &&
              approveMode &&
              shouldShowApprovalDialog[userRole].includes(
                submissionProgress
              ) && (
                <ApprovalActions
                  userRole={userRole}
                  uuid={uuid}
                  listNumber={property.listNumber}
                  rejectedFields={rejectedFields}
                />
              )}
          </>
        </Container>
      )}
    </>
  );
}

const mapStateToProps = state => ({
  user: state.user.user,
});

const mapDispatchToProps = {
  setLoading,
};

PropertyDetails.propTypes = {
  uuid: PropTypes.string.isRequired,
  user: PropTypes.objectOf(PropTypes.any).isRequired,
  setLoading: PropTypes.func.isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(PropertyDetails);
