import React, { useState, Fragment, useEffect } from 'react';
import {
  Grid,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import LocalMallIcon from '@material-ui/icons/LocalMall';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import SchoolIcon from '@material-ui/icons/School';
import LocalGroceryStore from '@material-ui/icons/LocalGroceryStore';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ExpandableCheckbox } from 'Components/fields';
import GoogleMapsFetchingWrapper from './GoogleMapsFetchingWrapper';

const useStyles = makeStyles(theme => ({
  formControl: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2.5),
    boxSizing: 'border-box',
  },
}));

function NearbyPlacesCheckboxes({ nearbyPlaces }) {
  const classes = useStyles();
  const [requestList, setRequestList] = useState([
    'departmentStores',
    'hospitals',
    'schools',
    'universities',
    'governmentBuildings',
    'supermarkets',
    'trainStations',
  ]);

  const [state, setState] = useState({
    departmentStores: true,
    hospitals: true,
    schools: true,
    universities: true,
    governmentBuildings: true,
    supermarkets: true,
    trainStations: true,
  });

  useEffect(() => {
    setRequestList(
      Object.keys(state)
        .map(key => {
          if (state[key]) {
            return key;
          }
          return false;
        })
        .filter(n => n)
    );
  }, [state]);

  const handleChange = name => event => {
    const newState = { ...state, [name]: event.target.checked };
    setState(newState);
  };

  const {
    departmentStores,
    hospitals,
    supermarkets,
    schools,
    universities,
    governmentBuildings,
  } = state;

  return (
    <Fragment>
      <Grid container justify="flex-end">
        <Grid item xs={12} md={12}>
          <FormControl
            fullWidth
            className={classes.formControl}
            component="fieldset"
          >
            <FormLabel component="legend">
              เลือกสถานที่ที่ต้องการดึงข้อมูล
            </FormLabel>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={departmentStores}
                    onChange={handleChange('departmentStores')}
                    value="departmentStores"
                  />
                }
                label="ห้างสรรพสินค้า"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={supermarkets}
                    onChange={handleChange('supermarkets')}
                    value="supermarkets"
                  />
                }
                label="ซูเปอร์มาร์เก็ต"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={universities}
                    onChange={handleChange('universities')}
                    value="universities"
                  />
                }
                label="มหาวิทยาลัย"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={hospitals}
                    onChange={handleChange('hospitals')}
                    value="hospitals"
                  />
                }
                label="โรงพยาบาล"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={governmentBuildings}
                    onChange={handleChange('governmentBuildings')}
                    value="governmentBuildings"
                  />
                }
                label="สถานที่ราชการ"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={schools}
                    onChange={handleChange('schools')}
                    value="schools"
                  />
                }
                label="โรงเรียน"
              />
            </FormGroup>
          </FormControl>
        </Grid>
      </Grid>
      <GoogleMapsFetchingWrapper requestList={requestList} />
      <Grid container spacing={2}>
        {nearbyPlaces.departmentStores && departmentStores && (
          <Grid item xs={12}>
            <ExpandableCheckbox
              labelIcon={<LocalMallIcon />}
              name="departmentStoreList"
              options={nearbyPlaces.departmentStores}
              secondaryLabelName="distance"
              label="ห้างสรรพสินค้าใกล้เคียง"
            />
          </Grid>
        )}
        {nearbyPlaces.supermarkets && supermarkets && (
          <Grid item xs={12}>
            <ExpandableCheckbox
              labelIcon={<LocalGroceryStore />}
              name="supermarketList"
              options={nearbyPlaces.supermarkets}
              secondaryLabelName="distance"
              label="ซูเปอร์มาร์เก็ตใกล้เคียง"
            />
          </Grid>
        )}
        {nearbyPlaces.universities && universities && (
          <Grid item xs={12}>
            <ExpandableCheckbox
              labelIcon={<SchoolIcon />}
              name="universityList"
              options={nearbyPlaces.universities}
              secondaryLabelName="distance"
              label="มหาวิทยาลัยใกล้เคียง"
            />
          </Grid>
        )}
        {nearbyPlaces.hospitals && hospitals && (
          <Grid item xs={12}>
            <ExpandableCheckbox
              labelIcon={<LocalHospitalIcon />}
              name="hospitalList"
              options={nearbyPlaces.hospitals}
              secondaryLabelName="distance"
              label="โรงพยาบาลใกล้เคียง"
            />
          </Grid>
        )}
        {nearbyPlaces.governmentBuildings && governmentBuildings && (
          <Grid item xs={12}>
            <ExpandableCheckbox
              labelIcon={<BusinessCenterIcon />}
              name="governmentBuildingList"
              options={nearbyPlaces.governmentBuildings}
              secondaryLabelName="distance"
              label="สถานที่ราชการใกล้เคียง"
            />
          </Grid>
        )}
        {nearbyPlaces.schools && schools && (
          <Grid item xs={12}>
            <ExpandableCheckbox
              labelIcon={<SchoolIcon />}
              name="schoolList"
              options={nearbyPlaces.schools}
              secondaryLabelName="distance"
              label="โรงเรียนใกล้เคียง"
            />
          </Grid>
        )}
      </Grid>
    </Fragment>
  );
}

NearbyPlacesCheckboxes.propTypes = {
  nearbyPlaces: PropTypes.shape({
    departmentStores: PropTypes.array,
    hospitals: PropTypes.array,
    schools: PropTypes.array,
    universities: PropTypes.array,
    governmentBuildings: PropTypes.array,
    supermarkets: PropTypes.array,
    trainStations: PropTypes.array,
  }).isRequired,
};

const mapStateToProps = state => ({
  nearbyPlaces: state.nearbyPlaces.nearbyPlaces,
});

export default connect(mapStateToProps)(NearbyPlacesCheckboxes);
