import React from 'react';
import { Typography, Button, Box } from '@material-ui/core';

function NotFoundPage() {
  return (
    <Box
      display="flex"
      justifyContent="center"
      flexDirection="column"
      alignItems="center"
    >
      <Typography align="center" variant="h1">
        404 ERROR
      </Typography>
      <Button style={{ width: 'fit-content' }} href="/login">
        กลับหน้าหลัก
      </Button>
    </Box>
  );
}

export default NotFoundPage;
