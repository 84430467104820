import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Box, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  header: {
    fontWeight: 500,
  },
  button: {
    marginTop: theme.spacing(2),
  },
}));

const PropertyShowDirections = ({ baseAdsDirections }) => {
  const classes = useStyles();
  return (
    <Box p={{ xs: 2, sm: 4 }}>
      <Box mb={2}>
        <Typography className={classes.header} variant="body1">
          การเดินทาง
        </Typography>
      </Box>
      <Typography
        style={{
          lineHeight: 2,
          whiteSpace: 'pre-wrap',
          color: 'rgb(72,72,72)',
        }}
        variant="body1"
      >
        {baseAdsDirections}
      </Typography>
    </Box>
  );
};

PropertyShowDirections.propTypes = {
  baseAdsDirections: PropTypes.string.isRequired,
};

export default PropertyShowDirections;
