export const getRejectedFields = ({
  userRole,
  approveMode,
  submissionProgress,
  rejectedFields,
}) => {
  const adminRejectedFields = rejectedFields.admin;
  const managerRejectedFields = rejectedFields.manager;

  if (userRole === 'sales' && !approveMode) {
    return ['admin_rejected', 'sales_editing'].includes(submissionProgress)
      ? adminRejectedFields
      : {};
  }
  if (userRole === 'admin') {
    if (!approveMode) {
      return ['admin_editing', 'manager_rejected'].includes(submissionProgress)
        ? managerRejectedFields
        : {};
    }
    return ['sales_submitted', 'sales_edited', 'admin_editing'].includes(
      submissionProgress
    )
      ? adminRejectedFields
      : {};
  }
  if (userRole === 'manager' && approveMode) {
    return ['admin_edited', 'admin_approved', 'manager_editing'].includes(
      submissionProgress
    )
      ? managerRejectedFields
      : {};
  }
  return {};
};

export const editedFieldsIsFor = ({
  userRole,
  editedSubmissionProgress,
  isComplete,
}) => {
  switch (userRole) {
    case 'admin':
      if (isComplete) {
        return [
          'sales_edited',
          'admin_approved',
          'admin_edited',
          'completed',
        ].includes(editedSubmissionProgress);
      }
      return ['sales_edited', 'sales_submitted', 'admin_rejected'].includes(
        editedSubmissionProgress
      );

    case 'manager':
      return ['manager_rejected', 'completed'].includes(
        editedSubmissionProgress
      );
    default:
      return false;
  }
};
