import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  useMediaQuery,
  useTheme,
  Slide,
  Grid,
  makeStyles,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Button,
  Container,
  useScrollTrigger,
} from '@material-ui/core';
import { formatPhoneNumber } from 'Utils';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  gridContainer: {
    maxHeight: 80,
  },
  avatar: {
    width: 30,
    height: 30,
  },
  avatarRoot: {
    minWidth: theme.spacing(5),
  },
  noAvatar: {
    background: theme.palette.primary.main,
  },
}));

const FloatingContactBar = ({
  contactInfoVisible,
  name,
  phone,
  lineId,
  avatar,
}) => {
  const [scrolled, setScrolled] = useState(false);
  const theme = useTheme();
  const smallViewPort = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();
  const trigger = useScrollTrigger();

  useEffect(() => {
    if (trigger) setScrolled(true);
  }, [trigger]);

  return (
    smallViewPort && (
      <Slide appear={false} direction="up" in={!contactInfoVisible && scrolled}>
        <Box width="100%" position="fixed" bottom={0} left={0} right={0}>
          <Container maxWidth="md" style={{ padding: 0 }}>
            <Grid className={classes.gridContainer} justify="center" container>
              <Grid item xs={12} sm={10} md={8}>
                <Box
                  p={1}
                  pl={2}
                  pr={2}
                  height="100%"
                  bgcolor="white"
                  width="100%"
                  boxShadow={2}
                  display="flex"
                  justifyContent="space-between"
                >
                  <ListItem className={classes.listItem} dense disableGutters>
                    <ListItemAvatar
                      classes={{
                        root: classes.avatarRoot,
                      }}
                    >
                      <Avatar
                        className={clsx(
                          classes.avatar,
                          !avatar && classes.noAvatar
                        )}
                        src={avatar || null}
                      >
                        {avatar || name[0]}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primaryTypographyProps={{
                        variant: 'body2',
                        style: {
                          fontWeight: 400,
                        },
                      }}
                      primary={`คุณ${name} · ${formatPhoneNumber(phone)}`}
                      secondary={`${lineId} (Line)`}
                    />
                  </ListItem>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Button
                      onClick={() =>
                        window.open(`http://line.me/ti/p/~${lineId}`)
                      }
                      style={{ whiteSpace: 'nowrap' }}
                      variant="contained"
                      color="secondary"
                    >
                      สอบถามข้อมูล
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Slide>
    )
  );
};

FloatingContactBar.defaultProps = {
  name: '',
  phone: '',
  lineId: '',
  avatar: '',
};

FloatingContactBar.propTypes = {
  contactInfoVisible: PropTypes.bool.isRequired,
  name: PropTypes.string,
  phone: PropTypes.string,
  lineId: PropTypes.string,
  avatar: PropTypes.string,
};

export default FloatingContactBar;
