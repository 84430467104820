import React from 'react'
import PropTypes from 'prop-types'
import { Box, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  imgBox: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  img: {
    [theme.breakpoints.down('xs')]: {
      width: 70,
      height: 50,
    },
  },
}))

const PropertyImage = ({ image, uuid }) => {
  const classes = useStyles()
  return (
    <Box className={classes.imgBox}>
      <a href={`property/view?${uuid}`}>
        <img
          className={classes.img}
          height={70}
          width={150}
          style={{ objectFit: 'cover' }}
          src={image}
          alt="property"
        />
      </a>
    </Box>
  )
}

PropertyImage.propTypes = {
  image: PropTypes.string.isRequired,
  uuid: PropTypes.string.isRequired,
}

export default PropertyImage
