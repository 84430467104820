import React from 'react';
import { Box, IconButton, Tooltip } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'formik';
import FileUploadField from './FileUploadField';

const nameMap = {
  propertyImages: 'propertyImagesAttached',
  agentAssignmentFiles: 'agentAssignmentFilesAttached',
  ownerIdCards: 'ownerIdCardsAttached',
  deeds: 'deedsAttached',
};

const useStyles = makeStyles(theme => ({
  fieldset: {
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    border: '1px dashed rgba(0, 0, 0, 0.3)',
    borderRadius: theme.shape.borderRadius,
  },
  label: {
    display: 'flex',
    justifyContent: 'center',
  },
  removeIcon: {
    color: theme.palette.error.main,
  },
}));

function FileUploadFieldSet(props) {
  const classes = useStyles();
  const {
    hasMultipleFields,
    name,
    formik,
    unitText,
    imageList,
    fieldCountList,
    setFieldCountList,
    setImageList,
  } = props;
  const { values, setFieldValue, setFieldTouched, errors, status } = formik;

  const currentFieldCount = fieldCountList[name];

  return (
    <Box mb={16}>
      {hasMultipleFields && (
        <Box display="flex" justifyContent="flex-end">
          <Tooltip title="ลบช่องอัพโหลด">
            <div>
              <IconButton
                disabled={currentFieldCount === 1}
                onClick={() =>
                  setFieldCountList({
                    ...fieldCountList,
                    [name]: currentFieldCount - 1,
                  })
                }
                className={classes.removeIcon}
              >
                <RemoveCircleIcon />
              </IconButton>
            </div>
          </Tooltip>

          <Tooltip title="เพิ่มช่องอัพโหลด">
            <IconButton
              onClick={() =>
                setFieldCountList({
                  ...fieldCountList,
                  [name]: currentFieldCount + 1,
                })
              }
              color="primary"
            >
              <AddCircleIcon />
            </IconButton>
          </Tooltip>
        </Box>
      )}
      <>
        {[...Array(currentFieldCount).keys()].map(currentField => (
          <FileUploadField
            setImageList={setImageList}
            imageList={imageList}
            unitText={unitText}
            key={currentField}
            name={name}
            fieldError={errors[nameMap[name]]}
            currentField={currentField + 1}
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
            values={values}
            status={status}
            hasMultipleFields={hasMultipleFields}
          />
        ))}
      </>
    </Box>
  );
}

export default connect(FileUploadFieldSet);
