import React, { useEffect } from 'react';
import SectionDividerWithIcon from 'Components/shared/SectionDividerWithIcon';
import { MegaPhoneIcon } from 'Components/customIcons';
import BaseAdsTitle from './BaseAdsTitle';
import BaseAdsDetails from './BaseAdsDetails';
import BaseAdsNearbyPlaces from './BaseAdsNearbyPlaces';
import BaseAdsDirections from './BaseAdsDirections';

export default function FourthSection() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);
  return (
    <>
      <SectionDividerWithIcon icon={<MegaPhoneIcon />}>
        ข้อมูลประกาศ
      </SectionDividerWithIcon>
      <BaseAdsTitle />
      <BaseAdsDetails />
      <BaseAdsNearbyPlaces />
      <BaseAdsDirections />
    </>
  );
}
