import React, { useEffect, useState } from 'react';
import SimpleDialog from 'Components/shared/SimpleDialog';
import { TextField } from '@material-ui/core';
import Axios from 'axios';
import snakecaseKeys from 'snakecase-keys';
import { useSnackbar } from 'notistack';

const NotationDialog = ({ open, handleClose, uuid, userRole, listNumber }) => {
  const [notationValue, setNotationValue] = useState('');
  const { enqueueSnackbar } = useSnackbar();

  const notationFieldName = `${userRole}Notation`;

  const handleChange = e => {
    setNotationValue(e.target.value);
  };

  useEffect(() => {
    Axios.get('/v1/properties/show', {
      params: {
        uuid,
        fields: [notationFieldName],
      },
    }).then(response => {
      const responseProperty = response.data.data.attributes;
      setNotationValue(responseProperty[notationFieldName] || '');
    });
  }, [uuid, notationFieldName]);

  const handleSave = () => {
    Axios.patch(
      '/v1/properties/update',
      snakecaseKeys({
        uuid,
        [`${userRole}_notation`]: notationValue,
      })
    ).then(() => {
      enqueueSnackbar(
        'บันทึกเรียบร้อยแล้ว สามารถดูบันทึกได้ที่หน้า "ดูทรัพย์"'
      );
      handleClose();
    });
  };

  return (
    <SimpleDialog
      open={open}
      dialogTitle={`จัดการบันทึก ${listNumber}`}
      message={
        <>
          <TextField
            autoFocus
            fullWidth
            variant="filled"
            rows={3}
            rowsMax={10}
            label="บันทึกข้อความ"
            multiline
            onChange={handleChange}
            value={notationValue}
          />
        </>
      }
      primaryBtnText="บันทึก"
      secondaryBtnText="ปิด"
      handleSecondaryClick={handleClose}
      handlePrimaryClick={handleSave}
    />
  );
};

export default NotationDialog;
