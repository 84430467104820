export const tableFieldNames = {
  manager: [
    { label: 'เลข List', value: 'listNumber', disabled: true },
    { label: 'สถานะ', value: 'propertyState' },
    { label: 'Featured', value: 'featured' },
    { label: 'Featured Tag', value: 'featuredTag' },
    { label: 'ชื่อทรัพย์', value: 'nickname' },
    { label: 'ประเภททรัพย์', value: 'propertyType' },
    { label: 'ประเภทสัญญา', value: 'contractType' },
    { label: 'เขต/อำเภอ', value: 'propertyAmphoe' },
    { label: 'จังหวัด', value: 'propertyProvince' },
    { label: 'พื้นที่', value: 'areaSize' },
    { label: 'ราคา', value: 'sellPrice' },
    { label: 'วันเริ่มสัญญา', value: 'dateReceived' },
    { label: 'วันหมดอายุ', value: 'dateExpired' },
    { label: 'สัญญาคงเหลือ', value: 'dateLeft' },
    { label: 'เจ้าหน้าที่', value: 'owner' },
  ],
  admin: [
    { label: 'เลข List', value: 'listNumber', disabled: true },
    { label: 'สถานะ', value: 'propertyState' },
    { label: 'ชื่อทรัพย์', value: 'nickname' },
    { label: 'ประเภททรัพย์', value: 'propertyType' },
    { label: 'ประเภทสัญญา', value: 'contractType' },
    { label: 'เขต/อำเภอ', value: 'propertyAmphoe' },
    { label: 'จังหวัด', value: 'propertyProvince' },
    { label: 'พื้นที่', value: 'areaSize' },
    { label: 'ราคา', value: 'sellPrice' },
    { label: 'วันเริ่มสัญญา', value: 'dateReceived' },
    { label: 'วันหมดอายุ', value: 'dateExpired' },
    { label: 'สัญญาคงเหลือ', value: 'dateLeft' },
    { label: 'เจ้าหน้าที่', value: 'owner' },
  ],
  sales: [
    { label: 'ภาพทรัพย์', value: 'propertyImage' },
    { label: 'สถานะ', value: 'propertyState' },
    { label: 'ประเภททรัพย์', value: 'propertyType' },
    { label: 'ประเภทสัญญา', value: 'contractType' },
    { label: 'สัญญาคงเหลือ', value: 'dateLeft' },
    { label: 'วันหมดอายุ', value: 'dateExpired' },
    { label: 'ชื่อทรัพย์', value: 'nickname' },
    { label: 'เขต/อำเภอ', value: 'propertyAmphoe' },
    { label: 'จังหวัด', value: 'propertyProvince' },
    { label: 'พื้นที่', value: 'areaSize' },
    { label: 'ราคา', value: 'sellPrice' },
    { label: 'เจ้าของ', value: 'seller' },
  ],
};

export const propertyAcronyms = {
  0: 'N/A',
  1: 'CD',
  2: 'H',
  3: 'H',
  4: 'HF',
  5: 'TH',
  6: 'TH',
  7: 'OF',
  8: 'SH',
  9: 'OF',
  10: 'LD',
  11: 'LD',
  12: 'FC',
  13: 'AP',
  14: 'OH',
  15: 'OH',
};
