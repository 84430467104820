import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { userPropsShape } from 'Constants'
import { useTheme, useMediaQuery } from '@material-ui/core'
import PropertyControlTable from './PropertyControlTable'
import TableFieldSelector from './TableFieldSelector'
import { tableFieldNames } from './constants'

const defaultControlFields = tableFieldNames.manager
  .map((name) => name.value)
  .filter(
    (name) =>
      !['dateExpired', 'dateReceived', 'featured', 'featuredTag'].includes(name)
  )

const defaultSalesFields = tableFieldNames.sales.map((name) => name.value)

const PropertyDashboard = ({ user }) => {
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.only('xs'))

  const [enabledFields, setEnabledFields] = useState(
    ['admin', 'manager'].includes(user.role)
      ? defaultControlFields
      : defaultSalesFields
  )

  useEffect(() => {
    if (user.role === 'sales' && mobile) {
      setEnabledFields(
        defaultSalesFields.filter((field) =>
          ['propertyImage', 'nickname', 'seller'].includes(field)
        )
      )
    }
  }, [mobile, user])

  return (
    <>
      <TableFieldSelector
        setEnabledFields={setEnabledFields}
        enabledFields={enabledFields}
      />
      <PropertyControlTable enabledFields={enabledFields} />
    </>
  )
}

const mapStateToProps = (state) => ({
  user: state.user.user,
})

PropertyDashboard.propTypes = {
  user: userPropsShape.isRequired,
}

export default connect(mapStateToProps)(PropertyDashboard)
