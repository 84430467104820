import React from 'react';
import { InputAdornment, Tooltip, IconButton } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import { connect } from 'formik';
import { FieldSet } from 'Components/fields';
import { composeDetails } from 'Services';

function BaseAdsDetails({ formik }) {
  const { values, setFieldValue } = formik;

  function handleDetailsCompose() {
    setFieldValue('baseAdsDetails', composeDetails(values));
  }

  return (
    <FieldSet
      required
      multiline
      name="baseAdsDetails"
      label="สถานภาพทรัพย์ การตกแต่ง ต่อเติม"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Tooltip title="ดึงข้อมูล">
              <IconButton onClick={handleDetailsCompose}>
                <RefreshIcon />
              </IconButton>
            </Tooltip>
          </InputAdornment>
        ),
      }}
    />
  );
}

export default connect(BaseAdsDetails);
