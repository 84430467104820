import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import { Formik, Form } from 'formik';
import { FieldSet, SelectFieldSet } from 'Components/fields';
import {
  Container,
  Paper,
  Typography,
  Button,
  Box,
  makeStyles,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import SimpleDialog from 'Components/shared/SimpleDialog';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { propertyTypeOptions } from 'Components/WorkSubmitForm/shared';
import snakecaseKeys from 'snakecase-keys';
import queryString from 'query-string';
import {
  adsCreateValidationSchema,
  adsEditValidationSchema,
} from './adsValidationSchema';
import AdsExampleBox from './AdsExampleBox';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(1.5),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
    },
  },
  heading: {
    marginBottom: theme.spacing(3),
  },
}));

const successRedirectPath = ({ userRole }) => {
  switch (userRole) {
    case 'admin':
    case 'manager':
      return `/${userRole}/complete-properties?tab=1`;
    default:
      return `/${userRole}`;
  }
};

function AdvertisementForm() {
  const history = useHistory();
  const location = useLocation();

  const parsed = queryString.parse(location.search, { parseBooleans: true });
  const { editMode, adsId, uuid } = parsed;
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [initialValues, setInitialValues] = useState({
    title: '',
    shortTitle: '',
    postPropertyType: 0,
    postDetails: '',
    postNearbyPlaces: '',
    postDirections: '',
  });
  const [loading, setLoading] = useState(true);
  const [nickname, setNickname] = useState('');
  const [latLong, setLatLong] = useState('');
  const [redirectDialogOpen, setRedirectDialogOpen] = useState(false);
  const [owner, setOwner] = useState({});
  const [currentUser, setCurrentUser] = useState({});
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [successRedirect, setSuccessRedirect] = useState(false);

  const handleSubmit = async (values, { setSubmitting }) => {
    const editingValues = {
      postDetails: values.postDetails,
      postNearbyPlaces: values.postNearbyPlaces,
      postDirections: values.postDirections,
      postPropertyType: values.postPropertyType,
    };
    const submittingValues = {
      ...editingValues,
      shortTitle: values.shortTitle,
      title: values.title,
    };
    const successMessage = editMode
      ? 'บันทึกเรียบร้อยแล้ว'
      : 'สร้างโฆษณาเรียบร้อยแล้ว';

    try {
      if (editMode) {
        await Axios.patch('/v1/advertisements/update', {
          uuid,
          id: adsId,
          advertisements: snakecaseKeys(editingValues),
        });
      } else {
        await Axios.post('/v1/advertisements/create', {
          uuid,
          advertisements: snakecaseKeys(submittingValues),
        });
      }
      enqueueSnackbar(successMessage);
      setSuccessDialogOpen(true);
      setSubmitting(false);
    } catch (error) {
      setSuccessDialogOpen(false);
      setSubmitting(false);
      enqueueSnackbar(JSON.stringify(error.response.data));
    }
  };

  useEffect(() => {
    Axios.get('/v1/users/show').then(response =>
      setCurrentUser(response.data.data.attributes)
    );
  }, [uuid]);

  useEffect(() => {
    setLoading(true);

    const fields = [
      'latLong',
      'owner',
      'nickname',
      'propertyType',
      'baseAdsTitle',
      'baseAdsDetails',
      'baseAdsNearbyPlaces',
      'baseAdsDirections',
      'advertisements',
    ];

    Axios.get('/v1/properties/show', {
      params: {
        uuid,
        fields,
      },
    }).then(response => {
      const property = response.data.data.attributes;
      const ownerUser = property.owner.data.attributes;
      setRedirectDialogOpen(!ownerUser.contactInfo.data);
      setLatLong(property.latLong);
      setOwner(ownerUser);
      setNickname(property.nickname);
      if (editMode) {
        const advertisement = property.advertisements.data.find(
          ads => Number(ads.id) === Number(adsId)
        ).attributes;
        setInitialValues(i => ({
          ...i,
          title: advertisement.title,
          shortTitle: advertisement.shortTitle,
          postDetails: advertisement.postDetails,
          postDirections: advertisement.postDirections,
          postNearbyPlaces: advertisement.postNearbyPlaces,
          postPropertyType: advertisement.postPropertyType,
        }));
      } else {
        setInitialValues(i => ({
          ...i,
          title: property.baseAdsTitle,
          postDetails: property.baseAdsDetails,
          postDirections: property.baseAdsDirections,
          postNearbyPlaces: property.baseAdsNearbyPlaces,
          postPropertyType: property.propertyType,
        }));
      }
      setLoading(false);
    });
  }, [uuid, editMode, adsId]);

  return (
    <Container maxWidth="sm" disableGutters>
      <SimpleDialog
        dialogTitle="ยังไม่มีข้อมูลการติดต่อ"
        open={redirectDialogOpen}
        primaryBtnText="กลับ"
        message="ยังไม่มีข้อมูลการติดต่อสำหรับลงโฆษณา กรุณาแจ้ง sales ให้กรอกข้อมูล"
        handlePrimaryClick={() => history.goBack()}
      />
      <SimpleDialog
        dialogTitle={
          editMode ? 'บันทึกการแก้ไขเรียบร้อยแล้ว' : 'สร้างโฆษณาเรียบร้อยแล้ว'
        }
        message={
          editMode
            ? 'คลิกตกลงเพื่อไปหน้ารวมทรัพย์'
            : 'คลิกตกลงเพื่อไปหน้ารวมทรัพย์ หรือเพิ่มโฆษณาอื่นเพื่ออยู่ต่อหน้านี้'
        }
        open={successDialogOpen}
        primaryBtnText="ตกลง"
        handlePrimaryClick={() => setSuccessRedirect(true)}
        secondaryBtnText={editMode ? '' : 'เพิ่มโฆษณาอื่น'}
        handleSecondaryClick={
          editMode ? null : () => setSuccessDialogOpen(false)
        }
        handleClose={() => setSuccessDialogOpen(false)}
      />
      {successRedirect && (
        <Redirect to={successRedirectPath({ userRole: currentUser.role })} />
      )}

      <Paper className={classes.paper} elevation={1}>
        <Typography color="textSecondary" variant="h6" align="right">
          {editMode ? 'แก้ไขโฆษณา' : 'สร้างโฆษณาใหม่'}
        </Typography>
        <Typography
          component="p"
          className={classes.heading}
          color="textSecondary"
          variant="caption"
          align="right"
        >
          {nickname}
        </Typography>
        <Formik
          validationSchema={
            editMode ? adsEditValidationSchema : adsCreateValidationSchema
          }
          initialValues={initialValues}
          enableReinitialize
          onSubmit={handleSubmit}
          render={({ isSubmitting, values }) => (
            <Form>
              <FieldSet
                title={editMode ? 'ไม่สามารถแก้ไขได้' : ''}
                disabled={editMode}
                required
                label="หัวข้อประกาศ"
                name="title"
                multiline
                helperText={`${values.title.length} / 140`}
              />
              <FieldSet
                title={editMode ? 'ไม่สามารถแก้ไขได้' : ''}
                disabled={editMode}
                required
                label="หัวข้อประกาศแบบสั้น (ไม่เกิน 50 ตัวอักษร)"
                name="shortTitle"
                helperText={`${values.shortTitle.length} / 50`}
              />
              <SelectFieldSet
                title={editMode ? 'ไม่สามารถแก้ไขได้' : ''}
                disabled={editMode}
                required
                name="postPropertyType"
                label="ประเภททรัพย์"
                options={propertyTypeOptions}
              />
              <FieldSet
                required
                multiline
                name="postDetails"
                label="สถานภาพทรัพย์ การตกแต่ง ต่อเติม"
              />
              <FieldSet
                required
                multiline
                name="postNearbyPlaces"
                label="สถานที่สำคัญใกล้เคียง"
              />
              <FieldSet
                required
                multiline
                name="postDirections"
                label="การเดินทาง"
              />
              <AdsExampleBox
                values={values}
                owner={owner}
                latLong={latLong}
                loading={loading}
              />

              <Box display="flex" justifyContent="flex-end">
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  color="secondary"
                  variant="contained"
                >
                  {editMode ? 'บันทึก' : 'สร้างโฆษณา'}
                </Button>
              </Box>
            </Form>
          )}
        />
      </Paper>
    </Container>
  );
}

export default AdvertisementForm;
