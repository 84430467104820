import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Axios from 'axios';
import { DialogActions, Button, makeStyles } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { Redirect } from 'react-router-dom';

const useStyles = makeStyles({
  root: {
    background: 'rgb(246,246,249)',
  },
});

const approvalSubmissionProgress = {
  admin: {
    approved: 'admin_approved',
    rejected: 'admin_rejected',
  },
  manager: {
    approved: 'completed',
    rejected: 'manager_rejected',
  },
};

const approvalStatus = rejectedFieldsCount =>
  rejectedFieldsCount === 0 ? 'approved' : 'rejected';

const submissionProgressForApproval = ({ userRole, rejectedFieldsCount }) => {
  return approvalSubmissionProgress[userRole][
    approvalStatus(rejectedFieldsCount)
  ];
};

const Actions = ({
  uuid,
  rejectedFieldsCount,
  handleClose,
  userRole,
  editRedirect,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [redirect, setRedirect] = useState(false);

  const handleConfirmClick = () => {
    if (editRedirect === 'false') {
      Axios.patch('/v1/properties/update', {
        uuid,
        submissionProgress: submissionProgressForApproval({
          userRole,
          rejectedFieldsCount,
        }),
      }).then(resp => {
        if (resp.data.hasError) {
          enqueueSnackbar(`${resp.data.message}`);
        } else {
          enqueueSnackbar('ยืนยันการตรวจสอบเรียบร้อยแล้ว');
          setRedirect(true);
        }
      });
    } else {
      setRedirect(true);
    }
  };

  // eslint-disable-next-line no-shadow
  const approvalRedirectPath = ({ userRole, isRejected }) => {
    switch (userRole) {
      case 'admin':
        return '/admin/property-control?tab=1';
      case 'manager':
        return isRejected
          ? '/manager/property-control?tab=1'
          : '/manager/complete-properties?tab=0';
      default:
        return `/${userRole}`;
    }
  };

  return (
    <>
      <DialogActions className={classes.root}>
        <Button color="primary" onClick={handleClose}>
          ยกเลิก
        </Button>
        <Button
          variant="contained"
          onClick={handleConfirmClick}
          color="secondary"
        >
          ยืนยัน
        </Button>
      </DialogActions>
      {redirect && editRedirect === 'true' && (
        <Redirect push to={`/${userRole}/work-submit-form/edit?${uuid}`} />
      )}
      {redirect && editRedirect === 'false' && (
        <Redirect
          push
          to={approvalRedirectPath({
            userRole,
            isRejected: rejectedFieldsCount > 0,
          })}
        />
      )}
    </>
  );
};

Actions.propTypes = {
  handleClose: PropTypes.func.isRequired,
  uuid: PropTypes.string.isRequired,
  rejectedFieldsCount: PropTypes.number.isRequired,
  userRole: PropTypes.string.isRequired,
  editRedirect: PropTypes.oneOf(['true', 'false']).isRequired,
};

export default Actions;
