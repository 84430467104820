import React, { useEffect, useState } from 'react'
import Axios from 'axios'
import { Box, makeStyles, Grid } from '@material-ui/core'
import {
  getSubmissionProgressByTabAndRole,
  getSubmissionProgressForControl,
} from 'Constants'
import PropTypes from 'prop-types'
import snakecaseKeys from 'snakecase-keys'
import { connect } from 'react-redux'
import { omit } from 'lodash'
import NoData from '../../TableComponents/NoData'
import PropertyCard from '../PropertyCard'
import PropertySearchBox from './PropertySearchBox'
import PropertyFilterToolbar from './PropertyFilterToolbar'
import cardListFields from './constants'
import PropertyCardListLoader from './PropertyCardListLoader'
import PropertyPagination from './PropertyPagination'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    [theme.breakpoints.only('xs')]: {
      padding: 0,
    },
  },
  noDataWrapper: {
    [theme.breakpoints.up('sm')]: {
      minWidth: 500,
    },
  },
  container: {
    [theme.breakpoints.up('xl')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
}))

const getPropertyStateFilter = (userRole, controlMode, currentTab) => {
  if (userRole === 'sales') {
    switch (currentTab) {
      case 4:
        return ['active']
      case 5:
        return ['expired']
      default:
        return null
    }
  }
  if (userRole !== 'sales' && !controlMode) {
    switch (currentTab) {
      case 1:
        return ['active']
      case 2:
        return ['passive_ads']
      case 3:
        return ['passive_sold', 'passive', 'expired']
      case 4:
        return ['suspended', 'pledged']
      default:
        return null
    }
  }
  return null
}

const refreshProperties = ({
  controlMode,
  currentTab,
  userRole,
  sortBy,
  perPage,
  currentPage,
  sortDirection,
  ...params
}) => {
  return Axios.post(
    '/v1/properties',
    snakecaseKeys({
      submissionProgress: controlMode
        ? getSubmissionProgressForControl(currentTab, userRole)
        : getSubmissionProgressByTabAndRole(currentTab, userRole),
      page: currentPage,
      items: perPage,
      accessType: controlMode ? 'control' : 'view',
      cardList: true,
      hasAds:
        // Disable ads inclusion in control mode
        userRole === 'sales' || controlMode ? null : currentTab !== 0,
      fields:
        userRole === 'sales'
          ? cardListFields.filter((item) => item !== 'advertisements')
          : cardListFields,
      state: getPropertyStateFilter(userRole, controlMode, currentTab),
      ...params,
    })
  )
}

const PropertyCardList = ({
  currentTab,
  userRole,
  controlMode,
  drawerOpen,
}) => {
  const classes = useStyles()

  const [loading, setLoading] = useState(false)
  const [propertyList, setPropertyList] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [totalCount, setTotalCount] = useState(0)
  const [perPage, setPerPage] = useState(12)
  const [filters, setFilters] = useState({})
  const [filteredItems, setFilteredItems] = useState({})

  useEffect(() => {
    setLoading(true)
    // Additional Code to check contract type tabs for sales
    let currentTabValue = null
    if (currentTab === 6) {
      currentTabValue = 'exClientNotSellable'
    } else if (currentTab === 7) {
      currentTabValue = 'openNoCommission'
    }
    refreshProperties({
      controlMode,
      currentTab,
      userRole,
      perPage,
      currentPage,
      ...filteredItems,
      contractType: currentTabValue,
    }).then((response) => {
      setFilters(response.data.filters)
      setPropertyList(response.data.data)
      setTotalPages(Number(response.headers['total-pages']))
      setTotalCount(Number(response.headers['total-count']))
      setLoading(false)
    })
  }, [currentTab, userRole, controlMode, perPage, currentPage, filteredItems])

  const defaultPropertyFetch = ({ ...params }) => {
    setLoading(true)
    refreshProperties({
      controlMode,
      currentTab,
      userRole,
      perPage,
      currentPage,
      ...params,
    }).then((response) => {
      setFilters(response.data.filters)
      setPropertyList(response.data.data)
      setTotalPages(Number(response.headers['total-pages']))
      setTotalCount(Number(response.headers['total-count']))
      setLoading(false)
    })
  }

  return (
    <Box className={classes.wrapper} elevation={0}>
      <Box p={1}>
        <PropertySearchBox propertyFetch={defaultPropertyFetch} />
      </Box>
      <PropertyFilterToolbar
        filteredItems={filteredItems}
        setFilteredItems={setFilteredItems}
        currentTab={currentTab}
        propertyFetch={defaultPropertyFetch}
        filters={omit(filters, ['remainingDays', 'propertyStates.name'])}
        totalCount={totalCount}
        totalPages={totalPages}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        perPage={perPage}
        setPerPage={setPerPage}
      />
      <Box py={{ xs: 0, md: 3 }} display="flex" justifyContent="center">
        <Grid container>
          {loading ? (
            <PropertyCardListLoader />
          ) : (
            propertyList.length > 0 &&
            propertyList.map((property) => (
              <Grid
                key={property.attributes.uuid}
                item
                xs={12}
                lg={drawerOpen ? 4 : 3}
                md={drawerOpen ? 6 : 4}
                sm={drawerOpen ? 12 : 6}
                xl={3}
              >
                <PropertyCard
                  currentTab={currentTab}
                  controlMode={controlMode}
                  refreshProperties={defaultPropertyFetch}
                  property={property.attributes}
                  userRole={userRole}
                />
              </Grid>
            ))
          )}
          {propertyList.length === 0 && !loading && (
            <Box className={classes.noDataWrapper}>
              <NoData />
            </Box>
          )}
        </Grid>
      </Box>
      <Box display="flex" justifyContent="flex-end">
        <PropertyPagination
          totalCount={totalCount}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          perPage={perPage}
          setPerPage={setPerPage}
        />
      </Box>
    </Box>
  )
}

PropertyCardList.defaultProps = {
  controlMode: false,
}

PropertyCardList.propTypes = {
  currentTab: PropTypes.number.isRequired,
  userRole: PropTypes.string.isRequired,
  controlMode: PropTypes.bool,
  drawerOpen: PropTypes.bool.isRequired,
}

const mapStateToProps = (state) => ({
  drawerOpen: state.drawer.drawerOpen,
})

export default connect(mapStateToProps)(PropertyCardList)
