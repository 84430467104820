const checkboxFieldList = [
  {
    id: 1,
    name: 'agentAssignmentFiles',
    label: 'สัญญาแต่งตั้งนายหน้า *',
    disabled: true,
  },
  {
    id: 2,
    name: 'ownerIdCards',
    label: 'สำเนาบัตรประชาชนเจ้าของกรรมสิทธิ์ *',
    disabled: true,
  },
  {
    id: 3,
    name: 'deeds',
    label: 'สำเนาโฉนด *',
    disabled: true,
  },
  {
    id: 4,
    name: 'ownerHouseDocuments',
    label: 'สำเนาทะเบียนบ้านเจ้าของกรรมสิทธิ์',
  },
  {
    id: 5,
    name: 'propertyHouseDocuments',
    label: 'สำเนาทะเบียนบ้านหลังที่ขาย',
  },
  {
    id: 6,
    name: 'authorizedPartyIdCards',
    label: 'สำเนาบัตรประชาชนผู้รับมอบอำนาจ',
  },
  {
    id: 7,
    name: 'authorizedPartyHouseDocuments',
    label: 'สำเนาทะเบียนบ้านของผู้รับมอบอำนาจ',
  },
  {
    id: 8,
    name: 'sellContractDocuments',
    label: 'หนังสือสัญญาขาย (ทด.13)',
  },
  {
    id: 9,
    name: 'buildingPermits',
    label: 'ใบอนุญาตก่อสร้าง',
  },
  {
    id: 10,
    name: 'operationPermits',
    label: 'ใบอนุญาต รง.4',
  },
];

export default checkboxFieldList;
