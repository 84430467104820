import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Tooltip,
  Typography,
} from '@material-ui/core';
import NoAvatarImage from 'Assets/avatar.svg';

const ContentOwnerInfo = ({ owner, dateSubmitted }) => {
  return (
    owner && (
      <Box
        mt={-3}
        mb={3}
        mx={-4}
        p={1.5}
        px={{ xs: 2, sm: 3 }}
        bgcolor="hsla(220, 20%, 97%, 1)"
      >
        <List disablePadding>
          <ListItem style={{ padding: 0 }} dense disableGutters>
            <ListItemAvatar>
              <Avatar src={owner.avatarUrl || NoAvatarImage} />
            </ListItemAvatar>
            <ListItemText
              primaryTypographyProps={{
                color: 'primary',
                style: {
                  fontWeight: 500,
                },
              }}
              primary={owner.name}
              secondary={
                <Tooltip title="วันที่ส่งงาน">
                  <Typography variant="body2" component="span">
                    {new Date(dateSubmitted).toLocaleString('th-TH', {
                      day: 'numeric',
                      month: 'short',
                      year: 'numeric',
                    })}
                  </Typography>
                </Tooltip>
              }
            />
          </ListItem>
        </List>
      </Box>
    )
  );
};

ContentOwnerInfo.propTypes = {
  owner: PropTypes.objectOf(PropTypes.any).isRequired,
  dateSubmitted: PropTypes.string.isRequired,
};

export default ContentOwnerInfo;
