import React, { PureComponent } from 'react';
import { Route, Redirect } from 'react-router-dom';
import Axios from 'axios';
import { connect } from 'react-redux';
import { setUser } from 'JS/actions';

class ProtectedRoute extends PureComponent {
  constructor() {
    super();
    this.state = {
      loggedIn: false,
      loading: true,
    };
    this.setLoggedIn = this.setLoggedIn.bind(this);
    this.setLoading = this.setLoading.bind(this);
  }

  componentDidMount() {
    const { setLoading, setLoggedIn } = this;
    const { userRole, setUser } = this.props;
    if (
      !localStorage.getItem('access-token') ||
      !localStorage.getItem('uid') ||
      !localStorage.getItem('client')
    ) {
      setLoggedIn(false);
      setLoading(false);
      return;
    }

    Axios.get('/v1/auth/validate_token', {
      params: {
        'access-token': localStorage.getItem('access-token'),
        uid: localStorage.getItem('uid'),
        client: localStorage.getItem('client'),
      },
    })
      .then(response => {
        const { success, data } = response.data;
        setUser({
          avatar: data.avatar_url,
          name: data.name,
          email: data.email,
          role: data.role,
          uuid: data.uuid,
        });
        setLoggedIn(success && data.role === userRole);
        setLoading(false);
      })
      .catch(() => {
        setLoggedIn(false);
        setLoading(false);
      });
  }

  setLoggedIn(bool) {
    this.setState({ loggedIn: bool });
  }

  setLoading(bool) {
    this.setState({ loading: bool });
  }

  render() {
    const { TrueComponent, redirectPath, ...rest } = this.props;
    const { loading, loggedIn } = this.state;

    return (
      !loading && (
        <Route
          {...rest}
          render={props =>
            loggedIn ? (
              <TrueComponent {...props} />
            ) : (
                <Redirect to={redirectPath} {...props} />
              )
          }
        />
      )
    );
  }
}

const mapDispatchToProps = {
  setUser,
};

export default connect(null, mapDispatchToProps)(ProtectedRoute);
