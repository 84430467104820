import React from 'react';
import PropTypes from 'prop-types';
import { Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  noWrap: {
    whiteSpace: 'nowrap',
  },
});

const ContentAddress = ({
  propertyTambon,
  propertyAmphoe,
  propertyProvince,
}) => {
  const classes = useStyles();
  return (
    propertyTambon &&
    propertyAmphoe &&
    propertyProvince && (
      <Typography
        component="p"
        variant="body2"
        color="textSecondary"
        className={classes.noWrap}
      >
        {`${propertyTambon} ${propertyAmphoe} จ.${propertyProvince} `}
      </Typography>
    )
  );
};

ContentAddress.defaultProps = {
  propertyTambon: '',
  propertyAmphoe: '',
  propertyProvince: '',
};

ContentAddress.propTypes = {
  propertyTambon: PropTypes.string,
  propertyAmphoe: PropTypes.string,
  propertyProvince: PropTypes.string,
};

export default ContentAddress;
