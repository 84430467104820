import React from 'react';
import PropTypes from 'prop-types';
import {
  CardMedia,
  makeStyles,
  Box,
  SvgIcon,
  Typography,
} from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';
import { mdiCircleMedium } from '@mdi/js';
import { upperCase } from 'lodash';

const useStyles = makeStyles(theme => ({
  cardImage: {
    width: '100%',
    objectFit: 'cover',
    [theme.breakpoints.down('xs')]: {
      height: 120,
    },
  },
  iconFilled: {
    color: theme.palette.primary.main,
  },
}));

const PropertyCardImage = ({ image, currentFormStep, propertyState }) => {
  const classes = useStyles();
  return (
    <Box position="relative">
      {image ? (
        <>
          <CardMedia
            height={150}
            className={classes.cardImage}
            component="img"
            image={image}
          />

          <Box
            p={1}
            px={2}
            width="100%"
            bgcolor="hsl(0,0%,0%,0.5)"
            position="absolute"
            bottom={0}
          >
            <Typography
              variant="body2"
              style={{
                color: 'white',
                fontFamily: 'Noto Sans',
                fontWeight: 500,
              }}
            >
              {upperCase(propertyState)}
            </Typography>
          </Box>
        </>
      ) : (
        <Box position="relative" textAlign="center">
          <Box height={150} style={{ backgroundColor: '#e6e6e6' }} />
          <Rating
            color="secondary"
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
            classes={{
              iconFilled: classes.iconFilled,
            }}
            size="small"
            icon={
              <SvgIcon className={classes.stepIcon}>
                <path d={mdiCircleMedium}></path>
              </SvgIcon>
            }
            value={currentFormStep}
            readOnly
          />
        </Box>
      )}
    </Box>
  );
};

PropertyCardImage.defaultProps = {
  image: '',
};

PropertyCardImage.propTypes = {
  image: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  propertyState: PropTypes.string.isRequired,
  currentFormStep: PropTypes.number.isRequired,
};

export default PropertyCardImage;
