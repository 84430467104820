/* eslint-disable camelcase */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Box, makeStyles } from '@material-ui/core';
import EditConfirmDialog from 'Components/shared/EditConfirmDialog';

const useStyles = makeStyles(theme => ({
  button: {
    marginRight: theme.spacing(1),
  },
}));

function FormButtons(props) {
  const classes = useStyles();
  const {
    isSubmitting,
    editMode,
    activeStep,
    steps,
    setActiveStep,
    listNumber,
    userRole,
    submissionProgress,
    uuid,
  } = props;

  const [editConfirmDialogOpen, setEditConfirmDialogOpen] = useState(false);

  const buttonContent = () => {
    if (editMode) return 'บันทึก';
    if (activeStep === steps.length - 1) return 'ส่งฟอร์ม';
    return 'ถัดไป';
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleSkip = () => {
    setActiveStep(activeStep + 1);
  };

  return (
    <>
      <EditConfirmDialog
        editConfirmDialogOpen={editConfirmDialogOpen}
        setEditConfirmDialogOpen={setEditConfirmDialogOpen}
        userRole={userRole}
        listNumber={listNumber}
        uuid={uuid}
      />
      {process.env.NODE_ENV === 'development' && (
        <Button onClick={handleSkip}>ข้าม</Button>
      )}
      <Box display="flex" justifyContent="flex-end">
        {editMode ? (
          <Button
            disabled={
              !['admin_editing', 'sales_editing', 'manager_editing'].includes(
                submissionProgress
              )
            }
            onClick={() => setEditConfirmDialogOpen(true)}
            className={classes.button}
            variant="outlined"
            color="primary"
          >
            ยืนยันการแก้ไข
          </Button>
        ) : (
          <Button
            disabled={activeStep === 0}
            onClick={handleBack}
            className={classes.button}
          >
            กลับ
          </Button>
        )}
        <Button
          disabled={isSubmitting}
          type="submit"
          variant="contained"
          color="primary"
          className={classes.button}
        >
          {buttonContent()}
        </Button>
      </Box>
    </>
  );
}

FormButtons.defaultProps = {
  uuid: '',
};

FormButtons.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  editMode: PropTypes.bool.isRequired,
  activeStep: PropTypes.number.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object).isRequired,
  setActiveStep: PropTypes.func.isRequired,
  listNumber: PropTypes.string.isRequired,
  userRole: PropTypes.string.isRequired,
  submissionProgress: PropTypes.string.isRequired,
  uuid: PropTypes.string,
};

export default FormButtons;
