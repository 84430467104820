import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DoneAll } from '@material-ui/icons';
import ApprovalConfirmationDialog from './ApprovalConfirmationDialog/ApprovalConfirmationDialog';

const useStyles = makeStyles(theme => ({
  button: {
    marginLeft: theme.spacing(2),
  },
}));

function ApprovalActions({ rejectedFields, listNumber, uuid, userRole }) {
  const classes = useStyles();
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const rejectedFieldsCount = Object.keys(rejectedFields).length;

  function handleConfirmDialogClose() {
    setConfirmDialogOpen(false);
  }

  function handleConfirmDialogOpen() {
    setConfirmDialogOpen(true);
  }

  return (
    <>
      <Box padding={2} display="flex" justifyContent="flex-end">
        <Button
          className={classes.button}
          color="primary"
          variant="contained"
          onClick={handleConfirmDialogOpen}
          startIcon={<DoneAll />}
        >
          ยืนยันการตรวจสอบ
        </Button>
      </Box>

      <ApprovalConfirmationDialog
        open={confirmDialogOpen}
        handleClose={handleConfirmDialogClose}
        listNumber={listNumber}
        rejectedFieldsCount={rejectedFieldsCount}
        rejectedFields={rejectedFields}
        uuid={uuid}
        userRole={userRole}
      />
    </>
  );
}

ApprovalActions.propTypes = {
  uuid: PropTypes.string.isRequired,
  rejectedFields: PropTypes.objectOf(PropTypes.string).isRequired,
  listNumber: PropTypes.string.isRequired,
  userRole: PropTypes.string.isRequired,
};

export default ApprovalActions;
