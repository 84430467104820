import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Box, IconButton, Menu, MenuItem } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import RejectBox from './RejectBox';

const useStyles = makeStyles(theme => ({
  menuIcon: {
    marginRight: theme.spacing(2),
  },
}));

function RejectWrapper(props) {
  const {
    positionElement,
    textElement,
    boxProps,
    isRejected,
    rejectMessage,
    fetchProperty,
    uuid,
    id,
  } = props;
  const classes = useStyles();
  const [rejectBoxAnchorEl, setRejectBoxAnchorEl] = useState(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  function handleRejectClick(e) {
    setRejectBoxAnchorEl(e.currentTarget);
  }

  function handleMenuClose() {
    setMenuAnchorEl(null);
  }

  function handleRejectBoxClose() {
    setRejectBoxAnchorEl(null);
  }

  function handleMenuClick(e) {
    setMenuAnchorEl(e.currentTarget);
  }

  return (
    <Box className={classes.bodyBox} {...boxProps}>
      {positionElement}
      {textElement}
      <IconButton size="small" onClick={handleMenuClick}>
        <MoreVertIcon fontSize="small" />
      </IconButton>
      <Menu
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem disabled={isRejected} onClick={handleRejectClick}>
          เพิ่มคอมเมนต์
        </MenuItem>
      </Menu>
      <RejectBox
        menuAnchorEl={menuAnchorEl}
        handleMenuClose={handleMenuClose}
        rejectMessage={rejectMessage}
        fetchProperty={fetchProperty}
        uuid={uuid}
        id={id}
        open={Boolean(rejectBoxAnchorEl)}
        handleClose={handleRejectBoxClose}
        anchorEl={rejectBoxAnchorEl}
      />
    </Box>
  );
}

RejectWrapper.defaultProps = {
  boxProps: null,
  rejectMessage: '',
  positionElement: null,
  editedFields: {},
  isEdited: false,
  fieldData: null,
};

RejectWrapper.propTypes = {
  positionElement: PropTypes.element,
  textElement: PropTypes.element.isRequired,
  boxProps: PropTypes.objectOf(PropTypes.string),
  isRejected: PropTypes.bool.isRequired,
  rejectMessage: PropTypes.string,
  fetchProperty: PropTypes.func.isRequired,
  uuid: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  editedFields: PropTypes.objectOf(PropTypes.any),
  isEdited: PropTypes.bool,
  fieldData: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default RejectWrapper;
