/* eslint-disable camelcase */
import Axios from 'axios';
import { pick } from 'lodash';
import { defaultInitialValues } from 'Components/WorkSubmitForm/shared/initialValues';
import { fieldNameBySection } from 'Constants';
import camelcaseKeys from 'camelcase-keys';

export function duplicateForm({ setInitialValues, setProperty, uuid }) {
  if (!uuid) return;
  Axios.get('/v1/properties/show', {
    params: {
      uuid,
    },
  }).then(response => {
    if (response.data.data) {
      const property = response.data.data.attributes;
      const {
        warehouseIsInsidePurpleZone,
        warehouseHasOperationPermit,
        warehouseTrailerAccessible,
      } = property;

      const customThirdStep = {
        warehouseIsInsidePurpleZone: String(warehouseIsInsidePurpleZone),
        warehouseHasOperationPermit: String(warehouseHasOperationPermit),
        warehouseTrailerAccessible: String(warehouseTrailerAccessible),
      };

      const customFourthStep = {
        selectedNearbyPlaces: camelcaseKeys(property.selectedNearbyPlaces, {
          deep: true,
        }),
      };

      setProperty(property);
      const settingValues = {
        ...pick(
          property,
          Object.values({ ...fieldNameBySection, fifthSection: [] }).flat()
        ),
        ...customThirdStep,
        ...customFourthStep,
      };
      console.log(settingValues);
      setInitialValues(settingValues);
    }
  });
}

export function rehydrateForm({
  uuid,
  setProperty,
  setInitialValues,
  setActiveStep,
  setSubmissionProgress,
}) {
  if (uuid === '') return;
  Axios.get('/v1/properties/show', { params: { uuid } }).then(response => {
    if (response.data.data) {
      const property = response.data.data.attributes;
      setProperty(property);
      setSubmissionProgress(property.submissionProgress);
      const { currentFormStep } = property;

      const firstStep = pick(property, fieldNameBySection.firstSection);
      const secondStep = pick(property, fieldNameBySection.secondSection);
      let thirdStep = pick(property, fieldNameBySection.thirdSection);

      thirdStep = {
        ...thirdStep,
        warehouseIsInsidePurpleZone: String(
          property.warehouseIsInsidePurpleZone
        ),
        warehouseHasOperationPermit: String(
          property.warehouseHasOperationPermit
        ),
        warehouseTrailerAccessible: String(property.warehouseTrailerAccessible),
      };

      const fourthStep = {
        ...pick(property, fieldNameBySection.fourthSection),
        selectedNearbyPlaces: camelcaseKeys(property.selectedNearbyPlaces, {
          deep: true,
        }),
      };
      const fifthStep = pick(property, 'videoLink');

      if (currentFormStep > 0) {
        setInitialValues({
          ...defaultInitialValues,
          ...firstStep,
        });
      }
      if (currentFormStep > 1) {
        setInitialValues({
          ...defaultInitialValues,
          ...firstStep,
          ...secondStep,
        });
      }
      if (currentFormStep > 2) {
        setInitialValues({
          ...defaultInitialValues,
          ...firstStep,
          ...secondStep,
          ...thirdStep,
        });
      }
      if (currentFormStep > 3) {
        setInitialValues({
          ...defaultInitialValues,
          ...firstStep,
          ...secondStep,
          ...thirdStep,
          ...fourthStep,
        });
      }
      if (currentFormStep > 4) {
        setInitialValues({
          ...defaultInitialValues,
          ...firstStep,
          ...secondStep,
          ...thirdStep,
          ...fourthStep,

          ...fifthStep,
        });
      }

      setActiveStep(currentFormStep === 5 ? 0 : currentFormStep);
    }
  });
}
