/* eslint-disable react/destructuring-assignment */
import { Table } from 'antd'
import React, { useState, useEffect } from 'react'
import Axios from 'axios'
import 'antd/dist/antd.css'
import './style.css'
import snakecaseKeys from 'snakecase-keys'
import { contractTypeMap, formatNumber, formatPhoneNumber } from 'Utils'
import moment from 'moment'
import PropTypes from 'prop-types'
import { Typography, makeStyles, Box, Tooltip, Link } from '@material-ui/core'
import { connect } from 'react-redux'
import { truncate } from 'lodash'
import { userPropsShape } from 'Constants'
import {
  sortDirectionWordMap,
  allSubmissionProgress,
  tableFields,
  showingColumns,
} from './constants'
import {
  dataIndexToSortName,
  filtersToParams,
  convertToFilterArray,
} from './functions'
import ExpiryBadge from './ExpiryBadge'
import TableSearch from './TableSearch'
import { propertyAcronyms } from '../constants'
import PropertyLink from './PropertyLink'
import PropertyStateChip from './PropertyStateChip'
import PropertyImage from './PropertyImage'
import EditableStateChip from './EditableStateChip'
import EditableFeaturedChip from './EditableFeaturedChip'
import EditableFeaturedTagChip from './EditableFeaturedTagChip'

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: theme.shape.borderRadius * 5,
  },
}))

const PropertyControlTable = React.memo(({ enabledFields, user }) => {
  const classes = useStyles()
  const [state, setState] = useState({
    data: [],
    pagination: {
      position: 'both',
      pageSize: 10,
      showSizeChanger: true,
      size: 'small',
      // eslint-disable-next-line no-use-before-define
      onShowSizeChange: handleShowSizeChange,
    },
    loading: true,
    filters: null,
  })

  function handleShowSizeChange(current, pageSize) {
    setState((prevState) => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
        current,
        pageSize,
      },
    }))
  }

  const fetch = (params = {}) => {
    setState((prevState) => ({ ...prevState, loading: true }))
    Axios.post(
      '/v1/properties',
      snakecaseKeys({
        submissionProgress: allSubmissionProgress,
        accessType: 'view',
        fields: tableFields,
        ...params,
      })
    ).then((response) => {
      const { headers } = response
      const total = Number(headers['total-count'])
      const current = Number(headers['current-page'])

      setState((prevState) => ({
        filters: response.data.filters,
        loading: false,
        data: response.data.data,
        pagination: {
          ...prevState.pagination,
          current,
          total,
        },
      }))
    })
  }

  const columns = [
    {
      title: 'ภาพ',
      id: 'propertyImage',
      dataIndex: ['attributes', 'firstImage'],
      width: '5%',
      render: (firstImage, record) => (
        <PropertyImage image={firstImage} uuid={record.attributes.uuid} />
      ),
    },
    {
      title: 'List No.',
      id: 'listNumber',
      dataIndex: ['attributes', 'listNumber'],
      sorter: true,
      width: '5%',
      render: (listNumber, record) => (
        <PropertyLink uuid={record.attributes.uuid}>{listNumber}</PropertyLink>
      ),
    },
    {
      title: 'สถานะ',
      id: 'propertyState',
      dataIndex: ['attributes', 'propertyState', 'data', 'attributes', 'name'],
      sorter: true,
      width: '5%',
      filters: convertToFilterArray(state.filters, 'propertyStates.name'),
      render: (propertyState, record) =>
        user.role !== 'manager' ? (
          <PropertyStateChip propertyState={propertyState} />
        ) : (
          <EditableStateChip
            propertyState={propertyState}
            id={record.attributes.propertyState.data.id}
          />
        ),
    },
    {
      title: 'Featured',
      id: 'featured',
      dataIndex: ['attributes', 'featured'],
      sorter: true,
      width: '5%',
      render: (featured, record) => (
        <EditableFeaturedChip
          featured={featured}
          uuid={record.attributes.uuid}
        />
      ),
    },
    {
      title: 'Featured Tag',
      id: 'featuredTag',
      dataIndex: ['attributes', 'featuredTag'],
      width: '5%',
      render: (featuredTag, record) => (
        <EditableFeaturedTagChip
          featuredTag={featuredTag}
          uuid={record.attributes.uuid}
        />
      ),
    },
    {
      title: 'ประเภททรัพย์',
      id: 'propertyType',
      dataIndex: ['attributes', 'propertyType'],
      width: '5%',
      // sorter: true,
      render: (propertyType) => propertyAcronyms[propertyType],
      filters: convertToFilterArray(state.filters, 'propertyType'),
    },
    {
      title: 'ประเภทสัญญา',
      id: 'contractType',
      dataIndex: ['attributes', 'contractType'],
      sorter: true,
      width: '5%',
      render: (contractType) => contractTypeMap[contractType],
      filters: convertToFilterArray(state.filters, 'contractType'),
    },
    {
      title: 'สัญญาคงเหลือ',
      id: 'dateLeft',
      sorter: true,
      width: '5%',
      dataIndex: ['attributes', 'dateExpired'],
      render: (_, record) => (
        <ExpiryBadge dateExpired={record.attributes.dateExpired} />
      ),
      filters: convertToFilterArray(state.filters, 'remainingDays'),
    },
    {
      title: 'เริ่มสัญญา',
      id: 'dateReceived',
      dataIndex: ['attributes', 'dateReceived'],
      sorter: true,
      width: '5%',
      render: (dateReceived) => moment(dateReceived).format('DD-MM-YYYY'),
    },
    {
      title: 'หมดอายุ',
      id: 'dateExpired',
      dataIndex: ['attributes', 'dateExpired'],
      sorter: true,
      width: '5%',
      render: (dateExpired) => moment(dateExpired).format('DD-MM-YYYY'),
    },
    {
      title: 'ชื่อทรัพย์',
      id: 'nickname',
      dataIndex: ['attributes', 'nickname'],
      render: (_, record) => {
        const { nickname } = record.attributes
        const ads = record.attributes.advertisements
        if (ads && ads.data[0] && ads.data[0].attributes.shortTitle) {
          return ads.data[0].attributes.shortTitle
        }
        return nickname
      },
    },
    {
      title: 'เขต/อำเภอ',
      id: 'propertyAmphoe',
      dataIndex: ['attributes', 'propertyAmphoe'],
      sorter: true,
    },
    {
      title: 'จังหวัด',
      id: 'propertyProvince',
      dataIndex: ['attributes', 'propertyProvince'],
      sorter: true,
      filters: convertToFilterArray(state.filters, 'propertyProvince'),
    },
    {
      title: (
        <Box>
          <Typography variant="subtitle2">เนื้อที่</Typography>
          <Typography variant="body2" color="textSecondary">
            ไร่-งาน-ตร.ว.
          </Typography>
        </Box>
      ),
      id: 'areaSize',
      dataIndex: ['attributes', 'areaSizeCombined'],
      align: 'right',
      render: (areaSize) => (
        <Typography
          variant="body2"
          style={{ fontWeight: 600, color: 'rgb(9, 49, 112)' }}
        >
          {areaSize}
        </Typography>
      ),
    },
    {
      title: 'ราคา',
      id: 'sellPrice',
      dataIndex: ['attributes', 'sellPrice'],
      sorter: true,
      align: 'right',
      render: (sellPrice) => (
        <Typography
          variant="body2"
          style={{ fontWeight: 600, color: 'rgb(9, 49, 112)' }}
        >
          {formatNumber(sellPrice)}
        </Typography>
      ),
    },
    {
      title: 'เจ้าหน้าที่',
      id: 'owner',
      dataIndex: [
        'attributes',
        'owner',
        'data',
        'attributes',
        'contactInfo',
        'data',
        'attributes',
        'name',
      ],

      render: (owner, record) => (
        <Tooltip title={record.attributes.owner.data.attributes.name}>
          <Typography variant="body2">{owner}</Typography>
        </Tooltip>
      ),
      filters: convertToFilterArray(state.filters, 'users.name'),
    },
    {
      title: 'เจ้าของทรัพย์',
      id: 'seller',
      dataIndex: ['attributes', 'sellerName'],

      render: (_, record) => (
        <Box>
          <Tooltip title={record.attributes.sellerName}>
            <Typography gutterBottom variant="body2">
              {truncate(record.attributes.sellerName, { length: 16 })}
            </Typography>
          </Tooltip>
          <Link
            href={`tel:${record.attributes.sellerPhone}`}
            variant="body2"
            style={{ fontWeight: 600 }}
          >
            {formatPhoneNumber(record.attributes.sellerPhone)}
          </Link>
        </Box>
      ),
    },
  ].filter(
    (column) =>
      showingColumns[user.role].includes(column.id) &&
      enabledFields.includes(column.id)
  )

  const handleTableChange = (pagination, filters, sorter) => {
    fetch({
      page: pagination.current,
      items: pagination.pageSize,
      sortBy: dataIndexToSortName(sorter.field),
      sortDirection: sortDirectionWordMap[sorter.order] || 'desc',
      ...filtersToParams(filters),
    })
  }

  useEffect(() => {
    fetch({
      page: 1,
      items: 10,
      sortBy: 'list_number',
      sortDirection: 'desc',
    })
  }, [])

  return (
    <Table
      bordered
      className={classes.root}
      title={() => <TableSearch fetch={fetch} />}
      columns={columns}
      rowKey={(record) => record.attributes.uuid}
      dataSource={state.data}
      pagination={state.pagination}
      loading={state.loading}
      onChange={handleTableChange}
      summary={() => (
        <tr>
          <th>Total</th>
          <td>
            <Typography variant="body2" style={{ fontWeight: 600 }}>
              {state.pagination.total}
            </Typography>
          </td>
        </tr>
      )}
    />
  )
})

PropertyControlTable.propTypes = {
  enabledFields: PropTypes.arrayOf(PropTypes.string).isRequired,
  user: userPropsShape.isRequired,
}

const mapStateToProps = (state) => ({
  user: state.user.user,
})

export default connect(mapStateToProps)(PropertyControlTable)
