import {
  SET_AREA_SIZE_WAA,
  SET_AREA_SIZE_RAI,
  SET_PROJECT_NAME,
  SET_SOI,
  SET_FEATURES,
  SET_PROVINCE,
  SET_FLOOR_COUNT,
  SET_BEDROOM_COUNT,
  SET_BATHROOM_COUNT,
  SET_TAMBON,
  SET_AMPHOE,
  SET_EXTERIOR_CONDITION,
  SET_NEARBY_PLACES,
  SET_SHOULD_FETCH_DATA,
  SET_LAT_LONG,
  SET_NEARBY_DATA_FETCHED,
  SET_FILES_TO_UPLOAD,
  SET_LAND_FRONT_WIDTH,
  SET_SELECTED_NEARBY_PLACES,
  SET_ROAD,
  SET_AREA_SIZE_NGAN,
  REHYDRATE,
  SET_USER,
  SET_LIST_NUMBER,
  SET_CONTRACT_TYPE,
  SET_PROGRAMME,
  SET_DATE_EXPIRED,
  SET_DATE_RECEIVED,
  SET_DATE_SUBMITTED,
  SET_REFER_FROM,
  SET_REFER_PERCENTAGE,
  SET_SELLER_NAME,
  SET_SELLER_ADDRESS_NUMBER,
  SET_SELLER_SOI,
  SET_SELLER_AMPHOE,
  SET_SELLER_TAMBON,
  SET_SELLER_PROVINCE,
  SET_SELLER_POST_CODE,
  SET_SELLER_ROAD,
  SET_SELLER_PHONE,
  SET_SELLER_LINE_ID,
  SET_COMMISSION_PERCENTAGE,
  SET_DEED_NUMBER,
  SET_PARCEL_NUMBER,
  SET_DEALING_FILE_NUMBER,
  SET_DEED_TAMBON,
  SET_DEED_AMPHOE,
  SET_DEED_PROVINCE,
  SET_REGION,
  SET_ZONE,
  SET_PROPERTY_STATUS,
  SET_PROPERTY_TYPE,
  SET_UTILITY_SPACE,
  SET_SELL_PRICE,
  SET_RENT_PRICE,
  SET_INTERIOR_CONDITION,
  SET_OTHER_ROOM_COUNT,
  SET_ADDITIONAL_EQUIPMENT,
  SET_ADDITIONAL_EQUIPMENT_STATE,
  SET_HEADING_DIRECTION,
  SET_LANDSCAPE_TYPE,
  SET_CONDO_BUILDING_NUMBER,
  SET_CONDO_LIVING_FLOOR,
  SET_CONDO_MAX_FLOOR_COUNT,
  SET_CONDO_PRICE_PER_SQM,
  SET_LAND_PRICE_PER_WAA,
  SET_LAND_ESTIMATED_PRICE_PER_WAA,
  SET_LAND_CONDITIONS,
  SET_LAND_OFFICIAL_ZONE_TYPE,
  SET_LAND_ROAD_CONDITION,
  SET_LAND_ROAD_LENGTH,
  SET_WAREHOUSE_HAS_OPERATION_PERMIT,
  SET_WAREHOUSE_IS_INSIDE_PURPLE_ZONE,
  SET_WAREHOUSE_MAXIMUM_BEAM_WEIGHT,
  SET_WAREHOUSE_MAXIMUM_WEIGHT_CAPACITY,
  SET_WAREHOUSE_TRAILER_ACCESSIBLE,
  SET_FEATURE_STATE,
  SET_LAND_CONDITION_STATE,
  SET_ADS_TITLE,
  SET_ADS_DETAILS,
  SET_ADS_NEARBY_PLACES,
  SET_ADS_DIRECTIONS,
  SET_LOADING,
  SET_ACTIVE_STEP,
  RESET_FORM,
  SET_DRAWER_OPEN,
} from '../constants/action-types';

export function resetForm() {
  return { type: RESET_FORM };
}
// FIRST SECTION
export function setListNumber(listNumber) {
  return { type: SET_LIST_NUMBER, listNumber };
}
export function setContractType(contractType) {
  return { type: SET_CONTRACT_TYPE, contractType };
}
export function setCommissionPercentage(commissionPercentage) {
  return { type: SET_COMMISSION_PERCENTAGE, commissionPercentage };
}
export function setProgramme(programme) {
  return { type: SET_PROGRAMME, programme };
}
export function setReferFrom(referFrom) {
  return { type: SET_REFER_FROM, referFrom };
}
export function setReferPercentage(referPercentage) {
  return { type: SET_REFER_PERCENTAGE, referPercentage };
}
export function setDateReceived(dateReceived) {
  return { type: SET_DATE_RECEIVED, dateReceived };
}
export function setDateExpired(dateExpired) {
  return { type: SET_DATE_EXPIRED, dateExpired };
}
export function setDateSubmitted(dateSubmitted) {
  return { type: SET_DATE_SUBMITTED, dateSubmitted };
}
export function setSellerName(sellerName) {
  return { type: SET_SELLER_NAME, sellerName };
}
export function setsellerInitialAddress(sellerInitialAddress) {
  return { type: SET_SELLER_ADDRESS_NUMBER, sellerInitialAddress };
}
export function setSellerSoi(sellerSoi) {
  return { type: SET_SELLER_SOI, sellerSoi };
}
export function setSellerRoad(sellerRoad) {
  return { type: SET_SELLER_ROAD, sellerRoad };
}
export function setSellerTambon(sellerTambon) {
  return { type: SET_SELLER_TAMBON, sellerTambon };
}
export function setSellerAmphoe(sellerAmphoe) {
  return { type: SET_SELLER_AMPHOE, sellerAmphoe };
}
export function setSellerProvince(sellerProvince) {
  return { type: SET_SELLER_PROVINCE, sellerProvince };
}
export function setSellerPostCode(sellerPostCode) {
  return { type: SET_SELLER_POST_CODE, sellerPostCode };
}
export function setSellerPhone(sellerPhone) {
  return { type: SET_SELLER_PHONE, sellerPhone };
}
export function setSellerLineId(sellerLineId) {
  return { type: SET_SELLER_LINE_ID, sellerLineId };
}

// SECOND SECTION

export function setDeedNumber(deedNumber) {
  return { type: SET_DEED_NUMBER, deedNumber };
}
export function setParcelNumber(parcelNumber) {
  return { type: SET_PARCEL_NUMBER, parcelNumber };
}
export function setDealingFileNumber(dealingFileNumber) {
  return { type: SET_DEALING_FILE_NUMBER, dealingFileNumber };
}
export function setDeedTambon(deedTambon) {
  return { type: SET_DEED_TAMBON, deedTambon };
}
export function setDeedAmphoe(deedAmphoe) {
  return { type: SET_DEED_AMPHOE, deedAmphoe };
}
export function setDeedProvince(deedProvince) {
  return { type: SET_DEED_PROVINCE, deedProvince };
}
export function setProjectName(projectName) {
  return { type: SET_PROJECT_NAME, projectName };
}
export function setLatLong(latLong) {
  return { type: SET_LAT_LONG, latLong };
}
export function setSoi(currentSoi) {
  return { type: SET_SOI, currentSoi };
}
export function setRoad(currentRoad) {
  return { type: SET_ROAD, currentRoad };
}
export function setTambon(currentTambon) {
  return { type: SET_TAMBON, currentTambon };
}
export function setAmphoe(currentAmphoe) {
  return { type: SET_AMPHOE, currentAmphoe };
}
export function setProvince(currentProvince) {
  return { type: SET_PROVINCE, currentProvince };
}
export function setRegion(currentRegion) {
  return { type: SET_REGION, currentRegion };
}
export function setZone(currentZone) {
  return { type: SET_ZONE, currentZone };
}

// THIRD SECTION
export function setPropertyStatus(propertyStatus) {
  return { type: SET_PROPERTY_STATUS, propertyStatus };
}
export function setPropertyType(propertyType) {
  return { type: SET_PROPERTY_TYPE, propertyType };
}
export function setUtilitySpace(utilitySpace) {
  return { type: SET_UTILITY_SPACE, utilitySpace };
}
export function setAreaSizeRai(areaSizeRai) {
  return { type: SET_AREA_SIZE_RAI, areaSizeRai };
}
export function setAreaSizeNgan(areaSizeNgan) {
  return { type: SET_AREA_SIZE_NGAN, areaSizeNgan };
}
export function setAreaSizeWaa(areaSizeWaa) {
  return { type: SET_AREA_SIZE_WAA, areaSizeWaa };
}
export function setSellPrice(sellPrice) {
  return { type: SET_SELL_PRICE, sellPrice };
}
export function setRentPrice(rentPrice) {
  return { type: SET_RENT_PRICE, rentPrice };
}
export function setExteriorCondition(exteriorCondition) {
  return { type: SET_EXTERIOR_CONDITION, exteriorCondition };
}
export function setInteriorCondition(interiorCondition) {
  return { type: SET_INTERIOR_CONDITION, interiorCondition };
}
export function setFloorCount(floorCount) {
  return { type: SET_FLOOR_COUNT, floorCount };
}
export function setBathroomCount(bathroomCount) {
  return { type: SET_BATHROOM_COUNT, bathroomCount };
}
export function setBedroomCount(bedroomCount) {
  return { type: SET_BEDROOM_COUNT, bedroomCount };
}
export function setOtherRoomCount(otherRoomCount) {
  return { type: SET_OTHER_ROOM_COUNT, otherRoomCount };
}
export function setAdditionalEquipment(additionalEquipment) {
  return { type: SET_ADDITIONAL_EQUIPMENT, additionalEquipment };
}
export function setAdditionalEquipmentState(additionalEquipmentState) {
  return { type: SET_ADDITIONAL_EQUIPMENT_STATE, additionalEquipmentState };
}
export function setFeatures(features) {
  return { type: SET_FEATURES, features };
}
export function setFeatureState(featureState) {
  return { type: SET_FEATURE_STATE, featureState };
}
export function setHeadingDirection(headingDirection) {
  return { type: SET_HEADING_DIRECTION, headingDirection };
}
export function setLandscapeType(landscapeType) {
  return { type: SET_LANDSCAPE_TYPE, landscapeType };
}
export function setCondoBuildingNumber(condoBuildingNumber) {
  return { type: SET_CONDO_BUILDING_NUMBER, condoBuildingNumber };
}
export function setCondoLivingFloor(condoLivingFloor) {
  return { type: SET_CONDO_LIVING_FLOOR, condoLivingFloor };
}
export function setCondoMaxFloorCount(condoMaxFloorCount) {
  return { type: SET_CONDO_MAX_FLOOR_COUNT, condoMaxFloorCount };
}
export function setCondoPricePerSQM(condoPricePerSQM) {
  return { type: SET_CONDO_PRICE_PER_SQM, condoPricePerSQM };
}
export function setLandPricePerWaa(landPricePerWaa) {
  return { type: SET_LAND_PRICE_PER_WAA, landPricePerWaa };
}
export function setLandEstimatedPricePerWaa(landEstimatedPricePerWaa) {
  return { type: SET_LAND_ESTIMATED_PRICE_PER_WAA, landEstimatedPricePerWaa };
}
export function setLandConditions(landConditions) {
  return { type: SET_LAND_CONDITIONS, landConditions };
}
export function setLandConditionState(landConditionState) {
  return { type: SET_LAND_CONDITION_STATE, landConditionState };
}
export function setlandFrontWidth(landFrontWidth) {
  return { type: SET_LAND_FRONT_WIDTH, landFrontWidth };
}
export function setLandOfficialZoneType(landOfficialZoneType) {
  return { type: SET_LAND_OFFICIAL_ZONE_TYPE, landOfficialZoneType };
}
export function setLandRoadCondition(landRoadCondition) {
  return { type: SET_LAND_ROAD_CONDITION, landRoadCondition };
}
export function setLandRoadLength(landRoadLength) {
  return { type: SET_LAND_ROAD_LENGTH, landRoadLength };
}
export function setWarehouseHasOperationPermit(warehouseHasOperationPermit) {
  return {
    type: SET_WAREHOUSE_HAS_OPERATION_PERMIT,
    warehouseHasOperationPermit,
  };
}
export function setWarehouseIsInsidePurpleZone(warehouseIsInsidePurpleZone) {
  return {
    type: SET_WAREHOUSE_IS_INSIDE_PURPLE_ZONE,
    warehouseIsInsidePurpleZone,
  };
}
export function setWarehouseMaximumBeamWeight(warehouseMaximumBeamWeight) {
  return {
    type: SET_WAREHOUSE_MAXIMUM_BEAM_WEIGHT,
    warehouseMaximumBeamWeight,
  };
}
export function setWarehouseMaximumWeightCapacity(
  warehouseMaximumWeightCapacity
) {
  return {
    type: SET_WAREHOUSE_MAXIMUM_WEIGHT_CAPACITY,
    warehouseMaximumWeightCapacity,
  };
}
export function setWarehouseTrailerAccessible(warehouseTrailerAccessible) {
  return {
    type: SET_WAREHOUSE_TRAILER_ACCESSIBLE,
    warehouseTrailerAccessible,
  };
}

// FOURTH SECTION
export function setAdsTitle(adsTitle) {
  return { type: SET_ADS_TITLE, adsTitle };
}
export function setAdsDetails(adsDetails) {
  return { type: SET_ADS_DETAILS, adsDetails };
}
export function setAdsNearbyPlaces(adsNearbyPlaces) {
  return { type: SET_ADS_NEARBY_PLACES, adsNearbyPlaces };
}
export function setAdsDirections(adsDirections) {
  return { type: SET_ADS_DIRECTIONS, adsDirections };
}

export function setNearbyPlaces(nearbyPlaces) {
  return { type: SET_NEARBY_PLACES, nearbyPlaces };
}
export function setShouldFetchData(shouldFetchData) {
  return { type: SET_SHOULD_FETCH_DATA, shouldFetchData };
}

export function setNearbyDataFetched(nearbyDataFetch) {
  return { type: SET_NEARBY_DATA_FETCHED, nearbyDataFetch };
}
export function setFilesToUpload(filesToUpload) {
  return { type: SET_FILES_TO_UPLOAD, filesToUpload };
}
export const setSelectedNearbyPlaces = selectedNearbyPlaces => ({
  type: SET_SELECTED_NEARBY_PLACES,
  selectedNearbyPlaces,
});

export const rehydrate = () => ({
  type: REHYDRATE,
});

export const setUser = user => ({
  type: SET_USER,
  user,
});

export function setLoading(loading) {
  return { type: SET_LOADING, loading };
}
export function setActiveStep(step) {
  return { type: SET_ACTIVE_STEP, step };
}

export const setDrawerOpen = drawerOpen => {
  return { type: SET_DRAWER_OPEN, drawerOpen };
};
