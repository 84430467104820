import { propertyAcronyms } from '../constants';

export const fetchingSubmissionProgress = [
  'draft',
  'sales_submitted',
  'sales_edited',
  'sales_editing',
  'admin_approved',
  'admin_editing',
  'admin_edited',
  'admin_rejected',
  'manager_editing',
  'manager_rejected',
  'completed',
];

export const sortDirectionWordMap = {
  ascend: 'asc',
  descend: 'desc',
};

export const allSubmissionProgress = [
  'sales_submitted',
  'sales_edited',
  'sales_editing',
  'admin_approved',
  'admin_editing',
  'admin_edited',
  'admin_rejected',
  'manager_editing',
  'manager_rejected',
  'completed',
];

export const filterValueMap = {
  'propertyStates.name': {
    pending: 'Pending',
    active: 'Active',
    expired: 'Expired',
    pledged: 'Pledged',
    suspended: 'Suspended',
    sold: 'Sold',
    passive: 'Passive',
    passive_sold: 'Passive Sold',
    passive_ads: 'Passive Ads',
  },
  propertyType: propertyAcronyms,
  contractType: {
    exClientNotSellable: 'Ex ขายเองไม่ได้',
    openNoCommission: 'Open',
    exSellable: 'Ex ขายเองได้',
  },
  'users.name': {},
  propertyProvince: {},
  remainingDays: {
    expired: 'หมดอายุ',
    one_month: '< 1 เดือน',
    three_months: '< 3 เดือน',
    six_months: '< 6 เดือน',
    six_months_plus: '> 6 เดือน',
  },
};

export const tableFields = [
  'uuid',
  'firstImage',
  'listNumber',
  'propertyState',
  'propertyType',
  'contractType',
  'dateExpired',
  'dateReceived',
  'advertisements',
  'propertyAmphoe',
  'propertyProvince',
  'areaSizeCombined',
  'sellPrice',
  'owner',
  'nickname',
  'sellerName',
  'sellerPhone',
  'featured',
  'featuredTag',
];

export const showingColumns = {
  manager: [
    'listNumber',
    'propertyState',
    'featured',
    'featuredTag',
    'propertyType',
    'contractType',
    'dateLeft',
    'dateReceived',
    'dateExpired',
    'nickname',
    'propertyAmphoe',
    'propertyProvince',
    'areaSize',
    'sellPrice',
    'owner',
  ],
  admin: [
    'listNumber',
    'propertyState',
    'propertyType',
    'contractType',
    'dateLeft',
    'dateReceived',
    'dateExpired',
    'nickname',
    'propertyAmphoe',
    'propertyProvince',
    'areaSize',
    'sellPrice',
    'owner',
  ],
  sales: [
    'propertyImage',
    'propertyState',
    'propertyType',
    'contractType',
    'dateLeft',
    'dateExpired',
    'nickname',
    'propertyAmphoe',
    'propertyProvince',
    'areaSize',
    'sellPrice',
    'seller', // Seller name & phone
  ],
};
