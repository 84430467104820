import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, darken } from '@material-ui/core';
import { capitalize, startCase } from 'lodash';
import { lightGreen, green, orange, red, blue } from '@material-ui/core/colors';

const bgcolorMap = {
  active: blue[50],
  passive: blue[50],
  passive_sold: blue[50],
  passive_ads: blue[50],
  pending: orange[50],
  expired: red[50],
  suspended: red[50],
  pledged: green[50],
  sold: green[50],
};

const textColorMap = {
  active: darken(blue[900], 0.3),
  passive: darken(blue[900], 0.3),
  passive_sold: darken(blue[900], 0.3),
  passive_ads: darken(blue[900], 0.3),
  pending: darken(orange[900], 0.5),
  expired: darken(red[900], 0.3),
  suspended: darken(red[900], 0.3),
  pledged: darken(lightGreen[900], 0.3),
  sold: darken(lightGreen[900], 0.3),
};

const PropertyStateChip = ({ propertyState }) => {
  return (
    <Box
      width="min-content"
      borderRadius={16}
      px={0.8}
      py={0.3}
      bgcolor={bgcolorMap[propertyState]}
    >
      <Typography
        variant="body2"
        style={{
          color: textColorMap[propertyState],
          fontSize: '0.8rem',
          fontWeight: 600,
        }}
      >
        {capitalize(startCase(propertyState))}
      </Typography>
    </Box>
  );
};

PropertyStateChip.propTypes = {
  propertyState: PropTypes.oneOf([
    'active',
    'pending',
    'expired',
    'passive',
    'passive_sold',
    'suspended',
    'pledged',
    'sold',
  ]).isRequired,
};

export default PropertyStateChip;
