import { hot } from 'react-hot-loader/root';
import React, { Fragment } from 'react';
import { responsiveFontSizes } from '@material-ui/core/styles';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import thLocale from 'date-fns/locale/th';
import { SnackbarProvider } from 'notistack';
import LinearLoading, {
  setAuthTokenHeaders,
  setApplicationJSONHeader,
  setDefaultAxiosUrl,
} from 'Services';
import SalesDashboard from 'Components/control/sales/Dashboard';
import ProtectedRoute from 'Components/ProtectedRoute';
import Login from 'Components/auth/Login';
import Register from 'Components/auth/Register';
import AdminDashboard from 'Components/control/admin/Dashboard';
import ManagerDashboard from 'Components/control/manager/Dashboard';
import {
  CssBaseline,
  createMuiTheme,
  MuiThemeProvider,
} from '@material-ui/core';
import PropertyShow from 'Components/general/PropertyShow';
import NotFoundPage from 'Components/pages/NotFoundPage';
import moment from 'moment';
import 'moment/locale/th';
import './App.css';
import { HelmetProvider, Helmet } from 'react-helmet-async';

moment.locale('th');

let theme = createMuiTheme({
  typography: {
    fontFamily: [
      '"Noto Sans Thai UI"',
      'Noto Sans',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  backUpShadow:
    '0px 1px 3px 0px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 2px 1px -1px rgba(0,0,0,0.12)',
  shadows: [
    'none',
    '0 1px 4px rgba(0,21,41,.08)',
    '0 1px 4px rgba(0,21,41,.18)',
    '0px 1px 8px 0px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 3px 3px -2px rgba(0,0,0,0.12)',
    '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
    '0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)',
    '0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)',
    '0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)',
    '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',
    '0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)',
    '0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)',
    '0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)',
    '0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)',
    '0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)',
    '0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)',
    '0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)',
    '0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)',
    '0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)',
    '0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)',
    '0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)',
    '0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)',
    '0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)',
    '0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)',
    '0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)',
    '0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)',
  ],

  shape: {
    borderRadius: 4,
  },
  palette: {
    text: {
      primary: '#042340',
    },
    background: {
      default: 'hsl(220, 20%, 95%)',
    },
    primary: {
      main: '#042340',
    },
    shades: {
      yellow: {
        main: 'rgb(244, 197, 66)',
      },
      grey: {
        lightest: '#C3CED6',
        lighter: 'hsl(203, 15%, 60%)',
        light: 'hsl(203,15%,47%)',
        medium: 'hsl(201,23%,34%)',
      },
      blue: {
        light: '#4D93D5',
        medium: '#0C6FCC',
        dark: '#095399',
        darker: '#1C354D',
      },
    },
    secondary: {
      main: '#0D76D9',
    },
  },
});

theme = responsiveFontSizes(theme);

setAuthTokenHeaders();
setApplicationJSONHeader();
setDefaultAxiosUrl();

function App() {
  return (
    <Fragment>
      <LinearLoading />
      <Router>
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={thLocale}>
            <SnackbarProvider maxSnack={1}>
              <HelmetProvider>
                <Helmet>
                  <title>AssetBeyond</title>
                  <meta name="description" content="นายหน้าอสังหาฯ มืออาชีพ" />
                  <meta name="og:title" content="Asset Beyond" />
                  <meta
                    name="og:description"
                    content="นายหน้าอสังหาฯ มืออาชีพ"
                  />
                  <meta
                    name="og:url"
                    content="https://client.assetbeyond.com/"
                  />
                </Helmet>
                <Switch>
                  <Route path="/" component={Login} exact></Route>
                  <ProtectedRoute
                    userRole="sales"
                    path="/sales"
                    TrueComponent={SalesDashboard}
                    redirectPath="/login"
                  />
                  <ProtectedRoute
                    userRole="admin"
                    path="/admin"
                    redirectPath="/login"
                    TrueComponent={AdminDashboard}
                  />
                  <ProtectedRoute
                    userRole="manager"
                    path="/manager"
                    redirectPath="/login"
                    TrueComponent={ManagerDashboard}
                  />
                  <Route path="/login" component={Login} />
                  <Route path="/register" component={Register} />
                  <Route path="/property" component={PropertyShow} />
                  <Route component={NotFoundPage} />
                </Switch>
              </HelmetProvider>
              {/* <Route path="/property/:id" component={Property} /> */}
            </SnackbarProvider>
          </MuiPickersUtilsProvider>
        </MuiThemeProvider>
      </Router>
    </Fragment>
  );
}
// export default App

export default process.env.NODE_ENV === 'development' ? hot(App) : App;
