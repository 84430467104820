import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, makeStyles, darken } from '@material-ui/core';
import { green, blue, orange, red, grey } from '@material-ui/core/colors';
import UuidEncoder from 'uuid-encoder';
import StatusIcon from './StatusIcon';

const encoder = new UuidEncoder('base64url');

const propertyLink =
  process.env.NODE_ENV === 'production'
    ? 'https://www.assetbeyond.com/property/'
    : 'https://www.assetbeyond.com/property/';

const textColorMap = {
  neutral: grey[900],
  complete: green[900],
  pending: orange[900],
  working: blue[900],
  alert: red[900],
};

const backgroundColorMap = {
  neutral: grey[200],
  complete: green[50],
  pending: orange[50],
  working: blue[50],
  alert: red[50],
};

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    maxWidth: '16em',
    [theme.breakpoints.up('sm')]: {
      width: '35em',
    },
  },
  text: {
    fontWeight: 400,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
}));

const ContentHeader = ({
  listNumber,
  nickname,
  submissionProgressType,
  thSubmissionProgress,
  uuid,
  submissionProgress,
}) => {
  const classes = useStyles();
  const encodedUuid = encoder.encode(uuid);

  return (
    <Box>
      <Box
        mb={1}
        py={1}
        pr={1}
        m={-2}
        ml={{ xs: -1.5, sm: -2 }}
        justifyContent="space-between"
        alignItems="center"
        display="flex"
      >
        <Box ml={2}>
          <Typography
            component={submissionProgress === 'completed' ? 'a' : 'p'}
            href={
              submissionProgress === 'completed'
                ? `${propertyLink}${encodedUuid}`
                : null
            }
            target="_blank"
            variant="subtitle1"
            style={{ fontWeight: 500 }}
          >
            {listNumber}
          </Typography>
        </Box>
        <Box
          mr={1}
          bgcolor={backgroundColorMap[submissionProgressType]}
          px={1}
          p={0.5}
          pl={0.5}
          display="flex"
          alignItems="center"
          borderRadius={8}
        >
          <StatusIcon statusType={submissionProgressType} />
          <Typography
            style={{
              color: darken(textColorMap[submissionProgressType], 0.3),
              fontWeight: 500,
              fontSize: '0.8rem',
            }}
            variant="caption"
          >
            {thSubmissionProgress}
          </Typography>
        </Box>
      </Box>
      <Box className={classes.wrapper}>
        <Typography
          className={classes.text}
          variant="subtitle2"
          color="textSecondary"
        >
          {nickname}
        </Typography>
      </Box>
    </Box>
  );
};

ContentHeader.propTypes = {
  submissionProgress: PropTypes.string.isRequired,
  listNumber: PropTypes.string.isRequired,
  nickname: PropTypes.string.isRequired,
  submissionProgressType: PropTypes.string.isRequired,
  thSubmissionProgress: PropTypes.string.isRequired,
  uuid: PropTypes.string.isRequired,
};

export default ContentHeader;
