import React from 'react';
import { Grid } from '@material-ui/core';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  grid: {
    padding: 0,
    [theme.breakpoints.up('xl')]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
  },
}));

const ContentWrapper = props => {
  const { dense, children } = props;
  const classes = useStyles();
  const theme = useTheme();
  return (
    <Grid
      style={{
        padding: theme.spacing(1),
        marginTop: dense ? theme.spacing(10) : theme.spacing(0),
        marginBottom: theme.spacing(8),
      }}
      justify="center"
      container
    >
      {dense ? (
        <Grid className={classes.grid} item xs={12} sm={6} md={5} lg={3} xl={3}>
          {children}
        </Grid>
      ) : (
        <Grid item xs={12} sm={10} md={7} lg={6} xl={6}>
          {children}
        </Grid>
      )}
    </Grid>
  );
};

ContentWrapper.defaultProps = {
  dense: false,
};

ContentWrapper.propTypes = {
  children: PropTypes.element.isRequired,
  dense: PropTypes.bool,
};

export default ContentWrapper;
