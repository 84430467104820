import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { Helmet } from 'react-helmet-async';
import { Box } from '@material-ui/core';
import {
  Error,
  ErrorOutline,
  WatchLater,
  AccessTimeOutlined,
} from '@material-ui/icons';
import PropertyByTabs from '../PropertyByTabs';
import PropertyCardList from '../PropertyList/PropertyCardList';

const getTabList = role => {
  switch (role) {
    case 'admin':
      return [
        {
          id: 1,
          label: 'ที่ต้องดำเนินการ',
          icon: <Error />,
          iconOutlined: <ErrorOutline />,
          submissionProgress: [
            'sales_submitted',
            'sales_edited',
            'manager_rejected',
            'admin_editing',
          ],
        },
        {
          id: 2,
          label: 'รอการดำเนินการ',
          icon: <WatchLater />,
          iconOutlined: <AccessTimeOutlined />,
          submissionProgress: [
            'sales_editing',
            'admin_edited',
            'admin_rejected',
            'admin_approved',
          ],
        },
      ];
    case 'manager':
      return [
        {
          id: 1,
          label: 'ที่ต้องดำเนินการ',
          icon: <Error />,
          iconOutlined: <ErrorOutline />,
          submissionProgress: [
            'admin_edited',
            'admin_approved',
            'manager_editing',
          ],
        },
        {
          id: 2,
          label: 'รอการดำเนินการ',
          icon: <WatchLater />,
          iconOutlined: <AccessTimeOutlined />,
          submissionProgress: [
            'sales_submitted',
            'sales_editing',
            'sales_edited',
            'admin_rejected',
            'admin_editing',
            'manager_rejected',
          ],
        },
      ];
    default:
      return {};
  }
};

function PropertyControl({ user, location, history }) {
  const values = queryString.parse(location.search);
  const userRole = user.role;
  const tabList = getTabList(userRole);
  const tabNumberFromUrl =
    Number(values.tab) > tabList.length - 1 ? 0 : Number(values.tab);

  return (
    <Box mx={{ xl: 25 }}>
      <Helmet>
        <title>จัดการทรัพย์ - AssetBeyond</title>
      </Helmet>
      <Box width="100%" borderRadius={4} mx="auto">
        <PropertyByTabs
          tabList={tabList}
          tabNumberFromUrl={tabNumberFromUrl}
          onTabChange={val =>
            history.push({
              pathname: `/${userRole}/property-control`,
              search: `?tab=${val}`,
            })
          }
        >
          {currentTab => (
            <PropertyCardList
              controlMode
              currentTab={currentTab}
              userRole={userRole}
            />
          )}
        </PropertyByTabs>
      </Box>
    </Box>
  );
}

const mapStateToProps = state => ({
  user: state.user.user,
});

PropertyControl.propTypes = {
  user: PropTypes.objectOf(PropTypes.string).isRequired,
  location: PropTypes.shape({
    hash: PropTypes.string,
    key: PropTypes.string,
    pathname: PropTypes.string,
    search: PropTypes.string,
    state: PropTypes.string,
  }).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default connect(mapStateToProps)(PropertyControl);
