import React from 'react';
import { SvgIcon, Box, Typography, makeStyles, Grid } from '@material-ui/core';
import { mdiShower } from '@mdi/js';
import { Hotel } from '@material-ui/icons';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  chipLabel: {
    fontWeight: 400,
    color: 'hsla(210, 80%, 34%, 0.81)',
  },
  chipIcon: {
    marginLeft: theme.spacing(1),
    verticalAlign: 'baseline',
    color: 'hsla(210, 80%, 34%, 0.51)',
  },
  unit: {
    color: 'hsla(210, 80%, 34%, 0.71)',
  },
  container: {
    borderRadius: theme.shape.borderRadius * 2,
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    paddingBottom: 0,
  },
  value: {
    whiteSpace: 'nowrap',
    color: 'hsla(210, 80%, 25%, 1)',
  },
  item: {
    borderRadius: theme.shape.borderRadius,
    background: 'hsla(210, 80%, 98%, 1)',
    padding: theme.spacing(2),
  },
}));

const chipList = ({ property }) => {
  const {
    bedroomCount,
    bathroomCount,
    utilitySpace,
    areaSizeRai,
    areaSizeNgan,
    areaSizeWaa,
    condoLivingFloor,
    condoMaxFloorCount,
    landFrontWidth,
    landRoadLength,
  } = property;

  return [
    {
      name: 'bedroomCount',
      value: bedroomCount,
      icon: <Hotel />,
      label: 'ห้องนอน',
    },
    {
      name: 'bathroomCount',
      value: bathroomCount,
      icon: (
        <SvgIcon>
          <path d={mdiShower} />
        </SvgIcon>
      ),
      label: 'ห้องน้ำ',
    },
    {
      name: 'utilitySpace',
      value: `${utilitySpace}`,
      label: 'พื้นที่ใช้สอย',
      unit: 'ตร.ม.',
      disabled: !utilitySpace,
    },
    {
      name: 'areaSizeTh',
      value: `${areaSizeRai}-${areaSizeNgan}-${areaSizeWaa}`,
      label: 'ขนาดที่ดิน',
      unit: 'ไร่',
      disabled: areaSizeRai === 0 && areaSizeNgan === 0 && areaSizeWaa === 0,
    },
    {
      name: 'condoLivingFloor',
      value: condoLivingFloor,
      label: 'อยู่ชั้นที่',
    },
    {
      name: 'condoMaxFloorCount',
      value: condoMaxFloorCount,
      label: 'ชั้นทั้งหมด',
    },
    {
      name: 'landFrontWidth',
      value: landFrontWidth,
      label: 'หน้ากว้าง',
    },
    {
      name: 'landRoadLength',
      value: landRoadLength,
      label: 'ถนนกว้าง',
      unit: 'เมตร',
    },
  ];
};

const PropertyDetailChipList = ({ property }) => {
  const classes = useStyles();
  const notEmpty = chip => {
    return chip.value !== 0 && chip.value && !chip.disabled;
  };

  return (
    <Grid className={classes.container} container spacing={1}>
      {chipList({ property })
        .filter(notEmpty)
        .map(chip => (
          <Grid item xs={4} sm={3} md={3} key={chip.name}>
            <Box className={classes.item}>
              <Typography
                className={classes.chipLabel}
                component="span"
                variant="subtitle2"
              >
                {chip.label}
              </Typography>
              <Box
                display="flex"
                mt={1}
                justifyContent="baseline"
                alignItems="baseline"
              >
                <Box display="flex" alignItems="center">
                  <Typography
                    className={classes.value}
                    style={{ fontWeight: 500 }}
                    variant="h6"
                  >
                    {chip.value}
                  </Typography>
                  {chip.icon &&
                    React.cloneElement(chip.icon, {
                      fontSize: 'small',
                      className: classes.chipIcon,
                    })}
                </Box>
                <Box ml={1}>
                  <Typography
                    className={classes.unit}
                    style={{ fontWeight: 500 }}
                    variant="body1"
                  >
                    {chip.unit}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
        ))}
    </Grid>
  );
};

PropertyDetailChipList.propTypes = {
  property: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default PropertyDetailChipList;
