import React from 'react';
import PropTypes from 'prop-types';
import { Grid, useTheme, useMediaQuery } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

const ImageSkeletons = ({ fileCount }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'));
  return fileCount > 0 ? (
    <>
      {[...Array(fileCount).keys()].map(el => (
        <Grid
          key={el}
          item
          style={{ margin: isMobile ? theme.spacing(0.5) : theme.spacing(1) }}
        >
          <Skeleton variant="rect" width={100} height={100} />
        </Grid>
      ))}
    </>
  ) : null;
};

ImageSkeletons.propTypes = {
  fileCount: PropTypes.number.isRequired,
};

export default ImageSkeletons;
