import React, { useState, useRef, useEffect } from 'react';
import {
  Avatar,
  makeStyles,
  Popper,
  Typography,
  Paper,
  Box,
  Fade,
} from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  avatar: {
    background: theme.palette.secondary.main,
    width: 30,
    height: 30,
  },
  hover: {
    height: 80,
    width: 80,
  },
  marker: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    userSelect: 'none',
    transform: 'translate(-50%, -50%)',
  },
  paper: {
    maxWidth: 200,
  },
}));

const Marker = ({ icon, color, $hover, name, distance }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const markerRef = useRef(null);

  useEffect(() => {
    if ($hover) {
      setAnchorEl(markerRef.current);
    } else {
      setAnchorEl(null);
    }
  }, [$hover]);

  return (
    <>
      <Avatar
        innerRef={markerRef}
        className={clsx(classes.marker, classes.avatar, classes[color])}
      >
        {icon}
      </Avatar>

      <Popper
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        placement="top"
        transition
        modifiers={{
          flip: {
            enabled: true,
          },
          preventOverflow: {
            enabled: true,
            boundariesElement: 'scrollParent',
          },
        }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper className={classes.paper} elevation={6}>
              <Box p={1}>
                <Typography style={{ fontWeight: 500 }} variant="body2">
                  {name}
                </Typography>
                <Typography color="textSecondary" variant="caption">
                  {distance}
                </Typography>
              </Box>
            </Paper>
          </Fade>
        )}
      </Popper>
    </>
  );
};

Marker.defaultProps = {
  main: false,
  $hover: false,
  distance: '',
  color: '',
};

Marker.propTypes = {
  icon: PropTypes.element.isRequired,
  color: PropTypes.string,
  main: PropTypes.bool,
  $hover: PropTypes.bool,
  name: PropTypes.string.isRequired,
  distance: PropTypes.string,
};

export default Marker;
