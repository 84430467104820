import React from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { Box } from "@material-ui/core";
import PropertyControlMenu from "./PropertyControlMenu";
import AdvertisementControlMenu from "./AdvertisementControlMenu";
import CreateAdvertisementButton from "./CreateAdvertisementButton";

const PropertyCardMenu = ({
  userRole,
  uuid,
  submissionProgress,
  listNumber,
  refreshProperties,
  currentTab
}) => {
  const location = useLocation();
  return (
    <Box width="100%" display="flex" justifyContent="space-between">
      <PropertyControlMenu
        userRole={userRole}
        uuid={uuid}
        submissionProgress={submissionProgress}
        listNumber={listNumber}
        refreshProperties={refreshProperties}
      />

      {location.pathname.includes("complete-properties") &&
        [1, 2, 3, 4].includes(currentTab) && (
          <AdvertisementControlMenu userRole={userRole} uuid={uuid} />
        )}

      {location.pathname.includes("complete-properties") &&
        currentTab === 0 && (
          <CreateAdvertisementButton userRole={userRole} uuid={uuid} />
        )}
    </Box>
  );
};

PropertyCardMenu.propTypes = {
  userRole: PropTypes.string.isRequired,
  uuid: PropTypes.string.isRequired,
  listNumber: PropTypes.string.isRequired,
  submissionProgress: PropTypes.string.isRequired,
  refreshProperties: PropTypes.func.isRequired,
  currentTab: PropTypes.number.isRequired
};

export default PropertyCardMenu;
