import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'Constants';
import { DialogContent, Box, Typography, makeStyles } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import SelfEditSelection from './SelfEditSelection';

const useStyles = makeStyles(theme => ({
  content: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
}));

const Content = ({
  editRedirect,
  setEditRedirect,
  rejectedFieldsCount,
  rejectedFields,
  userRole,
}) => {
  const classes = useStyles();
  return (
    <DialogContent className={classes.content}>
      {rejectedFieldsCount > 0 ? (
        <Box mb={6}>
          <Box ml={1}>
            <Typography paragraph>รายการที่คุณ Reject</Typography>
          </Box>
          {Object.keys(rejectedFields).map(field => (
            <Box borderRadius={4} bgcolor={red[50]} p={1} key={field} my={2}>
              <Typography color="textPrimary" variant="caption">
                {t[field]}
              </Typography>
              <Typography style={{ color: 'rgb(94,94,94)' }}>
                {rejectedFields[field]}
              </Typography>
            </Box>
          ))}
        </Box>
      ) : (
        <Typography paragraph>
          คุณได้ตรวจสอบรายละเอียดทรัพย์แล้ว ไม่มีการ Reject
        </Typography>
      )}
      <SelfEditSelection
        editRedirect={editRedirect}
        setEditRedirect={setEditRedirect}
        rejectedFieldsCount={rejectedFieldsCount}
        userRole={userRole}
      />
    </DialogContent>
  );
};

Content.propTypes = {
  editRedirect: PropTypes.oneOf(['true', 'false']).isRequired,
  setEditRedirect: PropTypes.func.isRequired,
  userRole: PropTypes.string.isRequired,
  rejectedFieldsCount: PropTypes.number.isRequired,
  rejectedFields: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default Content;
