import {
  FormControl,
  FormLabel,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import { connect, Field } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { RadioGroup } from 'formik-material-ui';
import CommentBox from 'Components/shared/CommentBox';

const useStyles = makeStyles(theme => ({
  formControl: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2.5),
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: theme.shape.borderRadius,
    boxSizing: 'border-box',
  },
}));

const RadioGroupFieldSet = connect(props => {
  const classes = useStyles();
  const { name, label, options, formik } = props;
  const { isSubmitting } = formik;
  return (
    <>
      <FormControl
        fullWidth
        component="fieldset"
        className={classes.formControl}
      >
        <FormLabel component="legend">{label}</FormLabel>
        <Field name={name} label={label} component={RadioGroup}>
          {options.map(option => (
            <FormControlLabel
              key={option.value}
              value={option.value}
              control={<Radio disabled={isSubmitting} />}
              label={option.label}
              disabled={isSubmitting}
            />
          ))}
        </Field>
      </FormControl>
      <CommentBox name={name} />
    </>
  );
});

export default RadioGroupFieldSet;
