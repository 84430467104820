import React from 'react';
import {
  Popper,
  Paper,
  ListItemText,
  ListItem,
  ClickAwayListener,
  List,
  Box,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import Skeleton from '@material-ui/lab/Skeleton';

function SearchFieldResult(props) {
  const {
    handleSelect,
    filteredAddress,
    anchorEl,
    handleClose,
    loading,
    searchInput,
  } = props;

  const getContent = () => {
    if (loading) {
      return (
        <>
          <Box ml={2} mt={3} mb={2}>
            <Skeleton height={15} width="30%" variant="text" />
            <Skeleton height={15} width="50%" />
          </Box>
          <Box ml={2} mt={3} mb={2}>
            <Skeleton height={15} width="30%" variant="text" />
            <Skeleton height={15} width="50%" />
          </Box>
        </>
      );
    }
    if (!loading && filteredAddress.length > 0) {
      return (
        <List>
          {filteredAddress.map(address => (
            <ListItem
              button
              key={address.id}
              onClick={e => handleSelect(e, address)}
              value={address.tambon}
            >
              <ListItemText
                primary={address.tambon}
                secondary={`${address.amphoe} > ${address.province} > ${address.post_code}`}
              />
            </ListItem>
          ))}
        </List>
      );
    }
    if (!loading && filteredAddress.length === 0) {
      return (
        <Box p={2}>
          <Typography color="textSecondary" variant="body2" align="center">
            ไม่พบที่อยู่
          </Typography>
        </Box>
      );
    }
  };

  return (
    anchorEl && (
      <Popper
        modifiers={{
          flip: { enabled: false },
        }}
        style={{ zIndex: 3 }}
        anchorEl={anchorEl}
        open={searchInput.length > 0}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <Paper
            elevation={6}
            style={{
              maxHeight: 250,
              overflowY: 'auto',
              width: anchorEl.offsetWidth,
            }}
          >
            {getContent()}
          </Paper>
        </ClickAwayListener>
      </Popper>
    )
  );
}

SearchFieldResult.defaultProps = {
  anchorEl: null,
};

SearchFieldResult.propTypes = {
  handleSelect: PropTypes.func.isRequired,
  filteredAddress: PropTypes.arrayOf(
    PropTypes.shape({
      tambon: PropTypes.string,
      post_code: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      amphoe: PropTypes.string,
    })
  ).isRequired,
  anchorEl: PropTypes.instanceOf(Element),
  loading: PropTypes.bool.isRequired,
};

export default SearchFieldResult;
