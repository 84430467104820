import React, { useState, Fragment } from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Button,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Logout } from 'Services';
import HideOnScroll from 'Components/shared/HideOnScroll';
import { connect } from 'react-redux';
import { setDrawerOpen } from 'JS/actions';
import { ExitToApp } from '@material-ui/icons';
import useStyles from './DashboardAppBar_Style';

// eslint-disable-next-line no-shadow
function DashboardAppBar({ drawerOpen, setDrawerOpen, children }) {
  const [initLogout, setInitLogout] = useState(false);
  const classes = useStyles();
  return (
    <Fragment>
      <HideOnScroll>
        <AppBar
          elevation={1}
          color="inherit"
          position="fixed"
          className={clsx(classes.appBar, drawerOpen && classes.appBarShift)}
        >
          <Toolbar className={classes.toolbar}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="Open drawer"
              onClick={() => setDrawerOpen(true)}
              className={clsx(
                classes.menuButton,
                drawerOpen && classes.menuButtonHidden
              )}
            >
              <MenuIcon />
            </IconButton>

            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              className={classes.title}
            >
              {children}
            </Typography>
            <Button
              title="ออกจากระบบ"
              color="inherit"
              onClick={() => setInitLogout(true)}
              endIcon={<ExitToApp />}
            >
              ออกจากระบบ
            </Button>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      <Logout initLogout={initLogout} setInitLogout={setInitLogout} />
    </Fragment>
  );
}

DashboardAppBar.propTypes = {
  setDrawerOpen: PropTypes.func.isRequired,
  drawerOpen: PropTypes.bool.isRequired,
  children: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  drawerOpen: state.drawer.drawerOpen,
});

const mapDispatchToProps = {
  setDrawerOpen,
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardAppBar);
