/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Box, makeStyles, Grid } from '@material-ui/core';
import {
  ArrowRight,
  Train,
  School,
  LocalHospital,
  LocalMall,
  BusinessCenter,
} from '@material-ui/icons';
import clsx from 'clsx';
import { lighten } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
    margin: theme.spacing(4),
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(6),
    },
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  categoryText: {
    fontWeight: 500,
    marginBottom: theme.spacing(2),
    paddingTop: 0,
    paddingBottom: 0,
    borderRadius: theme.shape.borderRadius * 2,
  },
  headerIcon: {
    verticalAlign: 'baseline',
  },
  headerWrapper: {
    display: 'flex',
    paddingBottom: theme.spacing(3),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  withBackground: {
    background: `${lighten(theme.palette.secondary.main, 0.95)}`,
  },
}));

const iconMap = {
  schoolList: <School />,
  hospitalList: <LocalHospital />,
  universityList: <School />,
  shoppingList: <LocalMall />,
  governmentBuildingList: <BusinessCenter />,
  trainStationList: <Train />,
};

const placeCategoryMap = {
  trainStationList: 'รถไฟฟ้า',
  schoolList: 'โรงเรียน',
  hospitalList: 'โรงพยาบาล',
  universityList: 'มหาวิทยาลัย',
  shoppingList: 'ช้อปปิ้ง',
  governmentBuildingList: 'สถานที่ราชการ',
};

const PropertyShowNearbyPlaces = ({ nearbyPlaces }) => {
  const classes = useStyles();

  const orderedNearbyPlaces = {
    trainStationList: nearbyPlaces.train_station_list,
    shoppingList: [
      ...nearbyPlaces.department_store_list,
      ...nearbyPlaces.supermarket_list,
    ],
    universityList: nearbyPlaces.university_list,
    hospitalList: nearbyPlaces.hospital_list,
    governmentBuildingList: nearbyPlaces.government_building_list,
    schoolList: nearbyPlaces.school_list,
  };

  return Object.values(nearbyPlaces).find(item => item.length > 1) ? (
    <section className={classes.root}>
      <Grid container spacing={6}>
        {Object.keys(orderedNearbyPlaces)
          .filter(key => orderedNearbyPlaces[key].length > 0)
          .map((category, index) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              key={category}
              className={clsx(index % 2 === 0 && classes.withBackground)}
            >
              {orderedNearbyPlaces[category].length > 0 && (
                <>
                  <Box mb={4}>
                    <Box mb={1}>
                      {React.cloneElement(iconMap[category], {
                        fontSize: 'small',
                        color: 'secondary',
                      })}
                    </Box>
                    <Typography className={classes.categoryText} gutterBottom>
                      {placeCategoryMap[category]}
                    </Typography>
                  </Box>
                  {orderedNearbyPlaces[category].map(place => (
                    <Box
                      key={place.name}
                      display="flex"
                      alignItems="flex-start"
                      my={1.5}
                    >
                      <ArrowRight
                        fontSize="small"
                        color="secondary"
                        className={classes.icon}
                      />
                      <Typography
                        variant="body1"
                        style={{ color: 'rgb(72,72,72)' }}
                      >
                        {place.name}
                      </Typography>
                    </Box>
                  ))}
                </>
              )}
            </Grid>
          ))}
      </Grid>
    </section>
  ) : (
    <div />
  );
};

PropertyShowNearbyPlaces.propTypes = {
  nearbyPlaces: PropTypes.objectOf(PropTypes.array).isRequired,
};

export default PropertyShowNearbyPlaces;
