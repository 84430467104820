/* eslint camelcase: 0 */
import { sample } from 'lodash';

export const defaultInitialValues = {
  listNumber: '',
  nickname: '',
  contractType: '',
  commissionPercentage: '',
  programme: '',
  referFrom: '',
  referPercentage: '',
  dateReceived: new Date(),
  dateExpired: new Date(),
  dateSubmitted: new Date(),
  sellerName: '',
  sellerInitialAddress: '',
  sellerSoi: '',
  sellerRoad: '',
  sellerTambon: '',
  sellerAmphoe: '',
  sellerProvince: '',
  sellerPostCode: '',
  sellerPhone: '',
  sellerLineId: '',
  deedNumber: '',
  deedParcelNumber: '',
  deedDealingFileNumber: '',
  deedTambon: '',
  deedAmphoe: '',
  deedProvince: '',
  projectName: '',
  latLong: '',
  propertySoi: '',
  propertyRoad: '',
  propertyTambon: '',
  propertyAmphoe: '',
  propertyProvince: '',
  region: '',
  zone: '',
  propertyType: '',
  propertyStatus: '',
  sellPrice: '',
  rentPrice: '',
  areaSizeRai: '',
  areaSizeNgan: '',
  areaSizeWaa: '',
  utilitySpace: '',
  floorCount: '',
  bedroomCount: '',
  bathroomCount: '',
  otherRoomCount: '',
  interiorCondition: '',
  exteriorCondition: '',
  additionalEquipment: [],
  features: [],
  headingDirection: '',
  landscapeType: '',
  landPricePerWaa: '',
  landEstimatedPricePerWaa: '',
  landFrontWidth: '',
  landOfficialZoneType: '',
  landRoadCondition: '',
  landRoadLength: '',
  landConditions: [],
  condoMaxFloorCount: '',
  condoPricePerSqm: '',
  condoLivingFloor: '',
  condoBuildingNumber: '',
  warehouseIsInsidePurpleZone: '',
  warehouseHasOperationPermit: '',
  warehouseMaximumWeightCapacity: '',
  warehouseMaximumBeamWeight: '',
  warehouseTrailerAccessible: '',
  baseAdsTitle: '',
  baseAdsDetails: '',
  baseAdsNearbyPlaces: '',
  baseAdsDirections: '',
  nearbyPlaces: {},
  selectedNearbyPlaces: {
    hospitalList: [],
    departmentStoreList: [],
    governmentBuildingList: [],
    supermarketList: [],
    schoolList: [],
    universityList: [],
    tollWayList: [],
    trainStationList: [],
  },
  propertyImagesAttached: 'false',
  agentAssignmentFilesAttached: 'false',
  ownerIdCardsAttached: 'false',
  deedsAttached: 'false',
  videoLink: '',
};

export const condoValues = {
  ...defaultInitialValues,
  listNumber: `condo${Math.random().toString()}`,
  nickname: 'ที่ดินเปล่าซอยอะไรไม่รู้ 15',
  contractType: 'exSellable',
  commissionPercentage: '15',
  programme: 'refer',
  referFrom: '15',
  referPercentage: '15',
  dateReceived: new Date(),
  dateExpired: new Date(new Date().setDate(new Date().getDate() + 555)),
  dateSubmitted: new Date(),
  sellerName: '453453',
  sellerInitialAddress: '13',
  sellerSoi: 'dfdd',
  sellerRoad: 'dddd',
  sellerTambon: 'คลองท่อมเหนือ',
  sellerAmphoe: 'คลองท่อม',
  sellerProvince: 'กระบี่',
  sellerPostCode: '81120',
  sellerPhone: '0825798767',
  sellerLineId: 'dfsdf',
  deedNumber: '453453',
  deedParcelNumber: '4534',
  deedDealingFileNumber: '5345345',
  deedTambon: '34534',
  deedAmphoe: '53453',
  deedProvince: '453453',
  projectName: 'สตรีท ไฟฟ์เซเว่น',
  latLong: '13.00,100.00',
  propertySoi: '123',
  propertyRoad: '123',
  propertyTambon: 'หนองขนาน',
  propertyAmphoe: 'เมืองเพชรบุรี',
  propertyProvince: 'เพชรบุรี',
  region: 'ตะวันตก',
  zone: 'L',
  propertyType: 1,
  propertyStatus: 1,
  sellPrice: '1500000',
  rentPrice: '',
  areaSizeRai: '0',
  areaSizeNgan: '0',
  areaSizeWaa: '0',
  utilitySpace: '150',
  floorCount: 3,
  bedroomCount: 2,
  bathroomCount: 3,
  otherRoomCount: 3,
  interiorCondition: 'ดี',
  exteriorCondition: 'ปานกลาง',
  additionalEquipment: ['พร้อมเฟอร์นิเจอร์', 'พร้อมเครื่องใช้ไฟฟ้า'],
  features: ['วิวแม่น้ำ', 'อยู่ในพื้นที่สีม่วง'],
  headingDirection: 'ตะวันตก',
  landscapeType: 'วิวเมือง',
  landPricePerWaa: '',
  landEstimatedPricePerWaa: '',
  landFrontWidth: '',
  landOfficialZoneType: '',
  landRoadCondition: '',
  landRoadLength: '',
  landConditions: [],
  condoMaxFloorCount: 3,
  condoPricePerSqm: 10000,
  condoLivingFloor: 3,
  condoBuildingNumber: 'N',
  warehouseIsInsidePurpleZone: '',
  warehouseHasOperationPermit: '',
  warehouseMaximumWeightCapacity: '',
  warehouseMaximumBeamWeight: '',
  warehouseTrailerAccessible: '',
  baseAdsTitle:
    'ขาย คอนโด สตรีท ไฟฟ์เซเว่น ซอย123 ถนน123 ตำบลหนองขนาน อำเภอเมืองเพชรบุรี เพชรบุรี',
  baseAdsDetails:
    'ขาย คอนโด สตรีท ไฟฟ์เซเว่น ซอย123 ถนน123\nเนื้อที่: 610,010 ตร.ว. (15 ไร่ 1 งาน 10 ตร.ว.)\nพื้นที่ใช้สอย: 150 ตร.ม.\n3 ชั้น 2 ห้องนอน 3 ห้องน้ำ\nราคาต่อตารางเมตร: 10,000 บาท',
  baseAdsNearbyPlaces:
    'สถานที่ใกล้เคียง\n\nห้างสรรพสินค้า / ซูเปอร์มาร์เก็ต\n- ร้านเจริญการเกษตร19 เพชรบุรี\n- ร้านเจิญการเกษตร 19 เพชรบุรี\n\nสถานที่ราชการ\n- ที่ทำการผู้ใหญ่บ้านหมู่ที่ 5 บ้านหนองช้างตาย ต.หนองขนาน\n- สำนักงานเทศบาลตำบลหนองขนาน\n\nโรงเรียน\n- โรงเรียนบ้านบางกุฬา\n- โรงเรียนดอนยางวิทยา\n',
  baseAdsDirections:
    'โทลล์เวย์ / ทางพิเศษใกล้เคียง\n- ทางยกระดับอุตราภิมุข\n- ทางพิเศษฉลองรัช สายรามอินทรา–วงแหวนรอบนอกกรุงเทพมหานคร\n- ทางพิเศษเฉลิมมหานคร สายดินแดง–ท่าเรือ\n- ทางพิเศษเฉลิมมหานคร สายบางนา–ท่าเรือ\n- ทางพิเศษฉลองรัช สายรามอินทรา–อาจณรงค์\n\nทางเข้าออกอื่นๆ\n',
  nearbyPlaces: {},
  selectedNearbyPlaces: {
    schoolList: [
      {
        name: 'โรงเรียนราชดำริ',
        distance: '4.84 km',
        latLong: [13.6814239, 100.67874089999998],
      },
      {
        name: 'โรงเรียนแก่นทองอุปถัมภ์',
        distance: '1.47 km',
        latLong: [13.6973934, 100.6522784],
      },
      {
        name: 'โรงเรียนสิริรัตนาธร',
        distance: '3.56 km',
        latLong: [13.6759649, 100.624053],
      },
    ],
    hospitalList: [
      {
        name: 'โรงพยาบาล ไทยนครินทร์',
        distance: '3.97 km',
        latLong: [13.6681477, 100.63856390000001],
      },
      {
        name: 'โรงพยาบาลวิภาราม พัฒนาการ',
        distance: '3.48 km',
        latLong: [13.734706, 100.64552660000004],
      },
      {
        name: 'โรงพยาบาลมนารมย์',
        distance: '5.96 km',
        latLong: [13.6648809, 100.60242330000005],
      },
    ],
    tollWayList: [],
    universityList: [],
    supermarketList: [
      {
        name: 'บริษัท เอก-ชัย ดีสทริบิวชั่น ซิสเทม จำกัด',
        distance: '1.96 km',
        latLong: [13.7189011, 100.63091689999999],
      },
      {
        name: 'มินิบิ๊กซี ซอยพึ่งมี 17',
        distance: '2.54 km',
        latLong: [13.7017089, 100.6169026],
      },
      {
        name: 'มินิบิ๊กซี บางจาก อุดมสุข 45',
        distance: '2.94 km',
        latLong: [13.6785286, 100.63235029999998],
      },
      {
        name: 'แม็คโคร ฟู้ดเซอร์วิส ศรีนครินทร์',
        distance: '0.82 km',
        latLong: [13.6993107, 100.6463149],
      },
    ],
    trainStationList: [
      {
        name: 'BTS ปุณณวิถี',
        distance: '3.75 km',
        latLong: [13.689267, 100.60898700000007],
      },
      {
        name: 'Airport Link หัวหมาก',
        distance: '3.84 km',
        latLong: [13.7380459, 100.64518129999999],
      },
      {
        name: 'BTS บางจาก',
        distance: '3.86 km',
        latLong: [13.696738, 100.605321],
      },
      {
        name: 'BTS อ่อนนุช',
        distance: '4.25 km',
        latLong: [13.7056546, 100.60100349999993],
      },
      {
        name: 'BTS อุดมสุข',
        distance: '4.26 km',
        latLong: [13.6799216, 100.60948819999999],
      },
    ],
    departmentStoreList: [
      {
        name: 'ซีคอนสแควร์',
        distance: '1.42 km',
        latLong: [13.6935189, 100.64816399999995],
      },
      {
        name: 'DOGZILLA',
        distance: '1.45 km',
        latLong: [13.692138, 100.63421800000003],
      },
      {
        name: 'คลองถมซีคอน',
        distance: '1.22 km',
        latLong: [13.696061, 100.64837779999993],
      },
      {
        name: 'มิวสิคอาร์ม',
        distance: '1.33 km',
        latLong: [13.6952469, 100.64887629999998],
      },
    ],
    governmentBuildingList: [],
  },
  propertyImagesAttached: 'false',
  agentAssignmentFilesAttached: 'false',
  ownerIdCardsAttached: 'false',
  deedsAttached: 'false',
  videoLink: '',
};

export const warehouseValues = {
  ...condoValues,
  propertyType: 12,
  listNumber: `warehouse${Math.random().toString()}`,
  warehouseIsInsidePurpleZone: 'true',
  warehouseHasOperationPermit: 'true',
  warehouseMaximumWeightCapacity: '20',
  warehouseMaximumBeamWeight: 'true',
  warehouseTrailerAccessible: 'false',
};

export const landValues = {
  ...condoValues,
  propertyType: sample([10, 11]),
  listNumber: `land${Math.random().toString()}`,
  landPricePerWaa: '50000',
  landEstimatedPricePerWaa: '50000',
  landFrontWidth: '20x30',
  landOfficialZoneType: '',
  landRoadCondition: '',
  landRoadLength: '',
  landConditions: [],
};

export const houseValues = {
  ...condoValues,
  propertyType: sample([2, 3, 4]),
  listNumber: `house${Math.random().toString()}`,
  areaSizeRai: '1',
  areaSizeNgan: '2',
  areaSizeWaa: '20',
};

export const townhouseValues = {
  ...condoValues,
  propertyType: sample([5, 6]),
  listNumber: `townhouse${Math.random().toString()}`,
  areaSizeRai: '1',
  areaSizeNgan: '2',
  areaSizeWaa: '20',
};

export const officeValues = {
  ...condoValues,
  propertyType: sample([7, 9]),
  listNumber: `townhouse${Math.random().toString()}`,
};

export const propertyTypeMap = {
  1: 'คอนโด',
  2: 'บ้านเดี่ยว',
  3: 'บ้านชั้นเดียว',
  4: 'บ้านแฝด',
  5: 'ทาวน์เฮ้าส์',
  6: 'ทาวน์โฮม',
  7: 'โฮมออฟฟิศ',
  8: 'อาคารพาณิชย์',
  9: 'สำนักงาน',
  10: 'ที่ดินเปล่า',
  11: 'ที่ดินพร้อมสิ่งปลูกสร้าง',
  12: 'โกดัง/โรงงาน',
  13: 'อพาร์ทเม้นท์',
  14: 'รีสอร์ท',
  15: 'ฟาร์ม',
};
