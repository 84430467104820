import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  makeStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  paper: {
    margin: theme.spacing(4),
  },
  margin: {
    marginLeft: theme.spacing(1),
  },
}));

const SimpleDialog = props => {
  const {
    dialogTitle,
    open,
    handleClose,
    message,
    handlePrimaryClick,
    handleSecondaryClick,
    secondaryBtnText,
    primaryBtnText,
    primaryBtnProps,
    ...rest
  } = props;

  const classes = useStyles();

  return (
    <Dialog
      classes={{
        paper: classes.paper,
      }}
      fullWidth
      maxWidth="sm"
      disableBackdropClick
      disableEscapeKeyDown
      open={open}
      onClose={handleClose}
      {...rest}
    >
      <DialogTitle>{dialogTitle}</DialogTitle>
      <DialogContent>
        {typeof message === 'string' ? (
          <DialogContentText component="div">{message}</DialogContentText>
        ) : (
          message
        )}
      </DialogContent>
      <DialogActions>
        {secondaryBtnText && (
          <Button
            onClick={handleSecondaryClick}
            color="secondary"
            variant="text"
          >
            {secondaryBtnText}
          </Button>
        )}
        <Button
          className={classes.margin}
          onClick={handlePrimaryClick}
          color="secondary"
          variant="contained"
          {...primaryBtnProps}
        >
          {primaryBtnText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

SimpleDialog.defaultProps = {
  dialogTitle: 'สำเร็จแล้ว',
  message: '',
  handleSecondaryClick: null,
  secondaryBtnText: '',
  primaryBtnText: 'ตกลง',
  handleClose: null,
  primaryBtnProps: {},
};

SimpleDialog.propTypes = {
  primaryBtnProps: PropTypes.objectOf(PropTypes.any),
  dialogTitle: PropTypes.string,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func,
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node,
  ]),
  primaryBtnText: PropTypes.string,
  handlePrimaryClick: PropTypes.func.isRequired,
  handleSecondaryClick: PropTypes.func,
  secondaryBtnText: PropTypes.string,
};

export default SimpleDialog;
