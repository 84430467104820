import React, { useEffect } from 'react';
import DateRangeIcon from '@material-ui/icons/DateRange';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PropTypes from 'prop-types';
import { InputAdornment } from '@material-ui/core';
import SectionDividerWithIcon from 'Components/shared/SectionDividerWithIcon';
import {
  FastFieldSet,
  SelectFieldSet,
  DatePickerFieldSet,
  FieldSet,
} from 'Components/fields';
import {
  contractTypeOptions,
  programmeOptions,
} from 'Components/WorkSubmitForm/shared/selectOptions';
import { connect } from 'react-redux';
import { getRejectedFields } from 'Components/control/shared/PropertyDetails/functions/propertyDetailsFunctions';
import AddressSearchField from './AddressSearchField';
import ListNumberField from './ListNumberField';

function FirstSection(props) {
  const {
    values,
    uuid,
    setStatus,
    user,
    editMode,
    firstTime,
    property,
  } = props;

  useEffect(() => {
    const timer = setTimeout(() => {
      if (uuid && property) {
        const userRole = user.role;
        const approveMode = false;
        const { submissionProgress, rejectedFields } = property;
        setStatus({
          ...getRejectedFields({
            userRole,
            approveMode,
            submissionProgress,
            rejectedFields,
          }),
          role: user.role,
        });
      }
    }, 50);

    return () => clearTimeout(timer);
  }, [property, uuid, setStatus, user.role]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <>
      <SectionDividerWithIcon icon={<InfoOutlinedIcon />}>
        ข้อมูลเบื้องต้น
      </SectionDividerWithIcon>
      <ListNumberField
        uuid={uuid}
        listNumber={values.listNumber}
        firstTime={firstTime}
        editMode={editMode}
        userRole={user.role}
        property={property}
      />
      <FastFieldSet
        required
        type="text"
        name="nickname"
        label="ชื่อทรัพย์โดยย่อ"
        placeholder="ที่ดินเปล่าสายไหมซอย 5"
        helperText="ตั้งชื่อทรัพย์ที่คุณจะจำได้ ไม่มีผลต่อการโฆษณา"
      />
      <SelectFieldSet
        required
        name="contractType"
        label="ประเภทสัญญา"
        options={contractTypeOptions}
      />
      {values.contractType === 'exSellable' && (
        <FastFieldSet
          type="text"
          name="commissionPercentage"
          label="ค่าคอมมิชชัน"
          disabled={values.contractType !== 'exSellable'}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
        />
      )}
      <SelectFieldSet
        required
        name="programme"
        label="Programme"
        options={programmeOptions}
      />
      {values.programme === 'refer' && (
        <>
          <FastFieldSet
            type="text"
            name="referFrom"
            label="ที่มาของ Refer"
            disabled={values.programme !== 'refer'}
          />
          <FastFieldSet
            type="text"
            name="referPercentage"
            label="ค่า Refer"
            disabled={values.programme !== 'refer'}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
          />
        </>
      )}
      <SectionDividerWithIcon icon={<DateRangeIcon />}>
        วันที่
      </SectionDividerWithIcon>
      <DatePickerFieldSet name="dateReceived" label="วันที่ได้รับ" />
      <DatePickerFieldSet name="dateExpired" label="วันที่หมดอายุ" />
      <DatePickerFieldSet name="dateSubmitted" label="วันที่ส่งงาน" />
      <SectionDividerWithIcon
        secondaryText="ที่อยู่ปัจจุบันไม่จำเป็นต้องตรงกับบัตรประชาชน"
        icon={<LocationOnIcon />}
      >
        ที่อยู่ปัจจุบันผู้ขาย
      </SectionDividerWithIcon>
      <FastFieldSet name="sellerName" label="ชื่อผู้ขาย" required />
      <FastFieldSet name="sellerInitialAddress" label="บ้านเลขที่" />
      <FastFieldSet name="sellerSoi" label="ซอย" />
      <FastFieldSet name="sellerRoad" label="ถนน" />
      <AddressSearchField value={values.sellerTambon} />
      <FieldSet name="sellerAmphoe" label="อำเภอ / เขต" />
      <FieldSet name="sellerProvince" label="จังหวัด" />
      <FieldSet name="sellerPostCode" label="รหัสไปรษณีย์" />
      <FastFieldSet
        required
        placeholder="0880001111"
        type="tel"
        name="sellerPhone"
        label="เบอร์โทรศัพท์"
      />
      <FastFieldSet name="sellerLineId" label="ไลน์ไอดี" />
    </>
  );
}

FirstSection.defaultProps = {
  values: {},
  uuid: null,
  setStatus: null,
  user: null,
  property: null,
};

FirstSection.propTypes = {
  values: PropTypes.objectOf(PropTypes.any),
  uuid: PropTypes.string,
  setStatus: PropTypes.func,
  user: PropTypes.objectOf(PropTypes.any),
  editMode: PropTypes.bool.isRequired,
  firstTime: PropTypes.bool.isRequired,
  property: PropTypes.objectOf(PropTypes.any),
};

const mapStateToProps = state => ({
  user: state.user.user,
});

export default connect(mapStateToProps)(FirstSection);
