import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FastFieldSet } from 'Components/fields';
import snakecaseKeys from 'snakecase-keys';
import Axios from 'axios';
import {
  InputAdornment,
  IconButton,
  TextField,
  makeStyles,
  SvgIcon,
} from '@material-ui/core';
import { Edit, Save } from '@material-ui/icons';
import { mdiCloseCircleOutline } from '@mdi/js';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles(theme => ({
  editableField: {
    marginBottom: theme.spacing(2.5),
  },
}));

const validateListNumber = value => {
  let error;
  return Axios.get('/v1/unique_list_number', {
    params: snakecaseKeys({ listNumber: value }),
  }).then(response => {
    if (!response.data) {
      error = 'มีเลข List นี้ในระบบแล้ว';
    }
    return error;
  });
};

const ListNumberField = ({
  firstTime,
  editMode,
  userRole,
  property,
  listNumber,
  uuid,
}) => {
  const classes = useStyles();
  const [editClicked, setEditClicked] = useState(false);
  const [editingValue, setEditingValue] = useState('');
  const isManager = userRole === 'manager';
  const notDraft = property && property.submissionProgress !== 'draft';
  const editable = isManager && notDraft && editMode;
  const { enqueueSnackbar } = useSnackbar();

  const updateListNumber = () => {
    Axios.patch('/v1/properties/update', {
      uuid,
      listNumber: editingValue,
    })
      .then(() => {
        window.location.reload();
        enqueueSnackbar('Success');
      })
      .catch(error => enqueueSnackbar(JSON.stringify(error.response.data)));
  };

  return editClicked ? (
    <TextField
      className={classes.editableField}
      label="หมายเลข List"
      fullWidth
      id="listNumberEdited"
      name="listNumberEdited"
      value={editingValue}
      onChange={e => setEditingValue(e.target.value)}
      variant="outlined"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={updateListNumber}>
              <Save />
            </IconButton>
            <IconButton onClick={() => setEditClicked(!editClicked)}>
              <SvgIcon>
                <path d={mdiCloseCircleOutline}></path>
              </SvgIcon>
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  ) : (
    <FastFieldSet
      // eslint-disable-next-line no-shadow
      validate={firstTime ? fieldValue => validateListNumber(fieldValue) : null}
      disabled={editMode || !firstTime}
      required
      type="text"
      name="listNumber"
      label="หมายเลข List"
      helperText={editMode ? '' : 'หมายเลข List ไม่สามารถแก้ไขได้ในภายหลัง'}
      InputProps={
        editable
          ? {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => {
                      setEditingValue(listNumber);
                      setEditClicked(!editClicked);
                    }}
                  >
                    <Edit />
                  </IconButton>
                </InputAdornment>
              ),
            }
          : null
      }
    />
  );
};

ListNumberField.propTypes = {
  firstTime: PropTypes.bool.isRequired,
  editMode: PropTypes.bool.isRequired,
  userRole: PropTypes.oneOf(['sales', 'admin', 'manager']).isRequired,
  property: PropTypes.objectOf(PropTypes.any),
  listNumber: PropTypes.string.isRequired,
  uuid: PropTypes.string.isRequired,
};

ListNumberField.defaultProps = {
  property: null,
};

export default ListNumberField;
