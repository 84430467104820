import React, { useState, useEffect } from 'react';
import {
  Box,
  makeStyles,
  GridList,
  GridListTile,
  Container,
  Button,
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import PropTypes from 'prop-types';
import { Image } from '@material-ui/icons';
import Axios from 'axios';
import ImageLightbox from './ImageLightbox';

const useStyles = makeStyles(theme => ({
  slider: {
    marginTop: theme.spacing(7),
  },
  gridList: {
    overflow: 'hidden',
    borderRadius: 20,
    height: '50vh',
    [theme.breakpoints.down('sm')]: {
      height: '40vh',
    },
  },
  tile: {
    width: '25%',
    height: ['100%', '!important'],
  },
  img: {
    objectFit: 'cover',
    '&:hover': {
      cursor: 'pointer',
      filter: 'opacity(90%)',
    },
  },
  swipableRoot: {
    marginTop: theme.spacing(7),
  },
  container: {
    maxWidth: 1440,
    paddingTop: theme.spacing(1),
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(7),
    },
  },
  largeTile: {
    marginRight: theme.spacing(1),
  },
}));

const DesktopImages = ({ uuid }) => {
  const [imageList, setImageList] = useState([]);
  const [loading, setLoading] = useState(true);
  const classes = useStyles();
  const [imageLightboxOpen, setImageLightboxOpen] = useState(false);
  const [clickedIndex, setClickedIndex] = useState(0);

  const handleImageTileClick = index => {
    setClickedIndex(index);
    setImageLightboxOpen(true);
  };

  useEffect(() => {
    Axios.get('/v1/properties/images', {
      params: {
        uuid,
        size: [600, 600],
        count: 5,
      },
    }).then(response => {
      setImageList(response.data);
      setLoading(false);
    });
  }, [uuid]);

  const ColumnTile = ({ children, hasMargin }) => {
    return (
      <GridListTile className={classes.tile} cols={1} rows={1}>
        <Box
          mr={hasMargin ? 1 : 0}
          height="100%"
          display="flex"
          flexDirection="column"
        >
          {children}
        </Box>
      </GridListTile>
    );
  };

  ColumnTile.propTypes = {
    children: PropTypes.node.isRequired,
    hasMargin: PropTypes.bool.isRequired,
  };

  const ImageTile = ({ hasMargin, index }) => {
    return (
      <Box
        onClick={() => handleImageTileClick(index)}
        mb={hasMargin ? 1 : 0}
        height="100%"
        width="100%"
        overflow="hidden"
      >
        {loading ? (
          <Skeleton variant="rect" height="100%" width="100%" />
        ) : (
          <img
            className={classes.img}
            height="100%"
            width="100%"
            src={imageList[index]}
            alt={imageList[index]}
          />
        )}
      </Box>
    );
  };

  ImageTile.propTypes = {
    index: PropTypes.number.isRequired,
    hasMargin: PropTypes.bool.isRequired,
  };

  return (
    <>
      <Container className={classes.container}>
        <Box bgcolor="#fff" mt={9} position="relative">
          <Box bottom={24} right={24} zIndex={1} position="absolute">
            <Button
              onClick={() => handleImageTileClick(0)}
              size="small"
              startIcon={<Image />}
              variant="contained"
              color="default"
            >
              ดูภาพทั้งหมด
            </Button>
          </Box>
          <GridList
            cellHeight="auto"
            cols={3}
            spacing={0}
            className={classes.gridList}
          >
            <GridListTile
              classes={{
                root: classes.tile,
                tile: classes.largeTile,
              }}
              cols={1.5}
              rows={1}
            >
              <ImageTile hasMargin={false} index={0} />
            </GridListTile>
            <ColumnTile hasMargin>
              <ImageTile hasMargin index={1} />
              <ImageTile hasMargin={false} index={2} />
            </ColumnTile>
            <ColumnTile hasMargin={false}>
              <ImageTile hasMargin index={3} />
              <ImageTile hasMargin={false} index={4} />
            </ColumnTile>
          </GridList>
        </Box>
      </Container>
      {imageLightboxOpen && (
        <ImageLightbox
          uuid={uuid}
          open={imageLightboxOpen}
          setOpen={setImageLightboxOpen}
          imageIndex={clickedIndex}
          setImageIndex={setClickedIndex}
        />
      )}
    </>
  );
};

DesktopImages.propTypes = {
  uuid: PropTypes.string.isRequired,
};

export default DesktopImages;
