import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import PropertyPagination from './PropertyPagination';
import PropertyFilter from './PropertyFilter';
import FilterChips from './PropertyFilter/FilterChips';

const PropertyFilterToolbar = ({
  filters,
  perPage,
  setPerPage,
  currentPage,
  setCurrentPage,
  totalCount,
  propertyFetch,
  currentTab,
  filteredItems,
  setFilteredItems,
}) => {
  const [activeFilters, setActiveFilters] = useState([]);

  useEffect(() => {
    setActiveFilters([]);
  }, [currentTab]);

  return (
    <Box py={2} width="100%">
      <Box
        display="flex"
        justifyContent={{ xs: 'flex-end', sm: 'space-between' }}
        alignItems="center"
        justifySelf="center"
        flexWrap="wrap"
        ml={0}
        px={1}
      >
        <PropertyFilter
          filteredItems={filteredItems}
          setFilteredItems={setFilteredItems}
          currentTab={currentTab}
          setActiveFilters={setActiveFilters}
          propertyFetch={propertyFetch}
          filters={filters}
          activeFilters={activeFilters}
        />
        <PropertyPagination
          currentTab={currentTab}
          totalCount={totalCount}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          perPage={perPage}
          setPerPage={setPerPage}
        />
      </Box>
      <FilterChips activeFilters={activeFilters} />
    </Box>
  );
};

PropertyFilterToolbar.propTypes = {
  propertyFetch: PropTypes.func.isRequired,
  filters: PropTypes.objectOf(PropTypes.array).isRequired,
  perPage: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  setPerPage: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  totalCount: PropTypes.number.isRequired,
  currentTab: PropTypes.number.isRequired,
  filteredItems: PropTypes.objectOf(PropTypes.array).isRequired,
  setFilteredItems: PropTypes.func.isRequired,
};

export default PropertyFilterToolbar;
