import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, makeStyles } from '@material-ui/core';
import { formatNumber } from 'Utils';

const useStyles = makeStyles(theme => ({
  priceNumber: {
    fontWeight: 500,
    fontFamily: 'Roboto',
    marginRight: theme.spacing(1),
  },
  separator: {
    color: 'hsla(203, 15%, 79%, 1)',
  },
  priceUnit: {
    color: theme.palette.shades.grey.light,
  },
}));

const isZero = num => Number(num) === 0;

const PropertyShowPrice = ({ sellPrice, rentPrice }) => {
  const classes = useStyles();

  const PriceText = (price, priceUnit) => (
    <>
      <Typography
        className={classes.priceNumber}
        color="secondary"
        display="inline"
        variant="h5"
      >
        {formatNumber(price)}
      </Typography>
      <Typography
        variant="caption"
        className={classes.priceUnit}
        component="span"
        display="inline"
      >
        {priceUnit}
      </Typography>
    </>
  );

  return (
    <Box display="flex" justifyContent="flex-start">
      <Box display="inline-block" px={{ xs: 1, sm: 3 }} borderRadius={8}>
        {!isZero(sellPrice) && isZero(rentPrice) && PriceText(sellPrice, 'บาท')}
        {!isZero(sellPrice) && !isZero(rentPrice) && (
          <>
            {PriceText(sellPrice, 'บาท')}

            <Box mx={2} display="inline-flex" flexDirection="row">
              <Typography
                className={classes.separator}
                component="span"
                variant="h5"
              >
                |
              </Typography>
            </Box>
            {PriceText(rentPrice, '/ เดือน')}
          </>
        )}
        {!isZero(rentPrice) &&
          isZero(sellPrice) &&
          PriceText(rentPrice, '/ เดือน')}
      </Box>
    </Box>
  );
};

PropertyShowPrice.defaultProps = {
  sellPrice: 0,
  rentPrice: 0,
};

PropertyShowPrice.propTypes = {
  sellPrice: PropTypes.number,
  rentPrice: PropTypes.number,
};

export default PropertyShowPrice;
