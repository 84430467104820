import React, { useState } from 'react';
import { LinearProgress, Typography } from '@material-ui/core';
import PasswordStrengthInfoDialog from '../PasswordStrengthInfoDialog/PasswordStrengthInfoDialog';
import useStyles from './PasswordStrengthIndicator_Style';
import {
  normalize,
  colorMap,
  scoreMap,
} from './PasswordStrengthIndicator_Constants';

const PasswordStrengthIndicator = props => {
  const classes = useStyles();
  const { passwordStrength } = props;
  const [dialogOpen, setDialogOpen] = useState(false);
  const [hovering, setHovering] = useState(false);

  function handleDialogClose() {
    setDialogOpen(false);
  }

  function handleClick() {
    setDialogOpen(true);
  }

  function handleHoverIn() {
    setHovering(true);
  }

  function handleHoverOut() {
    setHovering(false);
  }

  return (
    <div className={classes.passwordStrengthWrapper}>
      <LinearProgress
        className={classes.strengthIndicator}
        classes={{
          colorPrimary: classes[`linearColorPrimary${passwordStrength}`],
          barColorPrimary: classes[`linearBarColorPrimary${passwordStrength}`],
        }}
        variant="determinate"
        value={normalize(passwordStrength)}
      />
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <Typography style={{ display: 'inline' }} variant="caption">
            {passwordStrength === 0 ? 'รหัสผ่านสั้นเกินไป' : 'ความปลอดภัย:'}
          </Typography>
          <Typography
            style={{
              display: 'inline',
              marginLeft: 8,
              color: colorMap[`${passwordStrength}`],
            }}
            variant="caption"
            component="span"
          >
            {scoreMap[`${passwordStrength}`]}
          </Typography>
        </div>
        <Typography
          onClick={handleClick}
          onMouseEnter={handleHoverIn}
          onMouseLeave={handleHoverOut}
          style={{
            textDecoration: hovering ? 'underline' : 'none',
            cursor: 'pointer',
          }}
          color="primary"
          variant="caption"
        >
          ดูคำอธิบาย
        </Typography>
      </div>
      <PasswordStrengthInfoDialog
        open={dialogOpen}
        handleClose={handleDialogClose}
      />
    </div>
  );
};

export default PasswordStrengthIndicator;
