import React, { useState, useEffect } from 'react';
import {
  Avatar,
  Button,
  Paper,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import Axios from 'axios';
import { Redirect } from 'react-router-dom';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import zxcvbn from 'zxcvbn';
import { useSnackbar } from 'notistack';
import ContentWrapper from 'Components/shared/ContentWrapper';
import LoadingSpinner from 'Components/shared/LoadingSpinner';
import SimpleDialog from 'Components/shared/SimpleDialog';
import { UserPlusIcon } from 'Components/customIcons';
import SectionDividerWithText from 'Components/shared/SectionDividerWithText';
import HeadingText from '../shared/HeadingText';
import useStyles from './Register_Style';
import PasswordStrengthIndicator from './PasswordStrengthIndicator';
import GoogleLoginWrapper from '../shared/GoogleLogin';

const errorMessageMap = {
  'email has already been taken': 'อีเมลนี้ใช้สมัครสมาชิกไปแล้ว',
  'password is too short (minimum is 8 characters)':
    'รหัสผ่านสั้นเกินไป (ขั้นต่ำคือ 8 ตัวอักษร)',
  'email is not an email': 'อีเมลไม่ถูกต้อง',
};

function Register() {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const [redirect, setRedirect] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [showPassword, setShowPassword] = useState(false);
  const [errorObject, setErrorObject] = useState({});

  useEffect(() => {
    ValidatorForm.addValidationRule(
      'isPasswordMatch',
      value => value === password
    );
    ValidatorForm.addValidationRule(
      'isStrongPassword',
      value => zxcvbn(value).score >= 2
    );
  }, [password]);

  function handleClickShowPassword() {
    setShowPassword(!showPassword);
  }

  function handleEmailChange(e) {
    setEmail(e.target.value);
  }

  function emptyPassword(pwd) {
    return pwd === '';
  }

  function shortPassword(pwd) {
    return pwd.length < 8;
  }

  function handlePasswordChange(e) {
    const pwd = e.target.value;
    const pwdStrength = zxcvbn(pwd).score + 1;
    if (emptyPassword(pwd)) {
      setPassword('');
      setPasswordStrength(0);
    } else if (shortPassword(pwd)) {
      setPassword(pwd);
      setPasswordStrength(0);
    } else {
      setPassword(pwd);
      setPasswordStrength(pwdStrength);
    }
  }

  function handlePasswordConfirmationChange(e) {
    setPasswordConfirmation(e.target.value);
  }

  function handleRedirect() {
    window.location.href = '/';
  }

  function reset() {
    setEmail('');
    setPassword('');
    setLoading(false);
    setSuccess(true);
  }

  function handleResponse(response) {
    if (response.data.hasError) {
      setLoading(false);
      setErrorObject(response.data.saveErrorMessage);
      Object.entries(errorObject).forEach(([errorPart, errorMessages]) => {
        errorMessages.map(message => {
          const translatedMessage = errorMessageMap[`${errorPart} ${message}`];
          return enqueueSnackbar(translatedMessage);
        });
      });
    } else {
      reset();
    }
  }

  function handleSubmit(e) {
    setLoading(true);
    e.preventDefault();
    Axios.post('/v1/users/create', {
      user: {
        email,
        password,
        passwordConfirmation,
      },
    })
      .then(response => {
        handleResponse(response);
      })
      .catch(error => {
        enqueueSnackbar(JSON.stringify(error));
      });
  }

  const registerForm = (
    <ContentWrapper dense>
      {loading && <LoadingSpinner />}
      <Paper className={classes.paper}>
        <SimpleDialog
          open={success}
          handlePrimaryClick={handleRedirect}
          message="สมัครสมาชิกเรียบร้อยแล้ว กรุณาตรวจสอบอีเมลของคุณเพื่อยืนยันการเป็นสมาชิก"
        />
        <Avatar className={classes.avatar}>
          <UserPlusIcon />
        </Avatar>
        <HeadingText>ลงทะเบียน</HeadingText>
        <GoogleLoginWrapper setRedirect={setRedirect} />
        {/* <FacebookLoginWrapper setRedirect={setRedirect} /> */}
        <SectionDividerWithText text="หรือ" />
        <ValidatorForm
          instantValidate={false}
          onSubmit={handleSubmit}
          noValidate
          className={classes.form}
        >
          <TextValidator
            className={classes.textField}
            name="email"
            id="email"
            label="Email"
            type="email"
            value={email}
            onChange={handleEmailChange}
            autoFocus
            required
            fullWidth
            variant="outlined"
            validators={['isEmail', 'required']}
            errorMessages={['อีเมลไม่ถูกต้อง', 'จำเป็นต้องกรอก']}
          />

          <TextValidator
            className={classes.textField}
            name="password"
            id="password"
            label="Password"
            placeholder="ขั้นต่ำ 8 ตัวอักษร"
            value={password}
            onChange={handlePasswordChange}
            type={showPassword ? 'text' : 'password'}
            helperText={`${password.length} / 128`}
            required
            fullWidth
            variant="outlined"
            validators={[
              'minStringLength:8',
              'maxStringLength:128',
              'isStrongPassword',
            ]}
            errorMessages={[
              'รหัสผ่านขั้นต่ำ 8 ตัวอักษร',
              'รหัสผ่านสูงสุด 128 ตัวอักษร',
              'กรุณาเพิ่มความซับซ้อนของรหัสผ่าน',
            ]}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleClickShowPassword}>
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {password !== '' && (
            <PasswordStrengthIndicator passwordStrength={passwordStrength} />
          )}
          <TextValidator
            className={classes.textField}
            name="passwordConfirmation"
            id="passwordConfirmation"
            label="ยืนยันพาสเวิร์ด"
            value={passwordConfirmation}
            onChange={handlePasswordConfirmationChange}
            type="password"
            required
            fullWidth
            variant="outlined"
            validators={['required', 'isPasswordMatch']}
            errorMessages={['จำเป็นต้องกรอก', 'รหัสผ่านไม่ตรงกัน']}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            ลงทะเบียน
          </Button>
        </ValidatorForm>
      </Paper>
    </ContentWrapper>
  );

  if (redirect) {
    return <Redirect to="/forms/worksubmit" />;
  }
  return registerForm;
}

export default Register;
