import React, { useState, useEffect } from "react";
import {
  Box,
  GridListTileBar,
  IconButton,
  Grid,
  useMediaQuery,
  useTheme,
  Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import Axios from "axios";
import PropTypes, { oneOfType } from "prop-types";
import {
  SortableContainer,
  SortableElement,
  arrayMove
} from "react-sortable-hoc";
import { isEqual } from "lodash";
import { red } from "@material-ui/core/colors";
import snakecaseKeys from "snakecase-keys";
import ImageSkeletons from "./ImageSkeletons";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  gridList: {
    position: "relative"
  },
  tile: {
    borderRadius: theme.shape.borderRadius
  },
  icon: {
    color: "#fff"
  },
  thumb: {
    "&:hover button": {
      opacity: 1,
      height: "auto"
    },
    cursor: "grab",
    boxShadow: theme.shadows[1],
    position: "relative",
    display: "inline-flex",
    borderRadius: 2,
    border: "1px solid #eaeaea",
    background: "white",
    margin: theme.spacing(1),
    [theme.breakpoints.only("xs")]: {
      margin: theme.spacing(0.5)
    },
    width: 100,
    height: 100,
    padding: theme.spacing(0.5),
    boxSizing: "border-box"
  },
  tileBarRoot: {
    background: "none",
    top: "-22px",
    right: "-6px"
  },
  removeIcon: {
    opacity: 0,
    "&:hover": {
      opacity: 1,
      background: "#f44336"
    },
    transition: theme.transitions.create(["opacity"], {
      duration: theme.transitions.duration.shortest
    }),
    background: "#f44336",
    fontSize: "1rem",
    padding: 1
  },
  img: {
    position: "relative",
    width: "auto",
    height: "100%",
    display: "block"
  },
  closeIcon: {
    color: "#fff",
    fontSize: "1rem"
  },
  tileTitle: {
    fontWeight: 500,
    borderRadius: theme.shape.borderRadius,
    position: "absolute",
    top: 22,
    left: 0,
    background: red[500],
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  }
}));

const handleImageDelete = ({
  id,
  listNumber,
  setImageList,
  fieldNumber
}) => () => {
  Axios.post(
    "/v1/properties/delete_image",
    snakecaseKeys({
      id,
      listNumber,
      fieldNumber
    })
  ).then(response => {
    setImageList(response.data.data.attributes.images);
  });
};

const SortableItem = SortableElement(
  ({ image, index, listNumber, setImageList, name, fieldNumber }) => {
    const location = useLocation();
    const classes = useStyles();
    return (
      <Grid item className={classes.thumb} key={image.thumb}>
        <Box display="flex" minWidth={0} overflow="hidden">
          <img
            className={classes.img}
            src={image.thumb}
            alt={`preview${index}`}
          />
          <GridListTileBar
            title={
              image.type === "application/pdf" && (
                <Typography variant="body2" className={classes.tileTitle}>
                  PDF
                </Typography>
              )
            }
            classes={{ root: classes.tileBarRoot }}
            titlePosition="top"
            actionIcon={
              location.pathname !== "/sales/work-submit-form/edit" && (
                <IconButton
                  title="ลบภาพ"
                  classes={{ root: classes.removeIcon }}
                  color="primary"
                  size="small"
                  onClick={handleImageDelete({
                    id: image.id,
                    listNumber,
                    name,
                    setImageList,
                    fieldNumber
                  })}
                >
                  <CloseIcon className={classes.closeIcon} />
                </IconButton>
              )
            }
            actionPosition="right"
          />
        </Box>
      </Grid>
    );
  }
);

const SortableGrid = SortableContainer(
  ({
    imageList,
    setImageList,
    name,
    currentField,
    listNumber,
    imageCount,
    skeletons,
    uploading,
    compressing,
    loading
  }) => {
    const classes = useStyles();

    return (
      <Grid display="flex" container spacing={0} className={classes.gridList}>
        {(uploading || compressing) && imageCount === 0 ? (
          skeletons
        ) : (
          <>
            {loading ? (
              <ImageSkeletons fileCount={imageCount} />
            ) : (
              imageCount > 0 &&
              imageList.images[String(currentField - 1)].map((image, index) => (
                <SortableItem
                  fieldNumber={currentField}
                  setImageList={setImageList}
                  key={image.id}
                  image={image}
                  index={index}
                  listNumber={listNumber}
                  name={name}
                />
              ))
            )}
            {(uploading || compressing) && skeletons}
          </>
        )}
      </Grid>
    );
  }
);

function ImagePreview(props) {
  const {
    imageList,
    setImageList,
    listNumber,
    name,
    currentField,
    hasMultipleFields,
    imageCount,
    skeletons,
    uploading,
    compressing
  } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const [order, setOrder] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setOrder(imageList.order[String(currentField - 1)]);
  }, [imageList.order, currentField]);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const newOrder = arrayMove(order, oldIndex, newIndex);
    if (!isEqual(order, newOrder)) {
      setLoading(true);
      Axios.patch(
        "/v1/properties/update",
        snakecaseKeys({
          listNumber,
          attachmentOrder: JSON.stringify(newOrder),
          fieldNumber: currentField - 1,
          fieldName: name
        })
      ).then(response => {
        setImageList(response.data.data.attributes.images);
        setLoading(false);
      });
    }
  };

  return (
    <Box mb={2} width="100%">
      <div>
        <SortableGrid
          onSortEnd={onSortEnd}
          distance={isMobile ? null : 1}
          pressDelay={isMobile ? 200 : null}
          lockToContainerEdges
          lockOffset="0%"
          axis="xy"
          name={name}
          currentField={currentField}
          hasMultipleFields={hasMultipleFields}
          listNumber={listNumber}
          imageList={imageList}
          setImageList={setImageList}
          imageCount={imageCount}
          skeletons={skeletons}
          loading={loading}
          uploading={uploading}
          compressing={compressing}
        />
      </div>
    </Box>
  );
}

ImagePreview.propTypes = {
  listNumber: PropTypes.string.isRequired,
  imageList: PropTypes.objectOf(
    oneOfType([PropTypes.array, PropTypes.string, PropTypes.object])
  ).isRequired,
  name: PropTypes.string.isRequired,
  currentField: PropTypes.number.isRequired,
  hasMultipleFields: PropTypes.bool,
  imageCount: PropTypes.number.isRequired,
  skeletons: PropTypes.element.isRequired,
  uploading: PropTypes.bool.isRequired,
  compressing: PropTypes.bool.isRequired,
  setImageList: PropTypes.func.isRequired
};

ImagePreview.defaultProps = {
  hasMultipleFields: false
};

export default ImagePreview;
