import React from 'react'
import PropTypes from 'prop-types'
import PropertyDetails from 'Components/control/shared/PropertyDetails'
import MyProperties from 'Components/control/shared/MyProperties'
import WorkSubmitForm from 'Components/WorkSubmitForm'
import PropertyControl from 'Components/control/shared/PropertyControl'
import BuildIcon from '@material-ui/icons/Build'
import MyProfile from 'Components/control/shared/MyProfile'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import AdvertisementForm from 'Components/control/shared/AdvertisementForm'
import DashboardTemplate from 'Components/control/shared/DashboardTemplate'
import {
  Dashboard,
  Business,
  ContactPhone,
  Group,
  Landscape,
} from '@material-ui/icons'
import PropertyStats from 'Components/control/shared/PropertyStats'
import PropertyDashboard from 'Components/control/shared/PropertyDashboard'
import AdvertisementDetails from 'Components/control/shared/AdvertisementDetails'
import UserResponseTable from '../UserResponseTable'
import UserPostTable from '../UserPostTable'
import UserLandSellTable from '../UserLandSellTable'

const linkMap = [
  {
    pathname: '/manager/user-post-report',
    component: <UserPostTable />,
  },
  {
    pathname: '/manager/user-response-report',
    component: <UserResponseTable />,
  },
  {
    pathname: '/manager/user-response-land',
    component: <UserLandSellTable />,
  },
  {
    pathname: '/manager/ads-form',
    component: <AdvertisementForm />,
  },
  {
    pathname: `/manager/my-profile`,
    component: <MyProfile />,
  },
  {
    pathname: '/manager/work-submit-form/edit',
    component: <WorkSubmitForm />,
  },
  {
    pathname: '/manager/work-submit-form',
    component: <WorkSubmitForm />,
  },
  {
    pathname: '/manager/property-control',
    component: <PropertyControl />,
  },
  {
    pathname: '/manager/property/view',
    component: <PropertyDetails />,
  },
  {
    pathname: '/manager/property/approve',
    component: <PropertyDetails />,
  },
  {
    pathname: '/manager/complete-properties',
    component: <MyProperties />,
  },
  {
    pathname: '/manager/property/stats',
    component: <PropertyStats />,
  },
  {
    pathname: '/manager/property-dashboard',
    component: <PropertyDashboard />,
  },
  {
    pathname: '/manager/advertisements',
    component: <AdvertisementDetails />,
  },
]

const managerListItems = [
  {
    id: 1,
    pathname: '/manager/my-profile',
    menuLabel: 'ข้อมูลส่วนตัว',
    icon: <AccountCircleIcon />,
  },
  {
    id: 2,
    pathname: '/manager/property-control',
    menuLabel: 'จัดการทรัพย์',
    icon: <BuildIcon />,
  },
  {
    id: 3,
    pathname: '/manager/property-dashboard',
    menuLabel: 'รีพอร์ต',
    icon: <Dashboard />,
  },
  {
    id: 4,
    pathname: '/manager/complete-properties',
    menuLabel: 'ทรัพย์ที่สมบูรณ์',
    icon: <Business />,
  },
]

const secondaryMenuItems = [
  {
    id: 1,
    pathname: '/manager/user-response-report',
    menuLabel: 'ลูกค้าฝากขาย',
    icon: <ContactPhone />,
  },
  {
    id: 2,
    pathname: '/manager/user-response-land',
    menuLabel: 'ลูกค้าขายที่ดิน',
    icon: <Landscape />,
  },
  {
    id: 3,
    pathname: '/manager/user-post-report',
    menuLabel: 'สมาชิกโพสต์ประกาศ',
    icon: <Group />,
  },
]

function ManagerDashboard({ location, ...rest }) {
  return (
    <DashboardTemplate
      location={location}
      secondaryMenuItems={secondaryMenuItems}
      menuItems={managerListItems}
      userRole="manager"
      linkMap={linkMap}
      {...rest}
    />
  )
}

ManagerDashboard.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    hash: PropTypes.string,
    key: PropTypes.string,
  }).isRequired,
}

export default ManagerDashboard
