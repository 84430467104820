import React, { Fragment } from 'react';
import { Box } from '@material-ui/core';
import DashboardAppBar from 'Components/control/shared/DashboardAppBar';
import DashboardSidebar from 'Components/control/shared/DashboardSidebar';
import DashboardContentWrapper from 'Components/control/shared/DashboardContent';
import { capitalize } from 'lodash';
import PropTypes from 'prop-types';

function DashboardTemplate(props) {
  const {
    location,
    menuItems,
    secondaryMenuItems,
    userRole,
    linkMap,
    ...rest
  } = props;

  return (
    <Box display="flex">
      <DashboardAppBar>
        {`แผงควบคุมของ ${capitalize(userRole)}`}
      </DashboardAppBar>
      <DashboardSidebar
        secondaryMenuItems={secondaryMenuItems}
        mainListItemsArray={menuItems}
        location={location}
      />
      <DashboardContentWrapper>
        {linkMap.map(content => (
          <Fragment key={content.pathname}>
            {location.pathname === content.pathname &&
              React.cloneElement(content.component, {
                uuid: location.search.replace(/\?/g, ''),
                mode: userRole,
                location,
                ...rest,
              })}
          </Fragment>
        ))}
      </DashboardContentWrapper>
    </Box>
  );
}

DashboardTemplate.propTypes = {
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  menuItems: PropTypes.arrayOf(PropTypes.any).isRequired,
  userRole: PropTypes.oneOf(['sales', 'admin', 'manager']).isRequired,
  linkMap: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default DashboardTemplate;
