import React from 'react';
import {
  Drawer,
  IconButton,
  Divider,
  List,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setDrawerOpen } from 'JS/actions';
import useStyles from './DashboardSidebar_Style';
import DashboardSidebarItems from './DashboardSidebarItems';

function DashboardSidebar({
  drawerOpen,
  // eslint-disable-next-line no-shadow
  setDrawerOpen,
  location,
  mainListItemsArray,
  secondaryMenuItems,
}) {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.only('xs'));
  const classes = useStyles();
  return (
    <Drawer
      onClose={() => setDrawerOpen(false)}
      variant={isMobile ? 'temporary' : 'permanent'}
      classes={{
        paper: isMobile
          ? classes.drawerPaper
          : clsx(classes.drawerPaper, !drawerOpen && classes.drawerPaperClose),
        root: isMobile
          ? null
          : clsx(classes.drawerPaper, !drawerOpen && classes.drawerPaperClose),
      }}
      open={drawerOpen}
    >
      <div className={classes.toolbarIcon}>
        <div />
        <IconButton color="default" onClick={() => setDrawerOpen(false)}>
          <ChevronLeftIcon className={classes.expandIcon} />
        </IconButton>
      </div>
      <Divider />
      <List>
        <DashboardSidebarItems
          secondaryMenuItems={secondaryMenuItems}
          handleDrawerClose={() => setDrawerOpen(false)}
          location={location}
          mainListItemsArray={mainListItemsArray}
        />
      </List>
    </Drawer>
  );
}

DashboardSidebar.propTypes = {
  setDrawerOpen: PropTypes.func.isRequired,
  drawerOpen: PropTypes.bool.isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  mainListItemsArray: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      pathname: PropTypes.string,
      menuLabel: PropTypes.string,
      link: PropTypes.element,
      icon: PropTypes.element,
    })
  ).isRequired,
};

const mapStateToProps = state => ({
  drawerOpen: state.drawer.drawerOpen,
});

const mapDispatchToProps = {
  setDrawerOpen,
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardSidebar);
