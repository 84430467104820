import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Switch, FormControlLabel } from '@material-ui/core';
import { combineDescription } from 'Utils';

const AdsExampleBox = ({ values, owner, latLong, loading }) => {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <Box p={2} display="flex" justifyContent="flex-end">
        <FormControlLabel
          labelPlacement="start"
          control={
            <Switch
              color="primary"
              checked={open}
              onChange={() => setOpen(!open)}
            />
          }
          label="แสดงตัวอย่างโฆษณา"
        />
      </Box>
      {open && (
        <Box
          mb={2}
          borderRadius={6}
          p={2}
          bgcolor="#f0f1f5"
          style={{ whiteSpace: 'pre-wrap' }}
        >
          {!loading &&
            combineDescription({
              title: values.title,
              details: values.postDetails,
              nearbyPlaces: values.postNearbyPlaces,
              directions: values.postDirections,
              name: owner.contactInfo.data.attributes.name,
              phoneNumber: owner.contactInfo.data.attributes.phoneNumber,
              lineId: owner.contactInfo.data.attributes.lineId,
              latLong,
            })}
        </Box>
      )}
    </div>
  );
};

AdsExampleBox.propTypes = {
  values: PropTypes.shape({
    title: PropTypes.string.isRequired,
    shortTitle: PropTypes.string.isRequired,
    postPropertyType: PropTypes.number.isRequired,
    postDetails: PropTypes.string.isRequired,
    postNearbyPlaces: PropTypes.string.isRequired,
    postDirections: PropTypes.string.isRequired,
  }).isRequired,
  owner: PropTypes.objectOf(PropTypes.any).isRequired,
  latLong: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default AdsExampleBox;
