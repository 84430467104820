import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Typography, Box, Tooltip } from '@material-ui/core';
import humanizeDuration from 'humanize-duration';

const textColorMap = {
  danger: 'hsl(6, 100%, 67%)',
  warning: 'hsl(333, 72%, 60%)',
  neutral: 'hsl(278, 34%, 51%)',
  fine: 'hsla(206, 99%, 30%, 1)',
};

const getTimeLeftWords = (timeLeft, expired) => {
  const month = 1;
  if (expired) return { text: 'หมดอายุ', type: 'danger' };
  if (timeLeft <= month) return { text: '< 1 เดือน', type: 'danger' };
  if (timeLeft <= month * 3) return { text: '< 3 เดือน', type: 'warning' };
  if (timeLeft <= month * 6) return { text: '< 6 เดือน', type: 'neutral' };
  return { text: '> 6 เดือน', type: 'fine' };
};

const ExpiryBadge = ({ dateExpired }) => {
  const timeLeft = moment.duration(moment(dateExpired).diff(moment()));
  const timeLeftInMonths = timeLeft.asMonths();
  const expired = moment(dateExpired).isBefore(moment());
  const timeLeftObject = getTimeLeftWords(timeLeftInMonths, expired);

  return (
    <Tooltip
      title={
        expired
          ? 'หมดอายุ'
          : humanizeDuration(timeLeft.asMilliseconds(), {
              language: 'th',
              units: ['y', 'mo', 'd'],
              round: true,
              delimiter: '  ',
            })
      }
    >
      <Box width="min-content" borderRadius={8} px={0.8} py={0.2}>
        <Typography
          variant="body2"
          style={{
            color: textColorMap[timeLeftObject.type],
            fontSize: '0.9rem',
            fontWeight: 600,
          }}
        >
          {timeLeftObject.text}
        </Typography>
      </Box>
    </Tooltip>
  );
};

ExpiryBadge.propTypes = {
  dateExpired: PropTypes.string.isRequired,
};

export default ExpiryBadge;
