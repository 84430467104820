import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Tabs,
  Tab,
  Paper,
  useMediaQuery,
  makeStyles,
  Typography,
  Box,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import Axios from "axios";
import snakecaseKeys from "snakecase-keys";

const useStyles = makeStyles((theme) => ({
  paper: {
    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(1),
    },
  },
  root: {
    textTransform: "none",
    minWidth: 72,
    [theme.breakpoints.up("sm")]: {
      minWidth: 100,
      paddingTop: theme.spacing(2),
    },
    borderBottom: "1px solid hsla(0, 0%, 0%, 0.08)",
  },
  badge: {
    transform: "scale(0.9) translate(50%, -50%)",
  },
  labelIcon: {
    minHeight: 72,
  },
  itemCount: {
    fontWeight: 500,
    marginRight: theme.spacing(0.7),
  },
}));

function PropertyTabs({
  children,
  tabList,
  onTabChange,
  tabNumberFromUrl,
  user,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const [value, setValue] = useState(0);
  const [itemCountResponse, setItemCountResponse] = useState([]);

  function handleChange(event, newValue) {
    setValue(newValue);
    if (onTabChange) {
      onTabChange(newValue);
    }
  }

  useEffect(() => {
    if (tabNumberFromUrl) {
      setValue(tabNumberFromUrl);
    }
  }, [tabNumberFromUrl]);

  useEffect(() => {
    if (user.role === "sales") {
      setValue(3);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const requests = tabList.map((tab) => {
      return Axios.get("/v1/properties/item_count", {
        params: snakecaseKeys({
          submissionProgress: tab.submissionProgress,
          hasAds: tab.hasAds,
          state: tab.propertyState,
          contractType:
            tab.id === 7
              ? "exClientNotSellable"
              : tab.id === 8
              ? "openNoCommission"
              : null,
        }),
      });
    });
    Promise.all(requests).then((responses) => setItemCountResponse(responses));
  }, [tabList]);

  return (
    itemCountResponse.length > 0 && (
      <>
        <Paper
          className={classes.paper}
          style={{ background: "transparent" }}
          elevation={0}
          square
        >
          <Tabs
            textColor="secondary"
            variant={isMobile ? "scrollable" : "standard"}
            indicatorColor="secondary"
            value={value}
            onChange={handleChange}
          >
            {tabList.map((tab) => (
              <Tab
                classes={{
                  root: classes.root,
                  wrapper: classes.wrapper,
                  labelIcon: classes.labelIcon,
                }}
                icon={
                  <Box
                    borderRadius={4}
                    bgcolor={
                      value === tab.id - 1
                        ? "hsl(209,89%,92%)"
                        : "hsl(228,20%,90%)"
                    }
                    px={0.8}
                    py={0.5}
                  >
                    <Typography
                      variant="body2"
                      style={{
                        fontWeight: 500,
                        color: value === tab.id - 1 ? "#0D76D9" : "#727272",
                      }}
                    >
                      {`${itemCountResponse[tab.id - 1].data}`}
                    </Typography>
                  </Box>
                  // !isMobile &&
                  // React.cloneElement(
                  //   value === tab.id - 1 ? tab.icon : tab.iconOutlined,
                  //   { fontSize: "small" }
                  // )
                }
                disableRipple
                style={{ whiteSpace: "nowrap" }}
                key={tab.id}
                label={<Typography variant="body2">{tab.label}</Typography>}
              />
            ))}
          </Tabs>
        </Paper>
        {children(value)}
      </>
    )
  );
}
PropertyTabs.defaultProps = {
  tabNumberFromUrl: null,
};

PropertyTabs.propTypes = {
  user: PropTypes.objectOf(PropTypes.string).isRequired,
  children: PropTypes.func.isRequired,
  tabList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
    })
  ).isRequired,
  onTabChange: PropTypes.func.isRequired,
  tabNumberFromUrl: PropTypes.number,
};

const mapStateToProps = (state) => ({
  user: state.user.user,
});

export default connect(mapStateToProps)(PropertyTabs);
