import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

function RedirectPath(props) {
  const { user } = props;
  return <Redirect to={`/${user.role}`} />;
}

const mapStateToProps = state => ({
  user: state.user.user,
});

export default connect(mapStateToProps)(RedirectPath);
