import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  link: {
    cursor: 'pointer',
  },
  linkWrapper: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
  },
  spacer: {
    cursor: 'default',
    display: 'inline',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  submit: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    height: '43px',
  },
}));

export default useStyles;
