import Axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const useStyles = makeStyles(theme => ({
  slider: {
    marginTop: theme.spacing(7),
  },

  swipableRoot: {
    marginTop: theme.spacing(7),
  },
  imageCount: {
    background: 'hsl(36, 6%, 16%, 0.8)',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(0.3),
    paddingBottom: theme.spacing(0.3),
    borderRadius: theme.shape.borderRadius,
    color: '#fff',
  },
}));

const MobileImageSlider = ({ uuid }) => {
  const classes = useStyles();
  const [activeSlide, setActiveSlide] = useState(0);
  const [imageList, setImageList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    Axios.get('/v1/properties/images', {
      params: {
        uuid,
        size: [450, 450],
        count: 100,
      },
    }).then(response => {
      setImageList(response.data);
      setLoading(false);
    });
  }, [uuid]);

  return (
    <div>
      {loading ? (
        <Box mt={7}>
          <Skeleton variant="rect" height={250} width="100%" />
        </Box>
      ) : (
        <>
          <Slider
            className={classes.slider}
            accessibility={false}
            arrows={false}
            beforeChange={(current, next) => {
              setActiveSlide(next);
            }}
          >
            {imageList.map(image => (
              <Box key={image} height="250px">
                <img
                  alt="property"
                  height="100%"
                  width="100%"
                  style={{ objectFit: 'cover' }}
                  src={image}
                />
              </Box>
            ))}
          </Slider>
          <Box
            display="flex"
            position="absolute"
            width="100vw"
            mt={-5}
            pr={2}
            justifyContent="flex-end"
          >
            <Typography
              className={classes.imageCount}
              variant="caption"
              align="right"
            >
              {`${activeSlide + 1} / ${imageList.length}`}
            </Typography>
          </Box>
        </>
      )}
    </div>
  );
};

MobileImageSlider.propTypes = {
  uuid: PropTypes.string.isRequired,
};

export default MobileImageSlider;
