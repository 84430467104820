import { round, each } from 'lodash'
import { statusMap, propertyTypeMap } from 'Constants'
import imageCompression from 'browser-image-compression'

function toRad(num) {
  return (num * Math.PI) / 180
}

export function getDistance(latLongA, latLongB) {
  const latA = latLongA[0]
  const lngA = latLongA[1]
  const latB = latLongB[0]
  const lngB = latLongB[1]
  const R = 6371 // km
  // has a problem with the .toRad() method below.
  const x1 = latB - latA
  const dLat = toRad(x1)
  const x2 = lngB - lngA
  const dLon = toRad(x2)
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRad(latA)) *
      Math.cos(toRad(latB)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  const d = R * c
  return `${round(d, 2)} km`
}

export function numberWithCommas(x) {
  if (!x) return ''
  const parts = x.toString().split(',').join('').split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return parts.join('.')
}

export function formatNumber(number) {
  if (!number) {
    return ''
  }

  const cleanedNumber = String(number).replace(/[^0-9]/g, '')
  const parsedNumber = Number(cleanedNumber)
  const formattedNumber = parsedNumber.toLocaleString(navigator.language, {
    minimumFractionDigits: 0,
  })

  return formattedNumber
}

export function formatDateFromDateString(dateString, options = {}) {
  return new Date(dateString).toLocaleString(
    'th-TH',
    options.dateOnly
      ? {
          day: 'numeric',
          month: 'numeric',
          year: '2-digit',
        }
      : {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          hour12: false,
        }
  )
}

export function formatDateFromInt(dateInt, options = {}) {
  return new Date(Number(dateInt) * 1000).toLocaleString(
    'th-TH',
    options.dateOnly
      ? {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
        }
      : {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          hour12: false,
        }
  )
}

export function getAllSectionFieldsFromLocalStorage() {
  const firstSection = JSON.parse(localStorage.getItem('section1'))
  const secondSection = JSON.parse(localStorage.getItem('section2'))
  const thirdSection = JSON.parse(localStorage.getItem('section3'))
  const fourthSection = JSON.parse(localStorage.getItem('section4'))
  return {
    ...firstSection,
    ...secondSection,
    ...thirdSection,
    ...fourthSection,
  }
}

export function getPropertyDetailsContent(key, content) {
  if (key === 'contractType') {
    const contractType = {
      exClientNotSellable: 'สัญญา EX ขายเองไม่ได้',
      openNoCommission: 'สัญญา Open ลูกค้าขายเองได้ ไม่จ่ายค่าคอม',
      exSellable: 'สัญญา EX ลูกค้าขายเองได้ จ่ายค่าคอม',
    }
    return contractType[content]
  }
  if (key === 'programme') {
    const programme = {
      p1: 'P1 (งานจากพนักงาน)',
      p2: 'P2 (รับค่าลิสต์)',
      callIn: 'Call in (งานจากบริษัท)',
      refer: 'Refer',
    }
    return programme[content]
  }
  if (Array.isArray(content)) {
    if (content.length > 0) return content.join(', ')
    return 'ไม่ได้กรอก'
  }
  if (key === 'selectedNearbyPlaces') return 'ไม่ได้กรอก'
  if (key === 'propertyStatus') return statusMap(content)
  if (key === 'propertyType') return propertyTypeMap[content]
  if (['dateReceived', 'dateExpired', 'dateSubmitted'].includes(key)) {
    return formatDateFromDateString(content, { dateOnly: true })
  }
  if (content === true) return 'Yes'
  if (content === false) return 'No'
  if (content === '' || content === 0 || content === null) return 'ไม่ได้กรอก'
  return content
}

export function getImageData(images) {
  return [
    {
      id: 1,
      name: 'propertyImages',
      heading: 'ภาพทรัพย์',
      images: images.propertyImages,
    },
    {
      id: 2,
      name: 'agentAssignmentFiles',
      heading: 'สัญญาแต่งตั้งนายหน้า',
      images: images.agentAssignmentFiles,
    },
    {
      id: 3,
      name: 'ownerIdCards',
      heading: 'สำเนาบัตรประชาชนเจ้าของกรรมสิทธิ์',
      images: images.ownerIdCards,
    },
    {
      id: 4,
      name: 'ownerHouseDocuments',
      heading: 'สำเนาทะเบียนบ้านเจ้าของกรรมสิทธิ์',
      images: images.ownerHouseDocuments,
    },
    {
      id: 5,
      name: 'propertyHouseDocuments',
      heading: 'สำเนาทะเบียนบ้านหลังที่ขาย',
      images: images.propertyHouseDocuments,
    },
    {
      id: 6,
      name: 'authorizedPartyIdCards',
      heading: 'สำเนาบัตรประชาชนผู้รับมอบอำนาจ',
      images: images.authorizedPartyIdCards,
    },
    {
      id: 7,
      name: 'authorizedPartyHouseDocuments',
      heading: 'สำเนาทะเบียนบ้านของผู้รับมอบอำนาจ',
      images: images.authorizedPartyHouseDocuments,
    },
    {
      id: 8,
      name: 'sellContractDocuments',
      heading: 'หนังสือสัญญาขาย (ทด.13)',
      images: images.sellContractDocuments,
    },
    {
      id: 9,
      name: 'buildingPermits',
      heading: 'ใบอนุญาตก่อสร้าง',
      images: images.buildingPermits,
    },
    {
      id: 10,
      name: 'operationPermits',
      heading: 'ใบอนุญาต รง.4',
      images: images.operationPermits,
    },
  ]
}

export const copyToClipboard = (str) => {
  const el = document.createElement('textarea') // Create a <textarea> element
  el.value = str // Set its value to the string that you want copied
  el.setAttribute('readonly', '') // Make it readonly to be tamper-proof
  el.style.position = 'absolute'
  el.style.left = '-9999px' // Move outside the screen to make it invisible
  document.body.appendChild(el) // Append the <textarea> element to the HTML document
  const selected =
    document.getSelection().rangeCount > 0 // Check if there is any content selected previously
      ? document.getSelection().getRangeAt(0) // Store selection if found
      : false // Mark as false to know no selection existed before
  el.select() // Select the <textarea> content
  document.execCommand('copy') // Copy - only works as a result of a user action (e.g. click events)
  document.body.removeChild(el) // Remove the <textarea> element
  if (selected) {
    // If a selection existed before copying
    document.getSelection().removeAllRanges() // Unselect everything on the HTML document
    document.getSelection().addRange(selected) // Restore the original selection
  }
}

export function convertToWaa(sizeRai, sizeNgan, sizeWaa) {
  return round(sizeWaa + sizeNgan * 100 + sizeRai * 400, 2)
}

export function calculatePricePerWaa(sizeObject, price) {
  const { rai, ngan, waa } = sizeObject
  const waaSize = convertToWaa(Number(rai), Number(ngan), Number(waa))
  const result = round(Number(price) / waaSize)

  if (result === Infinity || Number.isNaN(result)) return 0
  return result
}

export function calculatePricePerSqm(sizeSqm, price) {
  const result = round(Number(price) / Number(sizeSqm))

  if (result === Infinity || Number.isNaN(result)) return 0
  return result
}

function blobToFile(blobData) {
  const fd = new FormData()
  fd.set('a', blobData, blobData.name)
  return fd.get('a')
}

export async function compressImageFiles(files) {
  const promises = []
  const compressOptions = {
    maxWidthOrHeight: 1920, // compressedFile will scale down by ratio to a point that width or height is smaller than maxWidthOrHeight (default: undefined)
    useWebWorker: false, // optional, use multi-thread web worker, fallback to run in main-thread (default: true)
  }
  each(files, (file) => {
    promises.push(imageCompression(file, compressOptions))
  })
  const compressedImageBlobs = await Promise.all(promises)
  const compressedImageFiles = compressedImageBlobs.map((imageBlob) =>
    blobToFile(imageBlob)
  )
  return compressedImageFiles
}

export const urlSafeTitle = (title) => {
  return encodeURI(title.replace(/\s|\//g, '-'))
}

export const isBangkok = (province) => {
  return ['กรุงเทพ', 'กทม', 'กรุงเทพมหานคร'].includes(province)
}

export const formatPhoneNumber = (number) =>
  number &&
  [number.slice(0, 3), number.slice(3, 6), number.slice(6, 10)].join('-')

export const composeAddress = ({ soi, road, amphoe, tambon, province }) => {
  const processedSoi = soi ? `ซอย${soi}` : ''
  const processedRoad = road ? `ถนน${road}` : ''
  const processedAmphoe = isBangkok(province)
    ? `แขวง${tambon}`
    : `ตำบล${tambon}`
  const processedTambon = isBangkok(province)
    ? `เขต${amphoe}`
    : `อำเภอ${amphoe}`
  const processedProvince = `จังหวัด${province}`

  return [
    processedSoi,
    processedRoad,
    processedAmphoe,
    processedTambon,
    processedProvince,
  ]
    .filter((n) => n !== '')
    .join(' ')
}

export const contractTypeMap = {
  exClientNotSellable: 'EX ขายเองไม่ได้',
  openNoCommission: 'Open',
  exSellable: 'EX ขายเองได้',
}

export const programmeMap = {
  callIn: 'Call In',
  refer: 'Refer',
  p1: 'P1',
  p2: 'P2',
}

export function combineDescription({
  title,
  details,
  nearbyPlaces,
  directions,
  name,
  phoneNumber,
  lineId,
  latLong,
}) {
  const propertyLocation = 'ที่ตั้งทรัพย์ (พิกัดแปลง)'
  const officerContact = 'สอบถามเพิ่มเติมหรือนัดชมสถานที่ติดต่อ'
  const officerName = `เจ้าหน้าที่ (officer) : คุณ${name}`
  const mobilePhoneNumber = `เบอร์โทรศัพท์ (Mobile Phone) : ${formatPhoneNumber(
    phoneNumber
  )}`
  const lineID = `ไลน์ไอดี (Line ID): ${lineId}`

  const lines = [
    title,
    '',
    details,
    '',
    nearbyPlaces,
    '',
    directions,
    '',
    propertyLocation,
    latLong,
    '',
    officerContact,
    '',
    officerName,
    mobilePhoneNumber,
    lineID,
  ]

  return lines.join('\n')
}
