/* eslint-disable camelcase */
import React, { useState, useEffect, Fragment } from 'react';
import {
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Box,
  FormHelperText,
} from '@material-ui/core';
import SectionDividerWithIcon from 'Components/shared/SectionDividerWithIcon';
import { FileUploadFieldSet, FastFieldSet } from 'Components/fields';
import { find } from 'lodash';
import { Mouse } from '@material-ui/icons';
import checkboxFieldList from './checkboxFieldList';
import {
  initialImageList,
  initialShowList,
  initialFieldCountList,
} from './initialState';
import uploadFieldList from './uploadFieldList';

export default function FifthSection({ values, property }) {
  const [loading, setLoading] = useState(false);
  const [imageList, setImageList] = useState(initialImageList);
  const [showingList, setShowingList] = useState(initialShowList);
  const [fieldCountList, setFieldCountList] = useState(initialFieldCountList);

  const findImageListByName = name => find(imageList, ['name', name]);

  // Get field count for each field
  useEffect(() => {
    setLoading(true);
    if (property) {
      setFieldCountList(countList => ({
        ...countList,
        ...property.attachmentFieldCount,
      }));
      setShowingList(property.attachmentStatus);
      setImageList(property.images);
    }
    setLoading(false);
  }, [values.listNumber, property]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const handleChange = name => e => {
    setShowingList({
      ...showingList,
      [name]: e.target.checked,
    });
  };

  return (
    !loading && (
      <>
        <Box mt={2}>
          <SectionDividerWithIcon icon={<Mouse />} noMargin>
            เลือกเอกสารที่ต้องการอัพโหลด
          </SectionDividerWithIcon>
          <FormControl style={{ padding: 16 }} fullWidth variant="outlined">
            <FormGroup>
              {checkboxFieldList.map(item => (
                <FormControlLabel
                  key={item.id}
                  control={
                    <Checkbox
                      checked={item.disabled || showingList[item.name]}
                      onChange={handleChange(item.name)}
                      disabled={item.disabled}
                    />
                  }
                  label={item.label}
                />
              ))}
            </FormGroup>
            <FormHelperText>* คือเอกสารที่บังคับ</FormHelperText>
          </FormControl>
        </Box>
        {uploadFieldList(showingList).map(
          list =>
            !list.disabled && (
              <Fragment key={list.name}>
                <SectionDividerWithIcon
                  secondaryText={list.secondaryLabel && list.secondaryLabel}
                  imgIcon={list.imageIcon ? list.icon : null}
                  icon={list.imageIcon ? null : list.icon}
                >
                  {list.label}
                </SectionDividerWithIcon>
                <FileUploadFieldSet
                  setImageList={setImageList}
                  fieldCountList={fieldCountList}
                  setFieldCountList={setFieldCountList}
                  imageList={findImageListByName(list.name)}
                  name={list.name}
                  hasMultipleFields={list.multiple}
                  unitText={list.unitText}
                />
              </Fragment>
            )
        )}
        <FastFieldSet
          name="videoLink"
          label="ลิงค์วิดีโอ YouTube"
          placeholder="https://youtu.be/ZeerrnuLi5E"
          helperText="วิดีโอจะแสดงหน้าโฆษณาทรัพย์"
        />
      </>
    )
  );
}
