import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const HouseIcon =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAC2SURBVEhL7YyxDQJBEAOPhIicBuiAHiiCMqANyqAJeqCDb4AYIhLAlv5Wi2RxZ/QZjDTS/57t8nPsRidnBg/wOcpv3iZhDo+wjld54xupN5sFPME8nOUbM/XfYgnPMA8qc6abFRxgHuqxizW8QDXQkt2PbOAVqnKPN8gNyRbeoSo6coNbb+zhA6rCN3KLmxJVcGyiSo5NnJKTDZySkw2ckpMNnJKTDZySkw2ckpMNnJKT/aMo5QVgxtkJzXBjOAAAAABJRU5ErkJggg==';

export const TownHouseIcon =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADwSURBVEhL7ZAxCgJBDEWnFPEagoW9d/AKNoI3sdIrWGjjjQQFW2+gWIiC/sD8IRM37jra6YMHm8/PiAk/QTtqacGq/C16cBOVbzKAW2jztxjBE7xH5XsCZ/AaM+bSbYz87SXkA1Xe4ByuVLaAtSfjSfRjVe5hFwpjeIaSvzyZPUmdR8jT9OEOSu6ezD7QVNKBa2jzhF7SBW+2OfHybEkXvNnmxMuzpRKJnRO6XCKxc0KXdcGbbU68PFvSBW+2OfHybKlEYueELpdI7JzQZV3wZpsTL8+WdMGbbU68PBygXvzEC3xiCL/xI/L4FP5pQggPALP9kSVEIhsAAAAASUVORK5CYII=';

export const CondoIcon =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAB0SURBVEhL7Y0xDsAgDAP5/6dbZfASLjKBDh046YbIxoxLl2fRbWiM3CYPuLuNG8x3Gw04BWVhCZVJQVlYkgun94R70L0nVHAKysISKpOCsrAkF07vCfege0+o4BSUhSVUJgVl4Wfkwf9/oAHnNjRGXlYZ4wU8FsM9b/KrNAAAAABJRU5ErkJggg==';
export const ResortIcon =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAFkSURBVEhL7dTNKwVRHMbxIUpJIpIFewt7FrJAsaNkYWMjCjsL2WAj/4CFUrKzVLK087LQTVIUeSlLhIQob9/nzDk1NGYmY3b3qU/3/OaeOb+5c+aOl08WqcUM9nCHR+QwjRr8KSWoQC/mMYkhjNj6BJ+4xzASpQqzOMYztIB84AyLaEOB1Qk10vc9iEw7rqEFV3Fkx2H20QylFGoamRa8QievYcyOo7xhALHRFVxCJ92gEju2jqNb04rITMCdMKcD5BbBhd5x8eOYc4hChEYbdQ432V3NE4KL6BcWoxsL2IX26wX6vg+haYImPECbWgflFMEGy1AG7WfqrCPYwD0xenzL/GG69MMtvqIDpBGqO0yVMkXQLTuAnjJlCmowbqp/iPan2h+ajEINlkyVQbqgBhumyiANUAPdukxSDzW4MlXCbGPLH8aOy6EGej0rv83/lk1LiRvrn6wGem0oYXPySRLP+wI9yYjmxENtrAAAAABJRU5ErkJggg==';

export const FarmIcon =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGTSURBVEhL7dO/K0VhHMfx43exYiEWiZISi4WByIidWMRgYbL4CxhMyiDKxCSjlIVkUgwykJDBzyTl9/tznW8dz3NOV5fRp17d5zzn+zz3e557T/Cf3+Yj9GepwCpKUlffv0BzuqeajKMNtOE+lsKxaKw5jVWTcdSlbRRH9+zpMo51rk7LUI61cG4BSjG6MIkV7OACN3iDar1Yl0YbW2qguScch+N0vLgF6r4F83gI55I8Yh1TGEAjEmNHdB9+JrmFjqwbhUibXAxBZxm3oTnEMIpgyUYTtH4WW/BygLgNzRF6oc2ULLRiBmdw6724BUZHNYZ8KPrsR1xDl5iDGvHiFssGKqGoY20c1+0e+lCAxEQXvGAcdhwN2Ea0RtTxIKwuDz1YTF05sUVXaNMEycEEnhHdWJahF07RH2QE0afzoslz6KVSSrEJW2BeMQpLO+J+Dy8nqPoaBvXQtbtI//0OKDrvabzDrRMv9mPqLbyGu0CPXwtFb/ku3Bpzitg04w7uAh1dNZQ6aAO3xuheJ/7zkwTBJ8/t0Q7TTQOfAAAAAElFTkSuQmCC';

export const FactoryIcon =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAACGSURBVEhL7ZBBCoAwDAR79inio/yJT/FR4lM8a6oESpOlNrEHoQNz2GVrwNAhTsVPaX7g/zT/RaYDA7mS450etC5SfWAiNzIOl1gQWsekH2YhM3mQPNxBl8J9KkQbW4RoY4uQfODNgtKD2izggVeINrYIyQfeLCg9qM0CHniFaGOLnbeEcAFBjcU6kaT1ngAAAABJRU5ErkJggg==';

export const CommercialBuildingIcon =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADwSURBVEhL7ZAxCgJBDEWnFPEagoW9d/AKNoI3sdIrWGjjjQQFW2+gWIiC/sD8IRM37jra6YMHm8/PiAk/QTtqacGq/C16cBOVbzKAW2jztxjBE7xH5XsCZ/AaM+bSbYz87SXkA1Xe4ByuVLaAtSfjSfRjVe5hFwpjeIaSvzyZPUmdR8jT9OEOSu6ezD7QVNKBa2jzhF7SBW+2OfHybEkXvNnmxMuzpRKJnRO6XCKxc0KXdcGbbU68PFvSBW+2OfHybKlEYueELpdI7JzQZV3wZpsTL8+WdMGbbU68PBygXvzEC3xiCL/xI/L4FP5pQggPALP9kSVEIhsAAAAASUVORK5CYII=';

export const TwinHouseIcon =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAEsSURBVEhL7dO/K8RxHMfxj5/HZjCzyyI2g0UGm8ViMpjsFoN/wKwrZVWS7AabTUlRKKVsBoOySDxf5/tW3t5f9/1+zmDwrEffu++973Pd5+6T/lo96P14+Ltp0WVc4wpL6EbH9WMFt3hzLrGIrA9qYBV38At751hAFyq3g2ixnzRRuQmcwi9ygjN3T+4xg9rZAg/Qj6xt0A+u7XuEva4tzeoVWxhqPUtpAPY3HcY2NJPdZHFV87jBBWZ1o2iquGY3ikPYdpgDjCA7nYF1PMMvbvTaBgZRqzno1EaLRnRedBbapq+8j2iRKo4whm/1YQ1PiN5Yxws2Yf+8VruIhjuxh8+mUWfP2znGOL6kAxQNG180Y0qLho0vmjGlRcPGF82Y0qJh44tmzH9VS+kdvG3XQQeizf4AAAAASUVORK5CYII=';

export const WareHoseIcon =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAQAAABKfvVzAAAAAmJLR0QA/4ePzL8AAACZSURBVDjLY2CgEuAAQhKAOcM1hjsMdsSa3cHwh+E/EP5lmMnARYzZ/4EaOoEQpO0aUISg2bcZbJE047QHmzTMCAz/MDO04XQAzJFtQFVw0Ap1NwcOp0L804IQegXkuuENDHegipcILigQCQEUNWRr+I8DjmogS8NTIMcGrwZbIPkEoaEdp1Jk2IbQwAbU8hSv4idA5WwMdAEA94vaUFWaj/UAAAAASUVORK5CYII=';

export const DeedIcon =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAQAAABKfvVzAAAAAmJLR0QA/4ePzL8AAADMSURBVDjLvdExSgNREAbgL4UxQSxdMIX3iZBbBK30AmksvUTOEBAUixDwCrGwEoM5xQruWKjRuOvbXRD/qQbeNwzz+L8cW4sfdZEC5edPaRKlOvIoXDYH0qQKJEk1SJDfwCdpAKr534O8DVgY6JoomoE7e+jgRFEP5voYu9HDeR14nz72Ktzq1X3cQh+nio9+lgaFwWZ6CC9GaZDb0XG96VeyupUm6LoScivhwWEZrLeWOsOumaEDy2oy9LxFvg6ZuRemWiQztd8GfMsbRvvwOFrh0/sAAAAASUVORK5CYII=';

export const ContractIcon =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAQAAABKfvVzAAAAAmJLR0QA/4ePzL8AAACsSURBVDjLY2CgH/BieMzwHw024NOAqfw+fi3/MaAcw10g2UG8Bgb8WrBpwKsFuwY8WnBpgGkhQgN27ZRreMHgwcDHEMzwgVgNoVB+KrEahIHsHUAsQawGMSB7OxCLkuIkbiAuZDjBIM/gyPAXv4a3DJVgnhTDQbDjGBhm4teACvRwysCFhRnsGRYyzGJQZRBgyGb4xTCVQZxqEfcYr/JHmBo8gYK4lXvQr6wAAJTo7yMMU6p5AAAAAElFTkSuQmCC';

export const ConstructionIcon =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAQAAABKfvVzAAAAAmJLR0QA/4ePzL8AAAEkSURBVDgRxcGxS5QBAAfQd8nhEDi4aGu4hG4quBiUS2YQ/gG2CDYIgnODq4JjY0uROAQ1KiG1HElDq5tgdyI0FCIYRN795OMjUMO7m+o9/8WMQw3TutYQUde1hoi6rk1rqHugg6q75i164qYu3HIkIuLYc0PamrEr9r10LCJODbtWxRcRrzwWERHr2pi0LSIi4oOo6WDMOy0R8VDLT1UdjdhwJn5rijFt9ZhSGPLCLxE7RrUxKnb0KgxadSqiZso1lsVHfTbdVxiw5kTEJ1t6/eWteIZZsa3Ub8V3EUuuqPgmxhXizB8VWyIOVF1yR/zQoxBNpQmfRUTMueSpeKMUTaWaiIjYc8MFr8WCUrQU7omIiIhHLvgqbitFFN6LiIiIXf/EOX1vjFmXjbJxAAAAAElFTkSuQmCC';

export const MarkerIcon =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAABmJLR0QAAAAAAAD5Q7t/AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH4wsEAg4LrmNFogAAAhFJREFUaN7tmb9LW1EUxz/vOYjQziJYMNQKDkJrxygxOkrpUNHBXfcurUqh9J/QzUUQ28Wog4sIJWgDRdyMa0upv1qEuiStSYe8B0E0nhvvuXnB94Ez5b57v9/z7rs/TiAm5n7jWe6rH3gJDAIdQGfw23fgCPgMZIC9qCVhHDgEysLIA2OWE1gXj4GcgfCrsQskGiV+ADi+g/gwzoBh1+LTQNGC+DAKQMqV+ARwYlF89ZvodvHB5hTEh7Gj/WFPKIoP45Vm9g8dGDjQMvDcQMQqkCoHAEPAmsHzzzQMfBAO/qZ8A8CssI/3Gga2JZkv3wKwLuhnS8OAZP6nBAbSwqOG9cPcH+BBrQYVfYJBvVuHvQAeSvryDQz4uMPTEHUqyGxa0GbExlj1GJDMy9eW2uQ1DOQEbV54njdXI/vvgFFLYxmTNNiI1oF01cozDGwYPJ/UMOADPxwcJX6azAyTKVQClh2sQEvBWGpXyEvF7JeAJ9oZyigayLjYZPqU3kIJeOpqp/yoYGDF5aW+B/hrUfw/oNd1ZWLRooHFRtSF2oHfFsSfB2XIhjBtwcBUI0uLPpC9g/gvjo/pNy6rhTqrcX1RqVDP1GHgLRHCF176w8gCLUSMR8JV6RzoIqJMCgxMEnEWaoifpwloBb5eI34faKNJSFCp91fX/hM0GYPBWl9E8S8kzaXsG/AL2AQ+ERMTo8J/eDujJsua21oAAAAASUVORK5CYII=';

export const LandIcon = props => (
  <SvgIcon {...props}>
    <path d="M17 3A3 3 0 1 0 17 9 3 3 0 1 0 17 3zM16.735 11.475L11.089 18.003 8.273 17.994 11.942 13.728 8.506 8.986 0 20 24 20z" />
  </SvgIcon>
);

export const DoubleHouseIcon = props => (
  <SvgIcon {...props}>
    <path d="M 9 2.7460938 L 2 8.03125 L 2 19 C 2 20.105 2.895 21 4 21 L 16 21 L 20.5 21 C 21.317063 21 22 20.317063 22 19.5 L 22 8.03125 L 15.035156 2.7734375 L 11.849609 4.8984375 L 9 2.7460938 z M 14.964844 5.2265625 L 20 9.0273438 L 20 19 L 16 19 L 16 17 L 18 17 L 18 15 L 16 15 L 16 13 L 18 13 L 18 11 L 16 11 L 16 8.03125 L 13.541016 6.1757812 L 14.964844 5.2265625 z M 6 9 L 8 9 L 8 11 L 6 11 L 6 9 z M 10 9 L 12 9 L 12 11 L 10 11 L 10 9 z M 6 13 L 8 13 L 8 15 L 6 15 L 6 13 z M 10 13 L 12 13 L 12 15 L 10 15 L 10 13 z M 6 17 L 8 17 L 8 19 L 6 19 L 6 17 z M 10 17 L 12 17 L 12 19 L 10 19 L 10 17 z" />
  </SvgIcon>
);

export const UserPlusIcon = props => (
  <SvgIcon {...props}>
    <path d="M15,14C12.33,14 7,15.33 7,18V20H23V18C23,15.33 17.67,14 15,14M6,10V7H4V10H1V12H4V15H6V12H9V10M15,12A4,4 0 0,0 19,8A4,4 0 0,0 15,4A4,4 0 0,0 11,8A4,4 0 0,0 15,12Z" />
  </SvgIcon>
);

export const ShowerIcon = props => {
  return (
    <SvgIcon {...props}>
      <path d="M21,14V15C21,16.91 19.93,18.57 18.35,19.41L19,22H17L16.5,20C16.33,20 16.17,20 16,20H8C7.83,20 7.67,20 7.5,20L7,22H5L5.65,19.41C4.07,18.57 3,16.91 3,15V14H2V12H20V5A1,1 0 0,0 19,4C18.5,4 18.12,4.34 18,4.79C18.63,5.33 19,6.13 19,7H13A3,3 0 0,1 16,4C16.06,4 16.11,4 16.17,4C16.58,2.84 17.69,2 19,2A3,3 0 0,1 22,5V14H21V14M19,14H5V15A3,3 0 0,0 8,18H16A3,3 0 0,0 19,15V14Z" />
    </SvgIcon>
  );
};

export const AreaSizeIcon = props => {
  return (
    <SvgIcon {...props}>
      <path d="M2,2H8V4H16V2H22V8H20V16H22V22H16V20H8V22H2V16H4V8H2V2M16,8V6H8V8H6V16H8V18H16V16H18V8H16M4,4V6H6V4H4M18,4V6H20V4H18M4,18V20H6V18H4M18,18V20H20V18H18Z" />
    </SvgIcon>
  );
};

export const UtilitySpaceIcon = props => {
  return (
    <SvgIcon {...props}>
      <path d="M9.5,13.09L10.91,14.5L6.41,19H10V21H3V14H5V17.59L9.5,13.09M10.91,9.5L9.5,10.91L5,6.41V10H3V3H10V5H6.41L10.91,9.5M14.5,13.09L19,17.59V14H21V21H14V19H17.59L13.09,14.5L14.5,13.09M13.09,9.5L17.59,5H14V3H21V10H19V6.41L14.5,10.91L13.09,9.5Z" />
    </SvgIcon>
  );
};

export const FileDocumentIcon = props => {
  return (
    <SvgIcon {...props}>
      <path d="M13,9H18.5L13,3.5V9M6,2H14L20,8V20A2,2 0 0,1 18,22H6C4.89,22 4,21.1 4,20V4C4,2.89 4.89,2 6,2M15,18V16H6V18H15M18,14V12H6V14H18Z" />
    </SvgIcon>
  );
};

export const HomeCityIcon = props => {
  return (
    <SvgIcon {...props}>
      <path d="M0,21V10L7.5,5L15,10V21H10V14H5V21H0M24,2V21H17V8.93L16,8.27V6H14V6.93L10,4.27V2H24M21,14H19V16H21V14M21,10H19V12H21V10M21,6H19V8H21V6Z" />
    </SvgIcon>
  );
};

export const FloorPlanIcon = props => {
  return (
    <SvgIcon {...props}>
      <path d="M10,5V10H9V5H5V13H9V12H10V17H9V14H5V19H12V17H13V19H19V17H21V21H3V3H21V15H19V10H13V15H12V9H19V5H10Z" />
    </SvgIcon>
  );
};

export const BuildingIcon = props => {
  return (
    <SvgIcon {...props}>
      <path d="M5,3V21H11V17.5H13V21H19V3H5M7,5H9V7H7V5M11,5H13V7H11V5M15,5H17V7H15V5M7,9H9V11H7V9M11,9H13V11H11V9M15,9H17V11H15V9M7,13H9V15H7V13M11,13H13V15H11V13M15,13H17V15H15V13M7,17H9V19H7V17M15,17H17V19H15V17Z" />
    </SvgIcon>
  );
};

export const PersonCardIcon = props => (
  <SvgIcon {...props}>
    <path d="M22,3H2C0.91,3.04 0.04,3.91 0,5V19C0.04,20.09 0.91,20.96 2,21H22C23.09,20.96 23.96,20.09 24,19V5C23.96,3.91 23.09,3.04 22,3M22,19H2V5H22V19M14,17V15.75C14,14.09 10.66,13.25 9,13.25C7.34,13.25 4,14.09 4,15.75V17H14M9,7A2.5,2.5 0 0,0 6.5,9.5A2.5,2.5 0 0,0 9,12A2.5,2.5 0 0,0 11.5,9.5A2.5,2.5 0 0,0 9,7M14,7V8H20V7H14M14,9V10H20V9H14M14,11V12H18V11H14" />
  </SvgIcon>
);

export const FileMultipleIcon = props => (
  <SvgIcon {...props}>
    <path d="M16,15H9V13H16V15M19,11H9V9H19V11M19,7H9V5H19V7M3,5V21H19V23H3A2,2 0 0,1 1,21V5H3M21,1A2,2 0 0,1 23,3V17C23,18.11 22.11,19 21,19H7A2,2 0 0,1 5,17V3C5,1.89 5.89,1 7,1H21M7,3V17H21V3H7Z" />
  </SvgIcon>
);

export const MegaPhoneIcon = props => {
  return (
    <SvgIcon {...props}>
      <path d="M12,8H4A2,2 0 0,0 2,10V14A2,2 0 0,0 4,16H5V20A1,1 0 0,0 6,21H8A1,1 0 0,0 9,20V16H12L17,20V4L12,8M15,15.6L13,14H4V10H13L15,8.4V15.6M21.5,12C21.5,13.71 20.54,15.26 19,16V8C20.53,8.75 21.5,10.3 21.5,12Z" />
    </SvgIcon>
  );
};

export const FileSearchIcon = props => (
  <SvgIcon {...props}>
    <path d="M15.5,12C18,12 20,14 20,16.5C20,17.38 19.75,18.21 19.31,18.9L22.39,22L21,23.39L17.88,20.32C17.19,20.75 16.37,21 15.5,21C13,21 11,19 11,16.5C11,14 13,12 15.5,12M15.5,14A2.5,2.5 0 0,0 13,16.5A2.5,2.5 0 0,0 15.5,19A2.5,2.5 0 0,0 18,16.5A2.5,2.5 0 0,0 15.5,14M5,3H19C20.11,3 21,3.89 21,5V13.03C20.5,12.23 19.81,11.54 19,11V5H5V19H9.5C9.81,19.75 10.26,20.42 10.81,21H5C3.89,21 3,20.11 3,19V5C3,3.89 3.89,3 5,3M7,7H17V9H7V7M7,11H12.03C11.23,11.5 10.54,12.19 10,13H7V11M7,15H9.17C9.06,15.5 9,16 9,16.5V17H7V15Z" />
  </SvgIcon>
);

export const FileViewIcon = props => (
  <SvgIcon {...props}>
    <path d="M17,18C17.56,18 18,18.44 18,19C18,19.56 17.56,20 17,20C16.44,20 16,19.56 16,19C16,18.44 16.44,18 17,18M17,15C14.27,15 11.94,16.66 11,19C11.94,21.34 14.27,23 17,23C19.73,23 22.06,21.34 23,19C22.06,16.66 19.73,15 17,15M17,21.5A2.5,2.5 0 0,1 14.5,19A2.5,2.5 0 0,1 17,16.5A2.5,2.5 0 0,1 19.5,19A2.5,2.5 0 0,1 17,21.5M9.14,19.75L8.85,19L9.14,18.26C10.43,15.06 13.5,13 17,13C18.05,13 19.06,13.21 20,13.56V8L14,2H6C4.89,2 4,2.89 4,4V20A2,2 0 0,0 6,22H10.5C9.95,21.34 9.5,20.58 9.14,19.75M13,3.5L18.5,9H13V3.5Z" />
  </SvgIcon>
);

export const ReviewIcon = props => (
  <SvgIcon {...props}>
    <path d="M20 2H4c-1.1 0-1.99.9-1.99 2L2 22l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zM6 14v-2.47l6.88-6.88c.2-.2.51-.2.71 0l1.77 1.77c.2.2.2.51 0 .71L8.47 14H6zm12 0h-7.5l2-2H18v2z" />
  </SvgIcon>
);

export const SaveEditIcon = props => (
  <SvgIcon {...props}>
    <path d="M10,19L10.14,18.86C8.9,18.5 8,17.36 8,16A3,3 0 0,1 11,13C12.36,13 13.5,13.9 13.86,15.14L20,9V7L16,3H4C2.89,3 2,3.9 2,5V19A2,2 0 0,0 4,21H10V19M4,5H14V9H4V5M20.04,12.13C19.9,12.13 19.76,12.19 19.65,12.3L18.65,13.3L20.7,15.35L21.7,14.35C21.92,14.14 21.92,13.79 21.7,13.58L20.42,12.3C20.31,12.19 20.18,12.13 20.04,12.13M18.07,13.88L12,19.94V22H14.06L20.12,15.93L18.07,13.88Z" />
  </SvgIcon>
);

export const FileEyeIcon = props => (
  <SvgIcon {...props}>
    <path d="M17,18C17.56,18 18,18.44 18,19C18,19.56 17.56,20 17,20C16.44,20 16,19.56 16,19C16,18.44 16.44,18 17,18M17,15C14.27,15 11.94,16.66 11,19C11.94,21.34 14.27,23 17,23C19.73,23 22.06,21.34 23,19C22.06,16.66 19.73,15 17,15M17,21.5A2.5,2.5 0 0,1 14.5,19A2.5,2.5 0 0,1 17,16.5A2.5,2.5 0 0,1 19.5,19A2.5,2.5 0 0,1 17,21.5M9.14,19.75L8.85,19L9.14,18.26C10.43,15.06 13.5,13 17,13C18.05,13 19.06,13.21 20,13.56V8L14,2H6C4.89,2 4,2.89 4,4V20A2,2 0 0,0 6,22H10.5C9.95,21.34 9.5,20.58 9.14,19.75M13,3.5L18.5,9H13V3.5Z" />
  </SvgIcon>
);

export const FormContinueIcon = props => (
  <SvgIcon {...props}>
    <path d="M12.08,4.08L20,12L12.08,19.92L10.67,18.5L16.17,13H2V11H16.17L10.67,5.5L12.08,4.08M20,12V22H22V2H20V12Z" />
  </SvgIcon>
);
