import React from 'react';
import { Typography, Icon, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  dividerWrapper: {
    display: 'flex',
    width: '100%',
    alignContent: 'center',
    justifyContent: 'center',
  },
  dividerLine: {
    borderTop: '1px solid #0000002b',
    marginTop: '12px',
    flex: 1,
  },
  icon: {
    marginLeft: theme.spacing(1),
  },
  orText: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),

    textAlign: 'center',
  },
  secondaryText: {
    fontWeight: 400,
    marginLeft: theme.spacing(5),
  },
  imgIcon: {
    marginLeft: theme.spacing(1),
    position: 'relative',
    filter:
      'invert(19%) sepia(76%) saturate(289%) hue-rotate(172deg) brightness(90%) contrast(93%)',
  },
}));

const SectionDividerWithIcon = props => {
  const {
    icon,
    imgIcon,
    imgIconAlt,
    secondaryText,
    children,
    noMargin,
  } = props;
  const classes = useStyles();
  return (
    <Box mt={4} mb={noMargin ? 0 : 4}>
      <div className={classes.dividerWrapper}>
        {icon && (
          <Icon className={classes.icon} color="primary">
            {icon}
          </Icon>
        )}
        {imgIcon && (
          <img className={classes.imgIcon} src={imgIcon} alt={imgIconAlt} />
        )}

        <Typography color="textPrimary" className={classes.orText}>
          {children}
        </Typography>
        <span className={classes.dividerLine} />
      </div>
      <Typography
        color="textSecondary"
        variant="caption"
        className={classes.secondaryText}
      >
        {secondaryText}
      </Typography>
    </Box>
  );
};

SectionDividerWithIcon.defaultProps = {
  children: '',
  icon: null,
  imgIcon: null,
  imgIconAlt: '',
  secondaryText: '',
  noMargin: false,
};

SectionDividerWithIcon.propTypes = {
  children: PropTypes.string,
  icon: PropTypes.element,
  imgIcon: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  imgIconAlt: PropTypes.string,
  secondaryText: PropTypes.string,
  noMargin: PropTypes.bool,
};

export default SectionDividerWithIcon;
