import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Box } from '@material-ui/core';
import { filterValueMap } from '../constants';

const FilterChips = ({ activeFilters }) => {
  return (
    <Box mt={2} display="flex" px={2} flexWrap="wrap">
      {activeFilters.map(key => (
        <Box
          px={1}
          py={0.3}
          borderRadius={8}
          border="1px solid #0D76D9"
          key={key}
          mr={1}
          my={0.5}
        >
          <Typography variant="subtitle2" style={{ color: '#0D76D9' }}>
            {filterValueMap[key] ? filterValueMap[key] : key}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

FilterChips.propTypes = {
  activeFilters: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default FilterChips;
