import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';
import { Box } from '@material-ui/core';
import { debounce } from 'lodash';

const { Search } = Input;

const TableSearch = ({ fetch }) => {
  const delayedFetch = debounce(value => {
    fetch({
      page: 1,
      items: 10,
      sortBy: 'list_number',
      sortDirection: 'desc',
      search: value.trim() || null,
    });
  }, 500);

  return (
    <Box py={1} maxWidth="500px" mx="auto">
      <Search
        size="large"
        placeholder="ค้นหาทรัพย์จากชื่อ..."
        onChange={e => delayedFetch(e.target.value)}
        onSearch={value =>
          fetch({
            page: 1,
            items: 10,
            sortBy: 'list_number',
            sortDirection: 'desc',
            search: value.trim() || null,
          })
        }
        enterButton
      />
    </Box>
  );
};

TableSearch.propTypes = {
  fetch: PropTypes.func.isRequired,
};

export default TableSearch;
