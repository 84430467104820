import { SET_NEARBY_PLACES } from '../constants/action-types';

const initialState = {
  nearbyPlaces: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_NEARBY_PLACES:
      return {
        ...state,
        nearbyPlaces: { ...state.nearbyPlaces, ...action.nearbyPlaces },
      };

    default:
      return state;
  }
};
