export const RESET_FORM = 'RESET_FORM';

// FIRST SECTION
export const SET_LIST_NUMBER = 'SET_LIST_NUMBER';
export const SET_CONTRACT_TYPE = 'SET_CONTRACT_TYPE';
export const SET_COMMISSION_PERCENTAGE = 'SET_COMMISSION_PERCENTAGE';
export const SET_PROGRAMME = 'SET_PROGRAMME';
export const SET_REFER_FROM = 'SET_REFER_FROM';
export const SET_REFER_PERCENTAGE = 'SET_REFER_PERCENTAGE';
export const SET_DATE_RECEIVED = 'SET_DATE_RECEIVED';
export const SET_DATE_EXPIRED = 'SET_DATE_EXPIRED';
export const SET_DATE_SUBMITTED = 'SET_DATE_SUBMITTED';
export const SET_SELLER_NAME = 'SET_SELLER_NAME';
export const SET_SELLER_ADDRESS_NUMBER = 'SET_SELLER_ADDRESS_NUMBER';
export const SET_SELLER_SOI = 'SET_SELLER_SOI';
export const SET_SELLER_ROAD = 'SET_SELLER_ROAD';
export const SET_SELLER_TAMBON = 'SET_SELLER_TAMBON';
export const SET_SELLER_AMPHOE = 'SET_SELLER_AMPHOE';
export const SET_SELLER_PROVINCE = 'SET_SELLER_PROVINCE';
export const SET_SELLER_POST_CODE = 'SET_SELLER_POST_CODE';
export const SET_SELLER_PHONE = 'SET_SELLER_PHONE';
export const SET_SELLER_LINE_ID = 'SET_SELLER_LINE_ID';

// SECOND SECTION

export const SET_DEED_NUMBER = 'SET_DEED_NUMBER';
export const SET_PARCEL_NUMBER = 'SET_PARCEL_NUMBER';
export const SET_DEALING_FILE_NUMBER = 'SET_DEALING_FILE_NUMBER';
export const SET_DEED_TAMBON = 'SET_DEED_TAMBON';
export const SET_DEED_AMPHOE = 'SET_DEED_AMPHOE';
export const SET_DEED_PROVINCE = 'SET_DEED_PROVINCE';
export const SET_PROJECT_NAME = 'SET_PROJECT_NAME';
export const SET_LAT_LONG = 'SET_LAT_LONG';
export const SET_SOI = 'SET_SOI';
export const SET_ROAD = 'SET_ROAD';
export const SET_TAMBON = 'SET_TAMBON';
export const SET_AMPHOE = 'SET_AMPHOE';
export const SET_PROVINCE = 'SET_PROVINCE';
export const SET_REGION = 'SET_REGION';
export const SET_ZONE = 'SET_ZONE';

// THIRD SECTION
export const SET_PROPERTY_TYPE = 'SET_PROPERTY_TYPE';
export const SET_PROPERTY_STATUS = 'SET_PROPERTY_STATUS';
export const SET_UTILITY_SPACE = 'SET_UTILITY_SPACE';
export const SET_AREA_SIZE_RAI = 'SET_AREA_SIZE_RAI';
export const SET_AREA_SIZE_NGAN = 'SET_AREA_SIZE_NGAN';
export const SET_AREA_SIZE_WAA = 'SET_AREA_SIZE_WAA';
export const SET_SELL_PRICE = 'SET_SELL_PRICE';
export const SET_RENT_PRICE = 'SET_RENT_PRICE';
export const SET_EXTERIOR_CONDITION = 'SET_EXTERIOR_CONDITION';
export const SET_INTERIOR_CONDITION = 'SET_INTERIOR_CONDITION';
export const SET_FLOOR_COUNT = 'SET_FLOOR_COUNT';
export const SET_BATHROOM_COUNT = 'SET_BATHROOM_COUNT';
export const SET_BEDROOM_COUNT = 'SET_BEDROOM_COUNT';
export const SET_OTHER_ROOM_COUNT = 'SET_OTHER_ROOM_COUNT';
export const SET_ADDITIONAL_EQUIPMENT = 'SET_ADDITIONAL_EQUIPMENT';
export const SET_ADDITIONAL_EQUIPMENT_STATE = 'SET_ADDITIONAL_EQUIPMENT_STATE';
export const SET_FEATURES = 'SET_FEATURES';
export const SET_FEATURE_STATE = 'SET_FEATURE_STATE';
export const SET_HEADING_DIRECTION = 'SET_HEADING_DIRECTION';
export const SET_LANDSCAPE_TYPE = 'SET_LANDSCAPE_TYPE';

export const SET_CONDO_BUILDING_NUMBER = 'SET_CONDO_BUILDING_NUMBER';
export const SET_CONDO_LIVING_FLOOR = 'SET_CONDO_LIVING_FLOOR';
export const SET_CONDO_MAX_FLOOR_COUNT = 'SET_CONDO_MAX_FLOOR_COUNT';
export const SET_CONDO_PRICE_PER_SQM = 'SET_CONDO_PRICE_PER_SQM';

export const SET_LAND_PRICE_PER_WAA = 'SET_LAND_PRICE_PER_WAA';
export const SET_LAND_ESTIMATED_PRICE_PER_WAA =
  'SET_LAND_ESTIMATED_PRICE_PER_WAA';
export const SET_LAND_CONDITIONS = 'SET_LAND_CONDITIONS';
export const SET_LAND_CONDITION_STATE = 'SET_LAND_CONDITION_STATE';
export const SET_LAND_FRONT_WIDTH = 'SET_LAND_FRONT_WIDTH';
export const SET_LAND_OFFICIAL_ZONE_TYPE = 'SET_LAND_OFFICIAL_ZONE_TYPE';
export const SET_LAND_ROAD_CONDITION = 'SET_LAND_ROAD_CONDITION';
export const SET_LAND_ROAD_LENGTH = 'SET_LAND_ROAD_LENGTH';

export const SET_WAREHOUSE_HAS_OPERATION_PERMIT =
  'SET_WAREHOUSE_HAS_OPERATION_PERMIT';
export const SET_WAREHOUSE_IS_INSIDE_PURPLE_ZONE =
  'SET_WAREHOUSE_IS_INSIDE_PURPLE_ZONE';
export const SET_WAREHOUSE_MAXIMUM_BEAM_WEIGHT =
  'SET_WAREHOUSE_MAXIMUM_BEAM_WEIGHT';
export const SET_WAREHOUSE_MAXIMUM_WEIGHT_CAPACITY =
  'SET_WAREHOUSE_MAXIMUM_WEIGHT_CAPACITY';
export const SET_WAREHOUSE_TRAILER_ACCESSIBLE =
  'SET_WAREHOUSE_TRAILER_ACCESSIBLE';

// FOURTH SECTION
export const SET_ADS_TITLE = 'SET_ADS_TITLE';
export const SET_ADS_DETAILS = 'SET_ADS_DETAILS';
export const SET_ADS_NEARBY_PLACES = 'SET_ADS_NEARBY_PLACES';
export const SET_ADS_DIRECTIONS = 'SET_ADS_DIRECTIONS';

export const SET_NEARBY_PLACES = 'SET_NEARBY_PLACES';
export const SET_SHOULD_FETCH_DATA = 'SET_SHOULD_FETCH_DATA';

export const SET_NEARBY_DATA_FETCHED = 'SET_NEARBY_DATA_FETCHED';
export const SET_FILES_TO_UPLOAD = 'SET_FILES_TO_UPLOAD';
export const REMOVE_FILE_TO_UPLOAD = 'REMOVE_FILE_TO_UPLOAD';

export const SET_PRICE_PER_WAA = 'SET_PRICE_PER_WAA';
export const SET_PRICE_PER_SQM = 'SET_PRICE_PER_SQM';
export const SET_SELECTED_NEARBY_PLACES = 'SET_SELECTED_NEARBY_PLACES';

export const REHYDRATE = 'REHYDRATE';

// USER REDUCER
export const SET_USER = 'SET_USER';

// LOADING REDUCER
export const SET_LOADING = 'SET_LOADING';
export const SET_ACTIVE_STEP = 'SET_ACTIVE_STEP';

// DRAWER REDUCER
export const SET_DRAWER_OPEN = 'SET_DRAWER_OPEN';
