import * as Yup from 'yup';

export const adsEditValidationSchema = Yup.object().shape({
  postDetails: Yup.string()
    .required('จำเป็นต้องกรอก')
    .min(79, 'กรอกขั้นต่ำ 79 ตัวอักษร'),
  postDirections: Yup.string()
    .required('จำเป็นต้องกรอก')
    .min(79, 'กรอกขั้นต่ำ 79 ตัวอักษร'),
  postNearbyPlaces: Yup.string()
    .required('จำเป็นต้องกรอก')
    .min(79, 'กรอกขั้นต่ำ 79 ตัวอักษร'),
  postPropertyType: Yup.number()
    .oneOf(Array.from({ length: 15 }, (_v, k) => k + 1))
    .required(),
});

export const adsCreateValidationSchema = Yup.object().shape({
  title: Yup.string()
    .required('จำเป็นต้องกรอก')
    .min(50, 'ขั้นต่ำ 50 ตัวอักษร')
    .max(140, 'สูงสุด 140 ตัวอักษร'),
  shortTitle: Yup.string()
    .required('จำเป็นต้องกรอก')
    .max(50, 'สูงสุด 50 ตัวอักษร'),
  postDetails: Yup.string()
    .required('จำเป็นต้องกรอก')
    .min(79, 'กรอกขั้นต่ำ 79 ตัวอักษร'),
  postDirections: Yup.string()
    .required('จำเป็นต้องกรอก')
    .min(79, 'กรอกขั้นต่ำ 79 ตัวอักษร'),
  postNearbyPlaces: Yup.string()
    .required('จำเป็นต้องกรอก')
    .min(79, 'กรอกขั้นต่ำ 79 ตัวอักษร'),
  postPropertyType: Yup.number()
    .oneOf(Array.from({ length: 15 }, (_v, k) => k + 1))
    .required(),
});
