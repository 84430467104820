import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Chip } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import Axios from 'axios';
import snakecaseKeys from 'snakecase-keys';
import RejectBox from '../RejectWrapper/RejectBox';

const useStyles = makeStyles(theme => ({
  chip: {
    marginRight: theme.spacing(1),
  },
}));

function CommentChip({ rejectMessage, fetchProperty, uuid, id }) {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [rejectBoxAnchorEl, setRejectBoxAnchorEl] = useState(null);

  function handleRejectClick(e) {
    setRejectBoxAnchorEl(e.currentTarget);
  }

  function handleRejectBoxClose() {
    setRejectBoxAnchorEl(null);
  }

  function handleCommentDelete() {
    Axios.patch(
      '/v1/properties/update',
      snakecaseKeys({
        uuid,
        rejectingField: JSON.stringify({ [id]: '' }),
      })
    ).then(() => {
      fetchProperty();
      enqueueSnackbar('ลบคอมเมนต์แล้ว');
    });
  }
  return (
    <>
      <Chip
        onClick={handleRejectClick}
        variant="outlined"
        clickable
        onDelete={handleCommentDelete}
        className={classes.chip}
        size="small"
        label="มีคอมเมนต์"
        color="secondary"
      />
      <RejectBox
        rejectMessage={rejectMessage}
        fetchProperty={fetchProperty}
        uuid={uuid}
        id={id}
        open={Boolean(rejectBoxAnchorEl)}
        handleClose={handleRejectBoxClose}
        anchorEl={rejectBoxAnchorEl}
      />
    </>
  );
}

CommentChip.propTypes = {
  rejectMessage: PropTypes.string.isRequired,
  fetchProperty: PropTypes.func.isRequired,
  uuid: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

export default CommentChip;
