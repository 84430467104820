import React, { useState, Fragment } from 'react';
import { useSnackbar } from 'notistack';
import { useTheme } from '@material-ui/core/styles';
import RefreshIcon from '@material-ui/icons/Refresh';
import {
  Grid,
  Button,
  Slider,
  Typography,
  Box,
  Divider,
} from '@material-ui/core';
import { connect } from 'formik';
import GoogleMapsAPI from 'Components/services/GoogleMapsAPI';

function GoogleMapsFetchingWrapper(props) {
  const { requestList, formik } = props;
  const { setFieldValue } = formik;

  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [shouldFetchData, setShouldFetchData] = useState(false);
  const [fetchingRadius, setFetchingRadius] = useState(5);
  const [isFetched, setIsFetched] = useState(false);
  const [fetchingData, setFetchingData] = useState(false);

  const fetchData = () => {
    if (fetchingRadius === '' || fetchingRadius === '0') {
      enqueueSnackbar('กรุณากรอกรัศมีให้ถูกต้อง');
    } else {
      setFieldValue('nearbyPlaces', {});
      setFieldValue('selectedNearbyPlaces', {
        hospitalList: [],
        departmentStoreList: [],
        governmentBuildingList: [],
        supermarketList: [],
        schoolList: [],
        universityList: [],
        tollWayList: [],
        trainStationList: [],
      });
      setShouldFetchData(true);
    }
  };

  const handleRadiusChange = (event, newValue) => {
    setFetchingRadius(newValue);
  };

  return (
    <Fragment>
      <Grid
        style={{ marginBottom: theme.spacing(2.5) }}
        spacing={2}
        container
        justify="flex-end"
        alignItems="center"
      >
        <Grid item xs={12}>
          <Divider />
          <Box
            mt={2}
            ml={1}
            mr={1}
            mb={6}
            display="flex"
            justifyContent="space-between"
          >
            <Typography>ระยะที่ใช้ดึงข้อมูล</Typography>
            <Typography color="textSecondary">{`${fetchingRadius} กิโลเมตร`}</Typography>
          </Box>
          <Box pl={1} pr={1}>
            <Slider
              onChange={handleRadiusChange}
              value={fetchingRadius}
              valueLabelDisplay="auto"
              step={0.5}
              marks
              min={1}
              max={15}
            />
          </Box>
        </Grid>
        <Grid item>
          <Button
            disabled={fetchingData}
            color="primary"
            variant="text"
            onClick={fetchData}
          >
            <RefreshIcon
              style={{ marginRight: theme.spacing(1) }}
              fontSize="small"
            />
            ดึงข้อมูล
          </Button>
        </Grid>
      </Grid>

      {shouldFetchData && (
        <GoogleMapsAPI
          requestList={requestList}
          radius={fetchingRadius * 1000}
          setShouldFetchData={setShouldFetchData}
          isFetched={isFetched}
          setIsFetched={setIsFetched}
          fetchingData={fetchingData}
          setFetchingData={setFetchingData}
        />
      )}
    </Fragment>
  );
}

export default connect(GoogleMapsFetchingWrapper);
