import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import { Box, makeStyles } from '@material-ui/core';
import Axios from 'axios';
import PropertyStateChip from '../PropertyStateChip';

const { Option } = Select;

const propertyStateOptions = selectedValue => [
  {
    value: 'active',
    label: 'Active',
    disabled: selectedValue === 'expired',
  },
  {
    value: 'sold',
    label: 'Sold',
  },
  {
    value: 'passive',
    label: 'Passive',
  },
  {
    value: 'passive_sold',
    label: 'Passive Sold',
  },
  {
    value: 'passive_ads',
    label: 'Passive Ads',
  },
  {
    value: 'pledged',
    label: 'Pledged',
  },
  {
    value: 'suspended',
    label: 'Suspended',
  },
  {
    value: 'expired',
    label: 'Expired',
    disabled: true,
  },
];

const useStyles = makeStyles(theme => ({
  box: {
    padding: '3.4px 5px',
    '&:hover': {
      cursor: 'pointer',
      border: '1px solid #d9d9d9',
      borderRadius: '4px',
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),
      paddingTop: theme.spacing(0.3),
      paddingBottom: theme.spacing(0.3),
    },
  },
}));

const EditableStateChip = ({ propertyState, id }) => {
  const classes = useStyles();
  const [editClicked, setEditClicked] = useState(false);
  const [selectedValue, setSelectedValue] = useState(propertyState);

  const handleChange = value => {
    Axios.patch(`/v1/property_states/${id}`, {
      name: value,
    }).then(response => {
      setSelectedValue(response.data.name);
      setEditClicked(false);
    });
  };

  return (
    <Box width="100px">
      {!editClicked ? (
        <Box
          onClick={() => setEditClicked(!editClicked)}
          className={classes.box}
        >
          <PropertyStateChip propertyState={selectedValue} />
        </Box>
      ) : (
        <Select
          open={editClicked}
          autoFocus
          onBlur={() => setEditClicked(false)}
          style={{ width: 100 }}
          onChange={handleChange}
          value={selectedValue}
          optionLabelProp="label"
        >
          {propertyStateOptions(selectedValue).map(option => (
            <Option
              key={option.value}
              value={option.value}
              label={option.label}
              disabled={option.disabled}
            >
              {option.label}
            </Option>
          ))}
        </Select>
      )}
    </Box>
  );
};

EditableStateChip.propTypes = {
  propertyState: PropTypes.oneOf([
    'active',
    'passive',
    'passive_sold',
    'pending',
    'expired',
    'suspended',
    'pledged',
    'sold',
  ]).isRequired,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default EditableStateChip;
