import React, { useEffect, useState, Fragment } from 'react';
import {
  Container,
  Typography,
  Box,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  makeStyles,
  useTheme,
  IconButton,
} from '@material-ui/core';
import { Edit, Delete } from '@material-ui/icons';
import Axios from 'axios';
import { useLocation } from 'react-router-dom';
import { combineDescription } from 'Utils';
import { connect } from 'react-redux';
import AdsDeleteDialog from './AdsDeleteDialog';

const useStyles = makeStyles(theme => ({
  heading: {
    marginBottom: theme.spacing(2),
    fontWeight: 400,
  },
  adTitle: {
    fontWeight: 400,
  },
}));

const fetchAds = ({ responseCallback, uuid }) => {
  return Axios.get('/v1/properties/show', {
    params: {
      uuid,
      fields: [
        'latLong',
        'owner',
        'nickname',
        'propertyType',
        'baseAdsTitle',
        'baseAdsDetails',
        'baseAdsNearbyPlaces',
        'baseAdsDirections',
        'advertisements',
        'listNumber',
      ],
    },
  }).then(responseCallback);
};

const AdvertisementDetails = ({ user }) => {
  const classes = useStyles();
  const location = useLocation();
  const uuid =
    location.search.includes('uuid') && location.search.replace('?uuid=', '');
  const [advertisements, setAdvertisements] = useState([]);
  const [listNumber, setListNumber] = useState('');
  const [owner, setOwner] = useState({});
  const [latLong, setLatLong] = useState('');
  const [loading, setLoading] = useState(true);
  const theme = useTheme();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const [deletingAd, setDeletingAd] = useState({
    title: '',
    id: '',
  });

  const handleDeleteClick = e => (adTitle, adId) => {
    e.stopPropagation();
    setDeletingAd({ title: adTitle, id: adId });
    setDeleteDialogOpen(true);
  };

  const handleEditClick = e => {
    e.stopPropagation();
  };

  const responseCallback = response => {
    const property = response.data.data.attributes;
    setAdvertisements(property.advertisements.data);
    setListNumber(property.listNumber);
    setOwner(property.owner.data.attributes);
    setLatLong(property.latLong);
    setLoading(false);
  };

  useEffect(() => {
    if (uuid) {
      setLoading(true);
      Axios.get('/v1/properties/show', {
        params: {
          uuid,
          fields: [
            'latLong',
            'owner',
            'nickname',
            'propertyType',
            'baseAdsTitle',
            'baseAdsDetails',
            'baseAdsNearbyPlaces',
            'baseAdsDirections',
            'advertisements',
            'listNumber',
          ],
        },
      }).then(responseCallback);
    }
  }, [uuid]);

  return (
    <Container
      disableGutters
      maxWidth="md"
      style={{ background: 'transparent' }}
    >
      <Typography align="right" className={classes.heading}>
        โฆษณาของทรัพย์เลขที่ {listNumber}
      </Typography>

      {advertisements.map(ad => {
        const advertisement = ad.attributes;
        return (
          <Fragment key={ad.id}>
            <ExpansionPanel key={ad.uuid}>
              <ExpansionPanelSummary>
                <Box
                  width="100%"
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography variant="subtitle2" className={classes.adTitle}>
                    {advertisement.title}
                  </Typography>
                  <Box flexGrow={1} />
                  <IconButton
                    href={`/${
                      user.role
                    }/ads-form?uuid=${uuid}&editMode=true&adsId=${String(
                      ad.id
                    )}`}
                    onClick={e => handleEditClick(e)}
                  >
                    <Edit color="action" fontSize="small" />
                  </IconButton>
                  <Box>
                    <IconButton
                      onClick={e =>
                        handleDeleteClick(e)(advertisement.title, ad.id)
                      }
                    >
                      <Delete color="action" fontSize="small" />
                    </IconButton>
                  </Box>
                </Box>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails
                style={{
                  background: 'hsl(0, 0%, 100%)',
                  whiteSpace: 'pre-wrap',
                }}
              >
                <Box
                  maxHeight="350px"
                  overflow="auto"
                  p={2.5}
                  bgcolor="hsl(0,0%,97%)"
                  width="100%"
                  color={theme.palette.text.secondary}
                >
                  {!loading &&
                    combineDescription({
                      title: advertisement.title,
                      details: advertisement.postDetails,
                      nearbyPlaces: advertisement.postNearbyPlaces,
                      directions: advertisement.postDirections,
                      name: owner.contactInfo.data.attributes.name,
                      phoneNumber:
                        owner.contactInfo.data.attributes.phoneNumber,
                      lineId: owner.contactInfo.data.attributes.lineId,
                      latLong,
                    })}
                </Box>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Fragment>
        );
      })}
      <AdsDeleteDialog
        open={deleteDialogOpen}
        handleClose={() => setDeleteDialogOpen(false)}
        deletingTitle={deletingAd.title}
        deletingId={deletingAd.id}
        fetchAds={() => fetchAds({ responseCallback, uuid })}
      />
    </Container>
  );
};
const mapStateToProps = state => ({
  user: state.user.user,
});

export default connect(mapStateToProps)(AdvertisementDetails);
